import React from 'react'
import { Orders } from '../components'
import { OrdersFilters } from '../components/OrdersFilters'
export const Dashboard: React.FC<any> = () => {
  return (
    <>
      <OrdersFilters />
      <Orders />
    </>
  )
}
