import { Product } from 'depoto-core'
import { PhotoSwipe } from '../../../components/PhotoSwipe'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import { Tag } from '../../../components/Tag'

export function ProductCell({ product }: { product?: Product }) {
  const thumbUrl = product?.mainImage?.thumbnails?.length
    ? product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = product?.mainImage?.thumbnails?.length
    ? product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  return (
    <div className="flex flex-row gap-2 items-center">
      <PhotoSwipe
        alt={''}
        caption={`
                        ${product?.fullName || product?.name ? product?.fullName || product?.name : ''} <br> 
                        ${product?.code ? 'Kód: ' + product?.code : ''} <br> 
                        ${product?.ean ? 'EAN: ' + product?.ean : ''}`}
        galleryImg={imageUrl}
        src={thumbUrl}
        thumbnails={''}
      />
      <div className={'font-semibold'}>
        {product?.fullName}
        <FontAwesomeIcon
          icon={faFile}
          size="lg"
          className={'ml-2 link'}
          onClick={() => window.open(`https://app.depoto.cz/product/${product?.id}/files`)}
        />
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2">
        {(product?.tags || []).map(tag => (
          <div key={tag.id}>
            <Tag tag={tag} />
          </div>
        ))}
      </div>
    </div>
  )
}
