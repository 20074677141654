import { useEffect, useMemo, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useCore } from '../../hooks'
import { chooseEnabledFilter, isNonEmptyValue, removeEmptyProperties, setSearchParams, useSearchParams } from '..'

/**
 * `useOrderFilters` - Custom hook for managing order filtering state and URL synchronization
 *
 * This hook provides a comprehensive solution for handling order filters with the following features:
 *
 * - Synchronizes filter state between URL parameters and application state
 * - Provides debounced search functionality
 * - Handles filter reset and updates
 * - Constructs API-ready filter objects based on current selections
 * - Supports various filter types: carriers, checkouts, countries, depots, tags, etc.
 *
 * The hook manages bidirectional updates:
 * 1. When URL parameters change, the application state is updated
 * 2. When application state changes, URL parameters are updated
 *
 * This ensures consistent filter state across page navigations and browser history.
 *
 * @returns {Object} An object containing:
 *   - Current filter state values
 *   - Filter update functions
 *   - Search handling functions
 *   - Reset function to clear all filters
 */

export function useOrderFilters() {
  // Core state from parent hook
  const {
    core,
    searchType,
    setSearchType,
    searchString,
    setSearchString,
    childCompanyId,
    setSelectedChildCompany,
    selectedCarrierId,
    setSelectedCarrierId,
    selectedCheckoutId,
    setSelectedCheckoutId,
    selectedCountry,
    setSelectedCountry,
    selectedDepotId,
    setSelectedDepotId,
    selectedTagId,
    setSelectedTagId,
    isStrictDepotFilter,
    selectedDate,
    setSelectedDate,
    tags,
  } = useCore()

  // Local state
  const [localSearchString, setLocalSearchString] = useState('')
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  // Router and params
  const history = useHistory()
  const { processStatus, searchString: searchStringParam }: any = useParams()
  const searchParams = useSearchParams()
  const {
    carrierId,
    checkoutId,
    countryId,
    depotId,
    groupId,
    searchType: searchTypeParam,
    tagId,
    date,
    childCompanyId: childCompanyIdParam,
  }: any = searchParams

  // Helper function to get effective values (from URL params or state)
  const getEffectiveValue = (paramValue: any, stateValue: any, defaultValue = 'all') => {
    return paramValue || stateValue || defaultValue
  }

  // URL synchronization function
  const syncFiltersToSearchParams = () => {
    const paramsToUpdate = {
      carrierId: selectedCarrierId === 'all' ? undefined : selectedCarrierId,
      checkoutId: selectedCheckoutId === 'all' ? undefined : selectedCheckoutId,
      countryId: selectedCountry === 'all' ? undefined : selectedCountry,
      depotId: selectedDepotId === 'all' ? undefined : selectedDepotId,
      tagId: selectedTagId,
      searchType: searchType,
      date: selectedDate,
      groupId: groupId,
    }

    setSearchParams(removeEmptyProperties(paramsToUpdate))
  }

  // Generic filter update function
  const createFilterUpdater =
    (setter: (value: any) => void, paramName: string, defaultValue = 'all', transformer = (v: any) => v) =>
    (value: any) => {
      setter(value)
      setSearchParams({
        ...searchParams,
        [paramName]: value === defaultValue ? undefined : transformer(value),
      })
    }

  // Wrapped setters that update both store and search params
  const updateChildCompanyId = createFilterUpdater(setSelectedChildCompany, 'childCompanyId')
  const updateCarrierId = createFilterUpdater(setSelectedCarrierId, 'carrierId')
  const updateCheckoutId = createFilterUpdater(setSelectedCheckoutId, 'checkoutId')
  const updateCountry = createFilterUpdater(setSelectedCountry, 'countryId')
  const updateDepotId = createFilterUpdater(setSelectedDepotId, 'depotId')
  const updateTagId = createFilterUpdater(setSelectedTagId, 'tagId', undefined)
  const updateSearchType = createFilterUpdater(setSearchType, 'searchType', '')
  const updateDate = createFilterUpdater(setSelectedDate, 'date', undefined)

  // Reset all filters to default values
  const resetFilters = () => {
    // Reset store values
    setSelectedChildCompany('all')
    setSelectedCarrierId('all')
    setSelectedCheckoutId('all')
    setSelectedCountry('all')
    setSelectedDepotId('all')
    setSelectedTagId(undefined)
    setSearchType('')
    setSelectedDate(undefined)

    // Clear search params while preserving current route
    const oldLocation = `${window.location.origin}${window.location.pathname}`
    history.replace('', oldLocation)
  }

  // Debounced search string update
  const updateSearchString = (value: string, onFilter?: () => void) => {
    setLocalSearchString(value)

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current)
    }

    searchTimeoutRef.current = setTimeout(() => {
      setSearchString(value)

      if (value && value.length > 0) {
        history.push(`/search/${value}`)
      } else {
        history.push(`/dashboard/${processStatus || 'all'}`)
        syncFiltersToSearchParams()
      }

      if (onFilter) {
        onFilter()
      }

      searchTimeoutRef.current = null
    }, 500)
  }

  // Plaintext filter parameters based on search type and search string
  const plaintextFilterParams = useMemo(() => {
    const PTFEnabled = (type: string) => searchString && searchString?.length >= 0 && searchType === type
    const PTFNumEnabled = (type: string) => searchString && !isNaN(Number(searchString)) && searchType === type
    const RFilters = removeEmptyProperties(
      {
        box: (PTFEnabled('box') || /^(box|pr)-.+/i.test(searchString || '')) && searchString,
        reservationNumber: PTFNumEnabled('reservationNumber') && Number(searchString),
        billNumber: PTFNumEnabled('billNumber') && Number(searchString),
        package: PTFEnabled('package') && searchString,
        customerFulltext: PTFEnabled('customer') && searchString,
      },
      el => el !== false,
    )
    return Object.keys(RFilters).length > 1 ? { fulltext: searchString } : RFilters
  }, [searchString, searchType])

  // Combined filters for API requests
  const filters = useMemo(() => {
    // Get effective values
    const effectiveCarrierId = getEffectiveValue(carrierId, selectedCarrierId)
    const effectiveCheckoutId = getEffectiveValue(checkoutId, selectedCheckoutId)
    const effectiveCountryId = getEffectiveValue(countryId, selectedCountry)
    const effectiveDepotId = getEffectiveValue(depotId, selectedDepotId)
    const effectiveTagId = tagId ? Number(tagId) : selectedTagId
    const effectiveDate = date || selectedDate

    return removeEmptyProperties(
      {
        processStatus: !searchString ? (processStatus === 'all' ? undefined : processStatus) : undefined,
        status: ['recieved', 'received', 'picking', 'packing'].includes(processStatus) ? 'reservation' : undefined,
        carriers: [effectiveCarrierId],
        checkouts: [effectiveCheckoutId && effectiveCheckoutId !== 'all' ? Number(effectiveCheckoutId) : undefined],
        shippingCountries: [effectiveCountryId],
        companies: [childCompanyId],
        group: groupId ? Number(groupId) : undefined,
        tags: [effectiveTagId],
        processStatusChange: effectiveDate && new Date(effectiveDate).toISOString(),
        [isStrictDepotFilter ? 'onlyDepots' : 'depots']: [
          effectiveDepotId && effectiveDepotId !== 'all' ? Number(effectiveDepotId) : undefined,
        ],

        ...plaintextFilterParams,
      },
      val =>
        Array.isArray(val) ? val.every(el => el !== 'all' && el !== undefined) : val !== 'all' && val !== undefined,
    )
  }, [
    carrierId,
    checkoutId,
    countryId,
    depotId,
    tagId,
    date,
    selectedCarrierId,
    selectedCheckoutId,
    selectedCountry,
    selectedDepotId,
    selectedTagId,
    selectedDate,
    childCompanyId,
    groupId,
    isStrictDepotFilter,
    processStatus,
    plaintextFilterParams,
    searchString,
  ])

  // Effect: Initialize local search string when searchString changes
  useEffect(() => {
    setLocalSearchString(searchString || '')
  }, [searchString])

  // Effect: Update search string when URL parameter changes
  useEffect(() => {
    if (searchStringParam) {
      setSearchString(searchStringParam)
    }
  }, [searchStringParam, setSearchString])

  // Effect: Initialize filters from search parameters on component mount
  useEffect(() => {
    // Set filters from URL parameters
    if (childCompanyIdParam && childCompanyIdParam !== 'all') {
      setSelectedChildCompany(childCompanyIdParam)
    }
    if (depotId && depotId !== 'all') {
      setSelectedDepotId(Number(depotId))
    }
    if (checkoutId && checkoutId !== 'all') {
      setSelectedCheckoutId(Number(checkoutId))
    }
    if (carrierId && carrierId !== 'all') {
      setSelectedCarrierId(carrierId)
    }
    if (countryId && countryId !== 'all') {
      setSelectedCountry(countryId)
    }
    if (tagId) {
      setSelectedTagId(Number(tagId))
    }
    if (searchTypeParam) {
      setSearchType(searchTypeParam)
    }
    if (date) {
      setSelectedDate(date)
    }

    // Sync missing parameters
    const needsSync =
      (selectedCarrierId && selectedCarrierId !== 'all' && !carrierId) ||
      (selectedCheckoutId && selectedCheckoutId !== 'all' && !checkoutId) ||
      (selectedCountry && selectedCountry !== 'all' && !countryId) ||
      (selectedDepotId && selectedDepotId !== 'all' && !depotId) ||
      (selectedTagId && !tagId) ||
      (searchType && !searchTypeParam) ||
      (selectedDate && !date)

    if (needsSync) {
      syncFiltersToSearchParams()
    }
  }, []) // Empty dependency array - only run on mount

  // Effect: Validate that the selected tag is of type 'order'
  useEffect(() => {
    const currentTag = tags.find(t => t.id === selectedTagId)
    if (currentTag?.type !== 'order') {
      updateTagId(undefined)
    }
  }, [selectedTagId, tags])

  return {
    // State
    searchType,
    searchString,
    localSearchString,
    selectedChildCompanyId: childCompanyId,
    selectedCarrierId: carrierId || selectedCarrierId,
    selectedCheckoutId: checkoutId ? Number(checkoutId) : selectedCheckoutId,
    selectedCountry: countryId || selectedCountry,
    selectedDepotId: depotId ? Number(depotId) : selectedDepotId,
    selectedTagId: tagId ? Number(tagId) : selectedTagId,
    processStatus,
    filters,
    plaintextFilterParams,
    groupId,

    // Actions
    setSearchType: updateSearchType,
    setSearchString,
    updateSearchString,
    setSelectedChildCompany,
    setSelectedCarrierId: updateCarrierId,
    setSelectedCheckoutId: updateCheckoutId,
    setSelectedCountry: updateCountry,
    setSelectedDepotId: updateDepotId,
    setSelectedTagId: updateTagId,
    resetFilters,
  }
}
