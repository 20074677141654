import clsx from 'clsx'
import * as React from 'react'

export interface CompactSelectProps extends React.ComponentProps<'select'> {
  right?: React.ReactNode
  label?: string
  options?: Array<{ value: string; label: string }>
}

const CompactSelect = React.forwardRef<HTMLSelectElement, CompactSelectProps>(
  ({ className, children, right, label, options, ...props }, ref) => {
    return (
      <div className="relative">
        {label && (
          <label className="absolute z-1 -top-2 left-3 px-1 bg-white text-xs text-shadow-grey font-medium">
            {label}
          </label>
        )}
        <div
          className={clsx(
            'flex items-center justify-center gap-2 flex h-10 rounded-md border border-input bg-background text-base ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            className,
            right && 'pr-2',
          )}
        >
          <select className={clsx('w-full px-3 py-2 bg-transparent outline-none')} ref={ref} {...props}>
            {options
              ? options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))
              : children}
          </select>
          <div className={'text-shadow-grey font-medium self-end mb-1'}>{right}</div>
        </div>
      </div>
    )
  },
)

CompactSelect.displayName = 'CompactSelect'

export { CompactSelect }
