import React from 'react'
import { FormSelect } from './Form/FormSelect'
import { useCore } from '../hooks'
import { useOrderFilters } from '../lib/hooks/useOrderFilters'

export function OrdersFilters(props) {
  const { onFilter } = props
  const { depots, checkouts, carriers, countries, tags, childCompanies } = useCore()

  // Use the new hook for filter management
  const {
    searchType,
    setSearchType,
    localSearchString,
    updateSearchString,
    selectedDepotId,
    setSelectedDepotId,
    selectedCheckoutId,
    setSelectedCheckoutId,
    selectedCarrierId,
    setSelectedCarrierId,
    selectedCountry,
    setSelectedCountry,
    selectedTagId,
    setSelectedTagId,
    selectedChildCompanyId,
    setSelectedChildCompany,
    resetFilters,
  } = useOrderFilters()

  const searchTypes = [
    { value: 'reservationNumber', label: 'Číslo rezervace' },
    { value: 'billNumber', label: 'Číslo objednávky' },
    { value: 'package', label: 'Balík' },
    { value: 'box', label: 'Přepravka' },
    { value: 'customer', label: 'Zákazník' },
    { value: 'fulltext', label: 'Fulltext' },
  ]

  // Transform carriers, depots, etc. into format expected by FormSelect
  const depotOptions = [
    { value: 'all', label: 'Všechny sklady' },
    ...depots.map(depot => ({ value: depot.id, label: depot.name })),
  ]

  const checkoutOptions = [
    { value: 'all', label: 'Všechny obchody' },
    ...checkouts.map(checkout => ({ value: checkout.id, label: checkout.name })),
  ]

  const carrierOptions = [
    { value: 'all', label: 'Všichni dopravci' },
    ...carriers.map(carrier => ({ value: carrier.id, label: carrier.name })),
  ]

  const countryOptions = [
    { value: 'all', label: 'Všechny země' },
    ...countries.map(country => ({ value: country.id, label: country.name })),
  ]

  const filteredTags = tags.filter(tag => tag.type === 'order')
  const tagOptions = [
    { value: 'all', label: 'Všechny štítky' },
    ...filteredTags.map(tag => ({ value: tag.id, label: tag.name })),
  ]

  const childCompanyOptions = [
    { value: 'all', label: 'Všechny firmy' },
    ...childCompanies.map(childCompany => ({ value: childCompany.id, label: childCompany.name })),
  ]

  return (
    <div className="orders-filters bg-white p-4 shadow mb-4">
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-9 gap-2`}>
        <div className="flex">
          <FormSelect
            label="Typ vyhledávání"
            value={searchType}
            options={searchTypes}
            onChange={setSearchType}
            className="mr-2 flex-1"
          />
        </div>
        <div className={`${childCompanies.length > 0 ? '' : 'xl:col-span-2'} form-control flex-1`}>
          <label>Hledat</label>
          <div className="flex">
            <input
              type="text"
              className="input flex-1"
              value={localSearchString}
              onChange={e => updateSearchString(e.target.value, onFilter)}
              placeholder="Zadejte hledaný výraz"
            />
          </div>
        </div>

        <FormSelect
          label="Sklad"
          value={selectedDepotId || 'all'}
          options={depotOptions}
          onChange={value => setSelectedDepotId(value === 'all' ? undefined : value)}
        />

        <FormSelect
          label="Obchod"
          value={selectedCheckoutId || 'all'}
          options={checkoutOptions}
          onChange={value => setSelectedCheckoutId(value === 'all' ? undefined : value)}
        />

        <FormSelect
          label="Dopravce"
          value={selectedCarrierId || 'all'}
          options={carrierOptions}
          onChange={value => setSelectedCarrierId(value === 'all' ? undefined : value)}
        />

        <FormSelect
          label="Země"
          value={selectedCountry || 'all'}
          options={countryOptions}
          onChange={value => setSelectedCountry(value === 'all' ? undefined : value)}
        />

        <FormSelect
          label="Štítek"
          value={selectedTagId || 'all'}
          options={tagOptions}
          onChange={value => setSelectedTagId(value === 'all' ? undefined : Number(value))}
        />

        {childCompanies.length > 0 && (
          <FormSelect
            label="Firma"
            value={selectedChildCompanyId || 'all'}
            options={childCompanyOptions}
            onChange={value => setSelectedChildCompany(value === 'all' ? undefined : value)}
          />
        )}

        <div className="flex items-end">
          <button onClick={resetFilters} className="btn btn-primary flex-1">
            Resetovat filtry
          </button>
        </div>
      </div>
    </div>
  )
}
