import React, { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  id?: string
  children?: React.ReactNode
  className: string
  isDisabled?: boolean
  icon?: any
  isLoading?: boolean
  onClick?: () => void
  title?: string
}

export const Btn = forwardRef<HTMLButtonElement, Props>(
  ({ id, className, children, icon, isLoading, isDisabled, onClick, title }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        id={id}
        onClick={onClick}
        className={isLoading ? className + ' loading ' + 'btn' : className + ' btn gap-1'}
        title={title}
        disabled={isDisabled}
      >
        {!isLoading && icon ? <FontAwesomeIcon icon={icon} size="lg" /> : null}
        {children}
      </button>
    )
  },
)
