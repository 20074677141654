/* eslint-disable */

import { AllTypesProps, Ops, ReturnTypes } from './const'

export const HOST = 'https://server1.depoto.cz/graphql'

export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query)
    const wsString = queryString.replace('http', 'ws')
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString
    const webSocketOptions = options[1]?.websocket || [host]
    const ws = new WebSocket(...webSocketOptions)
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data)
            const data = parsed.data
            return e(data)
          }
        }
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e
      },
      open: (e: () => void) => {
        ws.onopen = e
      },
    }
  } catch {
    throw new Error('No websockets implemented')
  }
}
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then(text => {
          try {
            reject(JSON.parse(text))
          } catch (err) {
            reject(text)
          }
        })
        .catch(reject)
    })
  }
  return response.json() as Promise<GraphQLResponse>
}

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {}
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response)
          }
          return response.data
        })
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response)
        }
        return response.data
      })
  }

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType
  returns: ReturnTypesType
  ops: Operations
  options?: OperationOptions
  scalars?: ScalarDefinition
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k)
    const newPath = [p, keyForPath].join(SEPARATOR)
    if (!o) {
      return ''
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k
    }
    if (typeof o === 'string') {
      return `${k} ${o}`
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath)
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            )
          }
          const operationName = Object.keys(objectUnderAlias)[0]
          const operation = objectUnderAlias[operationName]
          return ibb(`${alias}:${operationName}`, operation, p, false, vars)
        })
        .join('\n')
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : ''
    const keyForDirectives = o.__directives ?? ''
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(e => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`
    }
    const varsString = vars.map(v => `${v.name}: ${v.graphQLType}`).join(', ')
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`
  }
  return ibb
}

type UnionOverrideKeys<T, U> = Omit<T, keyof U> & U

export const Thunder =
  <SCLR extends ScalarDefinition>(fn: FetchFunction, thunderGraphQLOptions?: ThunderGraphQLOptions<SCLR>) =>
  <O extends keyof typeof Ops, OVERRIDESCLR extends SCLR, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<OVERRIDESCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: Z & {
      [P in keyof Z]: P extends keyof ValueTypes[R] ? Z[P] : never
    },
    ops?: OperationOptions & { variables?: Record<string, unknown> },
  ) => {
    const options = {
      ...thunderGraphQLOptions,
      ...graphqlOptions,
    }
    return fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: options?.scalars,
      }),
      ops?.variables,
    ).then(data => {
      if (options?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: options.scalars,
          ops: Ops,
        })
      }
      return data
    }) as Promise<InputType<GraphQLTypes[R], Z, UnionOverrideKeys<SCLR, OVERRIDESCLR>>>
  }

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options))

export const SubscriptionThunder =
  <SCLR extends ScalarDefinition>(fn: SubscriptionFunction, thunderGraphQLOptions?: ThunderGraphQLOptions<SCLR>) =>
  <O extends keyof typeof Ops, OVERRIDESCLR extends SCLR, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<OVERRIDESCLR>,
  ) =>
  <Z extends ValueTypes[R]>(
    o: Z & {
      [P in keyof Z]: P extends keyof ValueTypes[R] ? Z[P] : never
    },
    ops?: OperationOptions & { variables?: ExtractVariables<Z> },
  ) => {
    const options = {
      ...thunderGraphQLOptions,
      ...graphqlOptions,
    }
    type CombinedSCLR = UnionOverrideKeys<SCLR, OVERRIDESCLR>
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: options?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], CombinedSCLR>
    if (returnedFunction?.on && options?.scalars) {
      const wrapped = returnedFunction.on
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, CombinedSCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, CombinedSCLR>) => {
          if (options?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: options.scalars,
                ops: Ops,
              }),
            )
          }
          return fnToCall(data)
        })
    }
    return returnedFunction
  }

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options))
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z,
  ops?: {
    operationOptions?: OperationOptions
    scalars?: ScalarDefinition
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType)

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>()

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>()
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
})

export const ZeusScalars = ZeusSelect<ScalarCoders>()

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O
  response: any
  returns: ReturnTypesType
  scalars?: Record<string, ScalarResolver | undefined>
  initialOp: keyof O
  initialZeusQuery: InputValueType | VType
}) => {
  if (!scalars) {
    return response
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  })

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery)
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]])
    return r
  }
  return response
}

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` }
  resolvers: {
    [x: string]: ScalarResolver | undefined
  }
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map(eachO => ibb(k, eachO, p))
    }
    if (o == null) {
      return o
    }
    const scalarPathString = p.join(SEPARATOR)
    const currentScalarString = scalarPaths[scalarPathString]
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode
      if (currentDecoder) {
        return currentDecoder(o)
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const)
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v
      return a
    }, {})
    return objectFromEntries
  }
  return ibb
}

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined
            }
      }
}

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined
      }
    | `scalar.${string}`
    | undefined
}
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType
}
export type VType = undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType

export type PlainType = boolean | number | string | null | undefined
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType
    }
  | Array<ZeusArgsType>

export type Operations = Record<string, string>

export type VariableDefinition = {
  [x: string]: unknown
}

export const SEPARATOR = '|'

export type fetchOptions = Parameters<typeof fetch>
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]]
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>
export type SubscriptionFunction = (query: string) => any
type NotUndefined<T> = T extends undefined ? never : T
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>

export type OperationOptions = {
  operationName?: string
}

export type ScalarCoder = Record<string, (s: unknown) => string>

export interface GraphQLResponse {
  data?: Record<string, any>
  errors?: Array<{
    message: string
  }>
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('')
    console.error(response)
  }
  toString() {
    return 'GraphQL Response Error'
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? (typeof Ops)[O] : never
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders
}

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return
  }
  const oKey = mappedParts[0]
  const returnP1 = returns[oKey]
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]]
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns)
    }
    return undefined
  }
  return returnP1 as `scalar.${string}` | undefined
}

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey]
      const isScalar = ExtractScalar(extractionArray, returns)
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        }
        return partOfTree
      }
      return {}
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false)
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            )
          }
          const operationName = Object.keys(objectUnderAlias)[0]
          const operation = objectUnderAlias[operationName]
          return ibb(alias, operationName, operation, p, pOriginals, false)
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }))
    }
    const keyName = root ? ops[k] : k
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        )
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }))
  }
  return ibb
}

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '')

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>')
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const
}

type Part = ReturnType<typeof mapPart>

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v]
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v]
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum'
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not'
      }
      const propsP2 = propsP1[mappedParts[1].v]
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map(mp => mp.v)
            .join(SEPARATOR)}`,
        )
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not'
        }
        const propsP3 = propsP2[mappedParts[2].v]
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map(mp => mp.v)
              .join(SEPARATOR)}`,
          )
        }
      }
    }
  }
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not'
    }
    const oKey = ops[mappedParts[0].v]
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v]
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not'
      const returnP2 = returnP1[mappedParts[1].v]
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map(mp => mp.v)
            .join(SEPARATOR)}`,
        )
      }
    }
  }
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter(l => l.length > 0)
    const mappedParts = parts.map(mapPart)
    const propsP1 = ResolvePropsType(mappedParts)
    if (propsP1) {
      return propsP1
    }
    const returnP1 = ResolveReturnType(mappedParts)
    if (returnP1) {
      return returnP1
    }
    return 'not'
  }
  return rpp
}

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType
  returns: ReturnTypesType
  ops: Operations
  scalars?: ScalarDefinition
  vars: Array<{ name: string; graphQLType: string }>
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR)
        const v = vars.find(v => v.name === varName)
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          })
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            )
          }
        }
        return varName
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p)
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.')
      const scalarKey = splittedScalar.join('.')
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a)
    }
    if (Array.isArray(a)) {
      return `[${a.map(arr => arb(arr, p, false)).join(', ')}]`
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a
      }
      return `${JSON.stringify(a)}`
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n')
      if (!root) {
        return `{${returnedObjectString}}`
      }
      return returnedObjectString
    }
    return `${a}`
  }
  return arb
}

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>

export type WithTypeNameValue<T> = T & {
  __typename?: boolean
  __directives?: string
}
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>
}
type DeepAnify<T> = {
  [P in keyof T]?: any
}
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T
export type ScalarDefinition = Record<string, ScalarResolver>

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>
type FlattenArray<T> = T extends Array<infer R> ? R : T
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : Record<string, never>, SCLR>
        : never
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>
    }

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>]
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void
  open: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>

export type ScalarResolver = {
  encode?: (s: unknown) => string
  decode?: (s: unknown) => unknown
}

export type SelectionFunction<V> = <Z extends V>(
  t: Z & {
    [P in keyof Z]: P extends keyof V ? Z[P] : never
  },
) => Z

type BuiltInVariableTypes = {
  ['String']: string
  ['Int']: number
  ['Float']: number
  ['ID']: unknown
  ['Boolean']: boolean
}
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`
type VR<T extends string> = VariableRequired<VariableRequired<T>>

export type GraphQLVariableType = VR<AllVariableTypes>

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K
}[keyof T]

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>

type OptionalKeys<T> = {
  [P in keyof T]?: T[P]
}

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name
  ' __zeus_type': T
}

export type ExtractVariablesDeep<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariablesDeep<Query[K]>> }[keyof Query]>

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariablesDeep<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean | Array<string | number | boolean>
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export const START_VAR_NAME = `$ZEUS_VAR`
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>
}
type ZEUS_INTERFACES = never
export type ScalarCoders = {
  DateTimeAsString?: ScalarResolver
  DateTime?: ScalarResolver
  StringOrArray?: ScalarResolver
}
type ZEUS_UNIONS = never

export type ValueTypes = {
  ['RootSchemaQuery']: AliasType<{
    user?: [{ id: number | Variable<any, string> }, ValueTypes['user']]
    userMyProfile?: ValueTypes['userMyProfile']
    users?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['userFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['users'],
    ]
    isUserEmailUnique?: [{ email: string | Variable<any, string> }, ValueTypes['isUserEmailUnique']]
    userGroup?: [{ id: number | Variable<any, string> }, ValueTypes['userGroup']]
    userGroups?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['userGroupFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['userGroups'],
    ]
    roles?: ValueTypes['Role']
    product?: [{ id: number | Variable<any, string> }, ValueTypes['product']]
    products?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productFilters'] | undefined | null | Variable<any, string>
        currency?: string | undefined | null | Variable<any, string>
        currencyDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
      },
      ValueTypes['products'],
    ]
    productPurchasePrice?: [
      {
        id: number | Variable<any, string>
        depot: number | Variable<any, string>
        quantity: number | Variable<any, string>
      },
      ValueTypes['productPurchasePrice'],
    ]
    productSellItems?: [
      {
        product: number | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['productSellItems'],
    ]
    productMovePacks?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productMovePackFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productMovePacks'],
    ]
    productMoves?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productMoveFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productMoves'],
    ]
    productMovePack?: [{ id: number | Variable<any, string> }, ValueTypes['productMovePack']]
    institution?: [{ id: number | Variable<any, string> }, ValueTypes['institution']]
    institutions?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['institutionFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['institutions'],
    ]
    order?: [{ id: number | Variable<any, string> }, ValueTypes['order']]
    orders?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['orderFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['orders'],
    ]
    soldItems?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['soldItemFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['soldItems'],
    ]
    orderItems?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['orderItemFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['orderItems'],
    ]
    orderItem?: [{ id: number | Variable<any, string> }, ValueTypes['orderItem']]
    customer?: [{ id: string | Variable<any, string> }, ValueTypes['customer']]
    customers?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['customerFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['customers'],
    ]
    address?: [{ id: number | Variable<any, string> }, ValueTypes['address']]
    addresses?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['addressesFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['addresses'],
    ]
    checkout?: [{ id: number | Variable<any, string> }, ValueTypes['checkout']]
    checkouts?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['checkoutFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['checkouts'],
    ]
    eventTypes?: ValueTypes['eventTypes']
    consent?: [{ id: number | Variable<any, string> }, ValueTypes['consent']]
    consents?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['consentsFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['consents'],
    ]
    checkoutExtraOperation?: [{ id: number | Variable<any, string> }, ValueTypes['checkoutExtraOperation']]
    checkoutExtraOperations?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['checkoutExtraOperationFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['checkoutExtraOperations'],
    ]
    checkoutClosing?: [{ id: number | Variable<any, string> }, ValueTypes['checkoutClosing']]
    checkoutClosings?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['checkoutClosingFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['checkoutClosings'],
    ]
    vat?: [{ id: number | Variable<any, string> }, ValueTypes['vat']]
    vats?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['vatFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['vats'],
    ]
    depot?: [{ id: number | Variable<any, string> }, ValueTypes['depot']]
    depots?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['depotFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['depots'],
    ]
    supplier?: [{ id: number | Variable<any, string> }, ValueTypes['supplier']]
    suppliers?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['supplierFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['suppliers'],
    ]
    producer?: [{ id: number | Variable<any, string> }, ValueTypes['producer']]
    producers?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['producerFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['producers'],
    ]
    exportStocks?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportStockFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exportStocks'],
    ]
    exportSales?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportSaleFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exportSales'],
    ]
    exportPurchases?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportPurchaseFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exportPurchases'],
    ]
    exportOrders?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportOrderFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exportOrders'],
    ]
    exportInventory?: [{ id: number | Variable<any, string> }, ValueTypes['exportInventory']]
    exportInventories?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportInventoryFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exportInventories'],
    ]
    export?: [{ id: number | Variable<any, string> }, ValueTypes['export']]
    exports?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exports'],
    ]
    exportTypes?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['exportTypeFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['exportTypes'],
    ]
    shop?: [{ id: number | Variable<any, string> }, ValueTypes['shop']]
    shops?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['shopFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['shops'],
    ]
    cert?: [{ id: number | Variable<any, string> }, ValueTypes['cert']]
    certs?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['filters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['certs'],
    ]
    eetReceipts?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['eetReceiptFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['eetReceipts'],
    ]
    payment?: [{ id: number | Variable<any, string> }, ValueTypes['payment']]
    payments?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['paymentFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['payments'],
    ]
    paymentItem?: [{ id: number | Variable<any, string> }, ValueTypes['paymentItem']]
    paymentItems?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['paymentItemsFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['paymentItems'],
    ]
    paymentTypes?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['paymentTypeFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['paymentTypes'],
    ]
    processStatuses?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['processStatusesFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['processStatuses'],
    ]
    carriers?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['carriersFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['carriers'],
    ]
    currencies?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['currenciesFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['currencies'],
    ]
    package?: [{ id: string | Variable<any, string> }, ValueTypes['package']]
    packages?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['packageFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['packages'],
    ]
    stats?: [
      { filters?: ValueTypes['packageStatsFilters'] | undefined | null | Variable<any, string> },
      ValueTypes['packageStats'],
    ]
    disposal?: [{ id: string | Variable<any, string> }, ValueTypes['disposal']]
    disposals?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['disposalFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['disposals'],
    ]
    productDepotMetric?: [{ id: number | Variable<any, string> }, ValueTypes['productDepotMetric']]
    productDepotMetrics?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productDepotMetricFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productDepotMetrics'],
    ]
    file?: [{ id: number | Variable<any, string> }, ValueTypes['file']]
    files?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['fileFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['files'],
    ]
    nextEan?: ValueTypes['nextEan']
    statsCarrierUsage?: [
      {
        from: ValueTypes['DateTimeAsString'] | Variable<any, string>
        to: ValueTypes['DateTimeAsString'] | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        status?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['statsCarrierUsage'],
    ]
    statsPaymentSales?: [
      {
        from: ValueTypes['DateTimeAsString'] | Variable<any, string>
        to: ValueTypes['DateTimeAsString'] | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        status?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['statsPaymentSales'],
    ]
    statsBestsellers?: [
      {
        from: ValueTypes['DateTimeAsString'] | Variable<any, string>
        to: ValueTypes['DateTimeAsString'] | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        status?: string | undefined | null | Variable<any, string>
        results?: number | undefined | null | Variable<any, string>
        sort?: ValueTypes['statsBestsellersSort'] | undefined | null | Variable<any, string>
      },
      ValueTypes['statsBestsellers'],
    ]
    companyCarrier?: [{ id: string | Variable<any, string> }, ValueTypes['companyCarrier']]
    companyCarriers?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['companyCarrierFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['companyCarriers'],
    ]
    category?: [{ id: number | Variable<any, string> }, ValueTypes['category']]
    categories?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['categoryFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['categories'],
    ]
    parameter?: [{ id: number | Variable<any, string> }, ValueTypes['parameter']]
    parameters?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['parametersFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['parameters'],
    ]
    orderGroup?: [{ id: number | Variable<any, string> }, ValueTypes['orderGroup']]
    orderGroups?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['orderGroupFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['orderGroups'],
    ]
    voucher?: [{ id: number | Variable<any, string> }, ValueTypes['voucher']]
    vouchers?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['voucherFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['vouchers'],
    ]
    productPrices?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productPriceFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productPrices'],
    ]
    priceLevel?: [{ id: number | Variable<any, string> }, ValueTypes['priceLevel']]
    priceLevels?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['priceLevelFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['priceLevels'],
    ]
    productParameter?: [{ id: number | Variable<any, string> }, ValueTypes['productParameter']]
    productParameters?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productParameterFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productParameters'],
    ]
    productPriceLevel?: [{ id: number | Variable<any, string> }, ValueTypes['productPriceLevel']]
    productPriceLevels?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productPriceLevelFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productPriceLevels'],
    ]
    tag?: [{ id: number | Variable<any, string> }, ValueTypes['tag']]
    tags?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['tagFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['tags'],
    ]
    productPack?: [{ id: number | Variable<any, string> }, ValueTypes['productPack']]
    productPacks?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['productPackFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['productPacks'],
    ]
    purchaseOrder?: [{ id: number | Variable<any, string> }, ValueTypes['purchaseOrder']]
    purchaseOrders?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['purchaseOrderFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['purchaseOrders'],
    ]
    purchaseOrderItem?: [{ id: number | Variable<any, string> }, ValueTypes['purchaseOrderItem']]
    modules?: [
      {
        page?: number | undefined | null | Variable<any, string>
        sort?: string | undefined | null | Variable<any, string>
        direction?: string | undefined | null | Variable<any, string>
        filters?: ValueTypes['modulesFilters'] | undefined | null | Variable<any, string>
      },
      ValueTypes['modules'],
    ]
    __typename?: boolean | `@${string}`
  }>
  ['user']: AliasType<{
    data?: ValueTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['User']: AliasType<{
    id?: boolean | `@${string}`
    email?: boolean | `@${string}`
    name?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    groups?: ValueTypes['UserGroup']
    roles?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    company?: ValueTypes['Company']
    pin?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    customers?: ValueTypes['Customer']
    checkouts?: ValueTypes['Checkout']
    depots?: ValueTypes['Depot']
    locale?: boolean | `@${string}`
    deletable?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserGroup']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    roles?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Company']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    ic?: boolean | `@${string}`
    dic?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    email?: boolean | `@${string}`
    street?: boolean | `@${string}`
    city?: boolean | `@${string}`
    zip?: boolean | `@${string}`
    country?: boolean | `@${string}`
    currency?: ValueTypes['Currency']
    registrationNote?: boolean | `@${string}`
    nextEan?: boolean | `@${string}`
    billLogo?: boolean | `@${string}`
    parent?: ValueTypes['Company']
    children?: ValueTypes['Company']
    carrierRelations?: ValueTypes['CompanyCarrier']
    moduleRelations?: ValueTypes['CompanyModule']
    payments?: ValueTypes['Payment']
    defaultVoucherValidity?: boolean | `@${string}`
    pickingOnlyOneDepot?: boolean | `@${string}`
    customOptions?: boolean | `@${string}`
    batchExpirationMode?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    purchasePriceWithVat?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Currency']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    ratio?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CompanyCarrier']: AliasType<{
    id?: boolean | `@${string}`
    enable?: boolean | `@${string}`
    options?: boolean | `@${string}`
    carrier?: ValueTypes['Carrier']
    checkout?: ValueTypes['Checkout']
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Carrier']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    color?: boolean | `@${string}`
    position?: boolean | `@${string}`
    tariffs?: ValueTypes['CarrierTariff']
    requiredShippingAddress?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CarrierTariff']: AliasType<{
    id?: boolean | `@${string}`
    const?: boolean | `@${string}`
    name?: boolean | `@${string}`
    cod?: boolean | `@${string}`
    position?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Checkout']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    amount?: boolean | `@${string}`
    nextReservationNumber?: boolean | `@${string}`
    nextBillNumber?: boolean | `@${string}`
    billFooter?: boolean | `@${string}`
    payments?: ValueTypes['Payment']
    depots?: ValueTypes['Depot']
    returnsDepot?: ValueTypes['Depot']
    currentClosing?: ValueTypes['CheckoutClosing']
    eetId?: boolean | `@${string}`
    eetEnable?: boolean | `@${string}`
    eetPlayground?: boolean | `@${string}`
    eetVerificationMode?: boolean | `@${string}`
    billWidth?: boolean | `@${string}`
    billFontSize?: boolean | `@${string}`
    billFontWeight?: boolean | `@${string}`
    eventUrl?: boolean | `@${string}`
    eventTypes?: boolean | `@${string}`
    company?: ValueTypes['Company']
    negativeReservation?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    users?: ValueTypes['User']
    autoPicking?: boolean | `@${string}`
    autoPacking?: boolean | `@${string}`
    autoDispatched?: boolean | `@${string}`
    institution?: ValueTypes['Institution']
    position?: boolean | `@${string}`
    deletable?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Payment']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    eetEnable?: boolean | `@${string}`
    /** Expedovat i nezaplacené objednávky */
    dispatchUnpaid?: boolean | `@${string}`
    type?: ValueTypes['PaymentType']
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['PaymentType']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Depot']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    emailIn?: boolean | `@${string}`
    unavailablesUrl?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    companyCarriers?: ValueTypes['CompanyCarrier']
    checkouts?: ValueTypes['Checkout']
    institution?: ValueTypes['Institution']
    users?: ValueTypes['User']
    productDepots?: ValueTypes['ProductDepot']
    position?: boolean | `@${string}`
    deletable?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Institution']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    checkouts?: ValueTypes['Checkout']
    depots?: ValueTypes['Depot']
    __typename?: boolean | `@${string}`
  }>
  ['ProductDepot']: AliasType<{
    id?: boolean | `@${string}`
    depot?: ValueTypes['Depot']
    product?: ValueTypes['Product']
    supplier?: ValueTypes['Supplier']
    purchasePrice?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityAvailable?: boolean | `@${string}`
    quantityUnavailable?: boolean | `@${string}`
    inventoryQuantityStock?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    position?: boolean | `@${string}`
    position1?: boolean | `@${string}`
    position2?: boolean | `@${string}`
    position3?: boolean | `@${string}`
    clearanceItemId?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Product']: AliasType<{
    id?: boolean | `@${string}`
    /** Název */
    name?: boolean | `@${string}`
    /** Název včetně názvu rodičovského produktu */
    fullName?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean2?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean3?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean4?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean5?: boolean | `@${string}`
    /** Kód */
    code?: boolean | `@${string}`
    /** Kód2 */
    code2?: boolean | `@${string}`
    /** Kód3 */
    code3?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Prodejní cena */
    sellPrice?: boolean | `@${string}`
    /** Prodejní cena bez DPH */
    sellPriceWithoutVat?: boolean | `@${string}`
    /** Cena před slevou */
    beforeSellPrice?: boolean | `@${string}`
    /** Aktuální prodejní cena - včetně započtených akčních cen */
    actualSellPrice?: boolean | `@${string}`
    /** Aktuální prodejní cena bez DPH */
    actualSellPriceWithoutVat?: boolean | `@${string}`
    /** Aktuální cena před slevou - včetně započtených akčních cen */
    actualBeforeSellPrice?: boolean | `@${string}`
    /** Výchozí nákupní cena */
    purchasePrice?: boolean | `@${string}`
    /** Výchozí nákupní měna */
    purchaseCurrency?: ValueTypes['Currency']
    /** Váha */
    weight?: boolean | `@${string}`
    /** Rozměr X */
    dimensionX?: boolean | `@${string}`
    /** Rozměr Y */
    dimensionY?: boolean | `@${string}`
    /** Rozměr Z */
    dimensionZ?: boolean | `@${string}`
    /** Země výroby */
    originCountry?: boolean | `@${string}`
    /** HS kod */
    hsCode?: boolean | `@${string}`
    /** Je produkt aktnivní? */
    enabled?: boolean | `@${string}`
    /** DPH */
    vat?: ValueTypes['Vat']
    /** Výrobce */
    producer?: ValueTypes['Producer']
    /** Výchozí dodavatel */
    supplier?: ValueTypes['Supplier']
    /** Rodičovský produkt */
    parent?: ValueTypes['Product']
    /** Potomci / varianty produktu */
    children?: ValueTypes['Product']
    /** Deprecated: používejte productDepots */
    depots?: ValueTypes['ProductDepot']
    /** Skladové zásoby */
    productDepots?: ValueTypes['ProductDepot']
    /** Produkt je položka pro následné uplatnění (např. kupón) - pro EET */
    isForSubsequentSettlement?: boolean | `@${string}`
    /** Hlavní obrázek */
    mainImage?: ValueTypes['File']
    /** Obrázky */
    images?: ValueTypes['File']
    /** Soubory (mimo obrázků) */
    files?: ValueTypes['File']
    /** Jedná se o set produktů (virtuální produkt) */
    isBundle?: boolean | `@${string}`
    /** Křehký */
    isFragile?: boolean | `@${string}`
    /** Nadrozměrný */
    isOversize?: boolean | `@${string}`
    bundleParents?: ValueTypes['ProductBundle']
    bundleChildren?: ValueTypes['ProductBundle']
    /** Poznámka */
    note?: boolean | `@${string}`
    /** Popis */
    description?: boolean | `@${string}`
    /** Cokoli... */
    customData?: boolean | `@${string}`
    /** Akční ceny */
    advancedPrices?: ValueTypes['ProductPrice']
    /** Počet nedostupných kusů v rezervaci */
    quantityUnavailable?: boolean | `@${string}`
    /** Počet kusů skladem a všech skladech */
    quantityStock?: boolean | `@${string}`
    /** Počet dostupných kusů a všech skladech */
    quantityAvailable?: boolean | `@${string}`
    /** Počet kusů v rezervaci na všech skladech */
    quantityReservation?: boolean | `@${string}`
    /** Počet kusů objednaný od dodavatele */
    quantityPurchase?: boolean | `@${string}`
    /** Kategorie */
    categories?: ValueTypes['Category']
    /** Hlavní kategorie */
    mainCategory?: ValueTypes['Category']
    company?: ValueTypes['Company']
    /** Počty */
    quantities?: ValueTypes['Quantity']
    /** Metriky */
    metrics?: ValueTypes['ProductDepotMetric']
    /** Balení/Kartony */
    packs?: ValueTypes['ProductPack']
    /** Relace ProductParameter */
    productParameters?: ValueTypes['ProductParameter']
    /** Relace ProductPriceLevel */
    productPriceLevels?: ValueTypes['ProductPriceLevel']
    /** Tagy */
    tags?: ValueTypes['Tag']
    /** Položky k prodeji: jednotlivé expirace a šarže */
    sellItems?: ValueTypes['SellItem']
    /** Deprectable - use deletable. */
    isDeletable?: boolean | `@${string}`
    /** Lze smazat */
    deletable?: boolean | `@${string}`
    /** Eviduje expirace a šarže */
    enabledBatchExpiration?: boolean | `@${string}`
    /** Eviduje sériová čísla */
    requiredSerialNumbers?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Vat']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    percent?: boolean | `@${string}`
    default?: boolean | `@${string}`
    coefficient?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  /** Representation of date and time in same string format as PHP DateTime class. */
  ['DateTimeAsString']: unknown
  ['Producer']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Supplier']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    deletable?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['File']: AliasType<{
    id?: boolean | `@${string}`
    originalFilename?: boolean | `@${string}`
    text?: boolean | `@${string}`
    main?: boolean | `@${string}`
    mimeType?: boolean | `@${string}`
    size?: boolean | `@${string}`
    url?: boolean | `@${string}`
    product?: ValueTypes['Product']
    order?: ValueTypes['Order']
    thumbnails?: ValueTypes['FileThumbnail']
    __typename?: boolean | `@${string}`
  }>
  ['Order']: AliasType<{
    id?: boolean | `@${string}`
    company?: ValueTypes['Company']
    status?: ValueTypes['OrderStatus']
    reservationNumber?: boolean | `@${string}`
    billNumber?: boolean | `@${string}`
    vs?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    dateBill?: boolean | `@${string}`
    dateExpedition?: boolean | `@${string}`
    dateDue?: boolean | `@${string}`
    dateTax?: boolean | `@${string}`
    items?: ValueTypes['OrderItem']
    paymentItems?: ValueTypes['PaymentItem']
    checkout?: ValueTypes['Checkout']
    checkoutClosing?: ValueTypes['CheckoutClosing']
    customer?: ValueTypes['Customer']
    note?: boolean | `@${string}`
    privateNote?: boolean | `@${string}`
    invoiceAddress?: ValueTypes['Address']
    shippingAddress?: ValueTypes['Address']
    relatedParent?: ValueTypes['Order']
    rounding?: boolean | `@${string}`
    eetReceipt?: ValueTypes['Receipt']
    deleted?: boolean | `@${string}`
    priceAll?: boolean | `@${string}`
    priceAllWithoutVat?: boolean | `@${string}`
    profit?: boolean | `@${string}`
    vatAllocations?: ValueTypes['VatAllocation']
    /** DEPRECATED - Url PDF účtenky */
    billUrl?: boolean | `@${string}`
    /** Url PDF faktury */
    invoiceUrl?: boolean | `@${string}`
    /** Url PDF objednávky */
    reservationUrl?: boolean | `@${string}`
    /** Url PDF účtenky */
    invoiceReceiptUrl?: boolean | `@${string}`
    /** Url PDF účtenky-rezervace */
    reservationReceiptUrl?: boolean | `@${string}`
    processStatus?: ValueTypes['ProcessStatus']
    processStatusUpdated?: boolean | `@${string}`
    processStatusRelation?: ValueTypes['OrderProcessStatus']
    processStatusRelations?: ValueTypes['OrderProcessStatus']
    carrier?: ValueTypes['Carrier']
    packages?: ValueTypes['Package']
    boxes?: boolean | `@${string}`
    isPaid?: boolean | `@${string}`
    currency?: ValueTypes['Currency']
    files?: ValueTypes['File']
    quantityUnavailable?: boolean | `@${string}`
    createdBy?: ValueTypes['User']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    movePacks?: ValueTypes['ProductMovePack']
    depots?: ValueTypes['Depot']
    isEditable?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Externí číslo */
    externalReference?: boolean | `@${string}`
    group?: ValueTypes['OrderGroup']
    groupPosition?: boolean | `@${string}`
    clearanceItems?: ValueTypes['ClearanceItem']
    /** Tagy */
    tags?: ValueTypes['Tag']
    /** Priorita */
    priority?: boolean | `@${string}`
    /** Uzamčena */
    locked?: boolean | `@${string}`
    /** Od kdy uzamčena */
    dateLocked?: boolean | `@${string}`
    /** Kým uzamčena */
    lockedBy?: ValueTypes['User']
    __typename?: boolean | `@${string}`
  }>
  ['OrderStatus']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  /** Representation of date and time in "Y-m-d H:i:s" format */
  ['DateTime']: unknown
  ['OrderItem']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    ean?: boolean | `@${string}`
    code?: boolean | `@${string}`
    quantity?: boolean | `@${string}`
    sale?: boolean | `@${string}`
    price?: boolean | `@${string}`
    priceWithoutVat?: boolean | `@${string}`
    priceAll?: boolean | `@${string}`
    priceAllWithoutVat?: boolean | `@${string}`
    vat?: ValueTypes['Vat']
    product?: ValueTypes['Product']
    serial?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    note?: boolean | `@${string}`
    profit?: boolean | `@${string}`
    isForSubsequentSettlement?: boolean | `@${string}`
    picked?: boolean | `@${string}`
    packed?: boolean | `@${string}`
    quantityUnavailable?: boolean | `@${string}`
    moves?: ValueTypes['ProductMove']
    clearanceItems?: ValueTypes['ClearanceItem']
    voucher?: ValueTypes['Voucher']
    order?: ValueTypes['Order']
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMove']: AliasType<{
    id?: boolean | `@${string}`
    pack?: ValueTypes['ProductMovePack']
    productDepotFrom?: ValueTypes['ProductDepot']
    productDepotFromQuantityStock?: boolean | `@${string}`
    productDepotTo?: ValueTypes['ProductDepot']
    productDepotToQuantityStock?: boolean | `@${string}`
    product?: ValueTypes['Product']
    amount?: boolean | `@${string}`
    note?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    deleted?: boolean | `@${string}`
    quantityUnavailable?: boolean | `@${string}`
    createdBy?: ValueTypes['User']
    orderItem?: ValueTypes['OrderItem']
    picked?: boolean | `@${string}`
    packed?: boolean | `@${string}`
    locked?: boolean | `@${string}`
    purchasePrice?: boolean | `@${string}`
    purchasePriceAll?: boolean | `@${string}`
    purchasePriceWithVat?: boolean | `@${string}`
    purchasePriceAllWithVat?: boolean | `@${string}`
    purchaseOrderItem?: ValueTypes['PurchaseOrderItem']
    deletable?: boolean | `@${string}`
    serialNumbers?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMovePack']: AliasType<{
    id?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    externalReference?: boolean | `@${string}`
    number?: boolean | `@${string}`
    type?: ValueTypes['ProductMoveType']
    purpose?: ValueTypes['ProductMovePurpose']
    moves?: ValueTypes['ProductMove']
    note?: boolean | `@${string}`
    url?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    order?: ValueTypes['Order']
    checkout?: ValueTypes['Checkout']
    depotFrom?: ValueTypes['Depot']
    depotTo?: ValueTypes['Depot']
    purchasePrice?: boolean | `@${string}`
    purchasePriceWithVat?: boolean | `@${string}`
    purchaseOrder?: ValueTypes['PurchaseOrder']
    createdBy?: ValueTypes['User']
    files?: ValueTypes['File']
    company?: ValueTypes['Company']
    deletable?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMoveType']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMovePurpose']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseOrder']: AliasType<{
    id?: boolean | `@${string}`
    company?: ValueTypes['Company']
    status?: ValueTypes['PurchaseOrderStatus']
    supplier?: ValueTypes['Supplier']
    depot?: ValueTypes['Depot']
    items?: ValueTypes['PurchaseOrderItem']
    files?: ValueTypes['File']
    /** Tagy */
    tags?: ValueTypes['Tag']
    number?: boolean | `@${string}`
    note?: boolean | `@${string}`
    privateNote?: boolean | `@${string}`
    dateEstimatedArrival?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Externí reference */
    externalReference?: boolean | `@${string}`
    /** Reference dodavatele */
    supplierReference?: boolean | `@${string}`
    purchasePriceAll?: boolean | `@${string}`
    productMovePacks?: ValueTypes['ProductMovePack']
    createdBy?: ValueTypes['User']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseOrderStatus']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseOrderItem']: AliasType<{
    id?: boolean | `@${string}`
    purchaseOrder?: ValueTypes['PurchaseOrder']
    currency?: ValueTypes['Currency']
    product?: ValueTypes['Product']
    quantity?: boolean | `@${string}`
    purchasePrice?: boolean | `@${string}`
    purchasePriceAll?: boolean | `@${string}`
    productMoves?: ValueTypes['ProductMove']
    note?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Již naskladněný počet kusů */
    quantityIn?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Tag']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    color?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ClearanceItem']: AliasType<{
    id?: boolean | `@${string}`
    product?: ValueTypes['Product']
    amount?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityAvailable?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    position?: boolean | `@${string}`
    position1?: boolean | `@${string}`
    position2?: boolean | `@${string}`
    position3?: boolean | `@${string}`
    picked?: boolean | `@${string}`
    packed?: boolean | `@${string}`
    productMoves?: ValueTypes['ProductMove']
    productDepots?: ValueTypes['ProductDepot']
    orderItems?: ValueTypes['OrderItem']
    locked?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Voucher']: AliasType<{
    id?: boolean | `@${string}`
    company?: ValueTypes['Company']
    product?: ValueTypes['Product']
    name?: boolean | `@${string}`
    code?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    discountType?: boolean | `@${string}`
    discountPercent?: boolean | `@${string}`
    discountValue?: boolean | `@${string}`
    maxUse?: boolean | `@${string}`
    validFrom?: boolean | `@${string}`
    validTo?: boolean | `@${string}`
    isValid?: boolean | `@${string}`
    used?: boolean | `@${string}`
    paymentItems?: ValueTypes['Product']
    orderItems?: ValueTypes['Product']
    enabled?: boolean | `@${string}`
    isPayment?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PaymentItem']: AliasType<{
    id?: boolean | `@${string}`
    amount?: boolean | `@${string}`
    checkout?: ValueTypes['Checkout']
    payment?: ValueTypes['Payment']
    voucher?: ValueTypes['Voucher']
    dateCreated?: boolean | `@${string}`
    dateCancelled?: boolean | `@${string}`
    isPaid?: boolean | `@${string}`
    datePaid?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CheckoutClosing']: AliasType<{
    id?: boolean | `@${string}`
    company?: ValueTypes['Company']
    checkout?: ValueTypes['Checkout']
    dateOpen?: boolean | `@${string}`
    amountOpen?: boolean | `@${string}`
    amountRealOpen?: boolean | `@${string}`
    userOpen?: ValueTypes['User']
    noteOpen?: boolean | `@${string}`
    dateClosed?: boolean | `@${string}`
    amountClosed?: boolean | `@${string}`
    amountRealClosed?: boolean | `@${string}`
    userClosed?: ValueTypes['User']
    noteClosed?: boolean | `@${string}`
    orders?: ValueTypes['Order']
    profit?: boolean | `@${string}`
    vatAllocations?: ValueTypes['VatAllocation']
    extraOperations?: ValueTypes['CheckoutExtraOperation']
    billUrl?: boolean | `@${string}`
    enumeration?: boolean | `@${string}`
    paymentSum?: ValueTypes['paymentSum']
    __typename?: boolean | `@${string}`
  }>
  ['VatAllocation']: AliasType<{
    vat?: ValueTypes['Vat']
    priceWithoutVat?: boolean | `@${string}`
    vatPrice?: boolean | `@${string}`
    price?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CheckoutExtraOperation']: AliasType<{
    id?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    amount?: boolean | `@${string}`
    note?: boolean | `@${string}`
    user?: ValueTypes['User']
    checkout?: ValueTypes['Checkout']
    checkoutClosing?: ValueTypes['CheckoutClosing']
    __typename?: boolean | `@${string}`
  }>
  ['paymentSum']: AliasType<{
    payment?: ValueTypes['Payment']
    count?: boolean | `@${string}`
    sum?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Customer']: AliasType<{
    id?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    email?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    name?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    companyName?: boolean | `@${string}`
    addresses?: ValueTypes['Address']
    wholesale?: boolean | `@${string}`
    note?: boolean | `@${string}`
    consentRelations?: ValueTypes['CustomerConsent']
    birthday?: boolean | `@${string}`
    users?: ValueTypes['User']
    minExpirationDays?: boolean | `@${string}`
    priceLevel?: ValueTypes['PriceLevel']
    /** Tagy */
    tags?: ValueTypes['Tag']
    /** Cokoli... */
    customData?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Address']: AliasType<{
    id?: boolean | `@${string}`
    company?: ValueTypes['Company']
    customer?: ValueTypes['Customer']
    companyName?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    street?: boolean | `@${string}`
    city?: boolean | `@${string}`
    country?: boolean | `@${string}`
    state?: boolean | `@${string}`
    zip?: boolean | `@${string}`
    ic?: boolean | `@${string}`
    dic?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    email?: boolean | `@${string}`
    isStored?: boolean | `@${string}`
    isBilling?: boolean | `@${string}`
    zasilkovnaAddressId?: boolean | `@${string}`
    branchId?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Poznámka */
    note?: boolean | `@${string}`
    /** Cokoli... */
    customData?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CustomerConsent']: AliasType<{
    id?: boolean | `@${string}`
    customer?: ValueTypes['Customer']
    consent?: ValueTypes['Consent']
    name?: boolean | `@${string}`
    body?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Consent']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    body?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['PriceLevel']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    isPercentage?: boolean | `@${string}`
    percent?: boolean | `@${string}`
    withVat?: boolean | `@${string}`
    currency?: ValueTypes['Currency']
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Receipt']: AliasType<{
    id?: boolean | `@${string}`
    dic?: boolean | `@${string}`
    checkoutEetId?: boolean | `@${string}`
    shopEetId?: boolean | `@${string}`
    playground?: boolean | `@${string}`
    verificationMode?: boolean | `@${string}`
    number?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    totalPrice?: boolean | `@${string}`
    priceZeroVat?: boolean | `@${string}`
    priceStandardVat?: boolean | `@${string}`
    vatStandard?: boolean | `@${string}`
    priceFirstReducedVat?: boolean | `@${string}`
    vatFirstReduced?: boolean | `@${string}`
    priceSecondReducedVat?: boolean | `@${string}`
    vatSecondReduced?: boolean | `@${string}`
    priceForSubsequentSettlement?: boolean | `@${string}`
    priceUsedSubsequentSettlement?: boolean | `@${string}`
    fik?: boolean | `@${string}`
    bkp?: boolean | `@${string}`
    pkp?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProcessStatus']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    position?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['OrderProcessStatus']: AliasType<{
    status?: ValueTypes['ProcessStatus']
    note?: boolean | `@${string}`
    created?: boolean | `@${string}`
    createdBy?: ValueTypes['User']
    actual?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Package']: AliasType<{
    id?: boolean | `@${string}`
    code?: boolean | `@${string}`
    carrier?: ValueTypes['Carrier']
    tariff?: ValueTypes['CarrierTariff']
    items?: ValueTypes['OrderItem']
    statuses?: ValueTypes['PackageStatus']
    ticketUrl?: boolean | `@${string}`
    sent?: boolean | `@${string}`
    order?: ValueTypes['Order']
    disposal?: ValueTypes['PackageDisposal']
    weight?: boolean | `@${string}`
    weightRequired?: boolean | `@${string}`
    dimensionsRequired?: boolean | `@${string}`
    dimensionX?: boolean | `@${string}`
    dimensionY?: boolean | `@${string}`
    dimensionZ?: boolean | `@${string}`
    company?: ValueTypes['Company']
    clearanceItems?: ValueTypes['ClearanceItem']
    __typename?: boolean | `@${string}`
  }>
  ['PackageStatus']: AliasType<{
    id?: boolean | `@${string}`
    code?: boolean | `@${string}`
    text?: boolean | `@${string}`
    package?: ValueTypes['Package']
    created?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PackageDisposal']: AliasType<{
    id?: boolean | `@${string}`
    code?: boolean | `@${string}`
    company?: ValueTypes['Company']
    carrier?: ValueTypes['Carrier']
    packages?: ValueTypes['Package']
    ticketUrl?: boolean | `@${string}`
    sent?: boolean | `@${string}`
    files?: ValueTypes['File']
    __typename?: boolean | `@${string}`
  }>
  ['OrderGroup']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    company?: ValueTypes['Company']
    orders?: ValueTypes['Order']
    user?: ValueTypes['User']
    userPosition?: boolean | `@${string}`
    clearanceItems?: ValueTypes['ClearanceItem']
    __typename?: boolean | `@${string}`
  }>
  ['FileThumbnail']: AliasType<{
    format?: boolean | `@${string}`
    mimeType?: boolean | `@${string}`
    url?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductBundle']: AliasType<{
    id?: boolean | `@${string}`
    parent?: ValueTypes['Product']
    child?: ValueTypes['Product']
    /** Množství */
    quantity?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPrice']: AliasType<{
    id?: boolean | `@${string}`
    product?: ValueTypes['Product']
    sellPrice?: boolean | `@${string}`
    available?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    isActual?: boolean | `@${string}`
    note?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Category']: AliasType<{
    id?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    name?: boolean | `@${string}`
    text?: boolean | `@${string}`
    parent?: ValueTypes['Category']
    children?: ValueTypes['Category']
    hasChildren?: boolean | `@${string}`
    position?: boolean | `@${string}`
    company?: ValueTypes['Company']
    treePath?: boolean | `@${string}`
    /** Tagy */
    tags?: ValueTypes['Tag']
    __typename?: boolean | `@${string}`
  }>
  ['Quantity']: AliasType<{
    depot?: ValueTypes['Depot']
    quantityAvailable?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityPurchase?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductDepotMetric']: AliasType<{
    id?: boolean | `@${string}`
    depot?: ValueTypes['Depot']
    product?: ValueTypes['Product']
    quantityMinimum?: boolean | `@${string}`
    quantityOptimum?: boolean | `@${string}`
    quantityMaximum?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityPurchase?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPack']: AliasType<{
    id?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean?: boolean | `@${string}`
    /** Kód */
    code?: boolean | `@${string}`
    /** Poznámka */
    note?: boolean | `@${string}`
    /** Množství */
    quantity?: boolean | `@${string}`
    /** Produkt.id */
    product?: ValueTypes['Product']
    /** Externí ID */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductParameter']: AliasType<{
    id?: boolean | `@${string}`
    value?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    product?: ValueTypes['Product']
    parameter?: ValueTypes['Parameter']
    __typename?: boolean | `@${string}`
  }>
  /** String or Array */
  ['StringOrArray']: unknown
  ['Parameter']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    enumValues?: boolean | `@${string}`
    unit?: boolean | `@${string}`
    text?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['ProductPriceLevel']: AliasType<{
    id?: boolean | `@${string}`
    sellPrice?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    product?: ValueTypes['Product']
    priceLevel?: ValueTypes['PriceLevel']
    __typename?: boolean | `@${string}`
  }>
  ['SellItem']: AliasType<{
    id?: boolean | `@${string}`
    product?: ValueTypes['Product']
    quantityStock?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityAvailable?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    productDepots?: ValueTypes['ProductDepot']
    __typename?: boolean | `@${string}`
  }>
  ['CompanyModule']: AliasType<{
    id?: boolean | `@${string}`
    module?: ValueTypes['Module']
    __typename?: boolean | `@${string}`
  }>
  ['Module']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userMyProfile']: AliasType<{
    data?: ValueTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userFilters']: {
    pin?: string | undefined | null | Variable<any, string>
    email?: string | undefined | null | Variable<any, string>
    fulltext?: string | undefined | null | Variable<any, string>
    roles?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    enabled?: boolean | undefined | null | Variable<any, string>
  }
  ['users']: AliasType<{
    items?: ValueTypes['User']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Paginator']: AliasType<{
    current?: boolean | `@${string}`
    currentItemCount?: boolean | `@${string}`
    endPage?: boolean | `@${string}`
    first?: boolean | `@${string}`
    firstItemNumber?: boolean | `@${string}`
    firstPageInRange?: boolean | `@${string}`
    last?: boolean | `@${string}`
    lastItemNumber?: boolean | `@${string}`
    lastPageInRange?: boolean | `@${string}`
    next?: boolean | `@${string}`
    numItemsPerPage?: boolean | `@${string}`
    pageCount?: boolean | `@${string}`
    pageRange?: boolean | `@${string}`
    startPage?: boolean | `@${string}`
    totalCount?: boolean | `@${string}`
    pagesInRange?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['isUserEmailUnique']: AliasType<{
    data?: boolean | `@${string}`
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userGroup']: AliasType<{
    data?: ValueTypes['UserGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userGroupFilters']: {
    name?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['userGroups']: AliasType<{
    items?: ValueTypes['UserGroup']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Role']: AliasType<{
    name?: boolean | `@${string}`
    role?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['product']: AliasType<{
    data?: ValueTypes['Product']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productFilters']: {
    id?: number | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    name?: string | undefined | null | Variable<any, string>
    ean?: string | undefined | null | Variable<any, string>
    code?: string | undefined | null | Variable<any, string>
    codes?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    fulltext?: string | undefined | null | Variable<any, string>
    depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    metricDepots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    defaultSuppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    listType?: string | undefined | null | Variable<any, string>
    availability?: string | undefined | null | Variable<any, string>
    enabled?: boolean | undefined | null | Variable<any, string>
    isBundle?: boolean | undefined | null | Variable<any, string>
    expirationDateTo?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    position1?: string | undefined | null | Variable<any, string>
    position2?: string | undefined | null | Variable<any, string>
    position3?: string | undefined | null | Variable<any, string>
    hasBeforeSellPrice?: boolean | undefined | null | Variable<any, string>
    hasHsCode?: boolean | undefined | null | Variable<any, string>
    createdFrom?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    updatedFrom?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    categories?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    metrics?: string | undefined | null | Variable<any, string>
    enabledBatchExpiration?: boolean | undefined | null | Variable<any, string>
    requiredSerialNumbers?: boolean | undefined | null | Variable<any, string>
    company?: number | undefined | null | Variable<any, string>
  }
  ['products']: AliasType<{
    items?: ValueTypes['Product']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPurchasePrice']: AliasType<{
    items?: ValueTypes['ProductPurchasePrice']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPurchasePrice']: AliasType<{
    productDepot?: ValueTypes['Depot']
    quantity?: boolean | `@${string}`
    purchasePrice?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productSellItems']: AliasType<{
    items?: ValueTypes['SellItem']
    __typename?: boolean | `@${string}`
  }>
  ['productMovePackFilters']: {
    fulltext?: string | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    depot?: number | undefined | null | Variable<any, string>
    depotFrom?: number | undefined | null | Variable<any, string>
    depotTo?: number | undefined | null | Variable<any, string>
    number?: number | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    withoutCheckout?: boolean | undefined | null | Variable<any, string>
    product?: number | undefined | null | Variable<any, string>
    productFulltext?: string | undefined | null | Variable<any, string>
    order?: number | undefined | null | Variable<any, string>
    orderFulltext?: string | undefined | null | Variable<any, string>
    withOrder?: boolean | undefined | null | Variable<any, string>
    createdBy?: number | undefined | null | Variable<any, string>
    created?: ValueTypes['created'] | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    externalReference?: string | undefined | null | Variable<any, string>
    purchaseOrder?: number | undefined | null | Variable<any, string>
  }
  ['created']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['productMovePacks']: AliasType<{
    items?: ValueTypes['ProductMovePack']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productMoveFilters']: {
    product?: number | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    depotFrom?: number | undefined | null | Variable<any, string>
    depotTo?: number | undefined | null | Variable<any, string>
    createdBy?: number | undefined | null | Variable<any, string>
    created?: ValueTypes['created'] | undefined | null | Variable<any, string>
    expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    batch?: string | undefined | null | Variable<any, string>
  }
  ['productMoves']: AliasType<{
    items?: ValueTypes['ProductMove']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productMovePack']: AliasType<{
    data?: ValueTypes['ProductMovePack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['institution']: AliasType<{
    data?: ValueTypes['Institution']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['institutionFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['institutions']: AliasType<{
    items?: ValueTypes['Institution']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['order']: AliasType<{
    data?: ValueTypes['Order']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['orderFilters']: {
    fulltext?: string | undefined | null | Variable<any, string>
    reservationNumber?: number | undefined | null | Variable<any, string>
    billNumber?: number | undefined | null | Variable<any, string>
    notes?: string | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    onlyDepots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    payments?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    status?: string | undefined | null | Variable<any, string>
    processStatus?: string | undefined | null | Variable<any, string>
    processStatusChange?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    processStatusUpdated?: ValueTypes['processStatusUpdated'] | undefined | null | Variable<any, string>
    isPaid?: boolean | undefined | null | Variable<any, string>
    carrier?: string | undefined | null | Variable<any, string>
    carriers?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    deleted?: boolean | undefined | null | Variable<any, string>
    dateCreated?: ValueTypes['dateCreated'] | undefined | null | Variable<any, string>
    dateBill?: ValueTypes['dateBill'] | undefined | null | Variable<any, string>
    dateExpedition?: ValueTypes['dateExpedition'] | undefined | null | Variable<any, string>
    updated?: ValueTypes['updated'] | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    box?: string | undefined | null | Variable<any, string>
    item?: string | undefined | null | Variable<any, string>
    package?: string | undefined | null | Variable<any, string>
    customer?: number | undefined | null | Variable<any, string>
    customerFulltext?: string | undefined | null | Variable<any, string>
    shippingCountries?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    user?: number | undefined | null | Variable<any, string>
    tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    hasAvailableProducts?: boolean | undefined | null | Variable<any, string>
    group?: number | undefined | null | Variable<any, string>
    groups?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    createdBy?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    locked?: boolean | undefined | null | Variable<any, string>
    dateLocked?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    lockedBy?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['processStatusUpdated']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['dateCreated']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['dateBill']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['dateExpedition']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['updated']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['orders']: AliasType<{
    items?: ValueTypes['Order']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['soldItemFilters']: {
    productFulltext?: string | undefined | null | Variable<any, string>
    customerFulltext?: string | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    payments?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    status?: string | undefined | null | Variable<any, string>
    processStatus?: string | undefined | null | Variable<any, string>
    processStatusUpdated?: ValueTypes['processStatusUpdated'] | undefined | null | Variable<any, string>
    carriers?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    dateCreated?: ValueTypes['dateCreated'] | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    customer?: number | undefined | null | Variable<any, string>
    product?: number | undefined | null | Variable<any, string>
    shippingCountries?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    orderTags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['soldItems']: AliasType<{
    items?: ValueTypes['SoldItem']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['SoldItem']: AliasType<{
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    ean?: boolean | `@${string}`
    code?: boolean | `@${string}`
    quantity?: boolean | `@${string}`
    priceAll?: boolean | `@${string}`
    priceAllWithoutVat?: boolean | `@${string}`
    product?: ValueTypes['Product']
    profit?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['orderItemFilters']: {
    status?: string | undefined | null | Variable<any, string>
    processStatus?: string | undefined | null | Variable<any, string>
    order?: number | undefined | null | Variable<any, string>
    productDepot?: number | undefined | null | Variable<any, string>
    carrier?: string | undefined | null | Variable<any, string>
  }
  ['orderItems']: AliasType<{
    items?: ValueTypes['OrderItem']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['orderItem']: AliasType<{
    data?: ValueTypes['OrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['customer']: AliasType<{
    data?: ValueTypes['Customer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['customerFilters']: {
    externalId?: string | undefined | null | Variable<any, string>
    email?: string | undefined | null | Variable<any, string>
    fulltext?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    user?: number | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    priceLevel?: number | undefined | null | Variable<any, string>
    tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['customers']: AliasType<{
    items?: ValueTypes['Customer']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['address']: AliasType<{
    data?: ValueTypes['Address']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['addressesFilters']: {
    customer?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    isStored?: boolean | undefined | null | Variable<any, string>
    isBilling?: boolean | undefined | null | Variable<any, string>
    ic?: string | undefined | null | Variable<any, string>
    dic?: string | undefined | null | Variable<any, string>
    phone?: string | undefined | null | Variable<any, string>
    email?: string | undefined | null | Variable<any, string>
  }
  ['addresses']: AliasType<{
    items?: ValueTypes['Address']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['checkout']: AliasType<{
    data?: ValueTypes['Checkout']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['checkoutFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    user?: number | undefined | null | Variable<any, string>
  }
  ['checkouts']: AliasType<{
    items?: ValueTypes['Checkout']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['eventTypes']: AliasType<{
    items?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['consent']: AliasType<{
    data?: ValueTypes['Consent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['consentsFilters']: {
    name?: string | undefined | null | Variable<any, string>
  }
  ['consents']: AliasType<{
    items?: ValueTypes['Consent']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['checkoutExtraOperation']: AliasType<{
    data?: ValueTypes['CheckoutExtraOperation']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['checkoutExtraOperationFilters']: {
    checkout?: number | undefined | null | Variable<any, string>
    checkoutClosing?: number | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['checkoutExtraOperations']: AliasType<{
    items?: ValueTypes['CheckoutExtraOperation']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['checkoutClosing']: AliasType<{
    data?: ValueTypes['CheckoutClosing']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['checkoutClosingFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['checkoutClosings']: AliasType<{
    items?: ValueTypes['CheckoutClosing']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['vat']: AliasType<{
    data?: ValueTypes['Vat']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['vatFilters']: {
    name?: string | undefined | null | Variable<any, string>
    percent?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['vats']: AliasType<{
    items?: ValueTypes['Vat']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['depot']: AliasType<{
    data?: ValueTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['depotFilters']: {
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    name?: string | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['depots']: AliasType<{
    items?: ValueTypes['Depot']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['supplier']: AliasType<{
    data?: ValueTypes['Supplier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['supplierFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['suppliers']: AliasType<{
    items?: ValueTypes['Supplier']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['producer']: AliasType<{
    data?: ValueTypes['Producer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['producerFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['producers']: AliasType<{
    items?: ValueTypes['Producer']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['exportStockFilters']: {
    depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['exportStocks']: AliasType<{
    items?: ValueTypes['Stock']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Stock']: AliasType<{
    id?: boolean | `@${string}`
    date?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    depots?: ValueTypes['Depot']
    suppliers?: ValueTypes['Supplier']
    producers?: ValueTypes['Producer']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportSaleFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['exportSales']: AliasType<{
    items?: ValueTypes['Sale']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Sale']: AliasType<{
    id?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    checkouts?: ValueTypes['Checkout']
    suppliers?: ValueTypes['Supplier']
    producers?: ValueTypes['Producer']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportPurchaseFilters']: {
    depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['exportPurchases']: AliasType<{
    items?: ValueTypes['Purchase']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Purchase']: AliasType<{
    id?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    depots?: ValueTypes['Depot']
    checkouts?: ValueTypes['Checkout']
    suppliers?: ValueTypes['Supplier']
    producers?: ValueTypes['Producer']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportOrderFilters']: {
    status?: string | undefined | null | Variable<any, string>
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['exportOrders']: AliasType<{
    items?: ValueTypes['exportOrder']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['exportOrder']: AliasType<{
    id?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    status?: ValueTypes['OrderStatus']
    checkouts?: ValueTypes['Checkout']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportInventory']: AliasType<{
    data?: ValueTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Inventory']: AliasType<{
    id?: boolean | `@${string}`
    date?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    depots?: ValueTypes['Depot']
    suppliers?: ValueTypes['Supplier']
    producers?: ValueTypes['Producer']
    url?: boolean | `@${string}`
    files?: ValueTypes['File']
    approved?: boolean | `@${string}`
    rejected?: boolean | `@${string}`
    finished?: boolean | `@${string}`
    pmpIn?: ValueTypes['ProductMovePack']
    pmpOut?: ValueTypes['ProductMovePack']
    __typename?: boolean | `@${string}`
  }>
  ['exportInventoryFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    approved?: boolean | undefined | null | Variable<any, string>
    rejected?: boolean | undefined | null | Variable<any, string>
    finished?: boolean | undefined | null | Variable<any, string>
  }
  ['exportInventories']: AliasType<{
    items?: ValueTypes['Inventory']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['export']: AliasType<{
    data?: ValueTypes['Export']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Export']: AliasType<{
    id?: boolean | `@${string}`
    file?: ValueTypes['File']
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    url?: boolean | `@${string}`
    type?: ValueTypes['ExportType']
    filter?: boolean | `@${string}`
    createdBy?: ValueTypes['User']
    note?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ExportType']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    format?: boolean | `@${string}`
    group?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportFilters']: {
    types?: Array<string | undefined | null> | undefined | null | Variable<any, string>
    group?: string | undefined | null | Variable<any, string>
  }
  ['exports']: AliasType<{
    items?: ValueTypes['Export']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['exportTypeFilters']: {
    group?: string | undefined | null | Variable<any, string>
  }
  ['exportTypes']: AliasType<{
    items?: ValueTypes['ExportType']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['shop']: AliasType<{
    data?: ValueTypes['Shop']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Shop']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    street?: boolean | `@${string}`
    city?: boolean | `@${string}`
    country?: boolean | `@${string}`
    zip?: boolean | `@${string}`
    eetId?: boolean | `@${string}`
    cert?: ValueTypes['Cert']
    checkouts?: ValueTypes['Checkout']
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Cert']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    publicKey?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    company?: ValueTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['shopFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['shops']: AliasType<{
    items?: ValueTypes['Shop']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['cert']: AliasType<{
    data?: ValueTypes['Cert']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['filters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['certs']: AliasType<{
    items?: ValueTypes['Cert']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['eetReceiptFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    number?: number | undefined | null | Variable<any, string>
    hasFik?: boolean | undefined | null | Variable<any, string>
    playground?: boolean | undefined | null | Variable<any, string>
    verificationMode?: boolean | undefined | null | Variable<any, string>
  }
  ['eetReceipts']: AliasType<{
    items?: ValueTypes['Receipt']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['payment']: AliasType<{
    data?: ValueTypes['Payment']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['paymentFilters']: {
    company?: number | undefined | null | Variable<any, string>
    name?: string | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['payments']: AliasType<{
    items?: ValueTypes['Payment']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['paymentItem']: AliasType<{
    data?: ValueTypes['PaymentItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['paymentItemsFilters']: {
    order?: number | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    payment?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['paymentItems']: AliasType<{
    items?: ValueTypes['PaymentItem']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['paymentTypeFilters']: {
    const?: string | undefined | null | Variable<any, string>
  }
  ['paymentTypes']: AliasType<{
    items?: ValueTypes['PaymentType']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['processStatusesFilters']: {
    name?: string | undefined | null | Variable<any, string>
  }
  ['processStatuses']: AliasType<{
    items?: ValueTypes['ProcessStatus']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['carriersFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['carriers']: AliasType<{
    items?: ValueTypes['Carrier']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['currenciesFilters']: {
    name?: string | undefined | null | Variable<any, string>
  }
  ['currencies']: AliasType<{
    items?: ValueTypes['Currency']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['package']: AliasType<{
    data?: ValueTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['packageFilters']: {
    code?: string | undefined | null | Variable<any, string>
    carrier?: string | undefined | null | Variable<any, string>
    order?: number | undefined | null | Variable<any, string>
    disposal?: number | undefined | null | Variable<any, string>
    hasDisposal?: boolean | undefined | null | Variable<any, string>
    isSent?: boolean | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    sent?: string | undefined | null | Variable<any, string>
  }
  ['packages']: AliasType<{
    items?: ValueTypes['Package']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['packageStatsFilters']: {
    carrier?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    fromDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    toDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['packageStats']: AliasType<{
    items?: ValueTypes['PackageStats']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['PackageStats']: AliasType<{
    company?: ValueTypes['Company']
    processStatus?: ValueTypes['ProcessStatus']
    packageAmount?: boolean | `@${string}`
    orderAmount?: boolean | `@${string}`
    country?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['disposal']: AliasType<{
    data?: ValueTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['disposalFilters']: {
    carrier?: string | undefined | null | Variable<any, string>
  }
  ['disposals']: AliasType<{
    items?: ValueTypes['PackageDisposal']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productDepotMetric']: AliasType<{
    data?: ValueTypes['ProductDepotMetric']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productDepotMetricFilters']: {
    product?: number | undefined | null | Variable<any, string>
    depot?: number | undefined | null | Variable<any, string>
  }
  ['productDepotMetrics']: AliasType<{
    items?: ValueTypes['ProductDepotMetric']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['file']: AliasType<{
    data?: ValueTypes['File']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['fileFilters']: {
    product?: number | undefined | null | Variable<any, string>
    order?: number | undefined | null | Variable<any, string>
  }
  ['files']: AliasType<{
    items?: ValueTypes['File']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['nextEan']: AliasType<{
    data?: boolean | `@${string}`
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['statsCarrierUsage']: AliasType<{
    items?: ValueTypes['CarrierUsage']
    __typename?: boolean | `@${string}`
  }>
  ['CarrierUsage']: AliasType<{
    amount?: boolean | `@${string}`
    carrier?: ValueTypes['Carrier']
    __typename?: boolean | `@${string}`
  }>
  ['statsPaymentSales']: AliasType<{
    items?: ValueTypes['PaymentSales']
    __typename?: boolean | `@${string}`
  }>
  ['PaymentSales']: AliasType<{
    amount?: boolean | `@${string}`
    sales?: ValueTypes['Price']
    payment?: ValueTypes['Payment']
    __typename?: boolean | `@${string}`
  }>
  ['Price']: AliasType<{
    value?: boolean | `@${string}`
    currency?: ValueTypes['Currency']
    __typename?: boolean | `@${string}`
  }>
  ['statsBestsellersSort']: statsBestsellersSort
  ['statsBestsellers']: AliasType<{
    items?: ValueTypes['Bestsellers']
    __typename?: boolean | `@${string}`
  }>
  ['Bestsellers']: AliasType<{
    amount?: boolean | `@${string}`
    sales?: ValueTypes['Price']
    product?: ValueTypes['Product']
    __typename?: boolean | `@${string}`
  }>
  ['companyCarrier']: AliasType<{
    data?: ValueTypes['CompanyCarrier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['companyCarrierFilters']: {
    carrier?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['companyCarriers']: AliasType<{
    items?: ValueTypes['CompanyCarrier']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['category']: AliasType<{
    data?: ValueTypes['Category']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['categoryFilters']: {
    name?: string | undefined | null | Variable<any, string>
    parent?: number | undefined | null | Variable<any, string>
    hasParent?: boolean | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['categories']: AliasType<{
    items?: ValueTypes['Category']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['parameter']: AliasType<{
    data?: ValueTypes['Parameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['parametersFilters']: {
    name?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['parameters']: AliasType<{
    items?: ValueTypes['Parameter']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['orderGroup']: AliasType<{
    data?: ValueTypes['OrderGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['orderGroupFilters']: {
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    hasUser?: boolean | undefined | null | Variable<any, string>
    user?: number | undefined | null | Variable<any, string>
    name?: string | undefined | null | Variable<any, string>
    processStatus?: string | undefined | null | Variable<any, string>
  }
  ['orderGroups']: AliasType<{
    items?: ValueTypes['OrderGroup']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['voucher']: AliasType<{
    data?: ValueTypes['Voucher']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['voucherFilters']: {
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    name?: string | undefined | null | Variable<any, string>
    code?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    discountType?: string | undefined | null | Variable<any, string>
    enabled?: boolean | undefined | null | Variable<any, string>
    isPayment?: boolean | undefined | null | Variable<any, string>
    isValid?: boolean | undefined | null | Variable<any, string>
    isUsed?: boolean | undefined | null | Variable<any, string>
    validDate?: ValueTypes['voucherValidFilter'] | undefined | null | Variable<any, string>
    created?: ValueTypes['voucherCreatedFilter'] | undefined | null | Variable<any, string>
  }
  ['voucherValidFilter']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['voucherCreatedFilter']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['vouchers']: AliasType<{
    items?: ValueTypes['Voucher']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPriceFilters']: {
    product?: number | undefined | null | Variable<any, string>
  }
  ['productPrices']: AliasType<{
    items?: ValueTypes['ProductPrice']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['priceLevel']: AliasType<{
    data?: ValueTypes['PriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['priceLevelFilters']: {
    name?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    isPercentage?: boolean | undefined | null | Variable<any, string>
    withVat?: boolean | undefined | null | Variable<any, string>
    currency?: string | undefined | null | Variable<any, string>
  }
  ['priceLevels']: AliasType<{
    items?: ValueTypes['PriceLevel']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productParameter']: AliasType<{
    data?: ValueTypes['ProductParameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productParameterFilters']: {
    product?: number | undefined | null | Variable<any, string>
    parameter?: number | undefined | null | Variable<any, string>
    value?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['productParameters']: AliasType<{
    items?: ValueTypes['ProductParameter']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPriceLevel']: AliasType<{
    data?: ValueTypes['ProductPriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productPriceLevelFilters']: {
    product?: number | undefined | null | Variable<any, string>
    priceLevel?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['productPriceLevels']: AliasType<{
    items?: ValueTypes['ProductPriceLevel']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['tag']: AliasType<{
    data?: ValueTypes['Tag']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['tagFilters']: {
    name?: string | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['tags']: AliasType<{
    items?: ValueTypes['Tag']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPack']: AliasType<{
    data?: ValueTypes['ProductPack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productPackFilters']: {
    product?: number | undefined | null | Variable<any, string>
    quantity?: number | undefined | null | Variable<any, string>
    ean?: string | undefined | null | Variable<any, string>
    code?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['productPacks']: AliasType<{
    items?: ValueTypes['ProductPack']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['purchaseOrder']: AliasType<{
    data?: ValueTypes['PurchaseOrder']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['purchaseOrderFilters']: {
    fulltext?: string | undefined | null | Variable<any, string>
    status?: string | undefined | null | Variable<any, string>
    supplier?: number | undefined | null | Variable<any, string>
    depot?: number | undefined | null | Variable<any, string>
    number?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    externalReference?: string | undefined | null | Variable<any, string>
    supplierReference?: string | undefined | null | Variable<any, string>
    createdBy?: number | undefined | null | Variable<any, string>
    tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
    updated?: ValueTypes['updated'] | undefined | null | Variable<any, string>
    created?: ValueTypes['created'] | undefined | null | Variable<any, string>
    dateEstimatedArrival?: ValueTypes['dateEstimatedArrival'] | undefined | null | Variable<any, string>
    company?: number | undefined | null | Variable<any, string>
  }
  ['dateEstimatedArrival']: {
    left_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    right_date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['purchaseOrders']: AliasType<{
    items?: ValueTypes['PurchaseOrder']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['purchaseOrderItem']: AliasType<{
    data?: ValueTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['modulesFilters']: {
    name?: string | undefined | null | Variable<any, string>
    companies?: Array<number | undefined | null> | undefined | null | Variable<any, string>
  }
  ['modules']: AliasType<{
    items?: ValueTypes['Module']
    paginator?: ValueTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['RootSchemaMutation']: AliasType<{
    createProduct?: [
      {
        name: string | Variable<any, string>
        parent?: number | undefined | null | Variable<any, string>
        children?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producer?: number | undefined | null | Variable<any, string>
        supplier?: number | undefined | null | Variable<any, string>
        ean?: string | undefined | null | Variable<any, string>
        ean2?: string | undefined | null | Variable<any, string>
        ean3?: string | undefined | null | Variable<any, string>
        ean4?: string | undefined | null | Variable<any, string>
        ean5?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        code2?: string | undefined | null | Variable<any, string>
        code3?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        sellPrice?: string | undefined | null | Variable<any, string>
        purchasePrice?: string | undefined | null | Variable<any, string>
        purchaseCurrency?: string | undefined | null | Variable<any, string>
        beforeSellPrice?: string | undefined | null | Variable<any, string>
        weight?: string | undefined | null | Variable<any, string>
        dimensionX?: number | undefined | null | Variable<any, string>
        dimensionY?: number | undefined | null | Variable<any, string>
        dimensionZ?: number | undefined | null | Variable<any, string>
        originCountry?: string | undefined | null | Variable<any, string>
        hsCode?: string | undefined | null | Variable<any, string>
        enabled?: boolean | undefined | null | Variable<any, string>
        vat?: number | undefined | null | Variable<any, string>
        isForSubsequentSettlement?: boolean | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        description?: string | undefined | null | Variable<any, string>
        isBundle?: boolean | undefined | null | Variable<any, string>
        isFragile?: boolean | undefined | null | Variable<any, string>
        isOversize?: boolean | undefined | null | Variable<any, string>
        customData?: string | undefined | null | Variable<any, string>
        mainCategory?: number | undefined | null | Variable<any, string>
        mainImage?: number | undefined | null | Variable<any, string>
        categories?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        enabledBatchExpiration?: boolean | undefined | null | Variable<any, string>
        requiredSerialNumbers?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['createProduct'],
    ]
    updateProduct?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        parent?: number | undefined | null | Variable<any, string>
        children?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producer?: number | undefined | null | Variable<any, string>
        supplier?: number | undefined | null | Variable<any, string>
        ean?: string | undefined | null | Variable<any, string>
        ean2?: string | undefined | null | Variable<any, string>
        ean3?: string | undefined | null | Variable<any, string>
        ean4?: string | undefined | null | Variable<any, string>
        ean5?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        code2?: string | undefined | null | Variable<any, string>
        code3?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        sellPrice?: string | undefined | null | Variable<any, string>
        purchasePrice?: string | undefined | null | Variable<any, string>
        purchaseCurrency?: string | undefined | null | Variable<any, string>
        beforeSellPrice?: string | undefined | null | Variable<any, string>
        weight?: string | undefined | null | Variable<any, string>
        dimensionX?: number | undefined | null | Variable<any, string>
        dimensionY?: number | undefined | null | Variable<any, string>
        dimensionZ?: number | undefined | null | Variable<any, string>
        originCountry?: string | undefined | null | Variable<any, string>
        hsCode?: string | undefined | null | Variable<any, string>
        enabled?: boolean | undefined | null | Variable<any, string>
        vat?: number | undefined | null | Variable<any, string>
        isForSubsequentSettlement?: boolean | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        description?: string | undefined | null | Variable<any, string>
        isBundle?: boolean | undefined | null | Variable<any, string>
        isFragile?: boolean | undefined | null | Variable<any, string>
        isOversize?: boolean | undefined | null | Variable<any, string>
        customData?: string | undefined | null | Variable<any, string>
        mainCategory?: number | undefined | null | Variable<any, string>
        mainImage?: number | undefined | null | Variable<any, string>
        categories?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        enabledBatchExpiration?: boolean | undefined | null | Variable<any, string>
        requiredSerialNumbers?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProduct'],
    ]
    deleteProduct?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProduct']]
    refreshProductDepots?: [{ id: number | Variable<any, string> }, ValueTypes['refreshProductDepots']]
    createProductMovePack?: [
      {
        type: string | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        externalReference?: string | undefined | null | Variable<any, string>
        purpose?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        order?: number | undefined | null | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        purchaseOrder?: number | undefined | null | Variable<any, string>
        moves?: Array<ValueTypes['createProductMove'] | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['createProductMovePack'],
    ]
    updateProductMovePack?: [
      {
        id: number | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        externalReference?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductMovePack'],
    ]
    deleteProductMovePack?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductMovePack']]
    updateProductMove?: [
      {
        id: number | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        serialNumbers?: Array<string | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductMove'],
    ]
    deleteProductMove?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductMove']]
    createProductPrice?: [
      {
        product: number | Variable<any, string>
        sellPrice?: number | undefined | null | Variable<any, string>
        available?: number | undefined | null | Variable<any, string>
        dateFrom?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateTo?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createProductPrice'],
    ]
    updateProductPrice?: [
      {
        id: number | Variable<any, string>
        product: number | Variable<any, string>
        sellPrice?: number | undefined | null | Variable<any, string>
        available?: number | undefined | null | Variable<any, string>
        dateFrom?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateTo?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductPrice'],
    ]
    deleteProductPrice?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductPrice']]
    createProductBundle?: [
      {
        parent: number | Variable<any, string>
        child: number | Variable<any, string>
        quantity: number | Variable<any, string>
      },
      ValueTypes['createProductBundle'],
    ]
    updateProductBundle?: [
      {
        id: number | Variable<any, string>
        parent?: number | undefined | null | Variable<any, string>
        child?: number | undefined | null | Variable<any, string>
        quantity?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductBundle'],
    ]
    deleteProductBundle?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductBundle']]
    createProductDepotMetric?: [
      {
        product: number | Variable<any, string>
        depot: number | Variable<any, string>
        quantityMinimum?: number | undefined | null | Variable<any, string>
        quantityOptimum?: number | undefined | null | Variable<any, string>
        quantityMaximum?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['createProductDepotMetric'],
    ]
    updateProductDepotMetric?: [
      {
        id: number | Variable<any, string>
        quantityMinimum?: number | undefined | null | Variable<any, string>
        quantityOptimum?: number | undefined | null | Variable<any, string>
        quantityMaximum?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductDepotMetric'],
    ]
    deleteProductDepotMetric?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductDepotMetric']]
    createInstitution?: [
      {
        name: string | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['createInstitution'],
    ]
    updateInstitution?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['updateInstitution'],
    ]
    deleteInstitution?: [{ id: number | Variable<any, string> }, ValueTypes['deleteInstitution']]
    createOrder?: [
      {
        reservationNumber?: number | undefined | null | Variable<any, string>
        billNumber?: number | undefined | null | Variable<any, string>
        vs?: number | undefined | null | Variable<any, string>
        status?: string | undefined | null | Variable<any, string>
        dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateBill?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateExpedition?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateDue?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateTax?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        privateNote?: string | undefined | null | Variable<any, string>
        checkout: number | Variable<any, string>
        customer?: number | undefined | null | Variable<any, string>
        currency?: string | undefined | null | Variable<any, string>
        invoiceAddress?: number | undefined | null | Variable<any, string>
        shippingAddress?: number | undefined | null | Variable<any, string>
        items?: Array<ValueTypes['createOrderItem_'] | undefined | null> | undefined | null | Variable<any, string>
        paymentItems?:
          | Array<ValueTypes['createPaymentItem_'] | undefined | null>
          | undefined
          | null
          | Variable<any, string>
        relatedParent?: number | undefined | null | Variable<any, string>
        rounding?: number | undefined | null | Variable<any, string>
        processStatusRelation?: ValueTypes['processStatusRelation'] | undefined | null | Variable<any, string>
        carrier?: string | undefined | null | Variable<any, string>
        boxes?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        group?: number | undefined | null | Variable<any, string>
        groupPosition?: number | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        priority?: number | undefined | null | Variable<any, string>
        externalReference?: string | undefined | null | Variable<any, string>
        locked?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['createOrder'],
    ]
    updateOrder?: [
      {
        id: number | Variable<any, string>
        reservationNumber?: number | undefined | null | Variable<any, string>
        billNumber?: number | undefined | null | Variable<any, string>
        vs?: number | undefined | null | Variable<any, string>
        status?: string | undefined | null | Variable<any, string>
        dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateBill?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateExpedition?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateDue?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateTax?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        privateNote?: string | undefined | null | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        customer?: number | undefined | null | Variable<any, string>
        currency?: string | undefined | null | Variable<any, string>
        invoiceAddress?: number | undefined | null | Variable<any, string>
        shippingAddress?: number | undefined | null | Variable<any, string>
        items?: Array<ValueTypes['updateOrderItem_'] | undefined | null> | undefined | null | Variable<any, string>
        paymentItems?:
          | Array<ValueTypes['updatePaymentItem_'] | undefined | null>
          | undefined
          | null
          | Variable<any, string>
        relatedParent?: number | undefined | null | Variable<any, string>
        rounding?: number | undefined | null | Variable<any, string>
        processStatusRelation?: ValueTypes['processStatusRelation'] | undefined | null | Variable<any, string>
        carrier?: string | undefined | null | Variable<any, string>
        boxes?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        group?: number | undefined | null | Variable<any, string>
        groupPosition?: number | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        priority?: number | undefined | null | Variable<any, string>
        externalReference?: string | undefined | null | Variable<any, string>
        locked?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['updateOrder'],
    ]
    deleteReservation?: [{ id: number | Variable<any, string> }, ValueTypes['deleteReservation']]
    createOrderItem?: [
      {
        order?: number | undefined | null | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        ean?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        quantity?: number | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        price?: number | undefined | null | Variable<any, string>
        sale?: number | undefined | null | Variable<any, string>
        vat?: number | undefined | null | Variable<any, string>
        product?: number | undefined | null | Variable<any, string>
        serial?: string | undefined | null | Variable<any, string>
        expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        batch?: string | undefined | null | Variable<any, string>
        isForSubsequentSettlement?: boolean | undefined | null | Variable<any, string>
        picked?: boolean | undefined | null | Variable<any, string>
        packed?: boolean | undefined | null | Variable<any, string>
        voucher?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createOrderItem'],
    ]
    updateOrderItem?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        ean?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        quantity?: number | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        price?: number | undefined | null | Variable<any, string>
        sale?: number | undefined | null | Variable<any, string>
        vat?: number | undefined | null | Variable<any, string>
        product?: number | undefined | null | Variable<any, string>
        serial?: string | undefined | null | Variable<any, string>
        expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        batch?: string | undefined | null | Variable<any, string>
        isForSubsequentSettlement?: boolean | undefined | null | Variable<any, string>
        picked?: boolean | undefined | null | Variable<any, string>
        packed?: boolean | undefined | null | Variable<any, string>
        voucher?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateOrderItem'],
    ]
    deleteOrderItem?: [{ id: number | Variable<any, string> }, ValueTypes['deleteOrderItem']]
    updateClearanceItem?: [
      {
        id: string | Variable<any, string>
        orderItem?: number | undefined | null | Variable<any, string>
        orderGroup?: number | undefined | null | Variable<any, string>
        package?: number | undefined | null | Variable<any, string>
        order?: number | undefined | null | Variable<any, string>
        picked?: number | undefined | null | Variable<any, string>
        packed?: number | undefined | null | Variable<any, string>
        locations?:
          | Array<ValueTypes['updateClearanceItemLocations'] | undefined | null>
          | undefined
          | null
          | Variable<any, string>
      },
      ValueTypes['updateClearanceItem'],
    ]
    createUser?: [
      {
        email: string | Variable<any, string>
        plainPassword?: string | undefined | null | Variable<any, string>
        firstName: string | Variable<any, string>
        lastName: string | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        enabled?: boolean | undefined | null | Variable<any, string>
        groups?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        roles?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        pin?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        locale?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createUser'],
    ]
    updateUser?: [
      {
        id: number | Variable<any, string>
        email?: string | undefined | null | Variable<any, string>
        plainPassword?: string | undefined | null | Variable<any, string>
        firstName?: string | undefined | null | Variable<any, string>
        lastName?: string | undefined | null | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        enabled?: boolean | undefined | null | Variable<any, string>
        groups?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        roles?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        pin?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        locale?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateUser'],
    ]
    deleteUser?: [{ id: number | Variable<any, string> }, ValueTypes['deleteUser']]
    createUserGroup?: [
      {
        name: string | Variable<any, string>
        roles?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createUserGroup'],
    ]
    updateUserGroup?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        roles?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateUserGroup'],
    ]
    deleteUserGroup?: [{ id: number | Variable<any, string> }, ValueTypes['deleteUserGroup']]
    createCustomer?: [
      {
        externalId?: string | undefined | null | Variable<any, string>
        email: string | Variable<any, string>
        plainPassword?: string | undefined | null | Variable<any, string>
        firstName?: string | undefined | null | Variable<any, string>
        lastName?: string | undefined | null | Variable<any, string>
        companyName?: string | undefined | null | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        addresses?:
          | Array<ValueTypes['createCustomerAddress'] | undefined | null>
          | undefined
          | null
          | Variable<any, string>
        wholesale?: boolean | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        birthday?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        users?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        minExpirationDays?: number | undefined | null | Variable<any, string>
        priceLevel?: number | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        customData?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createCustomer'],
    ]
    updateCustomer?: [
      {
        id: number | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        email?: string | undefined | null | Variable<any, string>
        plainPassword?: string | undefined | null | Variable<any, string>
        firstName?: string | undefined | null | Variable<any, string>
        lastName?: string | undefined | null | Variable<any, string>
        companyName?: string | undefined | null | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        wholesale?: boolean | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        birthday?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        users?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        minExpirationDays?: number | undefined | null | Variable<any, string>
        priceLevel?: number | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        customData?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCustomer'],
    ]
    deleteCustomer?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCustomer']]
    createAddress?: [
      {
        customer?: number | undefined | null | Variable<any, string>
        companyName?: string | undefined | null | Variable<any, string>
        firstName?: string | undefined | null | Variable<any, string>
        lastName?: string | undefined | null | Variable<any, string>
        street?: string | undefined | null | Variable<any, string>
        city?: string | undefined | null | Variable<any, string>
        country: string | Variable<any, string>
        state?: string | undefined | null | Variable<any, string>
        zip?: string | undefined | null | Variable<any, string>
        ic?: string | undefined | null | Variable<any, string>
        dic?: string | undefined | null | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        email?: string | undefined | null | Variable<any, string>
        zasilkovnaAddressId?: string | undefined | null | Variable<any, string>
        branchId?: string | undefined | null | Variable<any, string>
        isStored?: boolean | undefined | null | Variable<any, string>
        isBilling?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        customData?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createAddress'],
    ]
    updateAddress?: [
      {
        id: number | Variable<any, string>
        customer?: number | undefined | null | Variable<any, string>
        companyName?: string | undefined | null | Variable<any, string>
        firstName?: string | undefined | null | Variable<any, string>
        lastName?: string | undefined | null | Variable<any, string>
        street?: string | undefined | null | Variable<any, string>
        city?: string | undefined | null | Variable<any, string>
        country?: string | undefined | null | Variable<any, string>
        state?: string | undefined | null | Variable<any, string>
        zip?: string | undefined | null | Variable<any, string>
        ic?: string | undefined | null | Variable<any, string>
        dic?: string | undefined | null | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        email?: string | undefined | null | Variable<any, string>
        zasilkovnaAddressId?: string | undefined | null | Variable<any, string>
        branchId?: string | undefined | null | Variable<any, string>
        isStored?: boolean | undefined | null | Variable<any, string>
        isBilling?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        customData?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateAddress'],
    ]
    deleteAddress?: [{ id: number | Variable<any, string> }, ValueTypes['deleteAddress']]
    createCheckout?: [
      {
        name: string | Variable<any, string>
        amount?: string | undefined | null | Variable<any, string>
        nextReservationNumber?: number | undefined | null | Variable<any, string>
        nextBillNumber?: number | undefined | null | Variable<any, string>
        billFooter?: string | undefined | null | Variable<any, string>
        payments?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        returnsDepot?: number | undefined | null | Variable<any, string>
        shop?: number | undefined | null | Variable<any, string>
        eetId?: number | undefined | null | Variable<any, string>
        eetEnable?: boolean | undefined | null | Variable<any, string>
        eetPlayground?: boolean | undefined | null | Variable<any, string>
        eetVerificationMode?: boolean | undefined | null | Variable<any, string>
        eventUrl?: string | undefined | null | Variable<any, string>
        eventTypes?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        negativeReservation?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        users?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        autoPicking?: boolean | undefined | null | Variable<any, string>
        autoPacking?: boolean | undefined | null | Variable<any, string>
        autoDispatched?: boolean | undefined | null | Variable<any, string>
        institution?: number | undefined | null | Variable<any, string>
        position?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['createCheckout'],
    ]
    updateCheckout?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        amount?: string | undefined | null | Variable<any, string>
        nextReservationNumber?: number | undefined | null | Variable<any, string>
        nextBillNumber?: number | undefined | null | Variable<any, string>
        billFooter?: string | undefined | null | Variable<any, string>
        payments?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        returnsDepot?: number | undefined | null | Variable<any, string>
        shop?: number | undefined | null | Variable<any, string>
        eetId?: number | undefined | null | Variable<any, string>
        eetEnable?: boolean | undefined | null | Variable<any, string>
        eetPlayground?: boolean | undefined | null | Variable<any, string>
        eetVerificationMode?: boolean | undefined | null | Variable<any, string>
        eventUrl?: string | undefined | null | Variable<any, string>
        eventTypes?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        negativeReservation?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        users?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        autoPicking?: boolean | undefined | null | Variable<any, string>
        autoPacking?: boolean | undefined | null | Variable<any, string>
        autoDispatched?: boolean | undefined | null | Variable<any, string>
        institution?: number | undefined | null | Variable<any, string>
        position?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCheckout'],
    ]
    deleteCheckout?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCheckout']]
    createCheckoutExtraOperation?: [
      {
        checkout: number | Variable<any, string>
        dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        amount: string | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createCheckoutExtraOperation'],
    ]
    updateCheckoutExtraOperation?: [
      {
        id: number | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        amount?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCheckoutExtraOperation'],
    ]
    deleteCheckoutExtraOperation?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCheckoutExtraOperation']]
    createCheckoutClosing?: [
      {
        checkout: number | Variable<any, string>
        dateOpen: ValueTypes['DateTimeAsString'] | Variable<any, string>
        amountOpen: string | Variable<any, string>
        amountRealOpen?: string | undefined | null | Variable<any, string>
        userOpen?: number | undefined | null | Variable<any, string>
        noteOpen?: string | undefined | null | Variable<any, string>
        dateClosed?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        amountClosed?: string | undefined | null | Variable<any, string>
        amountRealClosed?: string | undefined | null | Variable<any, string>
        userClosed?: number | undefined | null | Variable<any, string>
        noteClosed?: string | undefined | null | Variable<any, string>
        enumeration?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createCheckoutClosing'],
    ]
    updateCheckoutClosing?: [
      {
        id: number | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        dateOpen?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        amountOpen?: string | undefined | null | Variable<any, string>
        amountRealOpen?: string | undefined | null | Variable<any, string>
        userOpen?: number | undefined | null | Variable<any, string>
        noteOpen?: string | undefined | null | Variable<any, string>
        dateClosed?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        amountClosed?: string | undefined | null | Variable<any, string>
        amountRealClosed?: string | undefined | null | Variable<any, string>
        userClosed?: number | undefined | null | Variable<any, string>
        noteClosed?: string | undefined | null | Variable<any, string>
        enumeration?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCheckoutClosing'],
    ]
    deleteCheckoutClosing?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCheckoutClosing']]
    createVat?: [
      {
        name: string | Variable<any, string>
        percent: string | Variable<any, string>
        default: boolean | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createVat'],
    ]
    updateVat?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        percent?: string | undefined | null | Variable<any, string>
        default?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateVat'],
    ]
    deleteVat?: [{ id: number | Variable<any, string> }, ValueTypes['deleteVat']]
    createDepot?: [
      {
        name: string | Variable<any, string>
        emailIn?: string | undefined | null | Variable<any, string>
        position?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        institution?: number | undefined | null | Variable<any, string>
        users?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['createDepot'],
    ]
    updateDepot?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        emailIn?: string | undefined | null | Variable<any, string>
        position?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        institution?: number | undefined | null | Variable<any, string>
        users?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['updateDepot'],
    ]
    deleteDepot?: [{ id: number | Variable<any, string> }, ValueTypes['deleteDepot']]
    createSupplier?: [
      { name: string | Variable<any, string>; externalId?: string | undefined | null | Variable<any, string> },
      ValueTypes['createSupplier'],
    ]
    updateSupplier?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateSupplier'],
    ]
    deleteSupplier?: [{ id: number | Variable<any, string> }, ValueTypes['deleteSupplier']]
    createProducer?: [
      { name: string | Variable<any, string>; externalId?: string | undefined | null | Variable<any, string> },
      ValueTypes['createProducer'],
    ]
    updateProducer?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProducer'],
    ]
    deleteProducer?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProducer']]
    createExportStock?: [
      {
        date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        format?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createExportStock'],
    ]
    createExportSale?: [
      {
        dateFrom: ValueTypes['DateTimeAsString'] | Variable<any, string>
        dateTo: ValueTypes['DateTimeAsString'] | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        format?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createExportSale'],
    ]
    createExportPurchase?: [
      {
        dateFrom: ValueTypes['DateTimeAsString'] | Variable<any, string>
        dateTo: ValueTypes['DateTimeAsString'] | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        format?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createExportPurchase'],
    ]
    createExportOrder?: [
      {
        dateFrom: ValueTypes['DateTimeAsString'] | Variable<any, string>
        dateTo: ValueTypes['DateTimeAsString'] | Variable<any, string>
        status?: string | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        format?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createExportOrder'],
    ]
    createExportInventory?: [
      {
        date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        approved?: string | undefined | null | Variable<any, string>
        rejected?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createExportInventory'],
    ]
    updateExportInventory?: [
      {
        id: number | Variable<any, string>
        date?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        depots?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        suppliers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        producers?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        approved?: string | undefined | null | Variable<any, string>
        rejected?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateExportInventory'],
    ]
    refreshExportInventory?: [{ id: number | Variable<any, string> }, ValueTypes['refreshExportInventory']]
    createExport?: [
      {
        type: string | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        filter?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createExport'],
    ]
    updateCompany?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        ic?: string | undefined | null | Variable<any, string>
        dic?: string | undefined | null | Variable<any, string>
        phone?: string | undefined | null | Variable<any, string>
        email?: string | undefined | null | Variable<any, string>
        street?: string | undefined | null | Variable<any, string>
        city?: string | undefined | null | Variable<any, string>
        zip?: string | undefined | null | Variable<any, string>
        country?: string | undefined | null | Variable<any, string>
        registrationNote?: string | undefined | null | Variable<any, string>
        nextEan?: number | undefined | null | Variable<any, string>
        defaultVoucherValidity?: number | undefined | null | Variable<any, string>
        batchExpirationMode?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCompany'],
    ]
    createShop?: [
      {
        name: string | Variable<any, string>
        street?: string | undefined | null | Variable<any, string>
        city?: string | undefined | null | Variable<any, string>
        country?: string | undefined | null | Variable<any, string>
        zip?: string | undefined | null | Variable<any, string>
        eetId?: number | undefined | null | Variable<any, string>
        cert?: number | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['createShop'],
    ]
    updateShop?: [
      {
        id: number | Variable<any, string>
        name: string | Variable<any, string>
        street?: string | undefined | null | Variable<any, string>
        city?: string | undefined | null | Variable<any, string>
        country?: string | undefined | null | Variable<any, string>
        zip?: string | undefined | null | Variable<any, string>
        eetId?: number | undefined | null | Variable<any, string>
        cert?: number | undefined | null | Variable<any, string>
        checkouts?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['updateShop'],
    ]
    deleteShop?: [{ id: number | Variable<any, string> }, ValueTypes['deleteShop']]
    createCert?: [
      {
        name: string | Variable<any, string>
        password: string | Variable<any, string>
        pkcs12: string | Variable<any, string>
      },
      ValueTypes['createCert'],
    ]
    updateCert?: [
      { id: number | Variable<any, string>; name?: string | undefined | null | Variable<any, string> },
      ValueTypes['updateCert'],
    ]
    deleteCert?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCert']]
    createEetReceipt?: [
      {
        checkout: number | Variable<any, string>
        number: number | Variable<any, string>
        dateCreated: string | Variable<any, string>
        currency: string | Variable<any, string>
        totalPrice: number | Variable<any, string>
        priceZeroVat?: number | undefined | null | Variable<any, string>
        priceStandardVat?: number | undefined | null | Variable<any, string>
        vatStandard?: number | undefined | null | Variable<any, string>
        priceFirstReducedVat?: number | undefined | null | Variable<any, string>
        vatFirstReduced?: number | undefined | null | Variable<any, string>
        priceSecondReducedVat?: number | undefined | null | Variable<any, string>
        vatSecondReduced?: number | undefined | null | Variable<any, string>
        priceForSubsequentSettlement?: number | undefined | null | Variable<any, string>
        priceUsedSubsequentSettlement?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['createEetReceipt'],
    ]
    sendEetReceipt?: [{ id: number | Variable<any, string> }, ValueTypes['sendEetReceipt']]
    createPayment?: [
      {
        name: string | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        eetEnable?: boolean | undefined | null | Variable<any, string>
        dispatchUnpaid?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createPayment'],
    ]
    updatePayment?: [
      {
        id: number | Variable<any, string>
        name: string | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        eetEnable?: boolean | undefined | null | Variable<any, string>
        dispatchUnpaid?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updatePayment'],
    ]
    deletePayment?: [{ id: number | Variable<any, string> }, ValueTypes['deletePayment']]
    createPaymentItem?: [
      {
        payment: number | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        voucher?: number | undefined | null | Variable<any, string>
        amount?: number | undefined | null | Variable<any, string>
        dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateCancelled?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        isPaid?: boolean | undefined | null | Variable<any, string>
        datePaid?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
      },
      ValueTypes['createPaymentItem'],
    ]
    updatePaymentItem?: [
      {
        id: number | Variable<any, string>
        payment?: number | undefined | null | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        voucher?: number | undefined | null | Variable<any, string>
        amount?: number | undefined | null | Variable<any, string>
        dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        dateCancelled?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        isPaid?: boolean | undefined | null | Variable<any, string>
        datePaid?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
      },
      ValueTypes['updatePaymentItem'],
    ]
    deletePaymentItem?: [{ id: number | Variable<any, string> }, ValueTypes['deletePaymentItem']]
    createDevLog?: [
      {
        name?: string | undefined | null | Variable<any, string>
        content?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createDevLog'],
    ]
    createPackage?: [
      {
        carrier?: string | undefined | null | Variable<any, string>
        order?: number | undefined | null | Variable<any, string>
        items?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        weight?: number | undefined | null | Variable<any, string>
        dimensionX?: number | undefined | null | Variable<any, string>
        dimensionY?: number | undefined | null | Variable<any, string>
        dimensionZ?: number | undefined | null | Variable<any, string>
        tariff?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['createPackage'],
    ]
    updatePackage?: [
      {
        id: number | Variable<any, string>
        carrier?: string | undefined | null | Variable<any, string>
        order?: number | undefined | null | Variable<any, string>
        items?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        weight?: number | undefined | null | Variable<any, string>
        dimensionX?: number | undefined | null | Variable<any, string>
        dimensionY?: number | undefined | null | Variable<any, string>
        dimensionZ?: number | undefined | null | Variable<any, string>
        tariff?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updatePackage'],
    ]
    deletePackage?: [{ id: number | Variable<any, string> }, ValueTypes['deletePackage']]
    resetPackage?: [{ id: number | Variable<any, string> }, ValueTypes['resetPackage']]
    sendPackage?: [{ id: number | Variable<any, string> }, ValueTypes['sendPackage']]
    createDisposal?: [
      {
        company?: number | undefined | null | Variable<any, string>
        carrier?: string | undefined | null | Variable<any, string>
        packages?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['createDisposal'],
    ]
    updateDisposal?: [
      {
        id: number | Variable<any, string>
        company?: number | undefined | null | Variable<any, string>
        carrier?: string | undefined | null | Variable<any, string>
        packages?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['updateDisposal'],
    ]
    deleteDisposal?: [{ id: number | Variable<any, string> }, ValueTypes['deleteDisposal']]
    sendDisposal?: [{ id: string | Variable<any, string> }, ValueTypes['sendDisposal']]
    createFile?: [
      {
        text: string | Variable<any, string>
        originalFilename: string | Variable<any, string>
        mimeType: string | Variable<any, string>
        base64Data: string | Variable<any, string>
        product?: number | undefined | null | Variable<any, string>
        order?: number | undefined | null | Variable<any, string>
        inventory?: number | undefined | null | Variable<any, string>
        productMovePack?: number | undefined | null | Variable<any, string>
        disposal?: number | undefined | null | Variable<any, string>
        purchaseOrder?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['createFile'],
    ]
    updateFile?: [
      {
        id: number | Variable<any, string>
        text?: string | undefined | null | Variable<any, string>
        originalFilename?: string | undefined | null | Variable<any, string>
        mimeType?: string | undefined | null | Variable<any, string>
        base64Data?: string | undefined | null | Variable<any, string>
        product?: number | undefined | null | Variable<any, string>
        order?: number | undefined | null | Variable<any, string>
        inventory?: number | undefined | null | Variable<any, string>
        productMovePack?: number | undefined | null | Variable<any, string>
        disposal?: number | undefined | null | Variable<any, string>
        purchaseOrder?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updateFile'],
    ]
    deleteFile?: [{ id: number | Variable<any, string> }, ValueTypes['deleteFile']]
    createCompanyCarrier?: [
      {
        carrier: string | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        options?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        enable?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['createCompanyCarrier'],
    ]
    updateCompanyCarrier?: [
      {
        id: number | Variable<any, string>
        carrier?: string | undefined | null | Variable<any, string>
        checkout?: number | undefined | null | Variable<any, string>
        options?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        enable?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCompanyCarrier'],
    ]
    deleteCompanyCarrier?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCompanyCarrier']]
    createConsent?: [
      {
        name: string | Variable<any, string>
        body?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createConsent'],
    ]
    updateConsent?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        body?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateConsent'],
    ]
    deleteConsent?: [{ id: number | Variable<any, string> }, ValueTypes['deleteConsent']]
    createCustomerConsent?: [
      {
        customer: number | Variable<any, string>
        consent: number | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createCustomerConsent'],
    ]
    deleteCustomerConsent?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCustomerConsent']]
    updateProductDepot?: [
      {
        id: number | Variable<any, string>
        position1?: string | undefined | null | Variable<any, string>
        position2?: string | undefined | null | Variable<any, string>
        position3?: string | undefined | null | Variable<any, string>
        purchasePrice?: number | undefined | null | Variable<any, string>
        supplier?: number | undefined | null | Variable<any, string>
        batch?: string | undefined | null | Variable<any, string>
        expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        inventoryQuantityStock?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductDepot'],
    ]
    createCategory?: [
      {
        name: string | Variable<any, string>
        text?: string | undefined | null | Variable<any, string>
        parent?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        position?: number | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['createCategory'],
    ]
    updateCategory?: [
      {
        id: number | Variable<any, string>
        name: string | Variable<any, string>
        text?: string | undefined | null | Variable<any, string>
        parent?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        position?: number | undefined | null | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
      },
      ValueTypes['updateCategory'],
    ]
    deleteCategory?: [{ id: number | Variable<any, string> }, ValueTypes['deleteCategory']]
    createParameter?: [
      {
        name: string | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        enumValues?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        unit?: string | undefined | null | Variable<any, string>
        text?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createParameter'],
    ]
    updateParameter?: [
      {
        id: number | Variable<any, string>
        name: string | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        enumValues?: Array<string | undefined | null> | undefined | null | Variable<any, string>
        unit?: string | undefined | null | Variable<any, string>
        text?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateParameter'],
    ]
    deleteParameter?: [{ id: number | Variable<any, string> }, ValueTypes['deleteParameter']]
    createOrderGroup?: [
      {
        name: string | Variable<any, string>
        user?: number | undefined | null | Variable<any, string>
        userPosition?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['createOrderGroup'],
    ]
    updateOrderGroup?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        user?: number | undefined | null | Variable<any, string>
        userPosition?: number | undefined | null | Variable<any, string>
      },
      ValueTypes['updateOrderGroup'],
    ]
    deleteOrderGroup?: [{ id: number | Variable<any, string> }, ValueTypes['deleteOrderGroup']]
    createVoucher?: [
      {
        name: string | Variable<any, string>
        code: string | Variable<any, string>
        discountType: string | Variable<any, string>
        discountPercent?: number | undefined | null | Variable<any, string>
        discountValue?: number | undefined | null | Variable<any, string>
        maxUse?: number | undefined | null | Variable<any, string>
        validFrom?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        validTo?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        enabled?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        isPayment?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['createVoucher'],
    ]
    updateVoucher?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        discountType?: string | undefined | null | Variable<any, string>
        discountPercent?: number | undefined | null | Variable<any, string>
        discountValue?: number | undefined | null | Variable<any, string>
        maxUse?: number | undefined | null | Variable<any, string>
        validFrom?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        validTo?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        enabled?: boolean | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        isPayment?: boolean | undefined | null | Variable<any, string>
      },
      ValueTypes['updateVoucher'],
    ]
    deleteVoucher?: [{ id: number | Variable<any, string> }, ValueTypes['deleteVoucher']]
    createPriceLevel?: [
      {
        name: string | Variable<any, string>
        isPercentage?: boolean | undefined | null | Variable<any, string>
        percent?: number | undefined | null | Variable<any, string>
        withVat?: boolean | undefined | null | Variable<any, string>
        currency?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createPricePriceLevel'],
    ]
    updatePriceLevel?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        isPercentage?: boolean | undefined | null | Variable<any, string>
        percent?: number | undefined | null | Variable<any, string>
        withVat?: boolean | undefined | null | Variable<any, string>
        currency?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updatePriceLevel'],
    ]
    deletePriceLevel?: [{ id: number | Variable<any, string> }, ValueTypes['deletePriceLevel']]
    createProductParameter?: [
      {
        product: number | Variable<any, string>
        parameter: number | Variable<any, string>
        value?: ValueTypes['StringOrArray'] | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createProductParameter'],
    ]
    updateProductParameter?: [
      {
        id: number | Variable<any, string>
        product: number | Variable<any, string>
        parameter: number | Variable<any, string>
        value?: ValueTypes['StringOrArray'] | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductParameter'],
    ]
    deleteProductParameter?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductParameter']]
    createProductPriceLevel?: [
      {
        product: number | Variable<any, string>
        priceLevel: number | Variable<any, string>
        sellPrice?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createProductPriceLevel'],
    ]
    updateProductPriceLevel?: [
      {
        id: number | Variable<any, string>
        product: number | Variable<any, string>
        priceLevel: number | Variable<any, string>
        sellPrice?: number | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductPriceLevel'],
    ]
    deleteProductPriceLevel?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductPriceLevel']]
    createTag?: [
      {
        name: string | Variable<any, string>
        type: string | Variable<any, string>
        color?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createTag'],
    ]
    updateTag?: [
      {
        id: number | Variable<any, string>
        name?: string | undefined | null | Variable<any, string>
        type?: string | undefined | null | Variable<any, string>
        color?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateTag'],
    ]
    deleteTag?: [{ id: number | Variable<any, string> }, ValueTypes['deleteTag']]
    createProductPack?: [
      {
        product: number | Variable<any, string>
        quantity: number | Variable<any, string>
        ean?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createProductPack'],
    ]
    updateProductPack?: [
      {
        id: number | Variable<any, string>
        product: number | Variable<any, string>
        quantity: number | Variable<any, string>
        ean?: string | undefined | null | Variable<any, string>
        code?: string | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updateProductPack'],
    ]
    deleteProductPack?: [{ id: number | Variable<any, string> }, ValueTypes['deleteProductPack']]
    createPurchaseOrder?: [
      {
        supplier: number | Variable<any, string>
        depot: number | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        privateNote?: string | undefined | null | Variable<any, string>
        dateEstimatedArrival: ValueTypes['DateTimeAsString'] | Variable<any, string>
        items: Array<ValueTypes['createPurchaseOrderItem_'] | undefined | null> | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        files?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        externalReference?: string | undefined | null | Variable<any, string>
        supplierReference?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createPurchaseOrder'],
    ]
    updatePurchaseOrder?: [
      {
        id: number | Variable<any, string>
        supplier?: number | undefined | null | Variable<any, string>
        depot?: number | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        privateNote?: string | undefined | null | Variable<any, string>
        dateEstimatedArrival?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
        items?:
          | Array<ValueTypes['createPurchaseOrderItem_'] | undefined | null>
          | undefined
          | null
          | Variable<any, string>
        tags?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        files?: Array<number | undefined | null> | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
        externalReference?: string | undefined | null | Variable<any, string>
        supplierReference?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updatePurchaseOrder'],
    ]
    deletePurchaseOrder?: [{ id: number | Variable<any, string> }, ValueTypes['deletePurchaseOrder']]
    createPurchaseOrderItem?: [
      {
        purchaseOrder: number | Variable<any, string>
        currency: string | Variable<any, string>
        product: number | Variable<any, string>
        quantity: number | Variable<any, string>
        purchasePrice?: number | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['createPurchaseOrderItem'],
    ]
    updatePurchaseOrderItem?: [
      {
        id: number | Variable<any, string>
        currency?: string | undefined | null | Variable<any, string>
        product?: number | undefined | null | Variable<any, string>
        quantity?: number | undefined | null | Variable<any, string>
        purchasePrice?: number | undefined | null | Variable<any, string>
        note?: string | undefined | null | Variable<any, string>
        externalId?: string | undefined | null | Variable<any, string>
      },
      ValueTypes['updatePurchaseOrderItem'],
    ]
    deletePurchaseOrderItem?: [{ id: number | Variable<any, string> }, ValueTypes['deletePurchaseOrderItem']]
    __typename?: boolean | `@${string}`
  }>
  ['createProduct']: AliasType<{
    data?: ValueTypes['Product']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProduct']: AliasType<{
    data?: ValueTypes['Product']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProduct']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['refreshProductDepots']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductMove']: {
    product?: number | undefined | null | Variable<any, string>
    depotFrom?: number | undefined | null | Variable<any, string>
    depotTo?: number | undefined | null | Variable<any, string>
    productDepot?: number | undefined | null | Variable<any, string>
    supplier?: number | undefined | null | Variable<any, string>
    amount: number | Variable<any, string>
    purchasePrice?: number | undefined | null | Variable<any, string>
    purchaseCurrency?: string | undefined | null | Variable<any, string>
    purchaseCurrencyDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    purchaseOrderItem?: number | undefined | null | Variable<any, string>
    expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    batch?: string | undefined | null | Variable<any, string>
    position1?: string | undefined | null | Variable<any, string>
    position2?: string | undefined | null | Variable<any, string>
    position3?: string | undefined | null | Variable<any, string>
    note?: string | undefined | null | Variable<any, string>
    orderItem?: number | undefined | null | Variable<any, string>
    serialNumbers?: Array<string | undefined | null> | undefined | null | Variable<any, string>
  }
  ['createProductMovePack']: AliasType<{
    data?: ValueTypes['ProductMovePack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductMovePack']: AliasType<{
    data?: ValueTypes['ProductMovePack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductMovePack']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductMove']: AliasType<{
    data?: ValueTypes['ProductMove']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductMove']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductPrice']: AliasType<{
    data?: ValueTypes['ProductPrice']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductPrice']: AliasType<{
    data?: ValueTypes['ProductPrice']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductPrice']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductBundle']: AliasType<{
    data?: ValueTypes['ProductBundle']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductBundle']: AliasType<{
    data?: ValueTypes['ProductBundle']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductBundle']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductDepotMetric']: AliasType<{
    data?: ValueTypes['ProductDepotMetric']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductDepotMetric']: AliasType<{
    data?: ValueTypes['ProductDepotMetric']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductDepotMetric']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createInstitution']: AliasType<{
    data?: ValueTypes['Institution']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateInstitution']: AliasType<{
    data?: ValueTypes['Institution']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteInstitution']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createOrderItem_']: {
    name?: string | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    ean?: string | undefined | null | Variable<any, string>
    code?: string | undefined | null | Variable<any, string>
    quantity?: number | undefined | null | Variable<any, string>
    price?: number | undefined | null | Variable<any, string>
    sale?: number | undefined | null | Variable<any, string>
    vat?: number | undefined | null | Variable<any, string>
    product?: number | undefined | null | Variable<any, string>
    serial?: string | undefined | null | Variable<any, string>
    expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    batch?: string | undefined | null | Variable<any, string>
    note?: string | undefined | null | Variable<any, string>
    isForSubsequentSettlement?: boolean | undefined | null | Variable<any, string>
    picked?: boolean | undefined | null | Variable<any, string>
    packed?: boolean | undefined | null | Variable<any, string>
    voucher?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['createPaymentItem_']: {
    payment: number | Variable<any, string>
    checkout?: number | undefined | null | Variable<any, string>
    voucher?: number | undefined | null | Variable<any, string>
    amount: number | Variable<any, string>
    dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    dateCancelled?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    isPaid?: boolean | undefined | null | Variable<any, string>
    datePaid?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['processStatusRelation']: {
    status?: string | undefined | null | Variable<any, string>
    note?: string | undefined | null | Variable<any, string>
  }
  ['createOrder']: AliasType<{
    data?: ValueTypes['Order']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateOrderItem_']: {
    id?: number | undefined | null | Variable<any, string>
    name?: string | undefined | null | Variable<any, string>
    type?: string | undefined | null | Variable<any, string>
    ean?: string | undefined | null | Variable<any, string>
    code?: string | undefined | null | Variable<any, string>
    quantity?: number | undefined | null | Variable<any, string>
    price?: string | undefined | null | Variable<any, string>
    sale?: string | undefined | null | Variable<any, string>
    vat?: number | undefined | null | Variable<any, string>
    product?: number | undefined | null | Variable<any, string>
    serial?: string | undefined | null | Variable<any, string>
    expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    batch?: string | undefined | null | Variable<any, string>
    note?: string | undefined | null | Variable<any, string>
    isForSubsequentSettlement?: boolean | undefined | null | Variable<any, string>
    picked?: boolean | undefined | null | Variable<any, string>
    packed?: boolean | undefined | null | Variable<any, string>
    voucher?: number | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['updatePaymentItem_']: {
    payment?: number | undefined | null | Variable<any, string>
    checkout?: number | undefined | null | Variable<any, string>
    voucher?: number | undefined | null | Variable<any, string>
    amount?: string | undefined | null | Variable<any, string>
    dateCreated?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    dateCancelled?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
    isPaid?: boolean | undefined | null | Variable<any, string>
    datePaid?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
  }
  ['updateOrder']: AliasType<{
    data?: ValueTypes['Order']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteReservation']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createOrderItem']: AliasType<{
    data?: ValueTypes['OrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateOrderItem']: AliasType<{
    data?: ValueTypes['OrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteOrderItem']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateClearanceItemLocations']: {
    amount: number | Variable<any, string>
    depot?: number | undefined | null | Variable<any, string>
    productDepot?: number | undefined | null | Variable<any, string>
    position1?: string | undefined | null | Variable<any, string>
    position2?: string | undefined | null | Variable<any, string>
    position3?: string | undefined | null | Variable<any, string>
    expirationDate?: ValueTypes['DateTimeAsString'] | undefined | null | Variable<any, string>
    batch?: string | undefined | null | Variable<any, string>
    locked?: boolean | undefined | null | Variable<any, string>
  }
  ['updateClearanceItem']: AliasType<{
    data?: ValueTypes['ClearanceItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createUser']: AliasType<{
    data?: ValueTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateUser']: AliasType<{
    data?: ValueTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteUser']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createUserGroup']: AliasType<{
    data?: ValueTypes['UserGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateUserGroup']: AliasType<{
    data?: ValueTypes['UserGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteUserGroup']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCustomerAddress']: {
    companyName?: string | undefined | null | Variable<any, string>
    firstName?: string | undefined | null | Variable<any, string>
    lastName?: string | undefined | null | Variable<any, string>
    street?: string | undefined | null | Variable<any, string>
    city?: string | undefined | null | Variable<any, string>
    country?: string | undefined | null | Variable<any, string>
    zip?: string | undefined | null | Variable<any, string>
    ic?: string | undefined | null | Variable<any, string>
    dic?: string | undefined | null | Variable<any, string>
    phone?: string | undefined | null | Variable<any, string>
  }
  ['createCustomer']: AliasType<{
    data?: ValueTypes['Customer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCustomer']: AliasType<{
    data?: ValueTypes['Customer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCustomer']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createAddress']: AliasType<{
    data?: ValueTypes['Address']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateAddress']: AliasType<{
    data?: ValueTypes['Address']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteAddress']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCheckout']: AliasType<{
    data?: ValueTypes['Checkout']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCheckout']: AliasType<{
    data?: ValueTypes['Checkout']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCheckout']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCheckoutExtraOperation']: AliasType<{
    data?: ValueTypes['CheckoutExtraOperation']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCheckoutExtraOperation']: AliasType<{
    data?: ValueTypes['CheckoutExtraOperation']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCheckoutExtraOperation']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCheckoutClosing']: AliasType<{
    data?: ValueTypes['CheckoutClosing']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCheckoutClosing']: AliasType<{
    data?: ValueTypes['CheckoutClosing']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCheckoutClosing']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createVat']: AliasType<{
    data?: ValueTypes['Vat']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateVat']: AliasType<{
    data?: ValueTypes['Vat']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteVat']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createDepot']: AliasType<{
    data?: ValueTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateDepot']: AliasType<{
    data?: ValueTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteDepot']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createSupplier']: AliasType<{
    data?: ValueTypes['Supplier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateSupplier']: AliasType<{
    data?: ValueTypes['Supplier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteSupplier']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProducer']: AliasType<{
    data?: ValueTypes['Producer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProducer']: AliasType<{
    data?: ValueTypes['Producer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProducer']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportStock']: AliasType<{
    data?: ValueTypes['Stock']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportSale']: AliasType<{
    data?: ValueTypes['Sale']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportPurchase']: AliasType<{
    data?: ValueTypes['Purchase']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportOrder']: AliasType<{
    data?: ValueTypes['exportOrder']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportInventory']: AliasType<{
    data?: ValueTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateExportInventory']: AliasType<{
    data?: ValueTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['refreshExportInventory']: AliasType<{
    data?: ValueTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExport']: AliasType<{
    data?: ValueTypes['Export']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCompany']: AliasType<{
    data?: ValueTypes['Company']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createShop']: AliasType<{
    data?: ValueTypes['Shop']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateShop']: AliasType<{
    data?: ValueTypes['Shop']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteShop']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCert']: AliasType<{
    data?: ValueTypes['Cert']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCert']: AliasType<{
    data?: ValueTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCert']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createEetReceipt']: AliasType<{
    data?: ValueTypes['Receipt']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['sendEetReceipt']: AliasType<{
    data?: ValueTypes['Receipt']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPayment']: AliasType<{
    data?: ValueTypes['Payment']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePayment']: AliasType<{
    data?: ValueTypes['Payment']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePayment']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPaymentItem']: AliasType<{
    data?: ValueTypes['PaymentItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePaymentItem']: AliasType<{
    data?: ValueTypes['PaymentItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePaymentItem']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createDevLog']: AliasType<{
    data?: ValueTypes['DevLog']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['DevLog']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    content?: boolean | `@${string}`
    created?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPackage']: AliasType<{
    data?: ValueTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePackage']: AliasType<{
    data?: ValueTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePackage']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['resetPackage']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['sendPackage']: AliasType<{
    data?: ValueTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createDisposal']: AliasType<{
    data?: ValueTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateDisposal']: AliasType<{
    data?: ValueTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteDisposal']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['sendDisposal']: AliasType<{
    data?: ValueTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createFile']: AliasType<{
    data?: ValueTypes['File']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateFile']: AliasType<{
    data?: ValueTypes['File']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteFile']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCompanyCarrier']: AliasType<{
    data?: ValueTypes['CompanyCarrier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCompanyCarrier']: AliasType<{
    data?: ValueTypes['CompanyCarrier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCompanyCarrier']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createConsent']: AliasType<{
    data?: ValueTypes['Consent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateConsent']: AliasType<{
    data?: ValueTypes['Consent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteConsent']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCustomerConsent']: AliasType<{
    data?: ValueTypes['CustomerConsent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCustomerConsent']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductDepot']: AliasType<{
    data?: ValueTypes['ProductDepot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCategory']: AliasType<{
    data?: ValueTypes['Category']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCategory']: AliasType<{
    data?: ValueTypes['Category']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCategory']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createParameter']: AliasType<{
    data?: ValueTypes['Parameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateParameter']: AliasType<{
    data?: ValueTypes['Parameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteParameter']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createOrderGroup']: AliasType<{
    data?: ValueTypes['OrderGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateOrderGroup']: AliasType<{
    data?: ValueTypes['OrderGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteOrderGroup']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createVoucher']: AliasType<{
    data?: ValueTypes['Voucher']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateVoucher']: AliasType<{
    data?: ValueTypes['Voucher']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteVoucher']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPricePriceLevel']: AliasType<{
    data?: ValueTypes['PriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePriceLevel']: AliasType<{
    data?: ValueTypes['PriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePriceLevel']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductParameter']: AliasType<{
    data?: ValueTypes['ProductParameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductParameter']: AliasType<{
    data?: ValueTypes['ProductParameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductParameter']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductPriceLevel']: AliasType<{
    data?: ValueTypes['ProductPriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductPriceLevel']: AliasType<{
    data?: ValueTypes['ProductPriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductPriceLevel']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createTag']: AliasType<{
    data?: ValueTypes['Tag']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateTag']: AliasType<{
    data?: ValueTypes['Tag']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteTag']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductPack']: AliasType<{
    data?: ValueTypes['ProductPack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductPack']: AliasType<{
    data?: ValueTypes['ProductPack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductPack']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPurchaseOrderItem_']: {
    product: number | Variable<any, string>
    currency: string | Variable<any, string>
    quantity: number | Variable<any, string>
    purchasePrice?: number | undefined | null | Variable<any, string>
    note?: string | undefined | null | Variable<any, string>
    externalId?: string | undefined | null | Variable<any, string>
  }
  ['createPurchaseOrder']: AliasType<{
    data?: ValueTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePurchaseOrder']: AliasType<{
    data?: ValueTypes['PurchaseOrder']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePurchaseOrder']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPurchaseOrderItem']: AliasType<{
    data?: ValueTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePurchaseOrderItem']: AliasType<{
    data?: ValueTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePurchaseOrderItem']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
}

export type ResolverInputTypes = {
  ['schema']: AliasType<{
    query?: ResolverInputTypes['RootSchemaQuery']
    mutation?: ResolverInputTypes['RootSchemaMutation']
    __typename?: boolean | `@${string}`
  }>
  ['RootSchemaQuery']: AliasType<{
    user?: [{ id: number }, ResolverInputTypes['user']]
    userMyProfile?: ResolverInputTypes['userMyProfile']
    users?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['userFilters'] | undefined | null
      },
      ResolverInputTypes['users'],
    ]
    isUserEmailUnique?: [{ email: string }, ResolverInputTypes['isUserEmailUnique']]
    userGroup?: [{ id: number }, ResolverInputTypes['userGroup']]
    userGroups?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['userGroupFilters'] | undefined | null
      },
      ResolverInputTypes['userGroups'],
    ]
    roles?: ResolverInputTypes['Role']
    product?: [{ id: number }, ResolverInputTypes['product']]
    products?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productFilters'] | undefined | null
        currency?: string | undefined | null
        currencyDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
      },
      ResolverInputTypes['products'],
    ]
    productPurchasePrice?: [{ id: number; depot: number; quantity: number }, ResolverInputTypes['productPurchasePrice']]
    productSellItems?: [
      {
        product: number
        depots?: Array<number | undefined | null> | undefined | null
        checkout?: number | undefined | null
      },
      ResolverInputTypes['productSellItems'],
    ]
    productMovePacks?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productMovePackFilters'] | undefined | null
      },
      ResolverInputTypes['productMovePacks'],
    ]
    productMoves?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productMoveFilters'] | undefined | null
      },
      ResolverInputTypes['productMoves'],
    ]
    productMovePack?: [{ id: number }, ResolverInputTypes['productMovePack']]
    institution?: [{ id: number }, ResolverInputTypes['institution']]
    institutions?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['institutionFilters'] | undefined | null
      },
      ResolverInputTypes['institutions'],
    ]
    order?: [{ id: number }, ResolverInputTypes['order']]
    orders?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['orderFilters'] | undefined | null
      },
      ResolverInputTypes['orders'],
    ]
    soldItems?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['soldItemFilters'] | undefined | null
      },
      ResolverInputTypes['soldItems'],
    ]
    orderItems?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['orderItemFilters'] | undefined | null
      },
      ResolverInputTypes['orderItems'],
    ]
    orderItem?: [{ id: number }, ResolverInputTypes['orderItem']]
    customer?: [{ id: string }, ResolverInputTypes['customer']]
    customers?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['customerFilters'] | undefined | null
      },
      ResolverInputTypes['customers'],
    ]
    address?: [{ id: number }, ResolverInputTypes['address']]
    addresses?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['addressesFilters'] | undefined | null
      },
      ResolverInputTypes['addresses'],
    ]
    checkout?: [{ id: number }, ResolverInputTypes['checkout']]
    checkouts?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['checkoutFilters'] | undefined | null
      },
      ResolverInputTypes['checkouts'],
    ]
    eventTypes?: ResolverInputTypes['eventTypes']
    consent?: [{ id: number }, ResolverInputTypes['consent']]
    consents?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['consentsFilters'] | undefined | null
      },
      ResolverInputTypes['consents'],
    ]
    checkoutExtraOperation?: [{ id: number }, ResolverInputTypes['checkoutExtraOperation']]
    checkoutExtraOperations?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['checkoutExtraOperationFilters'] | undefined | null
      },
      ResolverInputTypes['checkoutExtraOperations'],
    ]
    checkoutClosing?: [{ id: number }, ResolverInputTypes['checkoutClosing']]
    checkoutClosings?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['checkoutClosingFilters'] | undefined | null
      },
      ResolverInputTypes['checkoutClosings'],
    ]
    vat?: [{ id: number }, ResolverInputTypes['vat']]
    vats?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['vatFilters'] | undefined | null
      },
      ResolverInputTypes['vats'],
    ]
    depot?: [{ id: number }, ResolverInputTypes['depot']]
    depots?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['depotFilters'] | undefined | null
      },
      ResolverInputTypes['depots'],
    ]
    supplier?: [{ id: number }, ResolverInputTypes['supplier']]
    suppliers?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['supplierFilters'] | undefined | null
      },
      ResolverInputTypes['suppliers'],
    ]
    producer?: [{ id: number }, ResolverInputTypes['producer']]
    producers?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['producerFilters'] | undefined | null
      },
      ResolverInputTypes['producers'],
    ]
    exportStocks?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportStockFilters'] | undefined | null
      },
      ResolverInputTypes['exportStocks'],
    ]
    exportSales?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportSaleFilters'] | undefined | null
      },
      ResolverInputTypes['exportSales'],
    ]
    exportPurchases?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportPurchaseFilters'] | undefined | null
      },
      ResolverInputTypes['exportPurchases'],
    ]
    exportOrders?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportOrderFilters'] | undefined | null
      },
      ResolverInputTypes['exportOrders'],
    ]
    exportInventory?: [{ id: number }, ResolverInputTypes['exportInventory']]
    exportInventories?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportInventoryFilters'] | undefined | null
      },
      ResolverInputTypes['exportInventories'],
    ]
    export?: [{ id: number }, ResolverInputTypes['export']]
    exports?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportFilters'] | undefined | null
      },
      ResolverInputTypes['exports'],
    ]
    exportTypes?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['exportTypeFilters'] | undefined | null
      },
      ResolverInputTypes['exportTypes'],
    ]
    shop?: [{ id: number }, ResolverInputTypes['shop']]
    shops?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['shopFilters'] | undefined | null
      },
      ResolverInputTypes['shops'],
    ]
    cert?: [{ id: number }, ResolverInputTypes['cert']]
    certs?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['filters'] | undefined | null
      },
      ResolverInputTypes['certs'],
    ]
    eetReceipts?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['eetReceiptFilters'] | undefined | null
      },
      ResolverInputTypes['eetReceipts'],
    ]
    payment?: [{ id: number }, ResolverInputTypes['payment']]
    payments?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['paymentFilters'] | undefined | null
      },
      ResolverInputTypes['payments'],
    ]
    paymentItem?: [{ id: number }, ResolverInputTypes['paymentItem']]
    paymentItems?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['paymentItemsFilters'] | undefined | null
      },
      ResolverInputTypes['paymentItems'],
    ]
    paymentTypes?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['paymentTypeFilters'] | undefined | null
      },
      ResolverInputTypes['paymentTypes'],
    ]
    processStatuses?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['processStatusesFilters'] | undefined | null
      },
      ResolverInputTypes['processStatuses'],
    ]
    carriers?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['carriersFilters'] | undefined | null
      },
      ResolverInputTypes['carriers'],
    ]
    currencies?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['currenciesFilters'] | undefined | null
      },
      ResolverInputTypes['currencies'],
    ]
    package?: [{ id: string }, ResolverInputTypes['package']]
    packages?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['packageFilters'] | undefined | null
      },
      ResolverInputTypes['packages'],
    ]
    stats?: [
      { filters?: ResolverInputTypes['packageStatsFilters'] | undefined | null },
      ResolverInputTypes['packageStats'],
    ]
    disposal?: [{ id: string }, ResolverInputTypes['disposal']]
    disposals?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['disposalFilters'] | undefined | null
      },
      ResolverInputTypes['disposals'],
    ]
    productDepotMetric?: [{ id: number }, ResolverInputTypes['productDepotMetric']]
    productDepotMetrics?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productDepotMetricFilters'] | undefined | null
      },
      ResolverInputTypes['productDepotMetrics'],
    ]
    file?: [{ id: number }, ResolverInputTypes['file']]
    files?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['fileFilters'] | undefined | null
      },
      ResolverInputTypes['files'],
    ]
    nextEan?: ResolverInputTypes['nextEan']
    statsCarrierUsage?: [
      {
        from: ResolverInputTypes['DateTimeAsString']
        to: ResolverInputTypes['DateTimeAsString']
        checkout?: number | undefined | null
        status?: string | undefined | null
      },
      ResolverInputTypes['statsCarrierUsage'],
    ]
    statsPaymentSales?: [
      {
        from: ResolverInputTypes['DateTimeAsString']
        to: ResolverInputTypes['DateTimeAsString']
        checkout?: number | undefined | null
        status?: string | undefined | null
      },
      ResolverInputTypes['statsPaymentSales'],
    ]
    statsBestsellers?: [
      {
        from: ResolverInputTypes['DateTimeAsString']
        to: ResolverInputTypes['DateTimeAsString']
        checkout?: number | undefined | null
        status?: string | undefined | null
        results?: number | undefined | null
        sort?: ResolverInputTypes['statsBestsellersSort'] | undefined | null
      },
      ResolverInputTypes['statsBestsellers'],
    ]
    companyCarrier?: [{ id: string }, ResolverInputTypes['companyCarrier']]
    companyCarriers?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['companyCarrierFilters'] | undefined | null
      },
      ResolverInputTypes['companyCarriers'],
    ]
    category?: [{ id: number }, ResolverInputTypes['category']]
    categories?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['categoryFilters'] | undefined | null
      },
      ResolverInputTypes['categories'],
    ]
    parameter?: [{ id: number }, ResolverInputTypes['parameter']]
    parameters?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['parametersFilters'] | undefined | null
      },
      ResolverInputTypes['parameters'],
    ]
    orderGroup?: [{ id: number }, ResolverInputTypes['orderGroup']]
    orderGroups?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['orderGroupFilters'] | undefined | null
      },
      ResolverInputTypes['orderGroups'],
    ]
    voucher?: [{ id: number }, ResolverInputTypes['voucher']]
    vouchers?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['voucherFilters'] | undefined | null
      },
      ResolverInputTypes['vouchers'],
    ]
    productPrices?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productPriceFilters'] | undefined | null
      },
      ResolverInputTypes['productPrices'],
    ]
    priceLevel?: [{ id: number }, ResolverInputTypes['priceLevel']]
    priceLevels?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['priceLevelFilters'] | undefined | null
      },
      ResolverInputTypes['priceLevels'],
    ]
    productParameter?: [{ id: number }, ResolverInputTypes['productParameter']]
    productParameters?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productParameterFilters'] | undefined | null
      },
      ResolverInputTypes['productParameters'],
    ]
    productPriceLevel?: [{ id: number }, ResolverInputTypes['productPriceLevel']]
    productPriceLevels?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productPriceLevelFilters'] | undefined | null
      },
      ResolverInputTypes['productPriceLevels'],
    ]
    tag?: [{ id: number }, ResolverInputTypes['tag']]
    tags?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['tagFilters'] | undefined | null
      },
      ResolverInputTypes['tags'],
    ]
    productPack?: [{ id: number }, ResolverInputTypes['productPack']]
    productPacks?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['productPackFilters'] | undefined | null
      },
      ResolverInputTypes['productPacks'],
    ]
    purchaseOrder?: [{ id: number }, ResolverInputTypes['purchaseOrder']]
    purchaseOrders?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['purchaseOrderFilters'] | undefined | null
      },
      ResolverInputTypes['purchaseOrders'],
    ]
    purchaseOrderItem?: [{ id: number }, ResolverInputTypes['purchaseOrderItem']]
    modules?: [
      {
        page?: number | undefined | null
        sort?: string | undefined | null
        direction?: string | undefined | null
        filters?: ResolverInputTypes['modulesFilters'] | undefined | null
      },
      ResolverInputTypes['modules'],
    ]
    __typename?: boolean | `@${string}`
  }>
  ['user']: AliasType<{
    data?: ResolverInputTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['User']: AliasType<{
    id?: boolean | `@${string}`
    email?: boolean | `@${string}`
    name?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    groups?: ResolverInputTypes['UserGroup']
    roles?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    pin?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    customers?: ResolverInputTypes['Customer']
    checkouts?: ResolverInputTypes['Checkout']
    depots?: ResolverInputTypes['Depot']
    locale?: boolean | `@${string}`
    deletable?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserGroup']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    roles?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Company']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    ic?: boolean | `@${string}`
    dic?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    email?: boolean | `@${string}`
    street?: boolean | `@${string}`
    city?: boolean | `@${string}`
    zip?: boolean | `@${string}`
    country?: boolean | `@${string}`
    currency?: ResolverInputTypes['Currency']
    registrationNote?: boolean | `@${string}`
    nextEan?: boolean | `@${string}`
    billLogo?: boolean | `@${string}`
    parent?: ResolverInputTypes['Company']
    children?: ResolverInputTypes['Company']
    carrierRelations?: ResolverInputTypes['CompanyCarrier']
    moduleRelations?: ResolverInputTypes['CompanyModule']
    payments?: ResolverInputTypes['Payment']
    defaultVoucherValidity?: boolean | `@${string}`
    pickingOnlyOneDepot?: boolean | `@${string}`
    customOptions?: boolean | `@${string}`
    batchExpirationMode?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    purchasePriceWithVat?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Currency']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    ratio?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CompanyCarrier']: AliasType<{
    id?: boolean | `@${string}`
    enable?: boolean | `@${string}`
    options?: boolean | `@${string}`
    carrier?: ResolverInputTypes['Carrier']
    checkout?: ResolverInputTypes['Checkout']
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Carrier']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    color?: boolean | `@${string}`
    position?: boolean | `@${string}`
    tariffs?: ResolverInputTypes['CarrierTariff']
    requiredShippingAddress?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CarrierTariff']: AliasType<{
    id?: boolean | `@${string}`
    const?: boolean | `@${string}`
    name?: boolean | `@${string}`
    cod?: boolean | `@${string}`
    position?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Checkout']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    amount?: boolean | `@${string}`
    nextReservationNumber?: boolean | `@${string}`
    nextBillNumber?: boolean | `@${string}`
    billFooter?: boolean | `@${string}`
    payments?: ResolverInputTypes['Payment']
    depots?: ResolverInputTypes['Depot']
    returnsDepot?: ResolverInputTypes['Depot']
    currentClosing?: ResolverInputTypes['CheckoutClosing']
    eetId?: boolean | `@${string}`
    eetEnable?: boolean | `@${string}`
    eetPlayground?: boolean | `@${string}`
    eetVerificationMode?: boolean | `@${string}`
    billWidth?: boolean | `@${string}`
    billFontSize?: boolean | `@${string}`
    billFontWeight?: boolean | `@${string}`
    eventUrl?: boolean | `@${string}`
    eventTypes?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    negativeReservation?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    users?: ResolverInputTypes['User']
    autoPicking?: boolean | `@${string}`
    autoPacking?: boolean | `@${string}`
    autoDispatched?: boolean | `@${string}`
    institution?: ResolverInputTypes['Institution']
    position?: boolean | `@${string}`
    deletable?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Payment']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    eetEnable?: boolean | `@${string}`
    /** Expedovat i nezaplacené objednávky */
    dispatchUnpaid?: boolean | `@${string}`
    type?: ResolverInputTypes['PaymentType']
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['PaymentType']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Depot']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    emailIn?: boolean | `@${string}`
    unavailablesUrl?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    companyCarriers?: ResolverInputTypes['CompanyCarrier']
    checkouts?: ResolverInputTypes['Checkout']
    institution?: ResolverInputTypes['Institution']
    users?: ResolverInputTypes['User']
    productDepots?: ResolverInputTypes['ProductDepot']
    position?: boolean | `@${string}`
    deletable?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Institution']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    checkouts?: ResolverInputTypes['Checkout']
    depots?: ResolverInputTypes['Depot']
    __typename?: boolean | `@${string}`
  }>
  ['ProductDepot']: AliasType<{
    id?: boolean | `@${string}`
    depot?: ResolverInputTypes['Depot']
    product?: ResolverInputTypes['Product']
    supplier?: ResolverInputTypes['Supplier']
    purchasePrice?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityAvailable?: boolean | `@${string}`
    quantityUnavailable?: boolean | `@${string}`
    inventoryQuantityStock?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    position?: boolean | `@${string}`
    position1?: boolean | `@${string}`
    position2?: boolean | `@${string}`
    position3?: boolean | `@${string}`
    clearanceItemId?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Product']: AliasType<{
    id?: boolean | `@${string}`
    /** Název */
    name?: boolean | `@${string}`
    /** Název včetně názvu rodičovského produktu */
    fullName?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean2?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean3?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean4?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean5?: boolean | `@${string}`
    /** Kód */
    code?: boolean | `@${string}`
    /** Kód2 */
    code2?: boolean | `@${string}`
    /** Kód3 */
    code3?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Prodejní cena */
    sellPrice?: boolean | `@${string}`
    /** Prodejní cena bez DPH */
    sellPriceWithoutVat?: boolean | `@${string}`
    /** Cena před slevou */
    beforeSellPrice?: boolean | `@${string}`
    /** Aktuální prodejní cena - včetně započtených akčních cen */
    actualSellPrice?: boolean | `@${string}`
    /** Aktuální prodejní cena bez DPH */
    actualSellPriceWithoutVat?: boolean | `@${string}`
    /** Aktuální cena před slevou - včetně započtených akčních cen */
    actualBeforeSellPrice?: boolean | `@${string}`
    /** Výchozí nákupní cena */
    purchasePrice?: boolean | `@${string}`
    /** Výchozí nákupní měna */
    purchaseCurrency?: ResolverInputTypes['Currency']
    /** Váha */
    weight?: boolean | `@${string}`
    /** Rozměr X */
    dimensionX?: boolean | `@${string}`
    /** Rozměr Y */
    dimensionY?: boolean | `@${string}`
    /** Rozměr Z */
    dimensionZ?: boolean | `@${string}`
    /** Země výroby */
    originCountry?: boolean | `@${string}`
    /** HS kod */
    hsCode?: boolean | `@${string}`
    /** Je produkt aktnivní? */
    enabled?: boolean | `@${string}`
    /** DPH */
    vat?: ResolverInputTypes['Vat']
    /** Výrobce */
    producer?: ResolverInputTypes['Producer']
    /** Výchozí dodavatel */
    supplier?: ResolverInputTypes['Supplier']
    /** Rodičovský produkt */
    parent?: ResolverInputTypes['Product']
    /** Potomci / varianty produktu */
    children?: ResolverInputTypes['Product']
    /** Deprecated: používejte productDepots */
    depots?: ResolverInputTypes['ProductDepot']
    /** Skladové zásoby */
    productDepots?: ResolverInputTypes['ProductDepot']
    /** Produkt je položka pro následné uplatnění (např. kupón) - pro EET */
    isForSubsequentSettlement?: boolean | `@${string}`
    /** Hlavní obrázek */
    mainImage?: ResolverInputTypes['File']
    /** Obrázky */
    images?: ResolverInputTypes['File']
    /** Soubory (mimo obrázků) */
    files?: ResolverInputTypes['File']
    /** Jedná se o set produktů (virtuální produkt) */
    isBundle?: boolean | `@${string}`
    /** Křehký */
    isFragile?: boolean | `@${string}`
    /** Nadrozměrný */
    isOversize?: boolean | `@${string}`
    bundleParents?: ResolverInputTypes['ProductBundle']
    bundleChildren?: ResolverInputTypes['ProductBundle']
    /** Poznámka */
    note?: boolean | `@${string}`
    /** Popis */
    description?: boolean | `@${string}`
    /** Cokoli... */
    customData?: boolean | `@${string}`
    /** Akční ceny */
    advancedPrices?: ResolverInputTypes['ProductPrice']
    /** Počet nedostupných kusů v rezervaci */
    quantityUnavailable?: boolean | `@${string}`
    /** Počet kusů skladem a všech skladech */
    quantityStock?: boolean | `@${string}`
    /** Počet dostupných kusů a všech skladech */
    quantityAvailable?: boolean | `@${string}`
    /** Počet kusů v rezervaci na všech skladech */
    quantityReservation?: boolean | `@${string}`
    /** Počet kusů objednaný od dodavatele */
    quantityPurchase?: boolean | `@${string}`
    /** Kategorie */
    categories?: ResolverInputTypes['Category']
    /** Hlavní kategorie */
    mainCategory?: ResolverInputTypes['Category']
    company?: ResolverInputTypes['Company']
    /** Počty */
    quantities?: ResolverInputTypes['Quantity']
    /** Metriky */
    metrics?: ResolverInputTypes['ProductDepotMetric']
    /** Balení/Kartony */
    packs?: ResolverInputTypes['ProductPack']
    /** Relace ProductParameter */
    productParameters?: ResolverInputTypes['ProductParameter']
    /** Relace ProductPriceLevel */
    productPriceLevels?: ResolverInputTypes['ProductPriceLevel']
    /** Tagy */
    tags?: ResolverInputTypes['Tag']
    /** Položky k prodeji: jednotlivé expirace a šarže */
    sellItems?: ResolverInputTypes['SellItem']
    /** Deprectable - use deletable. */
    isDeletable?: boolean | `@${string}`
    /** Lze smazat */
    deletable?: boolean | `@${string}`
    /** Eviduje expirace a šarže */
    enabledBatchExpiration?: boolean | `@${string}`
    /** Eviduje sériová čísla */
    requiredSerialNumbers?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Vat']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    percent?: boolean | `@${string}`
    default?: boolean | `@${string}`
    coefficient?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  /** Representation of date and time in same string format as PHP DateTime class. */
  ['DateTimeAsString']: unknown
  ['Producer']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Supplier']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    deletable?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['File']: AliasType<{
    id?: boolean | `@${string}`
    originalFilename?: boolean | `@${string}`
    text?: boolean | `@${string}`
    main?: boolean | `@${string}`
    mimeType?: boolean | `@${string}`
    size?: boolean | `@${string}`
    url?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    order?: ResolverInputTypes['Order']
    thumbnails?: ResolverInputTypes['FileThumbnail']
    __typename?: boolean | `@${string}`
  }>
  ['Order']: AliasType<{
    id?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    status?: ResolverInputTypes['OrderStatus']
    reservationNumber?: boolean | `@${string}`
    billNumber?: boolean | `@${string}`
    vs?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    dateBill?: boolean | `@${string}`
    dateExpedition?: boolean | `@${string}`
    dateDue?: boolean | `@${string}`
    dateTax?: boolean | `@${string}`
    items?: ResolverInputTypes['OrderItem']
    paymentItems?: ResolverInputTypes['PaymentItem']
    checkout?: ResolverInputTypes['Checkout']
    checkoutClosing?: ResolverInputTypes['CheckoutClosing']
    customer?: ResolverInputTypes['Customer']
    note?: boolean | `@${string}`
    privateNote?: boolean | `@${string}`
    invoiceAddress?: ResolverInputTypes['Address']
    shippingAddress?: ResolverInputTypes['Address']
    relatedParent?: ResolverInputTypes['Order']
    rounding?: boolean | `@${string}`
    eetReceipt?: ResolverInputTypes['Receipt']
    deleted?: boolean | `@${string}`
    priceAll?: boolean | `@${string}`
    priceAllWithoutVat?: boolean | `@${string}`
    profit?: boolean | `@${string}`
    vatAllocations?: ResolverInputTypes['VatAllocation']
    /** DEPRECATED - Url PDF účtenky */
    billUrl?: boolean | `@${string}`
    /** Url PDF faktury */
    invoiceUrl?: boolean | `@${string}`
    /** Url PDF objednávky */
    reservationUrl?: boolean | `@${string}`
    /** Url PDF účtenky */
    invoiceReceiptUrl?: boolean | `@${string}`
    /** Url PDF účtenky-rezervace */
    reservationReceiptUrl?: boolean | `@${string}`
    processStatus?: ResolverInputTypes['ProcessStatus']
    processStatusUpdated?: boolean | `@${string}`
    processStatusRelation?: ResolverInputTypes['OrderProcessStatus']
    processStatusRelations?: ResolverInputTypes['OrderProcessStatus']
    carrier?: ResolverInputTypes['Carrier']
    packages?: ResolverInputTypes['Package']
    boxes?: boolean | `@${string}`
    isPaid?: boolean | `@${string}`
    currency?: ResolverInputTypes['Currency']
    files?: ResolverInputTypes['File']
    quantityUnavailable?: boolean | `@${string}`
    createdBy?: ResolverInputTypes['User']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    movePacks?: ResolverInputTypes['ProductMovePack']
    depots?: ResolverInputTypes['Depot']
    isEditable?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Externí číslo */
    externalReference?: boolean | `@${string}`
    group?: ResolverInputTypes['OrderGroup']
    groupPosition?: boolean | `@${string}`
    clearanceItems?: ResolverInputTypes['ClearanceItem']
    /** Tagy */
    tags?: ResolverInputTypes['Tag']
    /** Priorita */
    priority?: boolean | `@${string}`
    /** Uzamčena */
    locked?: boolean | `@${string}`
    /** Od kdy uzamčena */
    dateLocked?: boolean | `@${string}`
    /** Kým uzamčena */
    lockedBy?: ResolverInputTypes['User']
    __typename?: boolean | `@${string}`
  }>
  ['OrderStatus']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  /** Representation of date and time in "Y-m-d H:i:s" format */
  ['DateTime']: unknown
  ['OrderItem']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    ean?: boolean | `@${string}`
    code?: boolean | `@${string}`
    quantity?: boolean | `@${string}`
    sale?: boolean | `@${string}`
    price?: boolean | `@${string}`
    priceWithoutVat?: boolean | `@${string}`
    priceAll?: boolean | `@${string}`
    priceAllWithoutVat?: boolean | `@${string}`
    vat?: ResolverInputTypes['Vat']
    product?: ResolverInputTypes['Product']
    serial?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    note?: boolean | `@${string}`
    profit?: boolean | `@${string}`
    isForSubsequentSettlement?: boolean | `@${string}`
    picked?: boolean | `@${string}`
    packed?: boolean | `@${string}`
    quantityUnavailable?: boolean | `@${string}`
    moves?: ResolverInputTypes['ProductMove']
    clearanceItems?: ResolverInputTypes['ClearanceItem']
    voucher?: ResolverInputTypes['Voucher']
    order?: ResolverInputTypes['Order']
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMove']: AliasType<{
    id?: boolean | `@${string}`
    pack?: ResolverInputTypes['ProductMovePack']
    productDepotFrom?: ResolverInputTypes['ProductDepot']
    productDepotFromQuantityStock?: boolean | `@${string}`
    productDepotTo?: ResolverInputTypes['ProductDepot']
    productDepotToQuantityStock?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    amount?: boolean | `@${string}`
    note?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    deleted?: boolean | `@${string}`
    quantityUnavailable?: boolean | `@${string}`
    createdBy?: ResolverInputTypes['User']
    orderItem?: ResolverInputTypes['OrderItem']
    picked?: boolean | `@${string}`
    packed?: boolean | `@${string}`
    locked?: boolean | `@${string}`
    purchasePrice?: boolean | `@${string}`
    purchasePriceAll?: boolean | `@${string}`
    purchasePriceWithVat?: boolean | `@${string}`
    purchasePriceAllWithVat?: boolean | `@${string}`
    purchaseOrderItem?: ResolverInputTypes['PurchaseOrderItem']
    deletable?: boolean | `@${string}`
    serialNumbers?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMovePack']: AliasType<{
    id?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    externalReference?: boolean | `@${string}`
    number?: boolean | `@${string}`
    type?: ResolverInputTypes['ProductMoveType']
    purpose?: ResolverInputTypes['ProductMovePurpose']
    moves?: ResolverInputTypes['ProductMove']
    note?: boolean | `@${string}`
    url?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    order?: ResolverInputTypes['Order']
    checkout?: ResolverInputTypes['Checkout']
    depotFrom?: ResolverInputTypes['Depot']
    depotTo?: ResolverInputTypes['Depot']
    purchasePrice?: boolean | `@${string}`
    purchasePriceWithVat?: boolean | `@${string}`
    purchaseOrder?: ResolverInputTypes['PurchaseOrder']
    createdBy?: ResolverInputTypes['User']
    files?: ResolverInputTypes['File']
    company?: ResolverInputTypes['Company']
    deletable?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMoveType']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductMovePurpose']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseOrder']: AliasType<{
    id?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    status?: ResolverInputTypes['PurchaseOrderStatus']
    supplier?: ResolverInputTypes['Supplier']
    depot?: ResolverInputTypes['Depot']
    items?: ResolverInputTypes['PurchaseOrderItem']
    files?: ResolverInputTypes['File']
    /** Tagy */
    tags?: ResolverInputTypes['Tag']
    number?: boolean | `@${string}`
    note?: boolean | `@${string}`
    privateNote?: boolean | `@${string}`
    dateEstimatedArrival?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Externí reference */
    externalReference?: boolean | `@${string}`
    /** Reference dodavatele */
    supplierReference?: boolean | `@${string}`
    purchasePriceAll?: boolean | `@${string}`
    productMovePacks?: ResolverInputTypes['ProductMovePack']
    createdBy?: ResolverInputTypes['User']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseOrderStatus']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseOrderItem']: AliasType<{
    id?: boolean | `@${string}`
    purchaseOrder?: ResolverInputTypes['PurchaseOrder']
    currency?: ResolverInputTypes['Currency']
    product?: ResolverInputTypes['Product']
    quantity?: boolean | `@${string}`
    purchasePrice?: boolean | `@${string}`
    purchasePriceAll?: boolean | `@${string}`
    productMoves?: ResolverInputTypes['ProductMove']
    note?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Již naskladněný počet kusů */
    quantityIn?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Tag']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    color?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ClearanceItem']: AliasType<{
    id?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    amount?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityAvailable?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    position?: boolean | `@${string}`
    position1?: boolean | `@${string}`
    position2?: boolean | `@${string}`
    position3?: boolean | `@${string}`
    picked?: boolean | `@${string}`
    packed?: boolean | `@${string}`
    productMoves?: ResolverInputTypes['ProductMove']
    productDepots?: ResolverInputTypes['ProductDepot']
    orderItems?: ResolverInputTypes['OrderItem']
    locked?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Voucher']: AliasType<{
    id?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    product?: ResolverInputTypes['Product']
    name?: boolean | `@${string}`
    code?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    discountType?: boolean | `@${string}`
    discountPercent?: boolean | `@${string}`
    discountValue?: boolean | `@${string}`
    maxUse?: boolean | `@${string}`
    validFrom?: boolean | `@${string}`
    validTo?: boolean | `@${string}`
    isValid?: boolean | `@${string}`
    used?: boolean | `@${string}`
    paymentItems?: ResolverInputTypes['Product']
    orderItems?: ResolverInputTypes['Product']
    enabled?: boolean | `@${string}`
    isPayment?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PaymentItem']: AliasType<{
    id?: boolean | `@${string}`
    amount?: boolean | `@${string}`
    checkout?: ResolverInputTypes['Checkout']
    payment?: ResolverInputTypes['Payment']
    voucher?: ResolverInputTypes['Voucher']
    dateCreated?: boolean | `@${string}`
    dateCancelled?: boolean | `@${string}`
    isPaid?: boolean | `@${string}`
    datePaid?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CheckoutClosing']: AliasType<{
    id?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    checkout?: ResolverInputTypes['Checkout']
    dateOpen?: boolean | `@${string}`
    amountOpen?: boolean | `@${string}`
    amountRealOpen?: boolean | `@${string}`
    userOpen?: ResolverInputTypes['User']
    noteOpen?: boolean | `@${string}`
    dateClosed?: boolean | `@${string}`
    amountClosed?: boolean | `@${string}`
    amountRealClosed?: boolean | `@${string}`
    userClosed?: ResolverInputTypes['User']
    noteClosed?: boolean | `@${string}`
    orders?: ResolverInputTypes['Order']
    profit?: boolean | `@${string}`
    vatAllocations?: ResolverInputTypes['VatAllocation']
    extraOperations?: ResolverInputTypes['CheckoutExtraOperation']
    billUrl?: boolean | `@${string}`
    enumeration?: boolean | `@${string}`
    paymentSum?: ResolverInputTypes['paymentSum']
    __typename?: boolean | `@${string}`
  }>
  ['VatAllocation']: AliasType<{
    vat?: ResolverInputTypes['Vat']
    priceWithoutVat?: boolean | `@${string}`
    vatPrice?: boolean | `@${string}`
    price?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CheckoutExtraOperation']: AliasType<{
    id?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    amount?: boolean | `@${string}`
    note?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    checkout?: ResolverInputTypes['Checkout']
    checkoutClosing?: ResolverInputTypes['CheckoutClosing']
    __typename?: boolean | `@${string}`
  }>
  ['paymentSum']: AliasType<{
    payment?: ResolverInputTypes['Payment']
    count?: boolean | `@${string}`
    sum?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Customer']: AliasType<{
    id?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    email?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    name?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    companyName?: boolean | `@${string}`
    addresses?: ResolverInputTypes['Address']
    wholesale?: boolean | `@${string}`
    note?: boolean | `@${string}`
    consentRelations?: ResolverInputTypes['CustomerConsent']
    birthday?: boolean | `@${string}`
    users?: ResolverInputTypes['User']
    minExpirationDays?: boolean | `@${string}`
    priceLevel?: ResolverInputTypes['PriceLevel']
    /** Tagy */
    tags?: ResolverInputTypes['Tag']
    /** Cokoli... */
    customData?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Address']: AliasType<{
    id?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    customer?: ResolverInputTypes['Customer']
    companyName?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    street?: boolean | `@${string}`
    city?: boolean | `@${string}`
    country?: boolean | `@${string}`
    state?: boolean | `@${string}`
    zip?: boolean | `@${string}`
    ic?: boolean | `@${string}`
    dic?: boolean | `@${string}`
    phone?: boolean | `@${string}`
    email?: boolean | `@${string}`
    isStored?: boolean | `@${string}`
    isBilling?: boolean | `@${string}`
    zasilkovnaAddressId?: boolean | `@${string}`
    branchId?: boolean | `@${string}`
    /** Externí identifikátor */
    externalId?: boolean | `@${string}`
    /** Poznámka */
    note?: boolean | `@${string}`
    /** Cokoli... */
    customData?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CustomerConsent']: AliasType<{
    id?: boolean | `@${string}`
    customer?: ResolverInputTypes['Customer']
    consent?: ResolverInputTypes['Consent']
    name?: boolean | `@${string}`
    body?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Consent']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    body?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['PriceLevel']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    isPercentage?: boolean | `@${string}`
    percent?: boolean | `@${string}`
    withVat?: boolean | `@${string}`
    currency?: ResolverInputTypes['Currency']
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Receipt']: AliasType<{
    id?: boolean | `@${string}`
    dic?: boolean | `@${string}`
    checkoutEetId?: boolean | `@${string}`
    shopEetId?: boolean | `@${string}`
    playground?: boolean | `@${string}`
    verificationMode?: boolean | `@${string}`
    number?: boolean | `@${string}`
    dateCreated?: boolean | `@${string}`
    totalPrice?: boolean | `@${string}`
    priceZeroVat?: boolean | `@${string}`
    priceStandardVat?: boolean | `@${string}`
    vatStandard?: boolean | `@${string}`
    priceFirstReducedVat?: boolean | `@${string}`
    vatFirstReduced?: boolean | `@${string}`
    priceSecondReducedVat?: boolean | `@${string}`
    vatSecondReduced?: boolean | `@${string}`
    priceForSubsequentSettlement?: boolean | `@${string}`
    priceUsedSubsequentSettlement?: boolean | `@${string}`
    fik?: boolean | `@${string}`
    bkp?: boolean | `@${string}`
    pkp?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProcessStatus']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    position?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['OrderProcessStatus']: AliasType<{
    status?: ResolverInputTypes['ProcessStatus']
    note?: boolean | `@${string}`
    created?: boolean | `@${string}`
    createdBy?: ResolverInputTypes['User']
    actual?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Package']: AliasType<{
    id?: boolean | `@${string}`
    code?: boolean | `@${string}`
    carrier?: ResolverInputTypes['Carrier']
    tariff?: ResolverInputTypes['CarrierTariff']
    items?: ResolverInputTypes['OrderItem']
    statuses?: ResolverInputTypes['PackageStatus']
    ticketUrl?: boolean | `@${string}`
    sent?: boolean | `@${string}`
    order?: ResolverInputTypes['Order']
    disposal?: ResolverInputTypes['PackageDisposal']
    weight?: boolean | `@${string}`
    weightRequired?: boolean | `@${string}`
    dimensionsRequired?: boolean | `@${string}`
    dimensionX?: boolean | `@${string}`
    dimensionY?: boolean | `@${string}`
    dimensionZ?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    clearanceItems?: ResolverInputTypes['ClearanceItem']
    __typename?: boolean | `@${string}`
  }>
  ['PackageStatus']: AliasType<{
    id?: boolean | `@${string}`
    code?: boolean | `@${string}`
    text?: boolean | `@${string}`
    package?: ResolverInputTypes['Package']
    created?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PackageDisposal']: AliasType<{
    id?: boolean | `@${string}`
    code?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    carrier?: ResolverInputTypes['Carrier']
    packages?: ResolverInputTypes['Package']
    ticketUrl?: boolean | `@${string}`
    sent?: boolean | `@${string}`
    files?: ResolverInputTypes['File']
    __typename?: boolean | `@${string}`
  }>
  ['OrderGroup']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    orders?: ResolverInputTypes['Order']
    user?: ResolverInputTypes['User']
    userPosition?: boolean | `@${string}`
    clearanceItems?: ResolverInputTypes['ClearanceItem']
    __typename?: boolean | `@${string}`
  }>
  ['FileThumbnail']: AliasType<{
    format?: boolean | `@${string}`
    mimeType?: boolean | `@${string}`
    url?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductBundle']: AliasType<{
    id?: boolean | `@${string}`
    parent?: ResolverInputTypes['Product']
    child?: ResolverInputTypes['Product']
    /** Množství */
    quantity?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPrice']: AliasType<{
    id?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    sellPrice?: boolean | `@${string}`
    available?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    isActual?: boolean | `@${string}`
    note?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Category']: AliasType<{
    id?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    name?: boolean | `@${string}`
    text?: boolean | `@${string}`
    parent?: ResolverInputTypes['Category']
    children?: ResolverInputTypes['Category']
    hasChildren?: boolean | `@${string}`
    position?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    treePath?: boolean | `@${string}`
    /** Tagy */
    tags?: ResolverInputTypes['Tag']
    __typename?: boolean | `@${string}`
  }>
  ['Quantity']: AliasType<{
    depot?: ResolverInputTypes['Depot']
    quantityAvailable?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityPurchase?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductDepotMetric']: AliasType<{
    id?: boolean | `@${string}`
    depot?: ResolverInputTypes['Depot']
    product?: ResolverInputTypes['Product']
    quantityMinimum?: boolean | `@${string}`
    quantityOptimum?: boolean | `@${string}`
    quantityMaximum?: boolean | `@${string}`
    quantityStock?: boolean | `@${string}`
    quantityPurchase?: boolean | `@${string}`
    created?: boolean | `@${string}`
    updated?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPack']: AliasType<{
    id?: boolean | `@${string}`
    /** Unikátní Ean 13 */
    ean?: boolean | `@${string}`
    /** Kód */
    code?: boolean | `@${string}`
    /** Poznámka */
    note?: boolean | `@${string}`
    /** Množství */
    quantity?: boolean | `@${string}`
    /** Produkt.id */
    product?: ResolverInputTypes['Product']
    /** Externí ID */
    externalId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductParameter']: AliasType<{
    id?: boolean | `@${string}`
    value?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    parameter?: ResolverInputTypes['Parameter']
    __typename?: boolean | `@${string}`
  }>
  /** String or Array */
  ['StringOrArray']: unknown
  ['Parameter']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    enumValues?: boolean | `@${string}`
    unit?: boolean | `@${string}`
    text?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['ProductPriceLevel']: AliasType<{
    id?: boolean | `@${string}`
    sellPrice?: boolean | `@${string}`
    externalId?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    priceLevel?: ResolverInputTypes['PriceLevel']
    __typename?: boolean | `@${string}`
  }>
  ['SellItem']: AliasType<{
    id?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    quantityStock?: boolean | `@${string}`
    quantityReservation?: boolean | `@${string}`
    quantityAvailable?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    batch?: boolean | `@${string}`
    productDepots?: ResolverInputTypes['ProductDepot']
    __typename?: boolean | `@${string}`
  }>
  ['CompanyModule']: AliasType<{
    id?: boolean | `@${string}`
    module?: ResolverInputTypes['Module']
    __typename?: boolean | `@${string}`
  }>
  ['Module']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userMyProfile']: AliasType<{
    data?: ResolverInputTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userFilters']: {
    pin?: string | undefined | null
    email?: string | undefined | null
    fulltext?: string | undefined | null
    roles?: Array<string | undefined | null> | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
    enabled?: boolean | undefined | null
  }
  ['users']: AliasType<{
    items?: ResolverInputTypes['User']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Paginator']: AliasType<{
    current?: boolean | `@${string}`
    currentItemCount?: boolean | `@${string}`
    endPage?: boolean | `@${string}`
    first?: boolean | `@${string}`
    firstItemNumber?: boolean | `@${string}`
    firstPageInRange?: boolean | `@${string}`
    last?: boolean | `@${string}`
    lastItemNumber?: boolean | `@${string}`
    lastPageInRange?: boolean | `@${string}`
    next?: boolean | `@${string}`
    numItemsPerPage?: boolean | `@${string}`
    pageCount?: boolean | `@${string}`
    pageRange?: boolean | `@${string}`
    startPage?: boolean | `@${string}`
    totalCount?: boolean | `@${string}`
    pagesInRange?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['isUserEmailUnique']: AliasType<{
    data?: boolean | `@${string}`
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userGroup']: AliasType<{
    data?: ResolverInputTypes['UserGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['userGroupFilters']: {
    name?: string | undefined | null
    externalId?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['userGroups']: AliasType<{
    items?: ResolverInputTypes['UserGroup']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Role']: AliasType<{
    name?: boolean | `@${string}`
    role?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['product']: AliasType<{
    data?: ResolverInputTypes['Product']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productFilters']: {
    id?: number | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    name?: string | undefined | null
    ean?: string | undefined | null
    code?: string | undefined | null
    codes?: Array<string | undefined | null> | undefined | null
    externalId?: string | undefined | null
    fulltext?: string | undefined | null
    depots?: Array<number | undefined | null> | undefined | null
    metricDepots?: Array<number | undefined | null> | undefined | null
    suppliers?: Array<number | undefined | null> | undefined | null
    defaultSuppliers?: Array<number | undefined | null> | undefined | null
    producers?: Array<number | undefined | null> | undefined | null
    listType?: string | undefined | null
    availability?: string | undefined | null
    enabled?: boolean | undefined | null
    isBundle?: boolean | undefined | null
    expirationDateTo?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    position1?: string | undefined | null
    position2?: string | undefined | null
    position3?: string | undefined | null
    hasBeforeSellPrice?: boolean | undefined | null
    hasHsCode?: boolean | undefined | null
    createdFrom?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    updatedFrom?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    tags?: Array<number | undefined | null> | undefined | null
    categories?: Array<number | undefined | null> | undefined | null
    metrics?: string | undefined | null
    enabledBatchExpiration?: boolean | undefined | null
    requiredSerialNumbers?: boolean | undefined | null
    company?: number | undefined | null
  }
  ['products']: AliasType<{
    items?: ResolverInputTypes['Product']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPurchasePrice']: AliasType<{
    items?: ResolverInputTypes['ProductPurchasePrice']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPurchasePrice']: AliasType<{
    productDepot?: ResolverInputTypes['Depot']
    quantity?: boolean | `@${string}`
    purchasePrice?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productSellItems']: AliasType<{
    items?: ResolverInputTypes['SellItem']
    __typename?: boolean | `@${string}`
  }>
  ['productMovePackFilters']: {
    fulltext?: string | undefined | null
    type?: string | undefined | null
    depot?: number | undefined | null
    depotFrom?: number | undefined | null
    depotTo?: number | undefined | null
    number?: number | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
    withoutCheckout?: boolean | undefined | null
    product?: number | undefined | null
    productFulltext?: string | undefined | null
    order?: number | undefined | null
    orderFulltext?: string | undefined | null
    withOrder?: boolean | undefined | null
    createdBy?: number | undefined | null
    created?: ResolverInputTypes['created'] | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
    externalReference?: string | undefined | null
    purchaseOrder?: number | undefined | null
  }
  ['created']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['productMovePacks']: AliasType<{
    items?: ResolverInputTypes['ProductMovePack']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productMoveFilters']: {
    product?: number | undefined | null
    type?: string | undefined | null
    depotFrom?: number | undefined | null
    depotTo?: number | undefined | null
    createdBy?: number | undefined | null
    created?: ResolverInputTypes['created'] | undefined | null
    expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    batch?: string | undefined | null
  }
  ['productMoves']: AliasType<{
    items?: ResolverInputTypes['ProductMove']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productMovePack']: AliasType<{
    data?: ResolverInputTypes['ProductMovePack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['institution']: AliasType<{
    data?: ResolverInputTypes['Institution']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['institutionFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
  }
  ['institutions']: AliasType<{
    items?: ResolverInputTypes['Institution']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['order']: AliasType<{
    data?: ResolverInputTypes['Order']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['orderFilters']: {
    fulltext?: string | undefined | null
    reservationNumber?: number | undefined | null
    billNumber?: number | undefined | null
    notes?: string | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
    depots?: Array<number | undefined | null> | undefined | null
    onlyDepots?: Array<number | undefined | null> | undefined | null
    payments?: Array<number | undefined | null> | undefined | null
    status?: string | undefined | null
    processStatus?: string | undefined | null
    processStatusChange?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    processStatusUpdated?: ResolverInputTypes['processStatusUpdated'] | undefined | null
    isPaid?: boolean | undefined | null
    carrier?: string | undefined | null
    carriers?: Array<string | undefined | null> | undefined | null
    deleted?: boolean | undefined | null
    dateCreated?: ResolverInputTypes['dateCreated'] | undefined | null
    dateBill?: ResolverInputTypes['dateBill'] | undefined | null
    dateExpedition?: ResolverInputTypes['dateExpedition'] | undefined | null
    updated?: ResolverInputTypes['updated'] | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    box?: string | undefined | null
    item?: string | undefined | null
    package?: string | undefined | null
    customer?: number | undefined | null
    customerFulltext?: string | undefined | null
    shippingCountries?: Array<string | undefined | null> | undefined | null
    externalId?: string | undefined | null
    user?: number | undefined | null
    tags?: Array<number | undefined | null> | undefined | null
    hasAvailableProducts?: boolean | undefined | null
    group?: number | undefined | null
    groups?: Array<number | undefined | null> | undefined | null
    createdBy?: Array<number | undefined | null> | undefined | null
    locked?: boolean | undefined | null
    dateLocked?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    lockedBy?: Array<number | undefined | null> | undefined | null
  }
  ['processStatusUpdated']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['dateCreated']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['dateBill']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['dateExpedition']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['updated']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['orders']: AliasType<{
    items?: ResolverInputTypes['Order']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['soldItemFilters']: {
    productFulltext?: string | undefined | null
    customerFulltext?: string | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
    depots?: Array<number | undefined | null> | undefined | null
    payments?: Array<number | undefined | null> | undefined | null
    status?: string | undefined | null
    processStatus?: string | undefined | null
    processStatusUpdated?: ResolverInputTypes['processStatusUpdated'] | undefined | null
    carriers?: Array<string | undefined | null> | undefined | null
    dateCreated?: ResolverInputTypes['dateCreated'] | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    customer?: number | undefined | null
    product?: number | undefined | null
    shippingCountries?: Array<string | undefined | null> | undefined | null
    orderTags?: Array<number | undefined | null> | undefined | null
  }
  ['soldItems']: AliasType<{
    items?: ResolverInputTypes['SoldItem']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['SoldItem']: AliasType<{
    name?: boolean | `@${string}`
    type?: boolean | `@${string}`
    ean?: boolean | `@${string}`
    code?: boolean | `@${string}`
    quantity?: boolean | `@${string}`
    priceAll?: boolean | `@${string}`
    priceAllWithoutVat?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    profit?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['orderItemFilters']: {
    status?: string | undefined | null
    processStatus?: string | undefined | null
    order?: number | undefined | null
    productDepot?: number | undefined | null
    carrier?: string | undefined | null
  }
  ['orderItems']: AliasType<{
    items?: ResolverInputTypes['OrderItem']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['orderItem']: AliasType<{
    data?: ResolverInputTypes['OrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['customer']: AliasType<{
    data?: ResolverInputTypes['Customer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['customerFilters']: {
    externalId?: string | undefined | null
    email?: string | undefined | null
    fulltext?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    user?: number | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
    priceLevel?: number | undefined | null
    tags?: Array<number | undefined | null> | undefined | null
  }
  ['customers']: AliasType<{
    items?: ResolverInputTypes['Customer']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['address']: AliasType<{
    data?: ResolverInputTypes['Address']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['addressesFilters']: {
    customer?: number | undefined | null
    externalId?: string | undefined | null
    isStored?: boolean | undefined | null
    isBilling?: boolean | undefined | null
    ic?: string | undefined | null
    dic?: string | undefined | null
    phone?: string | undefined | null
    email?: string | undefined | null
  }
  ['addresses']: AliasType<{
    items?: ResolverInputTypes['Address']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['checkout']: AliasType<{
    data?: ResolverInputTypes['Checkout']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['checkoutFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
    user?: number | undefined | null
  }
  ['checkouts']: AliasType<{
    items?: ResolverInputTypes['Checkout']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['eventTypes']: AliasType<{
    items?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['consent']: AliasType<{
    data?: ResolverInputTypes['Consent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['consentsFilters']: {
    name?: string | undefined | null
  }
  ['consents']: AliasType<{
    items?: ResolverInputTypes['Consent']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['checkoutExtraOperation']: AliasType<{
    data?: ResolverInputTypes['CheckoutExtraOperation']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['checkoutExtraOperationFilters']: {
    checkout?: number | undefined | null
    checkoutClosing?: number | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
  }
  ['checkoutExtraOperations']: AliasType<{
    items?: ResolverInputTypes['CheckoutExtraOperation']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['checkoutClosing']: AliasType<{
    data?: ResolverInputTypes['CheckoutClosing']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['checkoutClosingFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['checkoutClosings']: AliasType<{
    items?: ResolverInputTypes['CheckoutClosing']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['vat']: AliasType<{
    data?: ResolverInputTypes['Vat']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['vatFilters']: {
    name?: string | undefined | null
    percent?: number | undefined | null
    externalId?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['vats']: AliasType<{
    items?: ResolverInputTypes['Vat']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['depot']: AliasType<{
    data?: ResolverInputTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['depotFilters']: {
    companies?: Array<number | undefined | null> | undefined | null
    name?: string | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
  }
  ['depots']: AliasType<{
    items?: ResolverInputTypes['Depot']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['supplier']: AliasType<{
    data?: ResolverInputTypes['Supplier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['supplierFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
  }
  ['suppliers']: AliasType<{
    items?: ResolverInputTypes['Supplier']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['producer']: AliasType<{
    data?: ResolverInputTypes['Producer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['producerFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
  }
  ['producers']: AliasType<{
    items?: ResolverInputTypes['Producer']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['exportStockFilters']: {
    depots?: Array<number | undefined | null> | undefined | null
    producers?: Array<number | undefined | null> | undefined | null
    suppliers?: Array<number | undefined | null> | undefined | null
  }
  ['exportStocks']: AliasType<{
    items?: ResolverInputTypes['Stock']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Stock']: AliasType<{
    id?: boolean | `@${string}`
    date?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    depots?: ResolverInputTypes['Depot']
    suppliers?: ResolverInputTypes['Supplier']
    producers?: ResolverInputTypes['Producer']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportSaleFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null
    producers?: Array<number | undefined | null> | undefined | null
    suppliers?: Array<number | undefined | null> | undefined | null
  }
  ['exportSales']: AliasType<{
    items?: ResolverInputTypes['Sale']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Sale']: AliasType<{
    id?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    checkouts?: ResolverInputTypes['Checkout']
    suppliers?: ResolverInputTypes['Supplier']
    producers?: ResolverInputTypes['Producer']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportPurchaseFilters']: {
    depots?: Array<number | undefined | null> | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
    producers?: Array<number | undefined | null> | undefined | null
    suppliers?: Array<number | undefined | null> | undefined | null
  }
  ['exportPurchases']: AliasType<{
    items?: ResolverInputTypes['Purchase']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['Purchase']: AliasType<{
    id?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    depots?: ResolverInputTypes['Depot']
    checkouts?: ResolverInputTypes['Checkout']
    suppliers?: ResolverInputTypes['Supplier']
    producers?: ResolverInputTypes['Producer']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportOrderFilters']: {
    status?: string | undefined | null
    checkouts?: Array<number | undefined | null> | undefined | null
  }
  ['exportOrders']: AliasType<{
    items?: ResolverInputTypes['exportOrder']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['exportOrder']: AliasType<{
    id?: boolean | `@${string}`
    dateFrom?: boolean | `@${string}`
    dateTo?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    status?: ResolverInputTypes['OrderStatus']
    checkouts?: ResolverInputTypes['Checkout']
    url?: boolean | `@${string}`
    format?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportInventory']: AliasType<{
    data?: ResolverInputTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Inventory']: AliasType<{
    id?: boolean | `@${string}`
    date?: boolean | `@${string}`
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    depots?: ResolverInputTypes['Depot']
    suppliers?: ResolverInputTypes['Supplier']
    producers?: ResolverInputTypes['Producer']
    url?: boolean | `@${string}`
    files?: ResolverInputTypes['File']
    approved?: boolean | `@${string}`
    rejected?: boolean | `@${string}`
    finished?: boolean | `@${string}`
    pmpIn?: ResolverInputTypes['ProductMovePack']
    pmpOut?: ResolverInputTypes['ProductMovePack']
    __typename?: boolean | `@${string}`
  }>
  ['exportInventoryFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null
    producers?: Array<number | undefined | null> | undefined | null
    suppliers?: Array<number | undefined | null> | undefined | null
    approved?: boolean | undefined | null
    rejected?: boolean | undefined | null
    finished?: boolean | undefined | null
  }
  ['exportInventories']: AliasType<{
    items?: ResolverInputTypes['Inventory']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['export']: AliasType<{
    data?: ResolverInputTypes['Export']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Export']: AliasType<{
    id?: boolean | `@${string}`
    file?: ResolverInputTypes['File']
    generated?: boolean | `@${string}`
    created?: boolean | `@${string}`
    url?: boolean | `@${string}`
    type?: ResolverInputTypes['ExportType']
    filter?: boolean | `@${string}`
    createdBy?: ResolverInputTypes['User']
    note?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ExportType']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    format?: boolean | `@${string}`
    group?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['exportFilters']: {
    types?: Array<string | undefined | null> | undefined | null
    group?: string | undefined | null
  }
  ['exports']: AliasType<{
    items?: ResolverInputTypes['Export']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['exportTypeFilters']: {
    group?: string | undefined | null
  }
  ['exportTypes']: AliasType<{
    items?: ResolverInputTypes['ExportType']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['shop']: AliasType<{
    data?: ResolverInputTypes['Shop']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Shop']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    street?: boolean | `@${string}`
    city?: boolean | `@${string}`
    country?: boolean | `@${string}`
    zip?: boolean | `@${string}`
    eetId?: boolean | `@${string}`
    cert?: ResolverInputTypes['Cert']
    checkouts?: ResolverInputTypes['Checkout']
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['Cert']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    publicKey?: boolean | `@${string}`
    expirationDate?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    __typename?: boolean | `@${string}`
  }>
  ['shopFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['shops']: AliasType<{
    items?: ResolverInputTypes['Shop']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['cert']: AliasType<{
    data?: ResolverInputTypes['Cert']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['filters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['certs']: AliasType<{
    items?: ResolverInputTypes['Cert']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['eetReceiptFilters']: {
    checkouts?: Array<number | undefined | null> | undefined | null
    number?: number | undefined | null
    hasFik?: boolean | undefined | null
    playground?: boolean | undefined | null
    verificationMode?: boolean | undefined | null
  }
  ['eetReceipts']: AliasType<{
    items?: ResolverInputTypes['Receipt']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['payment']: AliasType<{
    data?: ResolverInputTypes['Payment']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['paymentFilters']: {
    company?: number | undefined | null
    name?: string | undefined | null
    type?: string | undefined | null
    externalId?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['payments']: AliasType<{
    items?: ResolverInputTypes['Payment']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['paymentItem']: AliasType<{
    data?: ResolverInputTypes['PaymentItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['paymentItemsFilters']: {
    order?: number | undefined | null
    type?: string | undefined | null
    payment?: number | undefined | null
    externalId?: string | undefined | null
  }
  ['paymentItems']: AliasType<{
    items?: ResolverInputTypes['PaymentItem']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['paymentTypeFilters']: {
    const?: string | undefined | null
  }
  ['paymentTypes']: AliasType<{
    items?: ResolverInputTypes['PaymentType']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['processStatusesFilters']: {
    name?: string | undefined | null
  }
  ['processStatuses']: AliasType<{
    items?: ResolverInputTypes['ProcessStatus']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['carriersFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['carriers']: AliasType<{
    items?: ResolverInputTypes['Carrier']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['currenciesFilters']: {
    name?: string | undefined | null
  }
  ['currencies']: AliasType<{
    items?: ResolverInputTypes['Currency']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['package']: AliasType<{
    data?: ResolverInputTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['packageFilters']: {
    code?: string | undefined | null
    carrier?: string | undefined | null
    order?: number | undefined | null
    disposal?: number | undefined | null
    hasDisposal?: boolean | undefined | null
    isSent?: boolean | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    sent?: string | undefined | null
  }
  ['packages']: AliasType<{
    items?: ResolverInputTypes['Package']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['packageStatsFilters']: {
    carrier?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    fromDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    toDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['packageStats']: AliasType<{
    items?: ResolverInputTypes['PackageStats']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['PackageStats']: AliasType<{
    company?: ResolverInputTypes['Company']
    processStatus?: ResolverInputTypes['ProcessStatus']
    packageAmount?: boolean | `@${string}`
    orderAmount?: boolean | `@${string}`
    country?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['disposal']: AliasType<{
    data?: ResolverInputTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['disposalFilters']: {
    carrier?: string | undefined | null
  }
  ['disposals']: AliasType<{
    items?: ResolverInputTypes['PackageDisposal']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productDepotMetric']: AliasType<{
    data?: ResolverInputTypes['ProductDepotMetric']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productDepotMetricFilters']: {
    product?: number | undefined | null
    depot?: number | undefined | null
  }
  ['productDepotMetrics']: AliasType<{
    items?: ResolverInputTypes['ProductDepotMetric']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['file']: AliasType<{
    data?: ResolverInputTypes['File']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['fileFilters']: {
    product?: number | undefined | null
    order?: number | undefined | null
  }
  ['files']: AliasType<{
    items?: ResolverInputTypes['File']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['nextEan']: AliasType<{
    data?: boolean | `@${string}`
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['statsCarrierUsage']: AliasType<{
    items?: ResolverInputTypes['CarrierUsage']
    __typename?: boolean | `@${string}`
  }>
  ['CarrierUsage']: AliasType<{
    amount?: boolean | `@${string}`
    carrier?: ResolverInputTypes['Carrier']
    __typename?: boolean | `@${string}`
  }>
  ['statsPaymentSales']: AliasType<{
    items?: ResolverInputTypes['PaymentSales']
    __typename?: boolean | `@${string}`
  }>
  ['PaymentSales']: AliasType<{
    amount?: boolean | `@${string}`
    sales?: ResolverInputTypes['Price']
    payment?: ResolverInputTypes['Payment']
    __typename?: boolean | `@${string}`
  }>
  ['Price']: AliasType<{
    value?: boolean | `@${string}`
    currency?: ResolverInputTypes['Currency']
    __typename?: boolean | `@${string}`
  }>
  ['statsBestsellersSort']: statsBestsellersSort
  ['statsBestsellers']: AliasType<{
    items?: ResolverInputTypes['Bestsellers']
    __typename?: boolean | `@${string}`
  }>
  ['Bestsellers']: AliasType<{
    amount?: boolean | `@${string}`
    sales?: ResolverInputTypes['Price']
    product?: ResolverInputTypes['Product']
    __typename?: boolean | `@${string}`
  }>
  ['companyCarrier']: AliasType<{
    data?: ResolverInputTypes['CompanyCarrier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['companyCarrierFilters']: {
    carrier?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
  }
  ['companyCarriers']: AliasType<{
    items?: ResolverInputTypes['CompanyCarrier']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['category']: AliasType<{
    data?: ResolverInputTypes['Category']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['categoryFilters']: {
    name?: string | undefined | null
    parent?: number | undefined | null
    hasParent?: boolean | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
    externalId?: string | undefined | null
    tags?: Array<number | undefined | null> | undefined | null
  }
  ['categories']: AliasType<{
    items?: ResolverInputTypes['Category']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['parameter']: AliasType<{
    data?: ResolverInputTypes['Parameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['parametersFilters']: {
    name?: string | undefined | null
    externalId?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['parameters']: AliasType<{
    items?: ResolverInputTypes['Parameter']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['orderGroup']: AliasType<{
    data?: ResolverInputTypes['OrderGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['orderGroupFilters']: {
    companies?: Array<number | undefined | null> | undefined | null
    hasUser?: boolean | undefined | null
    user?: number | undefined | null
    name?: string | undefined | null
    processStatus?: string | undefined | null
  }
  ['orderGroups']: AliasType<{
    items?: ResolverInputTypes['OrderGroup']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['voucher']: AliasType<{
    data?: ResolverInputTypes['Voucher']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['voucherFilters']: {
    companies?: Array<number | undefined | null> | undefined | null
    name?: string | undefined | null
    code?: string | undefined | null
    externalId?: string | undefined | null
    discountType?: string | undefined | null
    enabled?: boolean | undefined | null
    isPayment?: boolean | undefined | null
    isValid?: boolean | undefined | null
    isUsed?: boolean | undefined | null
    validDate?: ResolverInputTypes['voucherValidFilter'] | undefined | null
    created?: ResolverInputTypes['voucherCreatedFilter'] | undefined | null
  }
  ['voucherValidFilter']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['voucherCreatedFilter']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['vouchers']: AliasType<{
    items?: ResolverInputTypes['Voucher']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPriceFilters']: {
    product?: number | undefined | null
  }
  ['productPrices']: AliasType<{
    items?: ResolverInputTypes['ProductPrice']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['priceLevel']: AliasType<{
    data?: ResolverInputTypes['PriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['priceLevelFilters']: {
    name?: string | undefined | null
    externalId?: string | undefined | null
    isPercentage?: boolean | undefined | null
    withVat?: boolean | undefined | null
    currency?: string | undefined | null
  }
  ['priceLevels']: AliasType<{
    items?: ResolverInputTypes['PriceLevel']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productParameter']: AliasType<{
    data?: ResolverInputTypes['ProductParameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productParameterFilters']: {
    product?: number | undefined | null
    parameter?: number | undefined | null
    value?: string | undefined | null
    externalId?: string | undefined | null
  }
  ['productParameters']: AliasType<{
    items?: ResolverInputTypes['ProductParameter']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPriceLevel']: AliasType<{
    data?: ResolverInputTypes['ProductPriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productPriceLevelFilters']: {
    product?: number | undefined | null
    priceLevel?: number | undefined | null
    externalId?: string | undefined | null
  }
  ['productPriceLevels']: AliasType<{
    items?: ResolverInputTypes['ProductPriceLevel']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['tag']: AliasType<{
    data?: ResolverInputTypes['Tag']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['tagFilters']: {
    name?: string | undefined | null
    type?: string | undefined | null
    externalId?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['tags']: AliasType<{
    items?: ResolverInputTypes['Tag']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['productPack']: AliasType<{
    data?: ResolverInputTypes['ProductPack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['productPackFilters']: {
    product?: number | undefined | null
    quantity?: number | undefined | null
    ean?: string | undefined | null
    code?: string | undefined | null
    externalId?: string | undefined | null
  }
  ['productPacks']: AliasType<{
    items?: ResolverInputTypes['ProductPack']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['purchaseOrder']: AliasType<{
    data?: ResolverInputTypes['PurchaseOrder']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['purchaseOrderFilters']: {
    fulltext?: string | undefined | null
    status?: string | undefined | null
    supplier?: number | undefined | null
    depot?: number | undefined | null
    number?: number | undefined | null
    externalId?: string | undefined | null
    externalReference?: string | undefined | null
    supplierReference?: string | undefined | null
    createdBy?: number | undefined | null
    tags?: Array<number | undefined | null> | undefined | null
    updated?: ResolverInputTypes['updated'] | undefined | null
    created?: ResolverInputTypes['created'] | undefined | null
    dateEstimatedArrival?: ResolverInputTypes['dateEstimatedArrival'] | undefined | null
    company?: number | undefined | null
  }
  ['dateEstimatedArrival']: {
    left_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    right_date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['purchaseOrders']: AliasType<{
    items?: ResolverInputTypes['PurchaseOrder']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['purchaseOrderItem']: AliasType<{
    data?: ResolverInputTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['modulesFilters']: {
    name?: string | undefined | null
    companies?: Array<number | undefined | null> | undefined | null
  }
  ['modules']: AliasType<{
    items?: ResolverInputTypes['Module']
    paginator?: ResolverInputTypes['Paginator']
    __typename?: boolean | `@${string}`
  }>
  ['RootSchemaMutation']: AliasType<{
    createProduct?: [
      {
        name: string
        parent?: number | undefined | null
        children?: Array<number | undefined | null> | undefined | null
        producer?: number | undefined | null
        supplier?: number | undefined | null
        ean?: string | undefined | null
        ean2?: string | undefined | null
        ean3?: string | undefined | null
        ean4?: string | undefined | null
        ean5?: string | undefined | null
        code?: string | undefined | null
        code2?: string | undefined | null
        code3?: string | undefined | null
        externalId?: string | undefined | null
        sellPrice?: string | undefined | null
        purchasePrice?: string | undefined | null
        purchaseCurrency?: string | undefined | null
        beforeSellPrice?: string | undefined | null
        weight?: string | undefined | null
        dimensionX?: number | undefined | null
        dimensionY?: number | undefined | null
        dimensionZ?: number | undefined | null
        originCountry?: string | undefined | null
        hsCode?: string | undefined | null
        enabled?: boolean | undefined | null
        vat?: number | undefined | null
        isForSubsequentSettlement?: boolean | undefined | null
        note?: string | undefined | null
        description?: string | undefined | null
        isBundle?: boolean | undefined | null
        isFragile?: boolean | undefined | null
        isOversize?: boolean | undefined | null
        customData?: string | undefined | null
        mainCategory?: number | undefined | null
        mainImage?: number | undefined | null
        categories?: Array<number | undefined | null> | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        enabledBatchExpiration?: boolean | undefined | null
        requiredSerialNumbers?: boolean | undefined | null
      },
      ResolverInputTypes['createProduct'],
    ]
    updateProduct?: [
      {
        id: number
        name?: string | undefined | null
        parent?: number | undefined | null
        children?: Array<number | undefined | null> | undefined | null
        producer?: number | undefined | null
        supplier?: number | undefined | null
        ean?: string | undefined | null
        ean2?: string | undefined | null
        ean3?: string | undefined | null
        ean4?: string | undefined | null
        ean5?: string | undefined | null
        code?: string | undefined | null
        code2?: string | undefined | null
        code3?: string | undefined | null
        externalId?: string | undefined | null
        sellPrice?: string | undefined | null
        purchasePrice?: string | undefined | null
        purchaseCurrency?: string | undefined | null
        beforeSellPrice?: string | undefined | null
        weight?: string | undefined | null
        dimensionX?: number | undefined | null
        dimensionY?: number | undefined | null
        dimensionZ?: number | undefined | null
        originCountry?: string | undefined | null
        hsCode?: string | undefined | null
        enabled?: boolean | undefined | null
        vat?: number | undefined | null
        isForSubsequentSettlement?: boolean | undefined | null
        note?: string | undefined | null
        description?: string | undefined | null
        isBundle?: boolean | undefined | null
        isFragile?: boolean | undefined | null
        isOversize?: boolean | undefined | null
        customData?: string | undefined | null
        mainCategory?: number | undefined | null
        mainImage?: number | undefined | null
        categories?: Array<number | undefined | null> | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        enabledBatchExpiration?: boolean | undefined | null
        requiredSerialNumbers?: boolean | undefined | null
      },
      ResolverInputTypes['updateProduct'],
    ]
    deleteProduct?: [{ id: number }, ResolverInputTypes['deleteProduct']]
    refreshProductDepots?: [{ id: number }, ResolverInputTypes['refreshProductDepots']]
    createProductMovePack?: [
      {
        type: string
        externalId?: string | undefined | null
        externalReference?: string | undefined | null
        purpose?: string | undefined | null
        note?: string | undefined | null
        order?: number | undefined | null
        checkout?: number | undefined | null
        purchaseOrder?: number | undefined | null
        moves?: Array<ResolverInputTypes['createProductMove'] | undefined | null> | undefined | null
      },
      ResolverInputTypes['createProductMovePack'],
    ]
    updateProductMovePack?: [
      {
        id: number
        externalId?: string | undefined | null
        externalReference?: string | undefined | null
        note?: string | undefined | null
      },
      ResolverInputTypes['updateProductMovePack'],
    ]
    deleteProductMovePack?: [{ id: number }, ResolverInputTypes['deleteProductMovePack']]
    updateProductMove?: [
      {
        id: number
        note?: string | undefined | null
        serialNumbers?: Array<string | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateProductMove'],
    ]
    deleteProductMove?: [{ id: number }, ResolverInputTypes['deleteProductMove']]
    createProductPrice?: [
      {
        product: number
        sellPrice?: number | undefined | null
        available?: number | undefined | null
        dateFrom?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateTo?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        note?: string | undefined | null
      },
      ResolverInputTypes['createProductPrice'],
    ]
    updateProductPrice?: [
      {
        id: number
        product: number
        sellPrice?: number | undefined | null
        available?: number | undefined | null
        dateFrom?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateTo?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        note?: string | undefined | null
      },
      ResolverInputTypes['updateProductPrice'],
    ]
    deleteProductPrice?: [{ id: number }, ResolverInputTypes['deleteProductPrice']]
    createProductBundle?: [
      { parent: number; child: number; quantity: number },
      ResolverInputTypes['createProductBundle'],
    ]
    updateProductBundle?: [
      {
        id: number
        parent?: number | undefined | null
        child?: number | undefined | null
        quantity?: number | undefined | null
      },
      ResolverInputTypes['updateProductBundle'],
    ]
    deleteProductBundle?: [{ id: number }, ResolverInputTypes['deleteProductBundle']]
    createProductDepotMetric?: [
      {
        product: number
        depot: number
        quantityMinimum?: number | undefined | null
        quantityOptimum?: number | undefined | null
        quantityMaximum?: number | undefined | null
      },
      ResolverInputTypes['createProductDepotMetric'],
    ]
    updateProductDepotMetric?: [
      {
        id: number
        quantityMinimum?: number | undefined | null
        quantityOptimum?: number | undefined | null
        quantityMaximum?: number | undefined | null
      },
      ResolverInputTypes['updateProductDepotMetric'],
    ]
    deleteProductDepotMetric?: [{ id: number }, ResolverInputTypes['deleteProductDepotMetric']]
    createInstitution?: [
      {
        name: string
        externalId?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['createInstitution'],
    ]
    updateInstitution?: [
      {
        id: number
        name?: string | undefined | null
        externalId?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateInstitution'],
    ]
    deleteInstitution?: [{ id: number }, ResolverInputTypes['deleteInstitution']]
    createOrder?: [
      {
        reservationNumber?: number | undefined | null
        billNumber?: number | undefined | null
        vs?: number | undefined | null
        status?: string | undefined | null
        dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateBill?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateExpedition?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateDue?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateTax?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        note?: string | undefined | null
        privateNote?: string | undefined | null
        checkout: number
        customer?: number | undefined | null
        currency?: string | undefined | null
        invoiceAddress?: number | undefined | null
        shippingAddress?: number | undefined | null
        items?: Array<ResolverInputTypes['createOrderItem_'] | undefined | null> | undefined | null
        paymentItems?: Array<ResolverInputTypes['createPaymentItem_'] | undefined | null> | undefined | null
        relatedParent?: number | undefined | null
        rounding?: number | undefined | null
        processStatusRelation?: ResolverInputTypes['processStatusRelation'] | undefined | null
        carrier?: string | undefined | null
        boxes?: Array<string | undefined | null> | undefined | null
        externalId?: string | undefined | null
        group?: number | undefined | null
        groupPosition?: number | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        priority?: number | undefined | null
        externalReference?: string | undefined | null
        locked?: boolean | undefined | null
      },
      ResolverInputTypes['createOrder'],
    ]
    updateOrder?: [
      {
        id: number
        reservationNumber?: number | undefined | null
        billNumber?: number | undefined | null
        vs?: number | undefined | null
        status?: string | undefined | null
        dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateBill?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateExpedition?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateDue?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateTax?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        note?: string | undefined | null
        privateNote?: string | undefined | null
        checkout?: number | undefined | null
        customer?: number | undefined | null
        currency?: string | undefined | null
        invoiceAddress?: number | undefined | null
        shippingAddress?: number | undefined | null
        items?: Array<ResolverInputTypes['updateOrderItem_'] | undefined | null> | undefined | null
        paymentItems?: Array<ResolverInputTypes['updatePaymentItem_'] | undefined | null> | undefined | null
        relatedParent?: number | undefined | null
        rounding?: number | undefined | null
        processStatusRelation?: ResolverInputTypes['processStatusRelation'] | undefined | null
        carrier?: string | undefined | null
        boxes?: Array<string | undefined | null> | undefined | null
        externalId?: string | undefined | null
        group?: number | undefined | null
        groupPosition?: number | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        priority?: number | undefined | null
        externalReference?: string | undefined | null
        locked?: boolean | undefined | null
      },
      ResolverInputTypes['updateOrder'],
    ]
    deleteReservation?: [{ id: number }, ResolverInputTypes['deleteReservation']]
    createOrderItem?: [
      {
        order?: number | undefined | null
        name?: string | undefined | null
        type?: string | undefined | null
        ean?: string | undefined | null
        code?: string | undefined | null
        quantity?: number | undefined | null
        note?: string | undefined | null
        price?: number | undefined | null
        sale?: number | undefined | null
        vat?: number | undefined | null
        product?: number | undefined | null
        serial?: string | undefined | null
        expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        batch?: string | undefined | null
        isForSubsequentSettlement?: boolean | undefined | null
        picked?: boolean | undefined | null
        packed?: boolean | undefined | null
        voucher?: number | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createOrderItem'],
    ]
    updateOrderItem?: [
      {
        id: number
        name?: string | undefined | null
        type?: string | undefined | null
        ean?: string | undefined | null
        code?: string | undefined | null
        quantity?: number | undefined | null
        note?: string | undefined | null
        price?: number | undefined | null
        sale?: number | undefined | null
        vat?: number | undefined | null
        product?: number | undefined | null
        serial?: string | undefined | null
        expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        batch?: string | undefined | null
        isForSubsequentSettlement?: boolean | undefined | null
        picked?: boolean | undefined | null
        packed?: boolean | undefined | null
        voucher?: number | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateOrderItem'],
    ]
    deleteOrderItem?: [{ id: number }, ResolverInputTypes['deleteOrderItem']]
    updateClearanceItem?: [
      {
        id: string
        orderItem?: number | undefined | null
        orderGroup?: number | undefined | null
        package?: number | undefined | null
        order?: number | undefined | null
        picked?: number | undefined | null
        packed?: number | undefined | null
        locations?: Array<ResolverInputTypes['updateClearanceItemLocations'] | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateClearanceItem'],
    ]
    createUser?: [
      {
        email: string
        plainPassword?: string | undefined | null
        firstName: string
        lastName: string
        phone?: string | undefined | null
        enabled?: boolean | undefined | null
        groups?: Array<number | undefined | null> | undefined | null
        roles?: Array<string | undefined | null> | undefined | null
        pin?: string | undefined | null
        externalId?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        locale?: string | undefined | null
      },
      ResolverInputTypes['createUser'],
    ]
    updateUser?: [
      {
        id: number
        email?: string | undefined | null
        plainPassword?: string | undefined | null
        firstName?: string | undefined | null
        lastName?: string | undefined | null
        phone?: string | undefined | null
        enabled?: boolean | undefined | null
        groups?: Array<number | undefined | null> | undefined | null
        roles?: Array<string | undefined | null> | undefined | null
        pin?: string | undefined | null
        externalId?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        locale?: string | undefined | null
      },
      ResolverInputTypes['updateUser'],
    ]
    deleteUser?: [{ id: number }, ResolverInputTypes['deleteUser']]
    createUserGroup?: [
      {
        name: string
        roles?: Array<string | undefined | null> | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createUserGroup'],
    ]
    updateUserGroup?: [
      {
        id: number
        name?: string | undefined | null
        roles?: Array<string | undefined | null> | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateUserGroup'],
    ]
    deleteUserGroup?: [{ id: number }, ResolverInputTypes['deleteUserGroup']]
    createCustomer?: [
      {
        externalId?: string | undefined | null
        email: string
        plainPassword?: string | undefined | null
        firstName?: string | undefined | null
        lastName?: string | undefined | null
        companyName?: string | undefined | null
        phone?: string | undefined | null
        addresses?: Array<ResolverInputTypes['createCustomerAddress'] | undefined | null> | undefined | null
        wholesale?: boolean | undefined | null
        note?: string | undefined | null
        birthday?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        users?: Array<number | undefined | null> | undefined | null
        minExpirationDays?: number | undefined | null
        priceLevel?: number | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        customData?: string | undefined | null
      },
      ResolverInputTypes['createCustomer'],
    ]
    updateCustomer?: [
      {
        id: number
        externalId?: string | undefined | null
        email?: string | undefined | null
        plainPassword?: string | undefined | null
        firstName?: string | undefined | null
        lastName?: string | undefined | null
        companyName?: string | undefined | null
        phone?: string | undefined | null
        wholesale?: boolean | undefined | null
        note?: string | undefined | null
        birthday?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        users?: Array<number | undefined | null> | undefined | null
        minExpirationDays?: number | undefined | null
        priceLevel?: number | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        customData?: string | undefined | null
      },
      ResolverInputTypes['updateCustomer'],
    ]
    deleteCustomer?: [{ id: number }, ResolverInputTypes['deleteCustomer']]
    createAddress?: [
      {
        customer?: number | undefined | null
        companyName?: string | undefined | null
        firstName?: string | undefined | null
        lastName?: string | undefined | null
        street?: string | undefined | null
        city?: string | undefined | null
        country: string
        state?: string | undefined | null
        zip?: string | undefined | null
        ic?: string | undefined | null
        dic?: string | undefined | null
        phone?: string | undefined | null
        email?: string | undefined | null
        zasilkovnaAddressId?: string | undefined | null
        branchId?: string | undefined | null
        isStored?: boolean | undefined | null
        isBilling?: boolean | undefined | null
        externalId?: string | undefined | null
        note?: string | undefined | null
        customData?: string | undefined | null
      },
      ResolverInputTypes['createAddress'],
    ]
    updateAddress?: [
      {
        id: number
        customer?: number | undefined | null
        companyName?: string | undefined | null
        firstName?: string | undefined | null
        lastName?: string | undefined | null
        street?: string | undefined | null
        city?: string | undefined | null
        country?: string | undefined | null
        state?: string | undefined | null
        zip?: string | undefined | null
        ic?: string | undefined | null
        dic?: string | undefined | null
        phone?: string | undefined | null
        email?: string | undefined | null
        zasilkovnaAddressId?: string | undefined | null
        branchId?: string | undefined | null
        isStored?: boolean | undefined | null
        isBilling?: boolean | undefined | null
        externalId?: string | undefined | null
        note?: string | undefined | null
        customData?: string | undefined | null
      },
      ResolverInputTypes['updateAddress'],
    ]
    deleteAddress?: [{ id: number }, ResolverInputTypes['deleteAddress']]
    createCheckout?: [
      {
        name: string
        amount?: string | undefined | null
        nextReservationNumber?: number | undefined | null
        nextBillNumber?: number | undefined | null
        billFooter?: string | undefined | null
        payments?: Array<number | undefined | null> | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        returnsDepot?: number | undefined | null
        shop?: number | undefined | null
        eetId?: number | undefined | null
        eetEnable?: boolean | undefined | null
        eetPlayground?: boolean | undefined | null
        eetVerificationMode?: boolean | undefined | null
        eventUrl?: string | undefined | null
        eventTypes?: Array<string | undefined | null> | undefined | null
        negativeReservation?: boolean | undefined | null
        externalId?: string | undefined | null
        users?: Array<number | undefined | null> | undefined | null
        autoPicking?: boolean | undefined | null
        autoPacking?: boolean | undefined | null
        autoDispatched?: boolean | undefined | null
        institution?: number | undefined | null
        position?: number | undefined | null
      },
      ResolverInputTypes['createCheckout'],
    ]
    updateCheckout?: [
      {
        id: number
        name?: string | undefined | null
        amount?: string | undefined | null
        nextReservationNumber?: number | undefined | null
        nextBillNumber?: number | undefined | null
        billFooter?: string | undefined | null
        payments?: Array<number | undefined | null> | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        returnsDepot?: number | undefined | null
        shop?: number | undefined | null
        eetId?: number | undefined | null
        eetEnable?: boolean | undefined | null
        eetPlayground?: boolean | undefined | null
        eetVerificationMode?: boolean | undefined | null
        eventUrl?: string | undefined | null
        eventTypes?: Array<string | undefined | null> | undefined | null
        negativeReservation?: boolean | undefined | null
        externalId?: string | undefined | null
        users?: Array<number | undefined | null> | undefined | null
        autoPicking?: boolean | undefined | null
        autoPacking?: boolean | undefined | null
        autoDispatched?: boolean | undefined | null
        institution?: number | undefined | null
        position?: number | undefined | null
      },
      ResolverInputTypes['updateCheckout'],
    ]
    deleteCheckout?: [{ id: number }, ResolverInputTypes['deleteCheckout']]
    createCheckoutExtraOperation?: [
      {
        checkout: number
        dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        amount: string
        note?: string | undefined | null
      },
      ResolverInputTypes['createCheckoutExtraOperation'],
    ]
    updateCheckoutExtraOperation?: [
      {
        id: number
        checkout?: number | undefined | null
        dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        amount?: string | undefined | null
        note?: string | undefined | null
      },
      ResolverInputTypes['updateCheckoutExtraOperation'],
    ]
    deleteCheckoutExtraOperation?: [{ id: number }, ResolverInputTypes['deleteCheckoutExtraOperation']]
    createCheckoutClosing?: [
      {
        checkout: number
        dateOpen: ResolverInputTypes['DateTimeAsString']
        amountOpen: string
        amountRealOpen?: string | undefined | null
        userOpen?: number | undefined | null
        noteOpen?: string | undefined | null
        dateClosed?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        amountClosed?: string | undefined | null
        amountRealClosed?: string | undefined | null
        userClosed?: number | undefined | null
        noteClosed?: string | undefined | null
        enumeration?: string | undefined | null
      },
      ResolverInputTypes['createCheckoutClosing'],
    ]
    updateCheckoutClosing?: [
      {
        id: number
        checkout?: number | undefined | null
        dateOpen?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        amountOpen?: string | undefined | null
        amountRealOpen?: string | undefined | null
        userOpen?: number | undefined | null
        noteOpen?: string | undefined | null
        dateClosed?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        amountClosed?: string | undefined | null
        amountRealClosed?: string | undefined | null
        userClosed?: number | undefined | null
        noteClosed?: string | undefined | null
        enumeration?: string | undefined | null
      },
      ResolverInputTypes['updateCheckoutClosing'],
    ]
    deleteCheckoutClosing?: [{ id: number }, ResolverInputTypes['deleteCheckoutClosing']]
    createVat?: [
      { name: string; percent: string; default: boolean; externalId?: string | undefined | null },
      ResolverInputTypes['createVat'],
    ]
    updateVat?: [
      {
        id: number
        name?: string | undefined | null
        percent?: string | undefined | null
        default?: boolean | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateVat'],
    ]
    deleteVat?: [{ id: number }, ResolverInputTypes['deleteVat']]
    createDepot?: [
      {
        name: string
        emailIn?: string | undefined | null
        position?: number | undefined | null
        externalId?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        institution?: number | undefined | null
        users?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['createDepot'],
    ]
    updateDepot?: [
      {
        id: number
        name?: string | undefined | null
        emailIn?: string | undefined | null
        position?: number | undefined | null
        externalId?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        institution?: number | undefined | null
        users?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateDepot'],
    ]
    deleteDepot?: [{ id: number }, ResolverInputTypes['deleteDepot']]
    createSupplier?: [{ name: string; externalId?: string | undefined | null }, ResolverInputTypes['createSupplier']]
    updateSupplier?: [
      { id: number; name?: string | undefined | null; externalId?: string | undefined | null },
      ResolverInputTypes['updateSupplier'],
    ]
    deleteSupplier?: [{ id: number }, ResolverInputTypes['deleteSupplier']]
    createProducer?: [{ name: string; externalId?: string | undefined | null }, ResolverInputTypes['createProducer']]
    updateProducer?: [
      { id: number; name?: string | undefined | null; externalId?: string | undefined | null },
      ResolverInputTypes['updateProducer'],
    ]
    deleteProducer?: [{ id: number }, ResolverInputTypes['deleteProducer']]
    createExportStock?: [
      {
        date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        suppliers?: Array<number | undefined | null> | undefined | null
        producers?: Array<number | undefined | null> | undefined | null
        format?: string | undefined | null
      },
      ResolverInputTypes['createExportStock'],
    ]
    createExportSale?: [
      {
        dateFrom: ResolverInputTypes['DateTimeAsString']
        dateTo: ResolverInputTypes['DateTimeAsString']
        checkouts?: Array<number | undefined | null> | undefined | null
        suppliers?: Array<number | undefined | null> | undefined | null
        producers?: Array<number | undefined | null> | undefined | null
        format?: string | undefined | null
      },
      ResolverInputTypes['createExportSale'],
    ]
    createExportPurchase?: [
      {
        dateFrom: ResolverInputTypes['DateTimeAsString']
        dateTo: ResolverInputTypes['DateTimeAsString']
        depots?: Array<number | undefined | null> | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        suppliers?: Array<number | undefined | null> | undefined | null
        producers?: Array<number | undefined | null> | undefined | null
        format?: string | undefined | null
      },
      ResolverInputTypes['createExportPurchase'],
    ]
    createExportOrder?: [
      {
        dateFrom: ResolverInputTypes['DateTimeAsString']
        dateTo: ResolverInputTypes['DateTimeAsString']
        status?: string | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
        format?: string | undefined | null
      },
      ResolverInputTypes['createExportOrder'],
    ]
    createExportInventory?: [
      {
        date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        suppliers?: Array<number | undefined | null> | undefined | null
        producers?: Array<number | undefined | null> | undefined | null
        approved?: string | undefined | null
        rejected?: string | undefined | null
      },
      ResolverInputTypes['createExportInventory'],
    ]
    updateExportInventory?: [
      {
        id: number
        date?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        depots?: Array<number | undefined | null> | undefined | null
        suppliers?: Array<number | undefined | null> | undefined | null
        producers?: Array<number | undefined | null> | undefined | null
        approved?: string | undefined | null
        rejected?: string | undefined | null
      },
      ResolverInputTypes['updateExportInventory'],
    ]
    refreshExportInventory?: [{ id: number }, ResolverInputTypes['refreshExportInventory']]
    createExport?: [
      { type: string; note?: string | undefined | null; filter?: string | undefined | null },
      ResolverInputTypes['createExport'],
    ]
    updateCompany?: [
      {
        id: number
        name?: string | undefined | null
        ic?: string | undefined | null
        dic?: string | undefined | null
        phone?: string | undefined | null
        email?: string | undefined | null
        street?: string | undefined | null
        city?: string | undefined | null
        zip?: string | undefined | null
        country?: string | undefined | null
        registrationNote?: string | undefined | null
        nextEan?: number | undefined | null
        defaultVoucherValidity?: number | undefined | null
        batchExpirationMode?: string | undefined | null
      },
      ResolverInputTypes['updateCompany'],
    ]
    createShop?: [
      {
        name: string
        street?: string | undefined | null
        city?: string | undefined | null
        country?: string | undefined | null
        zip?: string | undefined | null
        eetId?: number | undefined | null
        cert?: number | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['createShop'],
    ]
    updateShop?: [
      {
        id: number
        name: string
        street?: string | undefined | null
        city?: string | undefined | null
        country?: string | undefined | null
        zip?: string | undefined | null
        eetId?: number | undefined | null
        cert?: number | undefined | null
        checkouts?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateShop'],
    ]
    deleteShop?: [{ id: number }, ResolverInputTypes['deleteShop']]
    createCert?: [{ name: string; password: string; pkcs12: string }, ResolverInputTypes['createCert']]
    updateCert?: [{ id: number; name?: string | undefined | null }, ResolverInputTypes['updateCert']]
    deleteCert?: [{ id: number }, ResolverInputTypes['deleteCert']]
    createEetReceipt?: [
      {
        checkout: number
        number: number
        dateCreated: string
        currency: string
        totalPrice: number
        priceZeroVat?: number | undefined | null
        priceStandardVat?: number | undefined | null
        vatStandard?: number | undefined | null
        priceFirstReducedVat?: number | undefined | null
        vatFirstReduced?: number | undefined | null
        priceSecondReducedVat?: number | undefined | null
        vatSecondReduced?: number | undefined | null
        priceForSubsequentSettlement?: number | undefined | null
        priceUsedSubsequentSettlement?: number | undefined | null
      },
      ResolverInputTypes['createEetReceipt'],
    ]
    sendEetReceipt?: [{ id: number }, ResolverInputTypes['sendEetReceipt']]
    createPayment?: [
      {
        name: string
        type?: string | undefined | null
        eetEnable?: boolean | undefined | null
        dispatchUnpaid?: boolean | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createPayment'],
    ]
    updatePayment?: [
      {
        id: number
        name: string
        type?: string | undefined | null
        eetEnable?: boolean | undefined | null
        dispatchUnpaid?: boolean | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updatePayment'],
    ]
    deletePayment?: [{ id: number }, ResolverInputTypes['deletePayment']]
    createPaymentItem?: [
      {
        payment: number
        checkout?: number | undefined | null
        voucher?: number | undefined | null
        amount?: number | undefined | null
        dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateCancelled?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        externalId?: string | undefined | null
        isPaid?: boolean | undefined | null
        datePaid?: ResolverInputTypes['DateTimeAsString'] | undefined | null
      },
      ResolverInputTypes['createPaymentItem'],
    ]
    updatePaymentItem?: [
      {
        id: number
        payment?: number | undefined | null
        checkout?: number | undefined | null
        voucher?: number | undefined | null
        amount?: number | undefined | null
        dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        dateCancelled?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        externalId?: string | undefined | null
        isPaid?: boolean | undefined | null
        datePaid?: ResolverInputTypes['DateTimeAsString'] | undefined | null
      },
      ResolverInputTypes['updatePaymentItem'],
    ]
    deletePaymentItem?: [{ id: number }, ResolverInputTypes['deletePaymentItem']]
    createDevLog?: [
      { name?: string | undefined | null; content?: string | undefined | null },
      ResolverInputTypes['createDevLog'],
    ]
    createPackage?: [
      {
        carrier?: string | undefined | null
        order?: number | undefined | null
        items?: Array<number | undefined | null> | undefined | null
        weight?: number | undefined | null
        dimensionX?: number | undefined | null
        dimensionY?: number | undefined | null
        dimensionZ?: number | undefined | null
        tariff?: number | undefined | null
      },
      ResolverInputTypes['createPackage'],
    ]
    updatePackage?: [
      {
        id: number
        carrier?: string | undefined | null
        order?: number | undefined | null
        items?: Array<number | undefined | null> | undefined | null
        weight?: number | undefined | null
        dimensionX?: number | undefined | null
        dimensionY?: number | undefined | null
        dimensionZ?: number | undefined | null
        tariff?: number | undefined | null
      },
      ResolverInputTypes['updatePackage'],
    ]
    deletePackage?: [{ id: number }, ResolverInputTypes['deletePackage']]
    resetPackage?: [{ id: number }, ResolverInputTypes['resetPackage']]
    sendPackage?: [{ id: number }, ResolverInputTypes['sendPackage']]
    createDisposal?: [
      {
        company?: number | undefined | null
        carrier?: string | undefined | null
        packages?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['createDisposal'],
    ]
    updateDisposal?: [
      {
        id: number
        company?: number | undefined | null
        carrier?: string | undefined | null
        packages?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateDisposal'],
    ]
    deleteDisposal?: [{ id: number }, ResolverInputTypes['deleteDisposal']]
    sendDisposal?: [{ id: string }, ResolverInputTypes['sendDisposal']]
    createFile?: [
      {
        text: string
        originalFilename: string
        mimeType: string
        base64Data: string
        product?: number | undefined | null
        order?: number | undefined | null
        inventory?: number | undefined | null
        productMovePack?: number | undefined | null
        disposal?: number | undefined | null
        purchaseOrder?: number | undefined | null
      },
      ResolverInputTypes['createFile'],
    ]
    updateFile?: [
      {
        id: number
        text?: string | undefined | null
        originalFilename?: string | undefined | null
        mimeType?: string | undefined | null
        base64Data?: string | undefined | null
        product?: number | undefined | null
        order?: number | undefined | null
        inventory?: number | undefined | null
        productMovePack?: number | undefined | null
        disposal?: number | undefined | null
        purchaseOrder?: number | undefined | null
      },
      ResolverInputTypes['updateFile'],
    ]
    deleteFile?: [{ id: number }, ResolverInputTypes['deleteFile']]
    createCompanyCarrier?: [
      {
        carrier: string
        checkout?: number | undefined | null
        options?: string | undefined | null
        externalId?: string | undefined | null
        enable?: boolean | undefined | null
      },
      ResolverInputTypes['createCompanyCarrier'],
    ]
    updateCompanyCarrier?: [
      {
        id: number
        carrier?: string | undefined | null
        checkout?: number | undefined | null
        options?: string | undefined | null
        externalId?: string | undefined | null
        enable?: boolean | undefined | null
      },
      ResolverInputTypes['updateCompanyCarrier'],
    ]
    deleteCompanyCarrier?: [{ id: number }, ResolverInputTypes['deleteCompanyCarrier']]
    createConsent?: [
      { name: string; body?: string | undefined | null; externalId?: string | undefined | null },
      ResolverInputTypes['createConsent'],
    ]
    updateConsent?: [
      {
        id: number
        name?: string | undefined | null
        body?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateConsent'],
    ]
    deleteConsent?: [{ id: number }, ResolverInputTypes['deleteConsent']]
    createCustomerConsent?: [
      { customer: number; consent: number; externalId?: string | undefined | null },
      ResolverInputTypes['createCustomerConsent'],
    ]
    deleteCustomerConsent?: [{ id: number }, ResolverInputTypes['deleteCustomerConsent']]
    updateProductDepot?: [
      {
        id: number
        position1?: string | undefined | null
        position2?: string | undefined | null
        position3?: string | undefined | null
        purchasePrice?: number | undefined | null
        supplier?: number | undefined | null
        batch?: string | undefined | null
        expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        inventoryQuantityStock?: number | undefined | null
      },
      ResolverInputTypes['updateProductDepot'],
    ]
    createCategory?: [
      {
        name: string
        text?: string | undefined | null
        parent?: number | undefined | null
        externalId?: string | undefined | null
        position?: number | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['createCategory'],
    ]
    updateCategory?: [
      {
        id: number
        name: string
        text?: string | undefined | null
        parent?: number | undefined | null
        externalId?: string | undefined | null
        position?: number | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
      },
      ResolverInputTypes['updateCategory'],
    ]
    deleteCategory?: [{ id: number }, ResolverInputTypes['deleteCategory']]
    createParameter?: [
      {
        name: string
        type?: string | undefined | null
        enumValues?: Array<string | undefined | null> | undefined | null
        unit?: string | undefined | null
        text?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createParameter'],
    ]
    updateParameter?: [
      {
        id: number
        name: string
        type?: string | undefined | null
        enumValues?: Array<string | undefined | null> | undefined | null
        unit?: string | undefined | null
        text?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateParameter'],
    ]
    deleteParameter?: [{ id: number }, ResolverInputTypes['deleteParameter']]
    createOrderGroup?: [
      { name: string; user?: number | undefined | null; userPosition?: number | undefined | null },
      ResolverInputTypes['createOrderGroup'],
    ]
    updateOrderGroup?: [
      {
        id: number
        name?: string | undefined | null
        user?: number | undefined | null
        userPosition?: number | undefined | null
      },
      ResolverInputTypes['updateOrderGroup'],
    ]
    deleteOrderGroup?: [{ id: number }, ResolverInputTypes['deleteOrderGroup']]
    createVoucher?: [
      {
        name: string
        code: string
        discountType: string
        discountPercent?: number | undefined | null
        discountValue?: number | undefined | null
        maxUse?: number | undefined | null
        validFrom?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        validTo?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        enabled?: boolean | undefined | null
        externalId?: string | undefined | null
        isPayment?: boolean | undefined | null
      },
      ResolverInputTypes['createVoucher'],
    ]
    updateVoucher?: [
      {
        id: number
        name?: string | undefined | null
        code?: string | undefined | null
        discountType?: string | undefined | null
        discountPercent?: number | undefined | null
        discountValue?: number | undefined | null
        maxUse?: number | undefined | null
        validFrom?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        validTo?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        enabled?: boolean | undefined | null
        externalId?: string | undefined | null
        isPayment?: boolean | undefined | null
      },
      ResolverInputTypes['updateVoucher'],
    ]
    deleteVoucher?: [{ id: number }, ResolverInputTypes['deleteVoucher']]
    createPriceLevel?: [
      {
        name: string
        isPercentage?: boolean | undefined | null
        percent?: number | undefined | null
        withVat?: boolean | undefined | null
        currency?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createPricePriceLevel'],
    ]
    updatePriceLevel?: [
      {
        id: number
        name?: string | undefined | null
        isPercentage?: boolean | undefined | null
        percent?: number | undefined | null
        withVat?: boolean | undefined | null
        currency?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updatePriceLevel'],
    ]
    deletePriceLevel?: [{ id: number }, ResolverInputTypes['deletePriceLevel']]
    createProductParameter?: [
      {
        product: number
        parameter: number
        value?: ResolverInputTypes['StringOrArray'] | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createProductParameter'],
    ]
    updateProductParameter?: [
      {
        id: number
        product: number
        parameter: number
        value?: ResolverInputTypes['StringOrArray'] | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateProductParameter'],
    ]
    deleteProductParameter?: [{ id: number }, ResolverInputTypes['deleteProductParameter']]
    createProductPriceLevel?: [
      {
        product: number
        priceLevel: number
        sellPrice?: number | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createProductPriceLevel'],
    ]
    updateProductPriceLevel?: [
      {
        id: number
        product: number
        priceLevel: number
        sellPrice?: number | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateProductPriceLevel'],
    ]
    deleteProductPriceLevel?: [{ id: number }, ResolverInputTypes['deleteProductPriceLevel']]
    createTag?: [
      { name: string; type: string; color?: string | undefined | null; externalId?: string | undefined | null },
      ResolverInputTypes['createTag'],
    ]
    updateTag?: [
      {
        id: number
        name?: string | undefined | null
        type?: string | undefined | null
        color?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateTag'],
    ]
    deleteTag?: [{ id: number }, ResolverInputTypes['deleteTag']]
    createProductPack?: [
      {
        product: number
        quantity: number
        ean?: string | undefined | null
        code?: string | undefined | null
        note?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createProductPack'],
    ]
    updateProductPack?: [
      {
        id: number
        product: number
        quantity: number
        ean?: string | undefined | null
        code?: string | undefined | null
        note?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updateProductPack'],
    ]
    deleteProductPack?: [{ id: number }, ResolverInputTypes['deleteProductPack']]
    createPurchaseOrder?: [
      {
        supplier: number
        depot: number
        note?: string | undefined | null
        privateNote?: string | undefined | null
        dateEstimatedArrival: ResolverInputTypes['DateTimeAsString']
        items: Array<ResolverInputTypes['createPurchaseOrderItem_'] | undefined | null>
        tags?: Array<number | undefined | null> | undefined | null
        files?: Array<number | undefined | null> | undefined | null
        externalId?: string | undefined | null
        externalReference?: string | undefined | null
        supplierReference?: string | undefined | null
      },
      ResolverInputTypes['createPurchaseOrder'],
    ]
    updatePurchaseOrder?: [
      {
        id: number
        supplier?: number | undefined | null
        depot?: number | undefined | null
        note?: string | undefined | null
        privateNote?: string | undefined | null
        dateEstimatedArrival?: ResolverInputTypes['DateTimeAsString'] | undefined | null
        items?: Array<ResolverInputTypes['createPurchaseOrderItem_'] | undefined | null> | undefined | null
        tags?: Array<number | undefined | null> | undefined | null
        files?: Array<number | undefined | null> | undefined | null
        externalId?: string | undefined | null
        externalReference?: string | undefined | null
        supplierReference?: string | undefined | null
      },
      ResolverInputTypes['updatePurchaseOrder'],
    ]
    deletePurchaseOrder?: [{ id: number }, ResolverInputTypes['deletePurchaseOrder']]
    createPurchaseOrderItem?: [
      {
        purchaseOrder: number
        currency: string
        product: number
        quantity: number
        purchasePrice?: number | undefined | null
        note?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['createPurchaseOrderItem'],
    ]
    updatePurchaseOrderItem?: [
      {
        id: number
        currency?: string | undefined | null
        product?: number | undefined | null
        quantity?: number | undefined | null
        purchasePrice?: number | undefined | null
        note?: string | undefined | null
        externalId?: string | undefined | null
      },
      ResolverInputTypes['updatePurchaseOrderItem'],
    ]
    deletePurchaseOrderItem?: [{ id: number }, ResolverInputTypes['deletePurchaseOrderItem']]
    __typename?: boolean | `@${string}`
  }>
  ['createProduct']: AliasType<{
    data?: ResolverInputTypes['Product']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProduct']: AliasType<{
    data?: ResolverInputTypes['Product']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProduct']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['refreshProductDepots']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductMove']: {
    product?: number | undefined | null
    depotFrom?: number | undefined | null
    depotTo?: number | undefined | null
    productDepot?: number | undefined | null
    supplier?: number | undefined | null
    amount: number
    purchasePrice?: number | undefined | null
    purchaseCurrency?: string | undefined | null
    purchaseCurrencyDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    purchaseOrderItem?: number | undefined | null
    expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    batch?: string | undefined | null
    position1?: string | undefined | null
    position2?: string | undefined | null
    position3?: string | undefined | null
    note?: string | undefined | null
    orderItem?: number | undefined | null
    serialNumbers?: Array<string | undefined | null> | undefined | null
  }
  ['createProductMovePack']: AliasType<{
    data?: ResolverInputTypes['ProductMovePack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductMovePack']: AliasType<{
    data?: ResolverInputTypes['ProductMovePack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductMovePack']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductMove']: AliasType<{
    data?: ResolverInputTypes['ProductMove']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductMove']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductPrice']: AliasType<{
    data?: ResolverInputTypes['ProductPrice']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductPrice']: AliasType<{
    data?: ResolverInputTypes['ProductPrice']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductPrice']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductBundle']: AliasType<{
    data?: ResolverInputTypes['ProductBundle']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductBundle']: AliasType<{
    data?: ResolverInputTypes['ProductBundle']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductBundle']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductDepotMetric']: AliasType<{
    data?: ResolverInputTypes['ProductDepotMetric']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductDepotMetric']: AliasType<{
    data?: ResolverInputTypes['ProductDepotMetric']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductDepotMetric']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createInstitution']: AliasType<{
    data?: ResolverInputTypes['Institution']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateInstitution']: AliasType<{
    data?: ResolverInputTypes['Institution']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteInstitution']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createOrderItem_']: {
    name?: string | undefined | null
    type?: string | undefined | null
    ean?: string | undefined | null
    code?: string | undefined | null
    quantity?: number | undefined | null
    price?: number | undefined | null
    sale?: number | undefined | null
    vat?: number | undefined | null
    product?: number | undefined | null
    serial?: string | undefined | null
    expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    batch?: string | undefined | null
    note?: string | undefined | null
    isForSubsequentSettlement?: boolean | undefined | null
    picked?: boolean | undefined | null
    packed?: boolean | undefined | null
    voucher?: number | undefined | null
    externalId?: string | undefined | null
  }
  ['createPaymentItem_']: {
    payment: number
    checkout?: number | undefined | null
    voucher?: number | undefined | null
    amount: number
    dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    dateCancelled?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    externalId?: string | undefined | null
    isPaid?: boolean | undefined | null
    datePaid?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['processStatusRelation']: {
    status?: string | undefined | null
    note?: string | undefined | null
  }
  ['createOrder']: AliasType<{
    data?: ResolverInputTypes['Order']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateOrderItem_']: {
    id?: number | undefined | null
    name?: string | undefined | null
    type?: string | undefined | null
    ean?: string | undefined | null
    code?: string | undefined | null
    quantity?: number | undefined | null
    price?: string | undefined | null
    sale?: string | undefined | null
    vat?: number | undefined | null
    product?: number | undefined | null
    serial?: string | undefined | null
    expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    batch?: string | undefined | null
    note?: string | undefined | null
    isForSubsequentSettlement?: boolean | undefined | null
    picked?: boolean | undefined | null
    packed?: boolean | undefined | null
    voucher?: number | undefined | null
    externalId?: string | undefined | null
  }
  ['updatePaymentItem_']: {
    payment?: number | undefined | null
    checkout?: number | undefined | null
    voucher?: number | undefined | null
    amount?: string | undefined | null
    dateCreated?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    dateCancelled?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    externalId?: string | undefined | null
    isPaid?: boolean | undefined | null
    datePaid?: ResolverInputTypes['DateTimeAsString'] | undefined | null
  }
  ['updateOrder']: AliasType<{
    data?: ResolverInputTypes['Order']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteReservation']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createOrderItem']: AliasType<{
    data?: ResolverInputTypes['OrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateOrderItem']: AliasType<{
    data?: ResolverInputTypes['OrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteOrderItem']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateClearanceItemLocations']: {
    amount: number
    depot?: number | undefined | null
    productDepot?: number | undefined | null
    position1?: string | undefined | null
    position2?: string | undefined | null
    position3?: string | undefined | null
    expirationDate?: ResolverInputTypes['DateTimeAsString'] | undefined | null
    batch?: string | undefined | null
    locked?: boolean | undefined | null
  }
  ['updateClearanceItem']: AliasType<{
    data?: ResolverInputTypes['ClearanceItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createUser']: AliasType<{
    data?: ResolverInputTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateUser']: AliasType<{
    data?: ResolverInputTypes['User']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteUser']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createUserGroup']: AliasType<{
    data?: ResolverInputTypes['UserGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateUserGroup']: AliasType<{
    data?: ResolverInputTypes['UserGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteUserGroup']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCustomerAddress']: {
    companyName?: string | undefined | null
    firstName?: string | undefined | null
    lastName?: string | undefined | null
    street?: string | undefined | null
    city?: string | undefined | null
    country?: string | undefined | null
    zip?: string | undefined | null
    ic?: string | undefined | null
    dic?: string | undefined | null
    phone?: string | undefined | null
  }
  ['createCustomer']: AliasType<{
    data?: ResolverInputTypes['Customer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCustomer']: AliasType<{
    data?: ResolverInputTypes['Customer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCustomer']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createAddress']: AliasType<{
    data?: ResolverInputTypes['Address']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateAddress']: AliasType<{
    data?: ResolverInputTypes['Address']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteAddress']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCheckout']: AliasType<{
    data?: ResolverInputTypes['Checkout']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCheckout']: AliasType<{
    data?: ResolverInputTypes['Checkout']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCheckout']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCheckoutExtraOperation']: AliasType<{
    data?: ResolverInputTypes['CheckoutExtraOperation']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCheckoutExtraOperation']: AliasType<{
    data?: ResolverInputTypes['CheckoutExtraOperation']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCheckoutExtraOperation']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCheckoutClosing']: AliasType<{
    data?: ResolverInputTypes['CheckoutClosing']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCheckoutClosing']: AliasType<{
    data?: ResolverInputTypes['CheckoutClosing']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCheckoutClosing']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createVat']: AliasType<{
    data?: ResolverInputTypes['Vat']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateVat']: AliasType<{
    data?: ResolverInputTypes['Vat']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteVat']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createDepot']: AliasType<{
    data?: ResolverInputTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateDepot']: AliasType<{
    data?: ResolverInputTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteDepot']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createSupplier']: AliasType<{
    data?: ResolverInputTypes['Supplier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateSupplier']: AliasType<{
    data?: ResolverInputTypes['Supplier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteSupplier']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProducer']: AliasType<{
    data?: ResolverInputTypes['Producer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProducer']: AliasType<{
    data?: ResolverInputTypes['Producer']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProducer']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportStock']: AliasType<{
    data?: ResolverInputTypes['Stock']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportSale']: AliasType<{
    data?: ResolverInputTypes['Sale']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportPurchase']: AliasType<{
    data?: ResolverInputTypes['Purchase']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportOrder']: AliasType<{
    data?: ResolverInputTypes['exportOrder']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExportInventory']: AliasType<{
    data?: ResolverInputTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateExportInventory']: AliasType<{
    data?: ResolverInputTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['refreshExportInventory']: AliasType<{
    data?: ResolverInputTypes['Inventory']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createExport']: AliasType<{
    data?: ResolverInputTypes['Export']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCompany']: AliasType<{
    data?: ResolverInputTypes['Company']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createShop']: AliasType<{
    data?: ResolverInputTypes['Shop']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateShop']: AliasType<{
    data?: ResolverInputTypes['Shop']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteShop']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCert']: AliasType<{
    data?: ResolverInputTypes['Cert']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCert']: AliasType<{
    data?: ResolverInputTypes['Depot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCert']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createEetReceipt']: AliasType<{
    data?: ResolverInputTypes['Receipt']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['sendEetReceipt']: AliasType<{
    data?: ResolverInputTypes['Receipt']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPayment']: AliasType<{
    data?: ResolverInputTypes['Payment']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePayment']: AliasType<{
    data?: ResolverInputTypes['Payment']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePayment']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPaymentItem']: AliasType<{
    data?: ResolverInputTypes['PaymentItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePaymentItem']: AliasType<{
    data?: ResolverInputTypes['PaymentItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePaymentItem']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createDevLog']: AliasType<{
    data?: ResolverInputTypes['DevLog']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['DevLog']: AliasType<{
    id?: boolean | `@${string}`
    name?: boolean | `@${string}`
    content?: boolean | `@${string}`
    created?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPackage']: AliasType<{
    data?: ResolverInputTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePackage']: AliasType<{
    data?: ResolverInputTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePackage']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['resetPackage']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['sendPackage']: AliasType<{
    data?: ResolverInputTypes['Package']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createDisposal']: AliasType<{
    data?: ResolverInputTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateDisposal']: AliasType<{
    data?: ResolverInputTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteDisposal']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['sendDisposal']: AliasType<{
    data?: ResolverInputTypes['PackageDisposal']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createFile']: AliasType<{
    data?: ResolverInputTypes['File']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateFile']: AliasType<{
    data?: ResolverInputTypes['File']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteFile']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCompanyCarrier']: AliasType<{
    data?: ResolverInputTypes['CompanyCarrier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCompanyCarrier']: AliasType<{
    data?: ResolverInputTypes['CompanyCarrier']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCompanyCarrier']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createConsent']: AliasType<{
    data?: ResolverInputTypes['Consent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateConsent']: AliasType<{
    data?: ResolverInputTypes['Consent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteConsent']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCustomerConsent']: AliasType<{
    data?: ResolverInputTypes['CustomerConsent']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCustomerConsent']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductDepot']: AliasType<{
    data?: ResolverInputTypes['ProductDepot']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createCategory']: AliasType<{
    data?: ResolverInputTypes['Category']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateCategory']: AliasType<{
    data?: ResolverInputTypes['Category']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteCategory']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createParameter']: AliasType<{
    data?: ResolverInputTypes['Parameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateParameter']: AliasType<{
    data?: ResolverInputTypes['Parameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteParameter']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createOrderGroup']: AliasType<{
    data?: ResolverInputTypes['OrderGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateOrderGroup']: AliasType<{
    data?: ResolverInputTypes['OrderGroup']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteOrderGroup']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createVoucher']: AliasType<{
    data?: ResolverInputTypes['Voucher']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateVoucher']: AliasType<{
    data?: ResolverInputTypes['Voucher']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteVoucher']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPricePriceLevel']: AliasType<{
    data?: ResolverInputTypes['PriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePriceLevel']: AliasType<{
    data?: ResolverInputTypes['PriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePriceLevel']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductParameter']: AliasType<{
    data?: ResolverInputTypes['ProductParameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductParameter']: AliasType<{
    data?: ResolverInputTypes['ProductParameter']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductParameter']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductPriceLevel']: AliasType<{
    data?: ResolverInputTypes['ProductPriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductPriceLevel']: AliasType<{
    data?: ResolverInputTypes['ProductPriceLevel']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductPriceLevel']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createTag']: AliasType<{
    data?: ResolverInputTypes['Tag']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateTag']: AliasType<{
    data?: ResolverInputTypes['Tag']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteTag']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createProductPack']: AliasType<{
    data?: ResolverInputTypes['ProductPack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updateProductPack']: AliasType<{
    data?: ResolverInputTypes['ProductPack']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deleteProductPack']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPurchaseOrderItem_']: {
    product: number
    currency: string
    quantity: number
    purchasePrice?: number | undefined | null
    note?: string | undefined | null
    externalId?: string | undefined | null
  }
  ['createPurchaseOrder']: AliasType<{
    data?: ResolverInputTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePurchaseOrder']: AliasType<{
    data?: ResolverInputTypes['PurchaseOrder']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePurchaseOrder']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['createPurchaseOrderItem']: AliasType<{
    data?: ResolverInputTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['updatePurchaseOrderItem']: AliasType<{
    data?: ResolverInputTypes['PurchaseOrderItem']
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['deletePurchaseOrderItem']: AliasType<{
    errors?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
}

export type ModelTypes = {
  ['schema']: {
    query?: ModelTypes['RootSchemaQuery'] | undefined
    mutation?: ModelTypes['RootSchemaMutation'] | undefined
  }
  ['RootSchemaQuery']: {
    user?: ModelTypes['user'] | undefined
    userMyProfile?: ModelTypes['userMyProfile'] | undefined
    users?: ModelTypes['users'] | undefined
    isUserEmailUnique?: ModelTypes['isUserEmailUnique'] | undefined
    userGroup?: ModelTypes['userGroup'] | undefined
    userGroups?: ModelTypes['userGroups'] | undefined
    roles?: Array<ModelTypes['Role'] | undefined> | undefined
    product?: ModelTypes['product'] | undefined
    products?: ModelTypes['products'] | undefined
    productPurchasePrice?: ModelTypes['productPurchasePrice'] | undefined
    productSellItems?: ModelTypes['productSellItems'] | undefined
    productMovePacks?: ModelTypes['productMovePacks'] | undefined
    productMoves?: ModelTypes['productMoves'] | undefined
    productMovePack?: ModelTypes['productMovePack'] | undefined
    institution?: ModelTypes['institution'] | undefined
    institutions?: ModelTypes['institutions'] | undefined
    order?: ModelTypes['order'] | undefined
    orders?: ModelTypes['orders'] | undefined
    soldItems?: ModelTypes['soldItems'] | undefined
    orderItems?: ModelTypes['orderItems'] | undefined
    orderItem?: ModelTypes['orderItem'] | undefined
    customer?: ModelTypes['customer'] | undefined
    customers?: ModelTypes['customers'] | undefined
    address?: ModelTypes['address'] | undefined
    addresses?: ModelTypes['addresses'] | undefined
    checkout?: ModelTypes['checkout'] | undefined
    checkouts?: ModelTypes['checkouts'] | undefined
    eventTypes?: ModelTypes['eventTypes'] | undefined
    consent?: ModelTypes['consent'] | undefined
    consents?: ModelTypes['consents'] | undefined
    checkoutExtraOperation?: ModelTypes['checkoutExtraOperation'] | undefined
    checkoutExtraOperations?: ModelTypes['checkoutExtraOperations'] | undefined
    checkoutClosing?: ModelTypes['checkoutClosing'] | undefined
    checkoutClosings?: ModelTypes['checkoutClosings'] | undefined
    vat?: ModelTypes['vat'] | undefined
    vats?: ModelTypes['vats'] | undefined
    depot?: ModelTypes['depot'] | undefined
    depots?: ModelTypes['depots'] | undefined
    supplier?: ModelTypes['supplier'] | undefined
    suppliers?: ModelTypes['suppliers'] | undefined
    producer?: ModelTypes['producer'] | undefined
    producers?: ModelTypes['producers'] | undefined
    exportStocks?: ModelTypes['exportStocks'] | undefined
    exportSales?: ModelTypes['exportSales'] | undefined
    exportPurchases?: ModelTypes['exportPurchases'] | undefined
    exportOrders?: ModelTypes['exportOrders'] | undefined
    exportInventory?: ModelTypes['exportInventory'] | undefined
    exportInventories?: ModelTypes['exportInventories'] | undefined
    export?: ModelTypes['export'] | undefined
    exports?: ModelTypes['exports'] | undefined
    exportTypes?: ModelTypes['exportTypes'] | undefined
    shop?: ModelTypes['shop'] | undefined
    shops?: ModelTypes['shops'] | undefined
    cert?: ModelTypes['cert'] | undefined
    certs?: ModelTypes['certs'] | undefined
    eetReceipts?: ModelTypes['eetReceipts'] | undefined
    payment?: ModelTypes['payment'] | undefined
    payments?: ModelTypes['payments'] | undefined
    paymentItem?: ModelTypes['paymentItem'] | undefined
    paymentItems?: ModelTypes['paymentItems'] | undefined
    paymentTypes?: ModelTypes['paymentTypes'] | undefined
    processStatuses?: ModelTypes['processStatuses'] | undefined
    carriers?: ModelTypes['carriers'] | undefined
    currencies?: ModelTypes['currencies'] | undefined
    package?: ModelTypes['package'] | undefined
    packages?: ModelTypes['packages'] | undefined
    stats?: ModelTypes['packageStats'] | undefined
    disposal?: ModelTypes['disposal'] | undefined
    disposals?: ModelTypes['disposals'] | undefined
    productDepotMetric?: ModelTypes['productDepotMetric'] | undefined
    productDepotMetrics?: ModelTypes['productDepotMetrics'] | undefined
    file?: ModelTypes['file'] | undefined
    files?: ModelTypes['files'] | undefined
    nextEan?: ModelTypes['nextEan'] | undefined
    statsCarrierUsage?: ModelTypes['statsCarrierUsage'] | undefined
    statsPaymentSales?: ModelTypes['statsPaymentSales'] | undefined
    statsBestsellers?: ModelTypes['statsBestsellers'] | undefined
    companyCarrier?: ModelTypes['companyCarrier'] | undefined
    companyCarriers?: ModelTypes['companyCarriers'] | undefined
    category?: ModelTypes['category'] | undefined
    categories?: ModelTypes['categories'] | undefined
    parameter?: ModelTypes['parameter'] | undefined
    parameters?: ModelTypes['parameters'] | undefined
    orderGroup?: ModelTypes['orderGroup'] | undefined
    orderGroups?: ModelTypes['orderGroups'] | undefined
    voucher?: ModelTypes['voucher'] | undefined
    vouchers?: ModelTypes['vouchers'] | undefined
    productPrices?: ModelTypes['productPrices'] | undefined
    priceLevel?: ModelTypes['priceLevel'] | undefined
    priceLevels?: ModelTypes['priceLevels'] | undefined
    productParameter?: ModelTypes['productParameter'] | undefined
    productParameters?: ModelTypes['productParameters'] | undefined
    productPriceLevel?: ModelTypes['productPriceLevel'] | undefined
    productPriceLevels?: ModelTypes['productPriceLevels'] | undefined
    tag?: ModelTypes['tag'] | undefined
    tags?: ModelTypes['tags'] | undefined
    productPack?: ModelTypes['productPack'] | undefined
    productPacks?: ModelTypes['productPacks'] | undefined
    purchaseOrder?: ModelTypes['purchaseOrder'] | undefined
    purchaseOrders?: ModelTypes['purchaseOrders'] | undefined
    purchaseOrderItem?: ModelTypes['purchaseOrderItem'] | undefined
    modules?: ModelTypes['modules'] | undefined
  }
  ['user']: {
    data?: ModelTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['User']: {
    id?: number | undefined
    email?: string | undefined
    name?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    phone?: string | undefined
    groups?: Array<ModelTypes['UserGroup'] | undefined> | undefined
    roles?: Array<string | undefined> | undefined
    enabled?: boolean | undefined
    company?: ModelTypes['Company'] | undefined
    pin?: string | undefined
    externalId?: string | undefined
    customers?: Array<ModelTypes['Customer'] | undefined> | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
    locale?: string | undefined
    deletable?: boolean | undefined
  }
  ['UserGroup']: {
    id?: number | undefined
    name?: string | undefined
    roles?: Array<string | undefined> | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['Company']: {
    id?: number | undefined
    name?: string | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
    email?: string | undefined
    street?: string | undefined
    city?: string | undefined
    zip?: string | undefined
    country?: string | undefined
    currency?: ModelTypes['Currency'] | undefined
    registrationNote?: string | undefined
    nextEan?: string | undefined
    billLogo?: string | undefined
    parent?: ModelTypes['Company'] | undefined
    children?: Array<ModelTypes['Company'] | undefined> | undefined
    carrierRelations?: Array<ModelTypes['CompanyCarrier'] | undefined> | undefined
    moduleRelations?: Array<ModelTypes['CompanyModule'] | undefined> | undefined
    payments?: Array<ModelTypes['Payment'] | undefined> | undefined
    defaultVoucherValidity?: number | undefined
    pickingOnlyOneDepot?: boolean | undefined
    customOptions?: string | undefined
    batchExpirationMode?: string | undefined
    externalId?: string | undefined
    purchasePriceWithVat?: boolean | undefined
  }
  ['Currency']: {
    id?: string | undefined
    name?: string | undefined
    ratio?: number | undefined
  }
  ['CompanyCarrier']: {
    id?: number | undefined
    enable?: boolean | undefined
    options?: string | undefined
    carrier?: ModelTypes['Carrier'] | undefined
    checkout?: ModelTypes['Checkout'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
  }
  ['Carrier']: {
    id?: string | undefined
    name?: string | undefined
    color?: string | undefined
    position?: number | undefined
    tariffs?: Array<ModelTypes['CarrierTariff'] | undefined> | undefined
    requiredShippingAddress?: boolean | undefined
  }
  ['CarrierTariff']: {
    id?: number | undefined
    const?: string | undefined
    name?: string | undefined
    cod?: boolean | undefined
    position?: number | undefined
  }
  ['Checkout']: {
    id?: number | undefined
    name?: string | undefined
    amount?: number | undefined
    nextReservationNumber?: number | undefined
    nextBillNumber?: number | undefined
    billFooter?: string | undefined
    payments?: Array<ModelTypes['Payment'] | undefined> | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
    returnsDepot?: ModelTypes['Depot'] | undefined
    currentClosing?: ModelTypes['CheckoutClosing'] | undefined
    eetId?: number | undefined
    eetEnable?: boolean | undefined
    eetPlayground?: boolean | undefined
    eetVerificationMode?: boolean | undefined
    billWidth?: number | undefined
    billFontSize?: number | undefined
    billFontWeight?: string | undefined
    eventUrl?: string | undefined
    eventTypes?: Array<string | undefined> | undefined
    company?: ModelTypes['Company'] | undefined
    negativeReservation?: boolean | undefined
    externalId?: string | undefined
    users?: Array<ModelTypes['User'] | undefined> | undefined
    autoPicking?: boolean | undefined
    autoPacking?: boolean | undefined
    autoDispatched?: boolean | undefined
    institution?: ModelTypes['Institution'] | undefined
    position?: number | undefined
    deletable?: boolean | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['Payment']: {
    id?: number | undefined
    name?: string | undefined
    eetEnable?: boolean | undefined
    /** Expedovat i nezaplacené objednávky */
    dispatchUnpaid?: boolean | undefined
    type?: ModelTypes['PaymentType'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['PaymentType']: {
    id?: string | undefined
    name?: string | undefined
  }
  ['Depot']: {
    id?: number | undefined
    name?: string | undefined
    emailIn?: string | undefined
    unavailablesUrl?: string | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
    companyCarriers?: Array<ModelTypes['CompanyCarrier'] | undefined> | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    institution?: ModelTypes['Institution'] | undefined
    users?: Array<ModelTypes['User'] | undefined> | undefined
    productDepots?: Array<ModelTypes['ProductDepot'] | undefined> | undefined
    position?: number | undefined
    deletable?: boolean | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['Institution']: {
    id?: number | undefined
    name?: string | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
  }
  ['ProductDepot']: {
    id?: number | undefined
    depot?: ModelTypes['Depot'] | undefined
    product?: ModelTypes['Product'] | undefined
    supplier?: ModelTypes['Supplier'] | undefined
    purchasePrice?: number | undefined
    quantityStock?: number | undefined
    quantityReservation?: number | undefined
    quantityAvailable?: number | undefined
    quantityUnavailable?: number | undefined
    inventoryQuantityStock?: number | undefined
    expirationDate?: ModelTypes['DateTime'] | undefined
    batch?: string | undefined
    position?: string | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    clearanceItemId?: string | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['Product']: {
    id?: number | undefined
    /** Název */
    name?: string | undefined
    /** Název včetně názvu rodičovského produktu */
    fullName?: string | undefined
    /** Unikátní Ean 13 */
    ean?: string | undefined
    /** Unikátní Ean 13 */
    ean2?: string | undefined
    /** Unikátní Ean 13 */
    ean3?: string | undefined
    /** Unikátní Ean 13 */
    ean4?: string | undefined
    /** Unikátní Ean 13 */
    ean5?: string | undefined
    /** Kód */
    code?: string | undefined
    /** Kód2 */
    code2?: string | undefined
    /** Kód3 */
    code3?: string | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Prodejní cena */
    sellPrice?: number | undefined
    /** Prodejní cena bez DPH */
    sellPriceWithoutVat?: number | undefined
    /** Cena před slevou */
    beforeSellPrice?: number | undefined
    /** Aktuální prodejní cena - včetně započtených akčních cen */
    actualSellPrice?: number | undefined
    /** Aktuální prodejní cena bez DPH */
    actualSellPriceWithoutVat?: number | undefined
    /** Aktuální cena před slevou - včetně započtených akčních cen */
    actualBeforeSellPrice?: number | undefined
    /** Výchozí nákupní cena */
    purchasePrice?: number | undefined
    /** Výchozí nákupní měna */
    purchaseCurrency?: ModelTypes['Currency'] | undefined
    /** Váha */
    weight?: number | undefined
    /** Rozměr X */
    dimensionX?: number | undefined
    /** Rozměr Y */
    dimensionY?: number | undefined
    /** Rozměr Z */
    dimensionZ?: number | undefined
    /** Země výroby */
    originCountry?: string | undefined
    /** HS kod */
    hsCode?: string | undefined
    /** Je produkt aktnivní? */
    enabled?: boolean | undefined
    /** DPH */
    vat?: ModelTypes['Vat'] | undefined
    /** Výrobce */
    producer?: ModelTypes['Producer'] | undefined
    /** Výchozí dodavatel */
    supplier?: ModelTypes['Supplier'] | undefined
    /** Rodičovský produkt */
    parent?: ModelTypes['Product'] | undefined
    /** Potomci / varianty produktu */
    children?: Array<ModelTypes['Product'] | undefined> | undefined
    /** Deprecated: používejte productDepots */
    depots?: Array<ModelTypes['ProductDepot'] | undefined> | undefined
    /** Skladové zásoby */
    productDepots?: Array<ModelTypes['ProductDepot'] | undefined> | undefined
    /** Produkt je položka pro následné uplatnění (např. kupón) - pro EET */
    isForSubsequentSettlement?: boolean | undefined
    /** Hlavní obrázek */
    mainImage?: ModelTypes['File'] | undefined
    /** Obrázky */
    images?: Array<ModelTypes['File'] | undefined> | undefined
    /** Soubory (mimo obrázků) */
    files?: Array<ModelTypes['File'] | undefined> | undefined
    /** Jedná se o set produktů (virtuální produkt) */
    isBundle?: boolean | undefined
    /** Křehký */
    isFragile?: boolean | undefined
    /** Nadrozměrný */
    isOversize?: boolean | undefined
    bundleParents?: Array<ModelTypes['ProductBundle'] | undefined> | undefined
    bundleChildren?: Array<ModelTypes['ProductBundle'] | undefined> | undefined
    /** Poznámka */
    note?: string | undefined
    /** Popis */
    description?: string | undefined
    /** Cokoli... */
    customData?: string | undefined
    /** Akční ceny */
    advancedPrices?: Array<ModelTypes['ProductPrice'] | undefined> | undefined
    /** Počet nedostupných kusů v rezervaci */
    quantityUnavailable?: number | undefined
    /** Počet kusů skladem a všech skladech */
    quantityStock?: number | undefined
    /** Počet dostupných kusů a všech skladech */
    quantityAvailable?: number | undefined
    /** Počet kusů v rezervaci na všech skladech */
    quantityReservation?: number | undefined
    /** Počet kusů objednaný od dodavatele */
    quantityPurchase?: number | undefined
    /** Kategorie */
    categories?: Array<ModelTypes['Category'] | undefined> | undefined
    /** Hlavní kategorie */
    mainCategory?: ModelTypes['Category'] | undefined
    company?: ModelTypes['Company'] | undefined
    /** Počty */
    quantities?: Array<ModelTypes['Quantity'] | undefined> | undefined
    /** Metriky */
    metrics?: Array<ModelTypes['ProductDepotMetric'] | undefined> | undefined
    /** Balení/Kartony */
    packs?: Array<ModelTypes['ProductPack'] | undefined> | undefined
    /** Relace ProductParameter */
    productParameters?: Array<ModelTypes['ProductParameter'] | undefined> | undefined
    /** Relace ProductPriceLevel */
    productPriceLevels?: Array<ModelTypes['ProductPriceLevel'] | undefined> | undefined
    /** Tagy */
    tags?: Array<ModelTypes['Tag'] | undefined> | undefined
    /** Položky k prodeji: jednotlivé expirace a šarže */
    sellItems?: Array<ModelTypes['SellItem'] | undefined> | undefined
    /** Deprectable - use deletable. */
    isDeletable?: boolean | undefined
    /** Lze smazat */
    deletable?: boolean | undefined
    /** Eviduje expirace a šarže */
    enabledBatchExpiration?: boolean | undefined
    /** Eviduje sériová čísla */
    requiredSerialNumbers?: boolean | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['Vat']: {
    id?: number | undefined
    name?: string | undefined
    percent?: number | undefined
    default?: boolean | undefined
    coefficient?: number | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
    created?: ModelTypes['DateTimeAsString'] | undefined
    updated?: ModelTypes['DateTimeAsString'] | undefined
  }
  /** Representation of date and time in same string format as PHP DateTime class. */
  ['DateTimeAsString']: any
  ['Producer']: {
    id?: number | undefined
    name?: string | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['Supplier']: {
    id?: number | undefined
    name?: string | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
    deletable?: boolean | undefined
  }
  ['File']: {
    id?: number | undefined
    originalFilename?: string | undefined
    text?: string | undefined
    main?: boolean | undefined
    mimeType?: string | undefined
    size?: number | undefined
    url?: string | undefined
    product?: ModelTypes['Product'] | undefined
    order?: ModelTypes['Order'] | undefined
    thumbnails?: Array<ModelTypes['FileThumbnail'] | undefined> | undefined
  }
  ['Order']: {
    id?: number | undefined
    company?: ModelTypes['Company'] | undefined
    status?: ModelTypes['OrderStatus'] | undefined
    reservationNumber?: number | undefined
    billNumber?: number | undefined
    vs?: number | undefined
    dateCreated?: ModelTypes['DateTime'] | undefined
    dateBill?: ModelTypes['DateTime'] | undefined
    dateExpedition?: ModelTypes['DateTime'] | undefined
    dateDue?: ModelTypes['DateTime'] | undefined
    dateTax?: ModelTypes['DateTime'] | undefined
    items?: Array<ModelTypes['OrderItem'] | undefined> | undefined
    paymentItems?: Array<ModelTypes['PaymentItem'] | undefined> | undefined
    checkout?: ModelTypes['Checkout'] | undefined
    checkoutClosing?: ModelTypes['CheckoutClosing'] | undefined
    customer?: ModelTypes['Customer'] | undefined
    note?: string | undefined
    privateNote?: string | undefined
    invoiceAddress?: ModelTypes['Address'] | undefined
    shippingAddress?: ModelTypes['Address'] | undefined
    relatedParent?: ModelTypes['Order'] | undefined
    rounding?: number | undefined
    eetReceipt?: ModelTypes['Receipt'] | undefined
    deleted?: ModelTypes['DateTime'] | undefined
    priceAll?: number | undefined
    priceAllWithoutVat?: number | undefined
    profit?: number | undefined
    vatAllocations?: Array<ModelTypes['VatAllocation'] | undefined> | undefined
    /** DEPRECATED - Url PDF účtenky */
    billUrl?: string | undefined
    /** Url PDF faktury */
    invoiceUrl?: string | undefined
    /** Url PDF objednávky */
    reservationUrl?: string | undefined
    /** Url PDF účtenky */
    invoiceReceiptUrl?: string | undefined
    /** Url PDF účtenky-rezervace */
    reservationReceiptUrl?: string | undefined
    processStatus?: ModelTypes['ProcessStatus'] | undefined
    processStatusUpdated?: ModelTypes['DateTime'] | undefined
    processStatusRelation?: ModelTypes['OrderProcessStatus'] | undefined
    processStatusRelations?: Array<ModelTypes['OrderProcessStatus'] | undefined> | undefined
    carrier?: ModelTypes['Carrier'] | undefined
    packages?: Array<ModelTypes['Package'] | undefined> | undefined
    boxes?: Array<string | undefined> | undefined
    isPaid?: boolean | undefined
    currency?: ModelTypes['Currency'] | undefined
    files?: Array<ModelTypes['File'] | undefined> | undefined
    quantityUnavailable?: number | undefined
    createdBy?: ModelTypes['User'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
    movePacks?: Array<ModelTypes['ProductMovePack'] | undefined> | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
    isEditable?: boolean | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Externí číslo */
    externalReference?: string | undefined
    group?: ModelTypes['OrderGroup'] | undefined
    groupPosition?: number | undefined
    clearanceItems?: Array<ModelTypes['ClearanceItem'] | undefined> | undefined
    /** Tagy */
    tags?: Array<ModelTypes['Tag'] | undefined> | undefined
    /** Priorita */
    priority?: number | undefined
    /** Uzamčena */
    locked?: boolean | undefined
    /** Od kdy uzamčena */
    dateLocked?: ModelTypes['DateTimeAsString'] | undefined
    /** Kým uzamčena */
    lockedBy?: ModelTypes['User'] | undefined
  }
  ['OrderStatus']: {
    id?: string | undefined
    name?: string | undefined
  }
  /** Representation of date and time in "Y-m-d H:i:s" format */
  ['DateTime']: any
  ['OrderItem']: {
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    sale?: number | undefined
    price?: number | undefined
    priceWithoutVat?: number | undefined
    priceAll?: number | undefined
    priceAllWithoutVat?: number | undefined
    vat?: ModelTypes['Vat'] | undefined
    product?: ModelTypes['Product'] | undefined
    serial?: string | undefined
    expirationDate?: ModelTypes['DateTime'] | undefined
    batch?: string | undefined
    note?: string | undefined
    profit?: number | undefined
    isForSubsequentSettlement?: boolean | undefined
    picked?: boolean | undefined
    packed?: boolean | undefined
    quantityUnavailable?: number | undefined
    moves?: Array<ModelTypes['ProductMove'] | undefined> | undefined
    clearanceItems?: Array<ModelTypes['ClearanceItem'] | undefined> | undefined
    voucher?: ModelTypes['Voucher'] | undefined
    order?: ModelTypes['Order'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
  }
  ['ProductMove']: {
    id?: number | undefined
    pack?: ModelTypes['ProductMovePack'] | undefined
    productDepotFrom?: ModelTypes['ProductDepot'] | undefined
    productDepotFromQuantityStock?: number | undefined
    productDepotTo?: ModelTypes['ProductDepot'] | undefined
    productDepotToQuantityStock?: number | undefined
    product?: ModelTypes['Product'] | undefined
    amount?: number | undefined
    note?: string | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
    deleted?: ModelTypes['DateTime'] | undefined
    quantityUnavailable?: number | undefined
    createdBy?: ModelTypes['User'] | undefined
    orderItem?: ModelTypes['OrderItem'] | undefined
    picked?: number | undefined
    packed?: number | undefined
    locked?: boolean | undefined
    purchasePrice?: number | undefined
    purchasePriceAll?: number | undefined
    purchasePriceWithVat?: number | undefined
    purchasePriceAllWithVat?: number | undefined
    purchaseOrderItem?: ModelTypes['PurchaseOrderItem'] | undefined
    deletable?: boolean | undefined
    serialNumbers?: Array<string | undefined> | undefined
  }
  ['ProductMovePack']: {
    id?: number | undefined
    externalId?: string | undefined
    externalReference?: string | undefined
    number?: number | undefined
    type?: ModelTypes['ProductMoveType'] | undefined
    purpose?: ModelTypes['ProductMovePurpose'] | undefined
    moves?: Array<ModelTypes['ProductMove'] | undefined> | undefined
    note?: string | undefined
    url?: string | undefined
    dateCreated?: ModelTypes['DateTime'] | undefined
    order?: ModelTypes['Order'] | undefined
    checkout?: ModelTypes['Checkout'] | undefined
    depotFrom?: ModelTypes['Depot'] | undefined
    depotTo?: ModelTypes['Depot'] | undefined
    purchasePrice?: number | undefined
    purchasePriceWithVat?: number | undefined
    purchaseOrder?: ModelTypes['PurchaseOrder'] | undefined
    createdBy?: ModelTypes['User'] | undefined
    files?: Array<ModelTypes['File'] | undefined> | undefined
    company?: ModelTypes['Company'] | undefined
    deletable?: boolean | undefined
  }
  ['ProductMoveType']: {
    id?: string | undefined
    name?: string | undefined
  }
  ['ProductMovePurpose']: {
    id?: string | undefined
    name?: string | undefined
  }
  ['PurchaseOrder']: {
    id?: number | undefined
    company?: ModelTypes['Company'] | undefined
    status?: ModelTypes['PurchaseOrderStatus'] | undefined
    supplier?: ModelTypes['Supplier'] | undefined
    depot?: ModelTypes['Depot'] | undefined
    items?: Array<ModelTypes['PurchaseOrderItem'] | undefined> | undefined
    files?: Array<ModelTypes['File'] | undefined> | undefined
    /** Tagy */
    tags?: Array<ModelTypes['Tag'] | undefined> | undefined
    number?: string | undefined
    note?: string | undefined
    privateNote?: string | undefined
    dateEstimatedArrival?: ModelTypes['DateTime'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Externí reference */
    externalReference?: string | undefined
    /** Reference dodavatele */
    supplierReference?: string | undefined
    purchasePriceAll?: number | undefined
    productMovePacks?: Array<ModelTypes['ProductMovePack'] | undefined> | undefined
    createdBy?: ModelTypes['User'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['PurchaseOrderStatus']: {
    id?: string | undefined
    name?: string | undefined
  }
  ['PurchaseOrderItem']: {
    id?: number | undefined
    purchaseOrder?: ModelTypes['PurchaseOrder'] | undefined
    currency?: ModelTypes['Currency'] | undefined
    product?: ModelTypes['Product'] | undefined
    quantity?: number | undefined
    purchasePrice?: number | undefined
    purchasePriceAll?: number | undefined
    productMoves?: Array<ModelTypes['ProductMove'] | undefined> | undefined
    note?: string | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Již naskladněný počet kusů */
    quantityIn?: number | undefined
  }
  ['Tag']: {
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    color?: string | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
    created?: ModelTypes['DateTimeAsString'] | undefined
    updated?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['ClearanceItem']: {
    id?: string | undefined
    product?: ModelTypes['Product'] | undefined
    amount?: number | undefined
    quantityStock?: number | undefined
    quantityReservation?: number | undefined
    quantityAvailable?: number | undefined
    expirationDate?: ModelTypes['DateTime'] | undefined
    batch?: string | undefined
    position?: string | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    picked?: number | undefined
    packed?: number | undefined
    productMoves?: Array<ModelTypes['ProductMove'] | undefined> | undefined
    productDepots?: Array<ModelTypes['ProductDepot'] | undefined> | undefined
    orderItems?: Array<ModelTypes['OrderItem'] | undefined> | undefined
    locked?: boolean | undefined
  }
  ['Voucher']: {
    id?: number | undefined
    company?: ModelTypes['Company'] | undefined
    product?: ModelTypes['Product'] | undefined
    name?: string | undefined
    code?: string | undefined
    externalId?: string | undefined
    discountType?: string | undefined
    discountPercent?: number | undefined
    discountValue?: number | undefined
    maxUse?: number | undefined
    validFrom?: ModelTypes['DateTimeAsString'] | undefined
    validTo?: ModelTypes['DateTimeAsString'] | undefined
    isValid?: boolean | undefined
    used?: number | undefined
    paymentItems?: Array<ModelTypes['Product'] | undefined> | undefined
    orderItems?: Array<ModelTypes['Product'] | undefined> | undefined
    enabled?: boolean | undefined
    isPayment?: boolean | undefined
  }
  ['PaymentItem']: {
    id?: number | undefined
    amount?: number | undefined
    checkout?: ModelTypes['Checkout'] | undefined
    payment?: ModelTypes['Payment'] | undefined
    voucher?: ModelTypes['Voucher'] | undefined
    dateCreated?: ModelTypes['DateTime'] | undefined
    dateCancelled?: ModelTypes['DateTime'] | undefined
    isPaid?: boolean | undefined
    datePaid?: ModelTypes['DateTime'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
  }
  ['CheckoutClosing']: {
    id?: number | undefined
    company?: ModelTypes['Company'] | undefined
    checkout?: ModelTypes['Checkout'] | undefined
    dateOpen?: ModelTypes['DateTime'] | undefined
    amountOpen?: number | undefined
    amountRealOpen?: number | undefined
    userOpen?: ModelTypes['User'] | undefined
    noteOpen?: string | undefined
    dateClosed?: ModelTypes['DateTime'] | undefined
    amountClosed?: number | undefined
    amountRealClosed?: number | undefined
    userClosed?: ModelTypes['User'] | undefined
    noteClosed?: string | undefined
    orders?: Array<ModelTypes['Order'] | undefined> | undefined
    profit?: number | undefined
    vatAllocations?: Array<ModelTypes['VatAllocation'] | undefined> | undefined
    extraOperations?: Array<ModelTypes['CheckoutExtraOperation'] | undefined> | undefined
    billUrl?: string | undefined
    enumeration?: string | undefined
    paymentSum?: Array<ModelTypes['paymentSum'] | undefined> | undefined
  }
  ['VatAllocation']: {
    vat?: ModelTypes['Vat'] | undefined
    priceWithoutVat?: number | undefined
    vatPrice?: number | undefined
    price?: number | undefined
  }
  ['CheckoutExtraOperation']: {
    id?: number | undefined
    dateCreated?: ModelTypes['DateTime'] | undefined
    amount?: number | undefined
    note?: string | undefined
    user?: ModelTypes['User'] | undefined
    checkout?: ModelTypes['Checkout'] | undefined
    checkoutClosing?: ModelTypes['CheckoutClosing'] | undefined
  }
  ['paymentSum']: {
    payment?: ModelTypes['Payment'] | undefined
    count?: number | undefined
    sum?: number | undefined
  }
  ['Customer']: {
    id?: number | undefined
    externalId?: string | undefined
    email?: string | undefined
    phone?: string | undefined
    name?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    companyName?: string | undefined
    addresses?: Array<ModelTypes['Address'] | undefined> | undefined
    wholesale?: boolean | undefined
    note?: string | undefined
    consentRelations?: Array<ModelTypes['CustomerConsent'] | undefined> | undefined
    birthday?: ModelTypes['DateTime'] | undefined
    users?: Array<ModelTypes['User'] | undefined> | undefined
    minExpirationDays?: number | undefined
    priceLevel?: ModelTypes['PriceLevel'] | undefined
    /** Tagy */
    tags?: Array<ModelTypes['Tag'] | undefined> | undefined
    /** Cokoli... */
    customData?: string | undefined
  }
  ['Address']: {
    id?: number | undefined
    company?: ModelTypes['Company'] | undefined
    customer?: ModelTypes['Customer'] | undefined
    companyName?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    street?: string | undefined
    city?: string | undefined
    country?: string | undefined
    state?: string | undefined
    zip?: string | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
    email?: string | undefined
    isStored?: boolean | undefined
    isBilling?: boolean | undefined
    zasilkovnaAddressId?: string | undefined
    branchId?: string | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Poznámka */
    note?: string | undefined
    /** Cokoli... */
    customData?: string | undefined
  }
  ['CustomerConsent']: {
    id?: number | undefined
    customer?: ModelTypes['Customer'] | undefined
    consent?: ModelTypes['Consent'] | undefined
    name?: string | undefined
    body?: string | undefined
    externalId?: string | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['Consent']: {
    id?: number | undefined
    name?: string | undefined
    body?: string | undefined
    externalId?: string | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['PriceLevel']: {
    id?: number | undefined
    name?: string | undefined
    isPercentage?: boolean | undefined
    percent?: number | undefined
    withVat?: boolean | undefined
    currency?: ModelTypes['Currency'] | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['Receipt']: {
    id?: number | undefined
    dic?: string | undefined
    checkoutEetId?: number | undefined
    shopEetId?: number | undefined
    playground?: boolean | undefined
    verificationMode?: boolean | undefined
    number?: number | undefined
    dateCreated?: ModelTypes['DateTime'] | undefined
    totalPrice?: number | undefined
    priceZeroVat?: number | undefined
    priceStandardVat?: number | undefined
    vatStandard?: number | undefined
    priceFirstReducedVat?: number | undefined
    vatFirstReduced?: number | undefined
    priceSecondReducedVat?: number | undefined
    vatSecondReduced?: number | undefined
    priceForSubsequentSettlement?: number | undefined
    priceUsedSubsequentSettlement?: number | undefined
    fik?: string | undefined
    bkp?: string | undefined
    pkp?: string | undefined
  }
  ['ProcessStatus']: {
    id?: string | undefined
    name?: string | undefined
    position?: number | undefined
  }
  ['OrderProcessStatus']: {
    status?: ModelTypes['ProcessStatus'] | undefined
    note?: string | undefined
    created?: ModelTypes['DateTime'] | undefined
    createdBy?: ModelTypes['User'] | undefined
    actual?: boolean | undefined
  }
  ['Package']: {
    id?: number | undefined
    code?: string | undefined
    carrier?: ModelTypes['Carrier'] | undefined
    tariff?: ModelTypes['CarrierTariff'] | undefined
    items?: Array<ModelTypes['OrderItem'] | undefined> | undefined
    statuses?: Array<ModelTypes['PackageStatus'] | undefined> | undefined
    ticketUrl?: string | undefined
    sent?: ModelTypes['DateTime'] | undefined
    order?: ModelTypes['Order'] | undefined
    disposal?: ModelTypes['PackageDisposal'] | undefined
    weight?: number | undefined
    weightRequired?: boolean | undefined
    dimensionsRequired?: boolean | undefined
    dimensionX?: number | undefined
    dimensionY?: number | undefined
    dimensionZ?: number | undefined
    company?: ModelTypes['Company'] | undefined
    clearanceItems?: Array<ModelTypes['ClearanceItem'] | undefined> | undefined
  }
  ['PackageStatus']: {
    id?: number | undefined
    code?: string | undefined
    text?: string | undefined
    package?: ModelTypes['Package'] | undefined
    created?: ModelTypes['DateTime'] | undefined
  }
  ['PackageDisposal']: {
    id?: number | undefined
    code?: string | undefined
    company?: ModelTypes['Company'] | undefined
    carrier?: ModelTypes['Carrier'] | undefined
    packages?: Array<ModelTypes['Package'] | undefined> | undefined
    ticketUrl?: string | undefined
    sent?: ModelTypes['DateTime'] | undefined
    files?: Array<ModelTypes['File'] | undefined> | undefined
  }
  ['OrderGroup']: {
    id?: number | undefined
    name?: string | undefined
    company?: ModelTypes['Company'] | undefined
    orders?: Array<ModelTypes['Order'] | undefined> | undefined
    user?: ModelTypes['User'] | undefined
    userPosition?: number | undefined
    clearanceItems?: Array<ModelTypes['ClearanceItem'] | undefined> | undefined
  }
  ['FileThumbnail']: {
    format?: string | undefined
    mimeType?: string | undefined
    url?: string | undefined
  }
  ['ProductBundle']: {
    id?: number | undefined
    parent?: ModelTypes['Product'] | undefined
    child?: ModelTypes['Product'] | undefined
    /** Množství */
    quantity?: number | undefined
  }
  ['ProductPrice']: {
    id?: number | undefined
    product?: ModelTypes['Product'] | undefined
    sellPrice?: number | undefined
    available?: number | undefined
    dateFrom?: ModelTypes['DateTime'] | undefined
    dateTo?: ModelTypes['DateTime'] | undefined
    isActual?: boolean | undefined
    note?: string | undefined
  }
  ['Category']: {
    id?: number | undefined
    externalId?: string | undefined
    name?: string | undefined
    text?: string | undefined
    parent?: ModelTypes['Category'] | undefined
    children?: Array<ModelTypes['Category'] | undefined> | undefined
    hasChildren?: boolean | undefined
    position?: number | undefined
    company?: ModelTypes['Company'] | undefined
    treePath?: Array<number | undefined> | undefined
    /** Tagy */
    tags?: Array<ModelTypes['Tag'] | undefined> | undefined
  }
  ['Quantity']: {
    depot?: ModelTypes['Depot'] | undefined
    quantityAvailable?: number | undefined
    quantityReservation?: number | undefined
    quantityStock?: number | undefined
    quantityPurchase?: number | undefined
  }
  ['ProductDepotMetric']: {
    id?: number | undefined
    depot?: ModelTypes['Depot'] | undefined
    product?: ModelTypes['Product'] | undefined
    quantityMinimum?: number | undefined
    quantityOptimum?: number | undefined
    quantityMaximum?: number | undefined
    quantityStock?: number | undefined
    quantityPurchase?: number | undefined
    created?: ModelTypes['DateTime'] | undefined
    updated?: ModelTypes['DateTime'] | undefined
  }
  ['ProductPack']: {
    id?: number | undefined
    /** Unikátní Ean 13 */
    ean?: string | undefined
    /** Kód */
    code?: string | undefined
    /** Poznámka */
    note?: string | undefined
    /** Množství */
    quantity?: number | undefined
    /** Produkt.id */
    product?: ModelTypes['Product'] | undefined
    /** Externí ID */
    externalId?: string | undefined
  }
  ['ProductParameter']: {
    id?: number | undefined
    value?: ModelTypes['StringOrArray'] | undefined
    externalId?: string | undefined
    product?: ModelTypes['Product'] | undefined
    parameter?: ModelTypes['Parameter'] | undefined
  }
  /** String or Array */
  ['StringOrArray']: any
  ['Parameter']: {
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    enumValues?: Array<string | undefined> | undefined
    unit?: string | undefined
    text?: string | undefined
    externalId?: string | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['ProductPriceLevel']: {
    id?: number | undefined
    sellPrice?: number | undefined
    externalId?: string | undefined
    product?: ModelTypes['Product'] | undefined
    priceLevel?: ModelTypes['PriceLevel'] | undefined
  }
  ['SellItem']: {
    id?: string | undefined
    product?: ModelTypes['Product'] | undefined
    quantityStock?: number | undefined
    quantityReservation?: number | undefined
    quantityAvailable?: number | undefined
    expirationDate?: ModelTypes['DateTime'] | undefined
    batch?: string | undefined
    productDepots?: Array<ModelTypes['ProductDepot'] | undefined> | undefined
  }
  ['CompanyModule']: {
    id?: number | undefined
    module?: ModelTypes['Module'] | undefined
  }
  ['Module']: {
    id?: string | undefined
    name?: string | undefined
  }
  ['userMyProfile']: {
    data?: ModelTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['userFilters']: {
    pin?: string | undefined
    email?: string | undefined
    fulltext?: string | undefined
    roles?: Array<string | undefined> | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    enabled?: boolean | undefined
  }
  ['users']: {
    items?: Array<ModelTypes['User'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['Paginator']: {
    current?: number | undefined
    currentItemCount?: number | undefined
    endPage?: number | undefined
    first?: number | undefined
    firstItemNumber?: number | undefined
    firstPageInRange?: number | undefined
    last?: number | undefined
    lastItemNumber?: number | undefined
    lastPageInRange?: number | undefined
    next?: number | undefined
    numItemsPerPage?: number | undefined
    pageCount?: number | undefined
    pageRange?: number | undefined
    startPage?: number | undefined
    totalCount?: number | undefined
    pagesInRange?: Array<number | undefined> | undefined
  }
  ['isUserEmailUnique']: {
    data?: boolean | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['userGroup']: {
    data?: ModelTypes['UserGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['userGroupFilters']: {
    name?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['userGroups']: {
    items?: Array<ModelTypes['UserGroup'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['Role']: {
    name?: string | undefined
    role?: string | undefined
  }
  ['product']: {
    data?: ModelTypes['Product'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productFilters']: {
    id?: number | undefined
    companies?: Array<number | undefined> | undefined
    name?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    codes?: Array<string | undefined> | undefined
    externalId?: string | undefined
    fulltext?: string | undefined
    depots?: Array<number | undefined> | undefined
    metricDepots?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
    defaultSuppliers?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    listType?: string | undefined
    availability?: string | undefined
    enabled?: boolean | undefined
    isBundle?: boolean | undefined
    expirationDateTo?: ModelTypes['DateTimeAsString'] | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    hasBeforeSellPrice?: boolean | undefined
    hasHsCode?: boolean | undefined
    createdFrom?: ModelTypes['DateTimeAsString'] | undefined
    updatedFrom?: ModelTypes['DateTimeAsString'] | undefined
    tags?: Array<number | undefined> | undefined
    categories?: Array<number | undefined> | undefined
    metrics?: string | undefined
    enabledBatchExpiration?: boolean | undefined
    requiredSerialNumbers?: boolean | undefined
    company?: number | undefined
  }
  ['products']: {
    items?: Array<ModelTypes['Product'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productPurchasePrice']: {
    items?: Array<ModelTypes['ProductPurchasePrice'] | undefined> | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['ProductPurchasePrice']: {
    productDepot?: ModelTypes['Depot'] | undefined
    quantity?: number | undefined
    purchasePrice?: number | undefined
  }
  ['productSellItems']: {
    items?: Array<ModelTypes['SellItem'] | undefined> | undefined
  }
  ['productMovePackFilters']: {
    fulltext?: string | undefined
    type?: string | undefined
    depot?: number | undefined
    depotFrom?: number | undefined
    depotTo?: number | undefined
    number?: number | undefined
    checkouts?: Array<number | undefined> | undefined
    withoutCheckout?: boolean | undefined
    product?: number | undefined
    productFulltext?: string | undefined
    order?: number | undefined
    orderFulltext?: string | undefined
    withOrder?: boolean | undefined
    createdBy?: number | undefined
    created?: ModelTypes['created'] | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    externalReference?: string | undefined
    purchaseOrder?: number | undefined
  }
  ['created']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['productMovePacks']: {
    items?: Array<ModelTypes['ProductMovePack'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productMoveFilters']: {
    product?: number | undefined
    type?: string | undefined
    depotFrom?: number | undefined
    depotTo?: number | undefined
    createdBy?: number | undefined
    created?: ModelTypes['created'] | undefined
    expirationDate?: ModelTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
  }
  ['productMoves']: {
    items?: Array<ModelTypes['ProductMove'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productMovePack']: {
    data?: ModelTypes['ProductMovePack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['institution']: {
    data?: ModelTypes['Institution'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['institutionFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['institutions']: {
    items?: Array<ModelTypes['Institution'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['order']: {
    data?: ModelTypes['Order'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['orderFilters']: {
    fulltext?: string | undefined
    reservationNumber?: number | undefined
    billNumber?: number | undefined
    notes?: string | undefined
    checkouts?: Array<number | undefined> | undefined
    depots?: Array<number | undefined> | undefined
    onlyDepots?: Array<number | undefined> | undefined
    payments?: Array<number | undefined> | undefined
    status?: string | undefined
    processStatus?: string | undefined
    processStatusChange?: ModelTypes['DateTimeAsString'] | undefined
    processStatusUpdated?: ModelTypes['processStatusUpdated'] | undefined
    isPaid?: boolean | undefined
    carrier?: string | undefined
    carriers?: Array<string | undefined> | undefined
    deleted?: boolean | undefined
    dateCreated?: ModelTypes['dateCreated'] | undefined
    dateBill?: ModelTypes['dateBill'] | undefined
    dateExpedition?: ModelTypes['dateExpedition'] | undefined
    updated?: ModelTypes['updated'] | undefined
    companies?: Array<number | undefined> | undefined
    box?: string | undefined
    item?: string | undefined
    package?: string | undefined
    customer?: number | undefined
    customerFulltext?: string | undefined
    shippingCountries?: Array<string | undefined> | undefined
    externalId?: string | undefined
    user?: number | undefined
    tags?: Array<number | undefined> | undefined
    hasAvailableProducts?: boolean | undefined
    group?: number | undefined
    groups?: Array<number | undefined> | undefined
    createdBy?: Array<number | undefined> | undefined
    locked?: boolean | undefined
    dateLocked?: ModelTypes['DateTimeAsString'] | undefined
    lockedBy?: Array<number | undefined> | undefined
  }
  ['processStatusUpdated']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['dateCreated']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['dateBill']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['dateExpedition']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['updated']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['orders']: {
    items?: Array<ModelTypes['Order'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['soldItemFilters']: {
    productFulltext?: string | undefined
    customerFulltext?: string | undefined
    checkouts?: Array<number | undefined> | undefined
    depots?: Array<number | undefined> | undefined
    payments?: Array<number | undefined> | undefined
    status?: string | undefined
    processStatus?: string | undefined
    processStatusUpdated?: ModelTypes['processStatusUpdated'] | undefined
    carriers?: Array<string | undefined> | undefined
    dateCreated?: ModelTypes['dateCreated'] | undefined
    companies?: Array<number | undefined> | undefined
    customer?: number | undefined
    product?: number | undefined
    shippingCountries?: Array<string | undefined> | undefined
    orderTags?: Array<number | undefined> | undefined
  }
  ['soldItems']: {
    items?: Array<ModelTypes['SoldItem'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['SoldItem']: {
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    priceAll?: number | undefined
    priceAllWithoutVat?: number | undefined
    product?: ModelTypes['Product'] | undefined
    profit?: number | undefined
  }
  ['orderItemFilters']: {
    status?: string | undefined
    processStatus?: string | undefined
    order?: number | undefined
    productDepot?: number | undefined
    carrier?: string | undefined
  }
  ['orderItems']: {
    items?: Array<ModelTypes['OrderItem'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['orderItem']: {
    data?: ModelTypes['OrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['customer']: {
    data?: ModelTypes['Customer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['customerFilters']: {
    externalId?: string | undefined
    email?: string | undefined
    fulltext?: string | undefined
    companies?: Array<number | undefined> | undefined
    user?: number | undefined
    checkouts?: Array<number | undefined> | undefined
    priceLevel?: number | undefined
    tags?: Array<number | undefined> | undefined
  }
  ['customers']: {
    items?: Array<ModelTypes['Customer'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['address']: {
    data?: ModelTypes['Address'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['addressesFilters']: {
    customer?: number | undefined
    externalId?: string | undefined
    isStored?: boolean | undefined
    isBilling?: boolean | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
    email?: string | undefined
  }
  ['addresses']: {
    items?: Array<ModelTypes['Address'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['checkout']: {
    data?: ModelTypes['Checkout'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['checkoutFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    user?: number | undefined
  }
  ['checkouts']: {
    items?: Array<ModelTypes['Checkout'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['eventTypes']: {
    items?: Array<string | undefined> | undefined
  }
  ['consent']: {
    data?: ModelTypes['Consent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['consentsFilters']: {
    name?: string | undefined
  }
  ['consents']: {
    items?: Array<ModelTypes['Consent'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['checkoutExtraOperation']: {
    data?: ModelTypes['CheckoutExtraOperation'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['checkoutExtraOperationFilters']: {
    checkout?: number | undefined
    checkoutClosing?: number | undefined
    checkouts?: Array<number | undefined> | undefined
  }
  ['checkoutExtraOperations']: {
    items?: Array<ModelTypes['CheckoutExtraOperation'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['checkoutClosing']: {
    data?: ModelTypes['CheckoutClosing'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['checkoutClosingFilters']: {
    checkouts?: Array<number | undefined> | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['checkoutClosings']: {
    items?: Array<ModelTypes['CheckoutClosing'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['vat']: {
    data?: ModelTypes['Vat'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['vatFilters']: {
    name?: string | undefined
    percent?: number | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['vats']: {
    items?: Array<ModelTypes['Vat'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['depot']: {
    data?: ModelTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['depotFilters']: {
    companies?: Array<number | undefined> | undefined
    name?: string | undefined
    checkouts?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['depots']: {
    items?: Array<ModelTypes['Depot'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['supplier']: {
    data?: ModelTypes['Supplier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['supplierFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['suppliers']: {
    items?: Array<ModelTypes['Supplier'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['producer']: {
    data?: ModelTypes['Producer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['producerFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['producers']: {
    items?: Array<ModelTypes['Producer'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['exportStockFilters']: {
    depots?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
  }
  ['exportStocks']: {
    items?: Array<ModelTypes['Stock'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['Stock']: {
    id?: number | undefined
    date?: ModelTypes['DateTime'] | undefined
    generated?: ModelTypes['DateTime'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
    suppliers?: Array<ModelTypes['Supplier'] | undefined> | undefined
    producers?: Array<ModelTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportSaleFilters']: {
    checkouts?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
  }
  ['exportSales']: {
    items?: Array<ModelTypes['Sale'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['Sale']: {
    id?: number | undefined
    dateFrom?: ModelTypes['DateTime'] | undefined
    dateTo?: ModelTypes['DateTime'] | undefined
    generated?: ModelTypes['DateTime'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    suppliers?: Array<ModelTypes['Supplier'] | undefined> | undefined
    producers?: Array<ModelTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportPurchaseFilters']: {
    depots?: Array<number | undefined> | undefined
    checkouts?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
  }
  ['exportPurchases']: {
    items?: Array<ModelTypes['Purchase'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['Purchase']: {
    id?: number | undefined
    dateFrom?: ModelTypes['DateTime'] | undefined
    dateTo?: ModelTypes['DateTime'] | undefined
    generated?: ModelTypes['DateTime'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    suppliers?: Array<ModelTypes['Supplier'] | undefined> | undefined
    producers?: Array<ModelTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportOrderFilters']: {
    status?: string | undefined
    checkouts?: Array<number | undefined> | undefined
  }
  ['exportOrders']: {
    items?: Array<ModelTypes['exportOrder'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['exportOrder']: {
    id?: number | undefined
    dateFrom?: ModelTypes['DateTime'] | undefined
    dateTo?: ModelTypes['DateTime'] | undefined
    generated?: ModelTypes['DateTime'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    status?: ModelTypes['OrderStatus'] | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportInventory']: {
    data?: ModelTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['Inventory']: {
    id?: number | undefined
    date?: ModelTypes['DateTime'] | undefined
    generated?: ModelTypes['DateTime'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    depots?: Array<ModelTypes['Depot'] | undefined> | undefined
    suppliers?: Array<ModelTypes['Supplier'] | undefined> | undefined
    producers?: Array<ModelTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    files?: Array<ModelTypes['File'] | undefined> | undefined
    approved?: ModelTypes['DateTime'] | undefined
    rejected?: ModelTypes['DateTime'] | undefined
    finished?: ModelTypes['DateTime'] | undefined
    pmpIn?: ModelTypes['ProductMovePack'] | undefined
    pmpOut?: ModelTypes['ProductMovePack'] | undefined
  }
  ['exportInventoryFilters']: {
    checkouts?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
    approved?: boolean | undefined
    rejected?: boolean | undefined
    finished?: boolean | undefined
  }
  ['exportInventories']: {
    items?: Array<ModelTypes['Inventory'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['export']: {
    data?: ModelTypes['Export'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['Export']: {
    id?: number | undefined
    file?: ModelTypes['File'] | undefined
    generated?: ModelTypes['DateTime'] | undefined
    created?: ModelTypes['DateTime'] | undefined
    url?: string | undefined
    type?: ModelTypes['ExportType'] | undefined
    filter?: string | undefined
    createdBy?: ModelTypes['User'] | undefined
    note?: string | undefined
  }
  ['ExportType']: {
    id?: string | undefined
    name?: string | undefined
    format?: string | undefined
    group?: string | undefined
  }
  ['exportFilters']: {
    types?: Array<string | undefined> | undefined
    group?: string | undefined
  }
  ['exports']: {
    items?: Array<ModelTypes['Export'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['exportTypeFilters']: {
    group?: string | undefined
  }
  ['exportTypes']: {
    items?: Array<ModelTypes['ExportType'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['shop']: {
    data?: ModelTypes['Shop'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['Shop']: {
    id?: number | undefined
    name?: string | undefined
    street?: string | undefined
    city?: string | undefined
    country?: string | undefined
    zip?: string | undefined
    eetId?: number | undefined
    cert?: ModelTypes['Cert'] | undefined
    checkouts?: Array<ModelTypes['Checkout'] | undefined> | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['Cert']: {
    id?: number | undefined
    name?: string | undefined
    publicKey?: string | undefined
    expirationDate?: ModelTypes['DateTime'] | undefined
    company?: ModelTypes['Company'] | undefined
  }
  ['shopFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['shops']: {
    items?: Array<ModelTypes['Shop'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['cert']: {
    data?: ModelTypes['Cert'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['filters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['certs']: {
    items?: Array<ModelTypes['Cert'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['eetReceiptFilters']: {
    checkouts?: Array<number | undefined> | undefined
    number?: number | undefined
    hasFik?: boolean | undefined
    playground?: boolean | undefined
    verificationMode?: boolean | undefined
  }
  ['eetReceipts']: {
    items?: Array<ModelTypes['Receipt'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['payment']: {
    data?: ModelTypes['Payment'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['paymentFilters']: {
    company?: number | undefined
    name?: string | undefined
    type?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['payments']: {
    items?: Array<ModelTypes['Payment'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['paymentItem']: {
    data?: ModelTypes['PaymentItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['paymentItemsFilters']: {
    order?: number | undefined
    type?: string | undefined
    payment?: number | undefined
    externalId?: string | undefined
  }
  ['paymentItems']: {
    items?: Array<ModelTypes['PaymentItem'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['paymentTypeFilters']: {
    const?: string | undefined
  }
  ['paymentTypes']: {
    items?: Array<ModelTypes['PaymentType'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['processStatusesFilters']: {
    name?: string | undefined
  }
  ['processStatuses']: {
    items?: Array<ModelTypes['ProcessStatus'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['carriersFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['carriers']: {
    items?: Array<ModelTypes['Carrier'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['currenciesFilters']: {
    name?: string | undefined
  }
  ['currencies']: {
    items?: Array<ModelTypes['Currency'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['package']: {
    data?: ModelTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['packageFilters']: {
    code?: string | undefined
    carrier?: string | undefined
    order?: number | undefined
    disposal?: number | undefined
    hasDisposal?: boolean | undefined
    isSent?: boolean | undefined
    companies?: Array<number | undefined> | undefined
    sent?: string | undefined
  }
  ['packages']: {
    items?: Array<ModelTypes['Package'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['packageStatsFilters']: {
    carrier?: string | undefined
    companies?: Array<number | undefined> | undefined
    fromDate?: ModelTypes['DateTimeAsString'] | undefined
    toDate?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['packageStats']: {
    items?: Array<ModelTypes['PackageStats'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['PackageStats']: {
    company?: ModelTypes['Company'] | undefined
    processStatus?: ModelTypes['ProcessStatus'] | undefined
    packageAmount?: number | undefined
    orderAmount?: number | undefined
    country?: string | undefined
  }
  ['disposal']: {
    data?: ModelTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['disposalFilters']: {
    carrier?: string | undefined
  }
  ['disposals']: {
    items?: Array<ModelTypes['PackageDisposal'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productDepotMetric']: {
    data?: ModelTypes['ProductDepotMetric'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productDepotMetricFilters']: {
    product?: number | undefined
    depot?: number | undefined
  }
  ['productDepotMetrics']: {
    items?: Array<ModelTypes['ProductDepotMetric'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['file']: {
    data?: ModelTypes['File'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['fileFilters']: {
    product?: number | undefined
    order?: number | undefined
  }
  ['files']: {
    items?: Array<ModelTypes['File'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['nextEan']: {
    data?: string | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['statsCarrierUsage']: {
    items?: Array<ModelTypes['CarrierUsage'] | undefined> | undefined
  }
  ['CarrierUsage']: {
    amount?: number | undefined
    carrier?: ModelTypes['Carrier'] | undefined
  }
  ['statsPaymentSales']: {
    items?: Array<ModelTypes['PaymentSales'] | undefined> | undefined
  }
  ['PaymentSales']: {
    amount?: number | undefined
    sales?: Array<ModelTypes['Price'] | undefined> | undefined
    payment?: ModelTypes['Payment'] | undefined
  }
  ['Price']: {
    value?: number | undefined
    currency?: ModelTypes['Currency'] | undefined
  }
  ['statsBestsellersSort']: statsBestsellersSort
  ['statsBestsellers']: {
    items?: Array<ModelTypes['Bestsellers'] | undefined> | undefined
  }
  ['Bestsellers']: {
    amount?: number | undefined
    sales?: Array<ModelTypes['Price'] | undefined> | undefined
    product?: ModelTypes['Product'] | undefined
  }
  ['companyCarrier']: {
    data?: ModelTypes['CompanyCarrier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['companyCarrierFilters']: {
    carrier?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['companyCarriers']: {
    items?: Array<ModelTypes['CompanyCarrier'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['category']: {
    data?: ModelTypes['Category'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['categoryFilters']: {
    name?: string | undefined
    parent?: number | undefined
    hasParent?: boolean | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    tags?: Array<number | undefined> | undefined
  }
  ['categories']: {
    items?: Array<ModelTypes['Category'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['parameter']: {
    data?: ModelTypes['Parameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['parametersFilters']: {
    name?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['parameters']: {
    items?: Array<ModelTypes['Parameter'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['orderGroup']: {
    data?: ModelTypes['OrderGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['orderGroupFilters']: {
    companies?: Array<number | undefined> | undefined
    hasUser?: boolean | undefined
    user?: number | undefined
    name?: string | undefined
    processStatus?: string | undefined
  }
  ['orderGroups']: {
    items?: Array<ModelTypes['OrderGroup'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['voucher']: {
    data?: ModelTypes['Voucher'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['voucherFilters']: {
    companies?: Array<number | undefined> | undefined
    name?: string | undefined
    code?: string | undefined
    externalId?: string | undefined
    discountType?: string | undefined
    enabled?: boolean | undefined
    isPayment?: boolean | undefined
    isValid?: boolean | undefined
    isUsed?: boolean | undefined
    validDate?: ModelTypes['voucherValidFilter'] | undefined
    created?: ModelTypes['voucherCreatedFilter'] | undefined
  }
  ['voucherValidFilter']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['voucherCreatedFilter']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['vouchers']: {
    items?: Array<ModelTypes['Voucher'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productPriceFilters']: {
    product?: number | undefined
  }
  ['productPrices']: {
    items?: Array<ModelTypes['ProductPrice'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['priceLevel']: {
    data?: ModelTypes['PriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['priceLevelFilters']: {
    name?: string | undefined
    externalId?: string | undefined
    isPercentage?: boolean | undefined
    withVat?: boolean | undefined
    currency?: string | undefined
  }
  ['priceLevels']: {
    items?: Array<ModelTypes['PriceLevel'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productParameter']: {
    data?: ModelTypes['ProductParameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productParameterFilters']: {
    product?: number | undefined
    parameter?: number | undefined
    value?: string | undefined
    externalId?: string | undefined
  }
  ['productParameters']: {
    items?: Array<ModelTypes['ProductParameter'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productPriceLevel']: {
    data?: ModelTypes['ProductPriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productPriceLevelFilters']: {
    product?: number | undefined
    priceLevel?: number | undefined
    externalId?: string | undefined
  }
  ['productPriceLevels']: {
    items?: Array<ModelTypes['ProductPriceLevel'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['tag']: {
    data?: ModelTypes['Tag'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['tagFilters']: {
    name?: string | undefined
    type?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['tags']: {
    items?: Array<ModelTypes['Tag'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['productPack']: {
    data?: ModelTypes['ProductPack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productPackFilters']: {
    product?: number | undefined
    quantity?: number | undefined
    ean?: string | undefined
    code?: string | undefined
    externalId?: string | undefined
  }
  ['productPacks']: {
    items?: Array<ModelTypes['ProductPack'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['purchaseOrder']: {
    data?: ModelTypes['PurchaseOrder'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['purchaseOrderFilters']: {
    fulltext?: string | undefined
    status?: string | undefined
    supplier?: number | undefined
    depot?: number | undefined
    number?: number | undefined
    externalId?: string | undefined
    externalReference?: string | undefined
    supplierReference?: string | undefined
    createdBy?: number | undefined
    tags?: Array<number | undefined> | undefined
    updated?: ModelTypes['updated'] | undefined
    created?: ModelTypes['created'] | undefined
    dateEstimatedArrival?: ModelTypes['dateEstimatedArrival'] | undefined
    company?: number | undefined
  }
  ['dateEstimatedArrival']: {
    left_date?: ModelTypes['DateTimeAsString'] | undefined
    right_date?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['purchaseOrders']: {
    items?: Array<ModelTypes['PurchaseOrder'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['purchaseOrderItem']: {
    data?: ModelTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['modulesFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['modules']: {
    items?: Array<ModelTypes['Module'] | undefined> | undefined
    paginator?: ModelTypes['Paginator'] | undefined
  }
  ['RootSchemaMutation']: {
    createProduct?: ModelTypes['createProduct'] | undefined
    updateProduct?: ModelTypes['updateProduct'] | undefined
    deleteProduct?: ModelTypes['deleteProduct'] | undefined
    refreshProductDepots?: ModelTypes['refreshProductDepots'] | undefined
    createProductMovePack?: ModelTypes['createProductMovePack'] | undefined
    updateProductMovePack?: ModelTypes['updateProductMovePack'] | undefined
    deleteProductMovePack?: ModelTypes['deleteProductMovePack'] | undefined
    updateProductMove?: ModelTypes['updateProductMove'] | undefined
    deleteProductMove?: ModelTypes['deleteProductMove'] | undefined
    createProductPrice?: ModelTypes['createProductPrice'] | undefined
    updateProductPrice?: ModelTypes['updateProductPrice'] | undefined
    deleteProductPrice?: ModelTypes['deleteProductPrice'] | undefined
    createProductBundle?: ModelTypes['createProductBundle'] | undefined
    updateProductBundle?: ModelTypes['updateProductBundle'] | undefined
    deleteProductBundle?: ModelTypes['deleteProductBundle'] | undefined
    createProductDepotMetric?: ModelTypes['createProductDepotMetric'] | undefined
    updateProductDepotMetric?: ModelTypes['updateProductDepotMetric'] | undefined
    deleteProductDepotMetric?: ModelTypes['deleteProductDepotMetric'] | undefined
    createInstitution?: ModelTypes['createInstitution'] | undefined
    updateInstitution?: ModelTypes['updateInstitution'] | undefined
    deleteInstitution?: ModelTypes['deleteInstitution'] | undefined
    createOrder?: ModelTypes['createOrder'] | undefined
    updateOrder?: ModelTypes['updateOrder'] | undefined
    deleteReservation?: ModelTypes['deleteReservation'] | undefined
    createOrderItem?: ModelTypes['createOrderItem'] | undefined
    updateOrderItem?: ModelTypes['updateOrderItem'] | undefined
    deleteOrderItem?: ModelTypes['deleteOrderItem'] | undefined
    updateClearanceItem?: ModelTypes['updateClearanceItem'] | undefined
    createUser?: ModelTypes['createUser'] | undefined
    updateUser?: ModelTypes['updateUser'] | undefined
    deleteUser?: ModelTypes['deleteUser'] | undefined
    createUserGroup?: ModelTypes['createUserGroup'] | undefined
    updateUserGroup?: ModelTypes['updateUserGroup'] | undefined
    deleteUserGroup?: ModelTypes['deleteUserGroup'] | undefined
    createCustomer?: ModelTypes['createCustomer'] | undefined
    updateCustomer?: ModelTypes['updateCustomer'] | undefined
    deleteCustomer?: ModelTypes['deleteCustomer'] | undefined
    createAddress?: ModelTypes['createAddress'] | undefined
    updateAddress?: ModelTypes['updateAddress'] | undefined
    deleteAddress?: ModelTypes['deleteAddress'] | undefined
    createCheckout?: ModelTypes['createCheckout'] | undefined
    updateCheckout?: ModelTypes['updateCheckout'] | undefined
    deleteCheckout?: ModelTypes['deleteCheckout'] | undefined
    createCheckoutExtraOperation?: ModelTypes['createCheckoutExtraOperation'] | undefined
    updateCheckoutExtraOperation?: ModelTypes['updateCheckoutExtraOperation'] | undefined
    deleteCheckoutExtraOperation?: ModelTypes['deleteCheckoutExtraOperation'] | undefined
    createCheckoutClosing?: ModelTypes['createCheckoutClosing'] | undefined
    updateCheckoutClosing?: ModelTypes['updateCheckoutClosing'] | undefined
    deleteCheckoutClosing?: ModelTypes['deleteCheckoutClosing'] | undefined
    createVat?: ModelTypes['createVat'] | undefined
    updateVat?: ModelTypes['updateVat'] | undefined
    deleteVat?: ModelTypes['deleteVat'] | undefined
    createDepot?: ModelTypes['createDepot'] | undefined
    updateDepot?: ModelTypes['updateDepot'] | undefined
    deleteDepot?: ModelTypes['deleteDepot'] | undefined
    createSupplier?: ModelTypes['createSupplier'] | undefined
    updateSupplier?: ModelTypes['updateSupplier'] | undefined
    deleteSupplier?: ModelTypes['deleteSupplier'] | undefined
    createProducer?: ModelTypes['createProducer'] | undefined
    updateProducer?: ModelTypes['updateProducer'] | undefined
    deleteProducer?: ModelTypes['deleteProducer'] | undefined
    createExportStock?: ModelTypes['createExportStock'] | undefined
    createExportSale?: ModelTypes['createExportSale'] | undefined
    createExportPurchase?: ModelTypes['createExportPurchase'] | undefined
    createExportOrder?: ModelTypes['createExportOrder'] | undefined
    createExportInventory?: ModelTypes['createExportInventory'] | undefined
    updateExportInventory?: ModelTypes['updateExportInventory'] | undefined
    refreshExportInventory?: ModelTypes['refreshExportInventory'] | undefined
    createExport?: ModelTypes['createExport'] | undefined
    updateCompany?: ModelTypes['updateCompany'] | undefined
    createShop?: ModelTypes['createShop'] | undefined
    updateShop?: ModelTypes['updateShop'] | undefined
    deleteShop?: ModelTypes['deleteShop'] | undefined
    createCert?: ModelTypes['createCert'] | undefined
    updateCert?: ModelTypes['updateCert'] | undefined
    deleteCert?: ModelTypes['deleteCert'] | undefined
    createEetReceipt?: ModelTypes['createEetReceipt'] | undefined
    sendEetReceipt?: ModelTypes['sendEetReceipt'] | undefined
    createPayment?: ModelTypes['createPayment'] | undefined
    updatePayment?: ModelTypes['updatePayment'] | undefined
    deletePayment?: ModelTypes['deletePayment'] | undefined
    createPaymentItem?: ModelTypes['createPaymentItem'] | undefined
    updatePaymentItem?: ModelTypes['updatePaymentItem'] | undefined
    deletePaymentItem?: ModelTypes['deletePaymentItem'] | undefined
    createDevLog?: ModelTypes['createDevLog'] | undefined
    createPackage?: ModelTypes['createPackage'] | undefined
    updatePackage?: ModelTypes['updatePackage'] | undefined
    deletePackage?: ModelTypes['deletePackage'] | undefined
    resetPackage?: ModelTypes['resetPackage'] | undefined
    sendPackage?: ModelTypes['sendPackage'] | undefined
    createDisposal?: ModelTypes['createDisposal'] | undefined
    updateDisposal?: ModelTypes['updateDisposal'] | undefined
    deleteDisposal?: ModelTypes['deleteDisposal'] | undefined
    sendDisposal?: ModelTypes['sendDisposal'] | undefined
    createFile?: ModelTypes['createFile'] | undefined
    updateFile?: ModelTypes['updateFile'] | undefined
    deleteFile?: ModelTypes['deleteFile'] | undefined
    createCompanyCarrier?: ModelTypes['createCompanyCarrier'] | undefined
    updateCompanyCarrier?: ModelTypes['updateCompanyCarrier'] | undefined
    deleteCompanyCarrier?: ModelTypes['deleteCompanyCarrier'] | undefined
    createConsent?: ModelTypes['createConsent'] | undefined
    updateConsent?: ModelTypes['updateConsent'] | undefined
    deleteConsent?: ModelTypes['deleteConsent'] | undefined
    createCustomerConsent?: ModelTypes['createCustomerConsent'] | undefined
    deleteCustomerConsent?: ModelTypes['deleteCustomerConsent'] | undefined
    updateProductDepot?: ModelTypes['updateProductDepot'] | undefined
    createCategory?: ModelTypes['createCategory'] | undefined
    updateCategory?: ModelTypes['updateCategory'] | undefined
    deleteCategory?: ModelTypes['deleteCategory'] | undefined
    createParameter?: ModelTypes['createParameter'] | undefined
    updateParameter?: ModelTypes['updateParameter'] | undefined
    deleteParameter?: ModelTypes['deleteParameter'] | undefined
    createOrderGroup?: ModelTypes['createOrderGroup'] | undefined
    updateOrderGroup?: ModelTypes['updateOrderGroup'] | undefined
    deleteOrderGroup?: ModelTypes['deleteOrderGroup'] | undefined
    createVoucher?: ModelTypes['createVoucher'] | undefined
    updateVoucher?: ModelTypes['updateVoucher'] | undefined
    deleteVoucher?: ModelTypes['deleteVoucher'] | undefined
    createPriceLevel?: ModelTypes['createPricePriceLevel'] | undefined
    updatePriceLevel?: ModelTypes['updatePriceLevel'] | undefined
    deletePriceLevel?: ModelTypes['deletePriceLevel'] | undefined
    createProductParameter?: ModelTypes['createProductParameter'] | undefined
    updateProductParameter?: ModelTypes['updateProductParameter'] | undefined
    deleteProductParameter?: ModelTypes['deleteProductParameter'] | undefined
    createProductPriceLevel?: ModelTypes['createProductPriceLevel'] | undefined
    updateProductPriceLevel?: ModelTypes['updateProductPriceLevel'] | undefined
    deleteProductPriceLevel?: ModelTypes['deleteProductPriceLevel'] | undefined
    createTag?: ModelTypes['createTag'] | undefined
    updateTag?: ModelTypes['updateTag'] | undefined
    deleteTag?: ModelTypes['deleteTag'] | undefined
    createProductPack?: ModelTypes['createProductPack'] | undefined
    updateProductPack?: ModelTypes['updateProductPack'] | undefined
    deleteProductPack?: ModelTypes['deleteProductPack'] | undefined
    createPurchaseOrder?: ModelTypes['createPurchaseOrder'] | undefined
    updatePurchaseOrder?: ModelTypes['updatePurchaseOrder'] | undefined
    deletePurchaseOrder?: ModelTypes['deletePurchaseOrder'] | undefined
    createPurchaseOrderItem?: ModelTypes['createPurchaseOrderItem'] | undefined
    updatePurchaseOrderItem?: ModelTypes['updatePurchaseOrderItem'] | undefined
    deletePurchaseOrderItem?: ModelTypes['deletePurchaseOrderItem'] | undefined
  }
  ['createProduct']: {
    data?: ModelTypes['Product'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProduct']: {
    data?: ModelTypes['Product'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProduct']: {
    errors?: Array<string | undefined> | undefined
  }
  ['refreshProductDepots']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductMove']: {
    product?: number | undefined
    depotFrom?: number | undefined
    depotTo?: number | undefined
    productDepot?: number | undefined
    supplier?: number | undefined
    amount: number
    purchasePrice?: number | undefined
    purchaseCurrency?: string | undefined
    purchaseCurrencyDate?: ModelTypes['DateTimeAsString'] | undefined
    purchaseOrderItem?: number | undefined
    expirationDate?: ModelTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    note?: string | undefined
    orderItem?: number | undefined
    serialNumbers?: Array<string | undefined> | undefined
  }
  ['createProductMovePack']: {
    data?: ModelTypes['ProductMovePack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductMovePack']: {
    data?: ModelTypes['ProductMovePack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductMovePack']: {
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductMove']: {
    data?: ModelTypes['ProductMove'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductMove']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductPrice']: {
    data?: ModelTypes['ProductPrice'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductPrice']: {
    data?: ModelTypes['ProductPrice'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductPrice']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductBundle']: {
    data?: ModelTypes['ProductBundle'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductBundle']: {
    data?: ModelTypes['ProductBundle'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductBundle']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductDepotMetric']: {
    data?: ModelTypes['ProductDepotMetric'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductDepotMetric']: {
    data?: ModelTypes['ProductDepotMetric'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductDepotMetric']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createInstitution']: {
    data?: ModelTypes['Institution'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateInstitution']: {
    data?: ModelTypes['Institution'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteInstitution']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createOrderItem_']: {
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    price?: number | undefined
    sale?: number | undefined
    vat?: number | undefined
    product?: number | undefined
    serial?: string | undefined
    expirationDate?: ModelTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    note?: string | undefined
    isForSubsequentSettlement?: boolean | undefined
    picked?: boolean | undefined
    packed?: boolean | undefined
    voucher?: number | undefined
    externalId?: string | undefined
  }
  ['createPaymentItem_']: {
    payment: number
    checkout?: number | undefined
    voucher?: number | undefined
    amount: number
    dateCreated?: ModelTypes['DateTimeAsString'] | undefined
    dateCancelled?: ModelTypes['DateTimeAsString'] | undefined
    externalId?: string | undefined
    isPaid?: boolean | undefined
    datePaid?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['processStatusRelation']: {
    status?: string | undefined
    note?: string | undefined
  }
  ['createOrder']: {
    data?: ModelTypes['Order'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateOrderItem_']: {
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    price?: string | undefined
    sale?: string | undefined
    vat?: number | undefined
    product?: number | undefined
    serial?: string | undefined
    expirationDate?: ModelTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    note?: string | undefined
    isForSubsequentSettlement?: boolean | undefined
    picked?: boolean | undefined
    packed?: boolean | undefined
    voucher?: number | undefined
    externalId?: string | undefined
  }
  ['updatePaymentItem_']: {
    payment?: number | undefined
    checkout?: number | undefined
    voucher?: number | undefined
    amount?: string | undefined
    dateCreated?: ModelTypes['DateTimeAsString'] | undefined
    dateCancelled?: ModelTypes['DateTimeAsString'] | undefined
    externalId?: string | undefined
    isPaid?: boolean | undefined
    datePaid?: ModelTypes['DateTimeAsString'] | undefined
  }
  ['updateOrder']: {
    data?: ModelTypes['Order'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteReservation']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createOrderItem']: {
    data?: ModelTypes['OrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateOrderItem']: {
    data?: ModelTypes['OrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteOrderItem']: {
    errors?: Array<string | undefined> | undefined
  }
  ['updateClearanceItemLocations']: {
    amount: number
    depot?: number | undefined
    productDepot?: number | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    expirationDate?: ModelTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    locked?: boolean | undefined
  }
  ['updateClearanceItem']: {
    data?: ModelTypes['ClearanceItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createUser']: {
    data?: ModelTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateUser']: {
    data?: ModelTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteUser']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createUserGroup']: {
    data?: ModelTypes['UserGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateUserGroup']: {
    data?: ModelTypes['UserGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteUserGroup']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCustomerAddress']: {
    companyName?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    street?: string | undefined
    city?: string | undefined
    country?: string | undefined
    zip?: string | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
  }
  ['createCustomer']: {
    data?: ModelTypes['Customer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCustomer']: {
    data?: ModelTypes['Customer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCustomer']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createAddress']: {
    data?: ModelTypes['Address'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateAddress']: {
    data?: ModelTypes['Address'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteAddress']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCheckout']: {
    data?: ModelTypes['Checkout'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCheckout']: {
    data?: ModelTypes['Checkout'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCheckout']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCheckoutExtraOperation']: {
    data?: ModelTypes['CheckoutExtraOperation'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCheckoutExtraOperation']: {
    data?: ModelTypes['CheckoutExtraOperation'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCheckoutExtraOperation']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCheckoutClosing']: {
    data?: ModelTypes['CheckoutClosing'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCheckoutClosing']: {
    data?: ModelTypes['CheckoutClosing'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCheckoutClosing']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createVat']: {
    data?: ModelTypes['Vat'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateVat']: {
    data?: ModelTypes['Vat'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteVat']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createDepot']: {
    data?: ModelTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateDepot']: {
    data?: ModelTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteDepot']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createSupplier']: {
    data?: ModelTypes['Supplier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateSupplier']: {
    data?: ModelTypes['Supplier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteSupplier']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProducer']: {
    data?: ModelTypes['Producer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProducer']: {
    data?: ModelTypes['Producer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProducer']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createExportStock']: {
    data?: ModelTypes['Stock'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportSale']: {
    data?: ModelTypes['Sale'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportPurchase']: {
    data?: ModelTypes['Purchase'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportOrder']: {
    data?: ModelTypes['exportOrder'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportInventory']: {
    data?: ModelTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateExportInventory']: {
    data?: ModelTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['refreshExportInventory']: {
    data?: ModelTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExport']: {
    data?: ModelTypes['Export'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCompany']: {
    data?: ModelTypes['Company'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createShop']: {
    data?: ModelTypes['Shop'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateShop']: {
    data?: ModelTypes['Shop'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteShop']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCert']: {
    data?: ModelTypes['Cert'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCert']: {
    data?: ModelTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCert']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createEetReceipt']: {
    data?: ModelTypes['Receipt'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['sendEetReceipt']: {
    data?: ModelTypes['Receipt'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createPayment']: {
    data?: ModelTypes['Payment'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePayment']: {
    data?: ModelTypes['Payment'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePayment']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createPaymentItem']: {
    data?: ModelTypes['PaymentItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePaymentItem']: {
    data?: ModelTypes['PaymentItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePaymentItem']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createDevLog']: {
    data?: ModelTypes['DevLog'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['DevLog']: {
    id?: number | undefined
    name?: string | undefined
    content?: string | undefined
    created?: ModelTypes['DateTime'] | undefined
  }
  ['createPackage']: {
    data?: ModelTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePackage']: {
    data?: ModelTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePackage']: {
    errors?: Array<string | undefined> | undefined
  }
  ['resetPackage']: {
    errors?: Array<string | undefined> | undefined
  }
  ['sendPackage']: {
    data?: ModelTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createDisposal']: {
    data?: ModelTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateDisposal']: {
    data?: ModelTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteDisposal']: {
    errors?: Array<string | undefined> | undefined
  }
  ['sendDisposal']: {
    data?: ModelTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createFile']: {
    data?: ModelTypes['File'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateFile']: {
    data?: ModelTypes['File'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteFile']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCompanyCarrier']: {
    data?: ModelTypes['CompanyCarrier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCompanyCarrier']: {
    data?: ModelTypes['CompanyCarrier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCompanyCarrier']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createConsent']: {
    data?: ModelTypes['Consent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateConsent']: {
    data?: ModelTypes['Consent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteConsent']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createCustomerConsent']: {
    data?: ModelTypes['CustomerConsent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCustomerConsent']: {
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductDepot']: {
    data?: ModelTypes['ProductDepot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createCategory']: {
    data?: ModelTypes['Category'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCategory']: {
    data?: ModelTypes['Category'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCategory']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createParameter']: {
    data?: ModelTypes['Parameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateParameter']: {
    data?: ModelTypes['Parameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteParameter']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createOrderGroup']: {
    data?: ModelTypes['OrderGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateOrderGroup']: {
    data?: ModelTypes['OrderGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteOrderGroup']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createVoucher']: {
    data?: ModelTypes['Voucher'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateVoucher']: {
    data?: ModelTypes['Voucher'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteVoucher']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createPricePriceLevel']: {
    data?: ModelTypes['PriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePriceLevel']: {
    data?: ModelTypes['PriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePriceLevel']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductParameter']: {
    data?: ModelTypes['ProductParameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductParameter']: {
    data?: ModelTypes['ProductParameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductParameter']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductPriceLevel']: {
    data?: ModelTypes['ProductPriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductPriceLevel']: {
    data?: ModelTypes['ProductPriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductPriceLevel']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createTag']: {
    data?: ModelTypes['Tag'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateTag']: {
    data?: ModelTypes['Tag'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteTag']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createProductPack']: {
    data?: ModelTypes['ProductPack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductPack']: {
    data?: ModelTypes['ProductPack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductPack']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createPurchaseOrderItem_']: {
    product: number
    currency: string
    quantity: number
    purchasePrice?: number | undefined
    note?: string | undefined
    externalId?: string | undefined
  }
  ['createPurchaseOrder']: {
    data?: ModelTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePurchaseOrder']: {
    data?: ModelTypes['PurchaseOrder'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePurchaseOrder']: {
    errors?: Array<string | undefined> | undefined
  }
  ['createPurchaseOrderItem']: {
    data?: ModelTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePurchaseOrderItem']: {
    data?: ModelTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePurchaseOrderItem']: {
    errors?: Array<string | undefined> | undefined
  }
}

export type GraphQLTypes = {
  ['RootSchemaQuery']: {
    __typename: 'RootSchemaQuery'
    user?: GraphQLTypes['user'] | undefined
    userMyProfile?: GraphQLTypes['userMyProfile'] | undefined
    users?: GraphQLTypes['users'] | undefined
    isUserEmailUnique?: GraphQLTypes['isUserEmailUnique'] | undefined
    userGroup?: GraphQLTypes['userGroup'] | undefined
    userGroups?: GraphQLTypes['userGroups'] | undefined
    roles?: Array<GraphQLTypes['Role'] | undefined> | undefined
    product?: GraphQLTypes['product'] | undefined
    products?: GraphQLTypes['products'] | undefined
    productPurchasePrice?: GraphQLTypes['productPurchasePrice'] | undefined
    productSellItems?: GraphQLTypes['productSellItems'] | undefined
    productMovePacks?: GraphQLTypes['productMovePacks'] | undefined
    productMoves?: GraphQLTypes['productMoves'] | undefined
    productMovePack?: GraphQLTypes['productMovePack'] | undefined
    institution?: GraphQLTypes['institution'] | undefined
    institutions?: GraphQLTypes['institutions'] | undefined
    order?: GraphQLTypes['order'] | undefined
    orders?: GraphQLTypes['orders'] | undefined
    soldItems?: GraphQLTypes['soldItems'] | undefined
    orderItems?: GraphQLTypes['orderItems'] | undefined
    orderItem?: GraphQLTypes['orderItem'] | undefined
    customer?: GraphQLTypes['customer'] | undefined
    customers?: GraphQLTypes['customers'] | undefined
    address?: GraphQLTypes['address'] | undefined
    addresses?: GraphQLTypes['addresses'] | undefined
    checkout?: GraphQLTypes['checkout'] | undefined
    checkouts?: GraphQLTypes['checkouts'] | undefined
    eventTypes?: GraphQLTypes['eventTypes'] | undefined
    consent?: GraphQLTypes['consent'] | undefined
    consents?: GraphQLTypes['consents'] | undefined
    checkoutExtraOperation?: GraphQLTypes['checkoutExtraOperation'] | undefined
    checkoutExtraOperations?: GraphQLTypes['checkoutExtraOperations'] | undefined
    checkoutClosing?: GraphQLTypes['checkoutClosing'] | undefined
    checkoutClosings?: GraphQLTypes['checkoutClosings'] | undefined
    vat?: GraphQLTypes['vat'] | undefined
    vats?: GraphQLTypes['vats'] | undefined
    depot?: GraphQLTypes['depot'] | undefined
    depots?: GraphQLTypes['depots'] | undefined
    supplier?: GraphQLTypes['supplier'] | undefined
    suppliers?: GraphQLTypes['suppliers'] | undefined
    producer?: GraphQLTypes['producer'] | undefined
    producers?: GraphQLTypes['producers'] | undefined
    exportStocks?: GraphQLTypes['exportStocks'] | undefined
    exportSales?: GraphQLTypes['exportSales'] | undefined
    exportPurchases?: GraphQLTypes['exportPurchases'] | undefined
    exportOrders?: GraphQLTypes['exportOrders'] | undefined
    exportInventory?: GraphQLTypes['exportInventory'] | undefined
    exportInventories?: GraphQLTypes['exportInventories'] | undefined
    export?: GraphQLTypes['export'] | undefined
    exports?: GraphQLTypes['exports'] | undefined
    exportTypes?: GraphQLTypes['exportTypes'] | undefined
    shop?: GraphQLTypes['shop'] | undefined
    shops?: GraphQLTypes['shops'] | undefined
    cert?: GraphQLTypes['cert'] | undefined
    certs?: GraphQLTypes['certs'] | undefined
    eetReceipts?: GraphQLTypes['eetReceipts'] | undefined
    payment?: GraphQLTypes['payment'] | undefined
    payments?: GraphQLTypes['payments'] | undefined
    paymentItem?: GraphQLTypes['paymentItem'] | undefined
    paymentItems?: GraphQLTypes['paymentItems'] | undefined
    paymentTypes?: GraphQLTypes['paymentTypes'] | undefined
    processStatuses?: GraphQLTypes['processStatuses'] | undefined
    carriers?: GraphQLTypes['carriers'] | undefined
    currencies?: GraphQLTypes['currencies'] | undefined
    package?: GraphQLTypes['package'] | undefined
    packages?: GraphQLTypes['packages'] | undefined
    stats?: GraphQLTypes['packageStats'] | undefined
    disposal?: GraphQLTypes['disposal'] | undefined
    disposals?: GraphQLTypes['disposals'] | undefined
    productDepotMetric?: GraphQLTypes['productDepotMetric'] | undefined
    productDepotMetrics?: GraphQLTypes['productDepotMetrics'] | undefined
    file?: GraphQLTypes['file'] | undefined
    files?: GraphQLTypes['files'] | undefined
    nextEan?: GraphQLTypes['nextEan'] | undefined
    statsCarrierUsage?: GraphQLTypes['statsCarrierUsage'] | undefined
    statsPaymentSales?: GraphQLTypes['statsPaymentSales'] | undefined
    statsBestsellers?: GraphQLTypes['statsBestsellers'] | undefined
    companyCarrier?: GraphQLTypes['companyCarrier'] | undefined
    companyCarriers?: GraphQLTypes['companyCarriers'] | undefined
    category?: GraphQLTypes['category'] | undefined
    categories?: GraphQLTypes['categories'] | undefined
    parameter?: GraphQLTypes['parameter'] | undefined
    parameters?: GraphQLTypes['parameters'] | undefined
    orderGroup?: GraphQLTypes['orderGroup'] | undefined
    orderGroups?: GraphQLTypes['orderGroups'] | undefined
    voucher?: GraphQLTypes['voucher'] | undefined
    vouchers?: GraphQLTypes['vouchers'] | undefined
    productPrices?: GraphQLTypes['productPrices'] | undefined
    priceLevel?: GraphQLTypes['priceLevel'] | undefined
    priceLevels?: GraphQLTypes['priceLevels'] | undefined
    productParameter?: GraphQLTypes['productParameter'] | undefined
    productParameters?: GraphQLTypes['productParameters'] | undefined
    productPriceLevel?: GraphQLTypes['productPriceLevel'] | undefined
    productPriceLevels?: GraphQLTypes['productPriceLevels'] | undefined
    tag?: GraphQLTypes['tag'] | undefined
    tags?: GraphQLTypes['tags'] | undefined
    productPack?: GraphQLTypes['productPack'] | undefined
    productPacks?: GraphQLTypes['productPacks'] | undefined
    purchaseOrder?: GraphQLTypes['purchaseOrder'] | undefined
    purchaseOrders?: GraphQLTypes['purchaseOrders'] | undefined
    purchaseOrderItem?: GraphQLTypes['purchaseOrderItem'] | undefined
    modules?: GraphQLTypes['modules'] | undefined
  }
  ['user']: {
    __typename: 'user'
    data?: GraphQLTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['User']: {
    __typename: 'User'
    id?: number | undefined
    email?: string | undefined
    name?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    phone?: string | undefined
    groups?: Array<GraphQLTypes['UserGroup'] | undefined> | undefined
    roles?: Array<string | undefined> | undefined
    enabled?: boolean | undefined
    company?: GraphQLTypes['Company'] | undefined
    pin?: string | undefined
    externalId?: string | undefined
    customers?: Array<GraphQLTypes['Customer'] | undefined> | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    locale?: string | undefined
    deletable?: boolean | undefined
  }
  ['UserGroup']: {
    __typename: 'UserGroup'
    id?: number | undefined
    name?: string | undefined
    roles?: Array<string | undefined> | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['Company']: {
    __typename: 'Company'
    id?: number | undefined
    name?: string | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
    email?: string | undefined
    street?: string | undefined
    city?: string | undefined
    zip?: string | undefined
    country?: string | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    registrationNote?: string | undefined
    nextEan?: string | undefined
    billLogo?: string | undefined
    parent?: GraphQLTypes['Company'] | undefined
    children?: Array<GraphQLTypes['Company'] | undefined> | undefined
    carrierRelations?: Array<GraphQLTypes['CompanyCarrier'] | undefined> | undefined
    moduleRelations?: Array<GraphQLTypes['CompanyModule'] | undefined> | undefined
    payments?: Array<GraphQLTypes['Payment'] | undefined> | undefined
    defaultVoucherValidity?: number | undefined
    pickingOnlyOneDepot?: boolean | undefined
    customOptions?: string | undefined
    batchExpirationMode?: string | undefined
    externalId?: string | undefined
    purchasePriceWithVat?: boolean | undefined
  }
  ['Currency']: {
    __typename: 'Currency'
    id?: string | undefined
    name?: string | undefined
    ratio?: number | undefined
  }
  ['CompanyCarrier']: {
    __typename: 'CompanyCarrier'
    id?: number | undefined
    enable?: boolean | undefined
    options?: string | undefined
    carrier?: GraphQLTypes['Carrier'] | undefined
    checkout?: GraphQLTypes['Checkout'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
  }
  ['Carrier']: {
    __typename: 'Carrier'
    id?: string | undefined
    name?: string | undefined
    color?: string | undefined
    position?: number | undefined
    tariffs?: Array<GraphQLTypes['CarrierTariff'] | undefined> | undefined
    requiredShippingAddress?: boolean | undefined
  }
  ['CarrierTariff']: {
    __typename: 'CarrierTariff'
    id?: number | undefined
    const?: string | undefined
    name?: string | undefined
    cod?: boolean | undefined
    position?: number | undefined
  }
  ['Checkout']: {
    __typename: 'Checkout'
    id?: number | undefined
    name?: string | undefined
    amount?: number | undefined
    nextReservationNumber?: number | undefined
    nextBillNumber?: number | undefined
    billFooter?: string | undefined
    payments?: Array<GraphQLTypes['Payment'] | undefined> | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    returnsDepot?: GraphQLTypes['Depot'] | undefined
    currentClosing?: GraphQLTypes['CheckoutClosing'] | undefined
    eetId?: number | undefined
    eetEnable?: boolean | undefined
    eetPlayground?: boolean | undefined
    eetVerificationMode?: boolean | undefined
    billWidth?: number | undefined
    billFontSize?: number | undefined
    billFontWeight?: string | undefined
    eventUrl?: string | undefined
    eventTypes?: Array<string | undefined> | undefined
    company?: GraphQLTypes['Company'] | undefined
    negativeReservation?: boolean | undefined
    externalId?: string | undefined
    users?: Array<GraphQLTypes['User'] | undefined> | undefined
    autoPicking?: boolean | undefined
    autoPacking?: boolean | undefined
    autoDispatched?: boolean | undefined
    institution?: GraphQLTypes['Institution'] | undefined
    position?: number | undefined
    deletable?: boolean | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['Payment']: {
    __typename: 'Payment'
    id?: number | undefined
    name?: string | undefined
    eetEnable?: boolean | undefined
    /** Expedovat i nezaplacené objednávky */
    dispatchUnpaid?: boolean | undefined
    type?: GraphQLTypes['PaymentType'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['PaymentType']: {
    __typename: 'PaymentType'
    id?: string | undefined
    name?: string | undefined
  }
  ['Depot']: {
    __typename: 'Depot'
    id?: number | undefined
    name?: string | undefined
    emailIn?: string | undefined
    unavailablesUrl?: string | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    companyCarriers?: Array<GraphQLTypes['CompanyCarrier'] | undefined> | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    institution?: GraphQLTypes['Institution'] | undefined
    users?: Array<GraphQLTypes['User'] | undefined> | undefined
    productDepots?: Array<GraphQLTypes['ProductDepot'] | undefined> | undefined
    position?: number | undefined
    deletable?: boolean | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['Institution']: {
    __typename: 'Institution'
    id?: number | undefined
    name?: string | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
  }
  ['ProductDepot']: {
    __typename: 'ProductDepot'
    id?: number | undefined
    depot?: GraphQLTypes['Depot'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    supplier?: GraphQLTypes['Supplier'] | undefined
    purchasePrice?: number | undefined
    quantityStock?: number | undefined
    quantityReservation?: number | undefined
    quantityAvailable?: number | undefined
    quantityUnavailable?: number | undefined
    inventoryQuantityStock?: number | undefined
    expirationDate?: GraphQLTypes['DateTime'] | undefined
    batch?: string | undefined
    position?: string | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    clearanceItemId?: string | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['Product']: {
    __typename: 'Product'
    id?: number | undefined
    /** Název */
    name?: string | undefined
    /** Název včetně názvu rodičovského produktu */
    fullName?: string | undefined
    /** Unikátní Ean 13 */
    ean?: string | undefined
    /** Unikátní Ean 13 */
    ean2?: string | undefined
    /** Unikátní Ean 13 */
    ean3?: string | undefined
    /** Unikátní Ean 13 */
    ean4?: string | undefined
    /** Unikátní Ean 13 */
    ean5?: string | undefined
    /** Kód */
    code?: string | undefined
    /** Kód2 */
    code2?: string | undefined
    /** Kód3 */
    code3?: string | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Prodejní cena */
    sellPrice?: number | undefined
    /** Prodejní cena bez DPH */
    sellPriceWithoutVat?: number | undefined
    /** Cena před slevou */
    beforeSellPrice?: number | undefined
    /** Aktuální prodejní cena - včetně započtených akčních cen */
    actualSellPrice?: number | undefined
    /** Aktuální prodejní cena bez DPH */
    actualSellPriceWithoutVat?: number | undefined
    /** Aktuální cena před slevou - včetně započtených akčních cen */
    actualBeforeSellPrice?: number | undefined
    /** Výchozí nákupní cena */
    purchasePrice?: number | undefined
    /** Výchozí nákupní měna */
    purchaseCurrency?: GraphQLTypes['Currency'] | undefined
    /** Váha */
    weight?: number | undefined
    /** Rozměr X */
    dimensionX?: number | undefined
    /** Rozměr Y */
    dimensionY?: number | undefined
    /** Rozměr Z */
    dimensionZ?: number | undefined
    /** Země výroby */
    originCountry?: string | undefined
    /** HS kod */
    hsCode?: string | undefined
    /** Je produkt aktnivní? */
    enabled?: boolean | undefined
    /** DPH */
    vat?: GraphQLTypes['Vat'] | undefined
    /** Výrobce */
    producer?: GraphQLTypes['Producer'] | undefined
    /** Výchozí dodavatel */
    supplier?: GraphQLTypes['Supplier'] | undefined
    /** Rodičovský produkt */
    parent?: GraphQLTypes['Product'] | undefined
    /** Potomci / varianty produktu */
    children?: Array<GraphQLTypes['Product'] | undefined> | undefined
    /** Deprecated: používejte productDepots */
    depots?: Array<GraphQLTypes['ProductDepot'] | undefined> | undefined
    /** Skladové zásoby */
    productDepots?: Array<GraphQLTypes['ProductDepot'] | undefined> | undefined
    /** Produkt je položka pro následné uplatnění (např. kupón) - pro EET */
    isForSubsequentSettlement?: boolean | undefined
    /** Hlavní obrázek */
    mainImage?: GraphQLTypes['File'] | undefined
    /** Obrázky */
    images?: Array<GraphQLTypes['File'] | undefined> | undefined
    /** Soubory (mimo obrázků) */
    files?: Array<GraphQLTypes['File'] | undefined> | undefined
    /** Jedná se o set produktů (virtuální produkt) */
    isBundle?: boolean | undefined
    /** Křehký */
    isFragile?: boolean | undefined
    /** Nadrozměrný */
    isOversize?: boolean | undefined
    bundleParents?: Array<GraphQLTypes['ProductBundle'] | undefined> | undefined
    bundleChildren?: Array<GraphQLTypes['ProductBundle'] | undefined> | undefined
    /** Poznámka */
    note?: string | undefined
    /** Popis */
    description?: string | undefined
    /** Cokoli... */
    customData?: string | undefined
    /** Akční ceny */
    advancedPrices?: Array<GraphQLTypes['ProductPrice'] | undefined> | undefined
    /** Počet nedostupných kusů v rezervaci */
    quantityUnavailable?: number | undefined
    /** Počet kusů skladem a všech skladech */
    quantityStock?: number | undefined
    /** Počet dostupných kusů a všech skladech */
    quantityAvailable?: number | undefined
    /** Počet kusů v rezervaci na všech skladech */
    quantityReservation?: number | undefined
    /** Počet kusů objednaný od dodavatele */
    quantityPurchase?: number | undefined
    /** Kategorie */
    categories?: Array<GraphQLTypes['Category'] | undefined> | undefined
    /** Hlavní kategorie */
    mainCategory?: GraphQLTypes['Category'] | undefined
    company?: GraphQLTypes['Company'] | undefined
    /** Počty */
    quantities?: Array<GraphQLTypes['Quantity'] | undefined> | undefined
    /** Metriky */
    metrics?: Array<GraphQLTypes['ProductDepotMetric'] | undefined> | undefined
    /** Balení/Kartony */
    packs?: Array<GraphQLTypes['ProductPack'] | undefined> | undefined
    /** Relace ProductParameter */
    productParameters?: Array<GraphQLTypes['ProductParameter'] | undefined> | undefined
    /** Relace ProductPriceLevel */
    productPriceLevels?: Array<GraphQLTypes['ProductPriceLevel'] | undefined> | undefined
    /** Tagy */
    tags?: Array<GraphQLTypes['Tag'] | undefined> | undefined
    /** Položky k prodeji: jednotlivé expirace a šarže */
    sellItems?: Array<GraphQLTypes['SellItem'] | undefined> | undefined
    /** Deprectable - use deletable. */
    isDeletable?: boolean | undefined
    /** Lze smazat */
    deletable?: boolean | undefined
    /** Eviduje expirace a šarže */
    enabledBatchExpiration?: boolean | undefined
    /** Eviduje sériová čísla */
    requiredSerialNumbers?: boolean | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['Vat']: {
    __typename: 'Vat'
    id?: number | undefined
    name?: string | undefined
    percent?: number | undefined
    default?: boolean | undefined
    coefficient?: number | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    created?: GraphQLTypes['DateTimeAsString'] | undefined
    updated?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  /** Representation of date and time in same string format as PHP DateTime class. */
  ['DateTimeAsString']: 'scalar' & { name: 'DateTimeAsString' }
  ['Producer']: {
    __typename: 'Producer'
    id?: number | undefined
    name?: string | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['Supplier']: {
    __typename: 'Supplier'
    id?: number | undefined
    name?: string | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    deletable?: boolean | undefined
  }
  ['File']: {
    __typename: 'File'
    id?: number | undefined
    originalFilename?: string | undefined
    text?: string | undefined
    main?: boolean | undefined
    mimeType?: string | undefined
    size?: number | undefined
    url?: string | undefined
    product?: GraphQLTypes['Product'] | undefined
    order?: GraphQLTypes['Order'] | undefined
    thumbnails?: Array<GraphQLTypes['FileThumbnail'] | undefined> | undefined
  }
  ['Order']: {
    __typename: 'Order'
    id?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    status?: GraphQLTypes['OrderStatus'] | undefined
    reservationNumber?: number | undefined
    billNumber?: number | undefined
    vs?: number | undefined
    dateCreated?: GraphQLTypes['DateTime'] | undefined
    dateBill?: GraphQLTypes['DateTime'] | undefined
    dateExpedition?: GraphQLTypes['DateTime'] | undefined
    dateDue?: GraphQLTypes['DateTime'] | undefined
    dateTax?: GraphQLTypes['DateTime'] | undefined
    items?: Array<GraphQLTypes['OrderItem'] | undefined> | undefined
    paymentItems?: Array<GraphQLTypes['PaymentItem'] | undefined> | undefined
    checkout?: GraphQLTypes['Checkout'] | undefined
    checkoutClosing?: GraphQLTypes['CheckoutClosing'] | undefined
    customer?: GraphQLTypes['Customer'] | undefined
    note?: string | undefined
    privateNote?: string | undefined
    invoiceAddress?: GraphQLTypes['Address'] | undefined
    shippingAddress?: GraphQLTypes['Address'] | undefined
    relatedParent?: GraphQLTypes['Order'] | undefined
    rounding?: number | undefined
    eetReceipt?: GraphQLTypes['Receipt'] | undefined
    deleted?: GraphQLTypes['DateTime'] | undefined
    priceAll?: number | undefined
    priceAllWithoutVat?: number | undefined
    profit?: number | undefined
    vatAllocations?: Array<GraphQLTypes['VatAllocation'] | undefined> | undefined
    /** DEPRECATED - Url PDF účtenky */
    billUrl?: string | undefined
    /** Url PDF faktury */
    invoiceUrl?: string | undefined
    /** Url PDF objednávky */
    reservationUrl?: string | undefined
    /** Url PDF účtenky */
    invoiceReceiptUrl?: string | undefined
    /** Url PDF účtenky-rezervace */
    reservationReceiptUrl?: string | undefined
    processStatus?: GraphQLTypes['ProcessStatus'] | undefined
    processStatusUpdated?: GraphQLTypes['DateTime'] | undefined
    processStatusRelation?: GraphQLTypes['OrderProcessStatus'] | undefined
    processStatusRelations?: Array<GraphQLTypes['OrderProcessStatus'] | undefined> | undefined
    carrier?: GraphQLTypes['Carrier'] | undefined
    packages?: Array<GraphQLTypes['Package'] | undefined> | undefined
    boxes?: Array<string | undefined> | undefined
    isPaid?: boolean | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    files?: Array<GraphQLTypes['File'] | undefined> | undefined
    quantityUnavailable?: number | undefined
    createdBy?: GraphQLTypes['User'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
    movePacks?: Array<GraphQLTypes['ProductMovePack'] | undefined> | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    isEditable?: boolean | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Externí číslo */
    externalReference?: string | undefined
    group?: GraphQLTypes['OrderGroup'] | undefined
    groupPosition?: number | undefined
    clearanceItems?: Array<GraphQLTypes['ClearanceItem'] | undefined> | undefined
    /** Tagy */
    tags?: Array<GraphQLTypes['Tag'] | undefined> | undefined
    /** Priorita */
    priority?: number | undefined
    /** Uzamčena */
    locked?: boolean | undefined
    /** Od kdy uzamčena */
    dateLocked?: GraphQLTypes['DateTimeAsString'] | undefined
    /** Kým uzamčena */
    lockedBy?: GraphQLTypes['User'] | undefined
  }
  ['OrderStatus']: {
    __typename: 'OrderStatus'
    id?: string | undefined
    name?: string | undefined
  }
  /** Representation of date and time in "Y-m-d H:i:s" format */
  ['DateTime']: 'scalar' & { name: 'DateTime' }
  ['OrderItem']: {
    __typename: 'OrderItem'
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    sale?: number | undefined
    price?: number | undefined
    priceWithoutVat?: number | undefined
    priceAll?: number | undefined
    priceAllWithoutVat?: number | undefined
    vat?: GraphQLTypes['Vat'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    serial?: string | undefined
    expirationDate?: GraphQLTypes['DateTime'] | undefined
    batch?: string | undefined
    note?: string | undefined
    profit?: number | undefined
    isForSubsequentSettlement?: boolean | undefined
    picked?: boolean | undefined
    packed?: boolean | undefined
    quantityUnavailable?: number | undefined
    moves?: Array<GraphQLTypes['ProductMove'] | undefined> | undefined
    clearanceItems?: Array<GraphQLTypes['ClearanceItem'] | undefined> | undefined
    voucher?: GraphQLTypes['Voucher'] | undefined
    order?: GraphQLTypes['Order'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
  }
  ['ProductMove']: {
    __typename: 'ProductMove'
    id?: number | undefined
    pack?: GraphQLTypes['ProductMovePack'] | undefined
    productDepotFrom?: GraphQLTypes['ProductDepot'] | undefined
    productDepotFromQuantityStock?: number | undefined
    productDepotTo?: GraphQLTypes['ProductDepot'] | undefined
    productDepotToQuantityStock?: number | undefined
    product?: GraphQLTypes['Product'] | undefined
    amount?: number | undefined
    note?: string | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
    deleted?: GraphQLTypes['DateTime'] | undefined
    quantityUnavailable?: number | undefined
    createdBy?: GraphQLTypes['User'] | undefined
    orderItem?: GraphQLTypes['OrderItem'] | undefined
    picked?: number | undefined
    packed?: number | undefined
    locked?: boolean | undefined
    purchasePrice?: number | undefined
    purchasePriceAll?: number | undefined
    purchasePriceWithVat?: number | undefined
    purchasePriceAllWithVat?: number | undefined
    purchaseOrderItem?: GraphQLTypes['PurchaseOrderItem'] | undefined
    deletable?: boolean | undefined
    serialNumbers?: Array<string | undefined> | undefined
  }
  ['ProductMovePack']: {
    __typename: 'ProductMovePack'
    id?: number | undefined
    externalId?: string | undefined
    externalReference?: string | undefined
    number?: number | undefined
    type?: GraphQLTypes['ProductMoveType'] | undefined
    purpose?: GraphQLTypes['ProductMovePurpose'] | undefined
    moves?: Array<GraphQLTypes['ProductMove'] | undefined> | undefined
    note?: string | undefined
    url?: string | undefined
    dateCreated?: GraphQLTypes['DateTime'] | undefined
    order?: GraphQLTypes['Order'] | undefined
    checkout?: GraphQLTypes['Checkout'] | undefined
    depotFrom?: GraphQLTypes['Depot'] | undefined
    depotTo?: GraphQLTypes['Depot'] | undefined
    purchasePrice?: number | undefined
    purchasePriceWithVat?: number | undefined
    purchaseOrder?: GraphQLTypes['PurchaseOrder'] | undefined
    createdBy?: GraphQLTypes['User'] | undefined
    files?: Array<GraphQLTypes['File'] | undefined> | undefined
    company?: GraphQLTypes['Company'] | undefined
    deletable?: boolean | undefined
  }
  ['ProductMoveType']: {
    __typename: 'ProductMoveType'
    id?: string | undefined
    name?: string | undefined
  }
  ['ProductMovePurpose']: {
    __typename: 'ProductMovePurpose'
    id?: string | undefined
    name?: string | undefined
  }
  ['PurchaseOrder']: {
    __typename: 'PurchaseOrder'
    id?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    status?: GraphQLTypes['PurchaseOrderStatus'] | undefined
    supplier?: GraphQLTypes['Supplier'] | undefined
    depot?: GraphQLTypes['Depot'] | undefined
    items?: Array<GraphQLTypes['PurchaseOrderItem'] | undefined> | undefined
    files?: Array<GraphQLTypes['File'] | undefined> | undefined
    /** Tagy */
    tags?: Array<GraphQLTypes['Tag'] | undefined> | undefined
    number?: string | undefined
    note?: string | undefined
    privateNote?: string | undefined
    dateEstimatedArrival?: GraphQLTypes['DateTime'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Externí reference */
    externalReference?: string | undefined
    /** Reference dodavatele */
    supplierReference?: string | undefined
    purchasePriceAll?: number | undefined
    productMovePacks?: Array<GraphQLTypes['ProductMovePack'] | undefined> | undefined
    createdBy?: GraphQLTypes['User'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['PurchaseOrderStatus']: {
    __typename: 'PurchaseOrderStatus'
    id?: string | undefined
    name?: string | undefined
  }
  ['PurchaseOrderItem']: {
    __typename: 'PurchaseOrderItem'
    id?: number | undefined
    purchaseOrder?: GraphQLTypes['PurchaseOrder'] | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    quantity?: number | undefined
    purchasePrice?: number | undefined
    purchasePriceAll?: number | undefined
    productMoves?: Array<GraphQLTypes['ProductMove'] | undefined> | undefined
    note?: string | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Již naskladněný počet kusů */
    quantityIn?: number | undefined
  }
  ['Tag']: {
    __typename: 'Tag'
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    color?: string | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    created?: GraphQLTypes['DateTimeAsString'] | undefined
    updated?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['ClearanceItem']: {
    __typename: 'ClearanceItem'
    id?: string | undefined
    product?: GraphQLTypes['Product'] | undefined
    amount?: number | undefined
    quantityStock?: number | undefined
    quantityReservation?: number | undefined
    quantityAvailable?: number | undefined
    expirationDate?: GraphQLTypes['DateTime'] | undefined
    batch?: string | undefined
    position?: string | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    picked?: number | undefined
    packed?: number | undefined
    productMoves?: Array<GraphQLTypes['ProductMove'] | undefined> | undefined
    productDepots?: Array<GraphQLTypes['ProductDepot'] | undefined> | undefined
    orderItems?: Array<GraphQLTypes['OrderItem'] | undefined> | undefined
    locked?: boolean | undefined
  }
  ['Voucher']: {
    __typename: 'Voucher'
    id?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    name?: string | undefined
    code?: string | undefined
    externalId?: string | undefined
    discountType?: string | undefined
    discountPercent?: number | undefined
    discountValue?: number | undefined
    maxUse?: number | undefined
    validFrom?: GraphQLTypes['DateTimeAsString'] | undefined
    validTo?: GraphQLTypes['DateTimeAsString'] | undefined
    isValid?: boolean | undefined
    used?: number | undefined
    paymentItems?: Array<GraphQLTypes['Product'] | undefined> | undefined
    orderItems?: Array<GraphQLTypes['Product'] | undefined> | undefined
    enabled?: boolean | undefined
    isPayment?: boolean | undefined
  }
  ['PaymentItem']: {
    __typename: 'PaymentItem'
    id?: number | undefined
    amount?: number | undefined
    checkout?: GraphQLTypes['Checkout'] | undefined
    payment?: GraphQLTypes['Payment'] | undefined
    voucher?: GraphQLTypes['Voucher'] | undefined
    dateCreated?: GraphQLTypes['DateTime'] | undefined
    dateCancelled?: GraphQLTypes['DateTime'] | undefined
    isPaid?: boolean | undefined
    datePaid?: GraphQLTypes['DateTime'] | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
  }
  ['CheckoutClosing']: {
    __typename: 'CheckoutClosing'
    id?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    checkout?: GraphQLTypes['Checkout'] | undefined
    dateOpen?: GraphQLTypes['DateTime'] | undefined
    amountOpen?: number | undefined
    amountRealOpen?: number | undefined
    userOpen?: GraphQLTypes['User'] | undefined
    noteOpen?: string | undefined
    dateClosed?: GraphQLTypes['DateTime'] | undefined
    amountClosed?: number | undefined
    amountRealClosed?: number | undefined
    userClosed?: GraphQLTypes['User'] | undefined
    noteClosed?: string | undefined
    orders?: Array<GraphQLTypes['Order'] | undefined> | undefined
    profit?: number | undefined
    vatAllocations?: Array<GraphQLTypes['VatAllocation'] | undefined> | undefined
    extraOperations?: Array<GraphQLTypes['CheckoutExtraOperation'] | undefined> | undefined
    billUrl?: string | undefined
    enumeration?: string | undefined
    paymentSum?: Array<GraphQLTypes['paymentSum'] | undefined> | undefined
  }
  ['VatAllocation']: {
    __typename: 'VatAllocation'
    vat?: GraphQLTypes['Vat'] | undefined
    priceWithoutVat?: number | undefined
    vatPrice?: number | undefined
    price?: number | undefined
  }
  ['CheckoutExtraOperation']: {
    __typename: 'CheckoutExtraOperation'
    id?: number | undefined
    dateCreated?: GraphQLTypes['DateTime'] | undefined
    amount?: number | undefined
    note?: string | undefined
    user?: GraphQLTypes['User'] | undefined
    checkout?: GraphQLTypes['Checkout'] | undefined
    checkoutClosing?: GraphQLTypes['CheckoutClosing'] | undefined
  }
  ['paymentSum']: {
    __typename: 'paymentSum'
    payment?: GraphQLTypes['Payment'] | undefined
    count?: number | undefined
    sum?: number | undefined
  }
  ['Customer']: {
    __typename: 'Customer'
    id?: number | undefined
    externalId?: string | undefined
    email?: string | undefined
    phone?: string | undefined
    name?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    companyName?: string | undefined
    addresses?: Array<GraphQLTypes['Address'] | undefined> | undefined
    wholesale?: boolean | undefined
    note?: string | undefined
    consentRelations?: Array<GraphQLTypes['CustomerConsent'] | undefined> | undefined
    birthday?: GraphQLTypes['DateTime'] | undefined
    users?: Array<GraphQLTypes['User'] | undefined> | undefined
    minExpirationDays?: number | undefined
    priceLevel?: GraphQLTypes['PriceLevel'] | undefined
    /** Tagy */
    tags?: Array<GraphQLTypes['Tag'] | undefined> | undefined
    /** Cokoli... */
    customData?: string | undefined
  }
  ['Address']: {
    __typename: 'Address'
    id?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    customer?: GraphQLTypes['Customer'] | undefined
    companyName?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    street?: string | undefined
    city?: string | undefined
    country?: string | undefined
    state?: string | undefined
    zip?: string | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
    email?: string | undefined
    isStored?: boolean | undefined
    isBilling?: boolean | undefined
    zasilkovnaAddressId?: string | undefined
    branchId?: string | undefined
    /** Externí identifikátor */
    externalId?: string | undefined
    /** Poznámka */
    note?: string | undefined
    /** Cokoli... */
    customData?: string | undefined
  }
  ['CustomerConsent']: {
    __typename: 'CustomerConsent'
    id?: number | undefined
    customer?: GraphQLTypes['Customer'] | undefined
    consent?: GraphQLTypes['Consent'] | undefined
    name?: string | undefined
    body?: string | undefined
    externalId?: string | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['Consent']: {
    __typename: 'Consent'
    id?: number | undefined
    name?: string | undefined
    body?: string | undefined
    externalId?: string | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['PriceLevel']: {
    __typename: 'PriceLevel'
    id?: number | undefined
    name?: string | undefined
    isPercentage?: boolean | undefined
    percent?: number | undefined
    withVat?: boolean | undefined
    currency?: GraphQLTypes['Currency'] | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['Receipt']: {
    __typename: 'Receipt'
    id?: number | undefined
    dic?: string | undefined
    checkoutEetId?: number | undefined
    shopEetId?: number | undefined
    playground?: boolean | undefined
    verificationMode?: boolean | undefined
    number?: number | undefined
    dateCreated?: GraphQLTypes['DateTime'] | undefined
    totalPrice?: number | undefined
    priceZeroVat?: number | undefined
    priceStandardVat?: number | undefined
    vatStandard?: number | undefined
    priceFirstReducedVat?: number | undefined
    vatFirstReduced?: number | undefined
    priceSecondReducedVat?: number | undefined
    vatSecondReduced?: number | undefined
    priceForSubsequentSettlement?: number | undefined
    priceUsedSubsequentSettlement?: number | undefined
    fik?: string | undefined
    bkp?: string | undefined
    pkp?: string | undefined
  }
  ['ProcessStatus']: {
    __typename: 'ProcessStatus'
    id?: string | undefined
    name?: string | undefined
    position?: number | undefined
  }
  ['OrderProcessStatus']: {
    __typename: 'OrderProcessStatus'
    status?: GraphQLTypes['ProcessStatus'] | undefined
    note?: string | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    createdBy?: GraphQLTypes['User'] | undefined
    actual?: boolean | undefined
  }
  ['Package']: {
    __typename: 'Package'
    id?: number | undefined
    code?: string | undefined
    carrier?: GraphQLTypes['Carrier'] | undefined
    tariff?: GraphQLTypes['CarrierTariff'] | undefined
    items?: Array<GraphQLTypes['OrderItem'] | undefined> | undefined
    statuses?: Array<GraphQLTypes['PackageStatus'] | undefined> | undefined
    ticketUrl?: string | undefined
    sent?: GraphQLTypes['DateTime'] | undefined
    order?: GraphQLTypes['Order'] | undefined
    disposal?: GraphQLTypes['PackageDisposal'] | undefined
    weight?: number | undefined
    weightRequired?: boolean | undefined
    dimensionsRequired?: boolean | undefined
    dimensionX?: number | undefined
    dimensionY?: number | undefined
    dimensionZ?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    clearanceItems?: Array<GraphQLTypes['ClearanceItem'] | undefined> | undefined
  }
  ['PackageStatus']: {
    __typename: 'PackageStatus'
    id?: number | undefined
    code?: string | undefined
    text?: string | undefined
    package?: GraphQLTypes['Package'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
  }
  ['PackageDisposal']: {
    __typename: 'PackageDisposal'
    id?: number | undefined
    code?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    carrier?: GraphQLTypes['Carrier'] | undefined
    packages?: Array<GraphQLTypes['Package'] | undefined> | undefined
    ticketUrl?: string | undefined
    sent?: GraphQLTypes['DateTime'] | undefined
    files?: Array<GraphQLTypes['File'] | undefined> | undefined
  }
  ['OrderGroup']: {
    __typename: 'OrderGroup'
    id?: number | undefined
    name?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
    orders?: Array<GraphQLTypes['Order'] | undefined> | undefined
    user?: GraphQLTypes['User'] | undefined
    userPosition?: number | undefined
    clearanceItems?: Array<GraphQLTypes['ClearanceItem'] | undefined> | undefined
  }
  ['FileThumbnail']: {
    __typename: 'FileThumbnail'
    format?: string | undefined
    mimeType?: string | undefined
    url?: string | undefined
  }
  ['ProductBundle']: {
    __typename: 'ProductBundle'
    id?: number | undefined
    parent?: GraphQLTypes['Product'] | undefined
    child?: GraphQLTypes['Product'] | undefined
    /** Množství */
    quantity?: number | undefined
  }
  ['ProductPrice']: {
    __typename: 'ProductPrice'
    id?: number | undefined
    product?: GraphQLTypes['Product'] | undefined
    sellPrice?: number | undefined
    available?: number | undefined
    dateFrom?: GraphQLTypes['DateTime'] | undefined
    dateTo?: GraphQLTypes['DateTime'] | undefined
    isActual?: boolean | undefined
    note?: string | undefined
  }
  ['Category']: {
    __typename: 'Category'
    id?: number | undefined
    externalId?: string | undefined
    name?: string | undefined
    text?: string | undefined
    parent?: GraphQLTypes['Category'] | undefined
    children?: Array<GraphQLTypes['Category'] | undefined> | undefined
    hasChildren?: boolean | undefined
    position?: number | undefined
    company?: GraphQLTypes['Company'] | undefined
    treePath?: Array<number | undefined> | undefined
    /** Tagy */
    tags?: Array<GraphQLTypes['Tag'] | undefined> | undefined
  }
  ['Quantity']: {
    __typename: 'Quantity'
    depot?: GraphQLTypes['Depot'] | undefined
    quantityAvailable?: number | undefined
    quantityReservation?: number | undefined
    quantityStock?: number | undefined
    quantityPurchase?: number | undefined
  }
  ['ProductDepotMetric']: {
    __typename: 'ProductDepotMetric'
    id?: number | undefined
    depot?: GraphQLTypes['Depot'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    quantityMinimum?: number | undefined
    quantityOptimum?: number | undefined
    quantityMaximum?: number | undefined
    quantityStock?: number | undefined
    quantityPurchase?: number | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    updated?: GraphQLTypes['DateTime'] | undefined
  }
  ['ProductPack']: {
    __typename: 'ProductPack'
    id?: number | undefined
    /** Unikátní Ean 13 */
    ean?: string | undefined
    /** Kód */
    code?: string | undefined
    /** Poznámka */
    note?: string | undefined
    /** Množství */
    quantity?: number | undefined
    /** Produkt.id */
    product?: GraphQLTypes['Product'] | undefined
    /** Externí ID */
    externalId?: string | undefined
  }
  ['ProductParameter']: {
    __typename: 'ProductParameter'
    id?: number | undefined
    value?: GraphQLTypes['StringOrArray'] | undefined
    externalId?: string | undefined
    product?: GraphQLTypes['Product'] | undefined
    parameter?: GraphQLTypes['Parameter'] | undefined
  }
  /** String or Array */
  ['StringOrArray']: 'scalar' & { name: 'StringOrArray' }
  ['Parameter']: {
    __typename: 'Parameter'
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    enumValues?: Array<string | undefined> | undefined
    unit?: string | undefined
    text?: string | undefined
    externalId?: string | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['ProductPriceLevel']: {
    __typename: 'ProductPriceLevel'
    id?: number | undefined
    sellPrice?: number | undefined
    externalId?: string | undefined
    product?: GraphQLTypes['Product'] | undefined
    priceLevel?: GraphQLTypes['PriceLevel'] | undefined
  }
  ['SellItem']: {
    __typename: 'SellItem'
    id?: string | undefined
    product?: GraphQLTypes['Product'] | undefined
    quantityStock?: number | undefined
    quantityReservation?: number | undefined
    quantityAvailable?: number | undefined
    expirationDate?: GraphQLTypes['DateTime'] | undefined
    batch?: string | undefined
    productDepots?: Array<GraphQLTypes['ProductDepot'] | undefined> | undefined
  }
  ['CompanyModule']: {
    __typename: 'CompanyModule'
    id?: number | undefined
    module?: GraphQLTypes['Module'] | undefined
  }
  ['Module']: {
    __typename: 'Module'
    id?: string | undefined
    name?: string | undefined
  }
  ['userMyProfile']: {
    __typename: 'userMyProfile'
    data?: GraphQLTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['userFilters']: {
    pin?: string | undefined
    email?: string | undefined
    fulltext?: string | undefined
    roles?: Array<string | undefined> | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    enabled?: boolean | undefined
  }
  ['users']: {
    __typename: 'users'
    items?: Array<GraphQLTypes['User'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['Paginator']: {
    __typename: 'Paginator'
    current?: number | undefined
    currentItemCount?: number | undefined
    endPage?: number | undefined
    first?: number | undefined
    firstItemNumber?: number | undefined
    firstPageInRange?: number | undefined
    last?: number | undefined
    lastItemNumber?: number | undefined
    lastPageInRange?: number | undefined
    next?: number | undefined
    numItemsPerPage?: number | undefined
    pageCount?: number | undefined
    pageRange?: number | undefined
    startPage?: number | undefined
    totalCount?: number | undefined
    pagesInRange?: Array<number | undefined> | undefined
  }
  ['isUserEmailUnique']: {
    __typename: 'isUserEmailUnique'
    data?: boolean | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['userGroup']: {
    __typename: 'userGroup'
    data?: GraphQLTypes['UserGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['userGroupFilters']: {
    name?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['userGroups']: {
    __typename: 'userGroups'
    items?: Array<GraphQLTypes['UserGroup'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['Role']: {
    __typename: 'Role'
    name?: string | undefined
    role?: string | undefined
  }
  ['product']: {
    __typename: 'product'
    data?: GraphQLTypes['Product'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productFilters']: {
    id?: number | undefined
    companies?: Array<number | undefined> | undefined
    name?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    codes?: Array<string | undefined> | undefined
    externalId?: string | undefined
    fulltext?: string | undefined
    depots?: Array<number | undefined> | undefined
    metricDepots?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
    defaultSuppliers?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    listType?: string | undefined
    availability?: string | undefined
    enabled?: boolean | undefined
    isBundle?: boolean | undefined
    expirationDateTo?: GraphQLTypes['DateTimeAsString'] | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    hasBeforeSellPrice?: boolean | undefined
    hasHsCode?: boolean | undefined
    createdFrom?: GraphQLTypes['DateTimeAsString'] | undefined
    updatedFrom?: GraphQLTypes['DateTimeAsString'] | undefined
    tags?: Array<number | undefined> | undefined
    categories?: Array<number | undefined> | undefined
    metrics?: string | undefined
    enabledBatchExpiration?: boolean | undefined
    requiredSerialNumbers?: boolean | undefined
    company?: number | undefined
  }
  ['products']: {
    __typename: 'products'
    items?: Array<GraphQLTypes['Product'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productPurchasePrice']: {
    __typename: 'productPurchasePrice'
    items?: Array<GraphQLTypes['ProductPurchasePrice'] | undefined> | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['ProductPurchasePrice']: {
    __typename: 'ProductPurchasePrice'
    productDepot?: GraphQLTypes['Depot'] | undefined
    quantity?: number | undefined
    purchasePrice?: number | undefined
  }
  ['productSellItems']: {
    __typename: 'productSellItems'
    items?: Array<GraphQLTypes['SellItem'] | undefined> | undefined
  }
  ['productMovePackFilters']: {
    fulltext?: string | undefined
    type?: string | undefined
    depot?: number | undefined
    depotFrom?: number | undefined
    depotTo?: number | undefined
    number?: number | undefined
    checkouts?: Array<number | undefined> | undefined
    withoutCheckout?: boolean | undefined
    product?: number | undefined
    productFulltext?: string | undefined
    order?: number | undefined
    orderFulltext?: string | undefined
    withOrder?: boolean | undefined
    createdBy?: number | undefined
    created?: GraphQLTypes['created'] | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    externalReference?: string | undefined
    purchaseOrder?: number | undefined
  }
  ['created']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['productMovePacks']: {
    __typename: 'productMovePacks'
    items?: Array<GraphQLTypes['ProductMovePack'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productMoveFilters']: {
    product?: number | undefined
    type?: string | undefined
    depotFrom?: number | undefined
    depotTo?: number | undefined
    createdBy?: number | undefined
    created?: GraphQLTypes['created'] | undefined
    expirationDate?: GraphQLTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
  }
  ['productMoves']: {
    __typename: 'productMoves'
    items?: Array<GraphQLTypes['ProductMove'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productMovePack']: {
    __typename: 'productMovePack'
    data?: GraphQLTypes['ProductMovePack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['institution']: {
    __typename: 'institution'
    data?: GraphQLTypes['Institution'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['institutionFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['institutions']: {
    __typename: 'institutions'
    items?: Array<GraphQLTypes['Institution'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['order']: {
    __typename: 'order'
    data?: GraphQLTypes['Order'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['orderFilters']: {
    fulltext?: string | undefined
    reservationNumber?: number | undefined
    billNumber?: number | undefined
    notes?: string | undefined
    checkouts?: Array<number | undefined> | undefined
    depots?: Array<number | undefined> | undefined
    onlyDepots?: Array<number | undefined> | undefined
    payments?: Array<number | undefined> | undefined
    status?: string | undefined
    processStatus?: string | undefined
    processStatusChange?: GraphQLTypes['DateTimeAsString'] | undefined
    processStatusUpdated?: GraphQLTypes['processStatusUpdated'] | undefined
    isPaid?: boolean | undefined
    carrier?: string | undefined
    carriers?: Array<string | undefined> | undefined
    deleted?: boolean | undefined
    dateCreated?: GraphQLTypes['dateCreated'] | undefined
    dateBill?: GraphQLTypes['dateBill'] | undefined
    dateExpedition?: GraphQLTypes['dateExpedition'] | undefined
    updated?: GraphQLTypes['updated'] | undefined
    companies?: Array<number | undefined> | undefined
    box?: string | undefined
    item?: string | undefined
    package?: string | undefined
    customer?: number | undefined
    customerFulltext?: string | undefined
    shippingCountries?: Array<string | undefined> | undefined
    externalId?: string | undefined
    user?: number | undefined
    tags?: Array<number | undefined> | undefined
    hasAvailableProducts?: boolean | undefined
    group?: number | undefined
    groups?: Array<number | undefined> | undefined
    createdBy?: Array<number | undefined> | undefined
    locked?: boolean | undefined
    dateLocked?: GraphQLTypes['DateTimeAsString'] | undefined
    lockedBy?: Array<number | undefined> | undefined
  }
  ['processStatusUpdated']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['dateCreated']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['dateBill']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['dateExpedition']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['updated']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['orders']: {
    __typename: 'orders'
    items?: Array<GraphQLTypes['Order'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['soldItemFilters']: {
    productFulltext?: string | undefined
    customerFulltext?: string | undefined
    checkouts?: Array<number | undefined> | undefined
    depots?: Array<number | undefined> | undefined
    payments?: Array<number | undefined> | undefined
    status?: string | undefined
    processStatus?: string | undefined
    processStatusUpdated?: GraphQLTypes['processStatusUpdated'] | undefined
    carriers?: Array<string | undefined> | undefined
    dateCreated?: GraphQLTypes['dateCreated'] | undefined
    companies?: Array<number | undefined> | undefined
    customer?: number | undefined
    product?: number | undefined
    shippingCountries?: Array<string | undefined> | undefined
    orderTags?: Array<number | undefined> | undefined
  }
  ['soldItems']: {
    __typename: 'soldItems'
    items?: Array<GraphQLTypes['SoldItem'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['SoldItem']: {
    __typename: 'SoldItem'
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    priceAll?: number | undefined
    priceAllWithoutVat?: number | undefined
    product?: GraphQLTypes['Product'] | undefined
    profit?: number | undefined
  }
  ['orderItemFilters']: {
    status?: string | undefined
    processStatus?: string | undefined
    order?: number | undefined
    productDepot?: number | undefined
    carrier?: string | undefined
  }
  ['orderItems']: {
    __typename: 'orderItems'
    items?: Array<GraphQLTypes['OrderItem'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['orderItem']: {
    __typename: 'orderItem'
    data?: GraphQLTypes['OrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['customer']: {
    __typename: 'customer'
    data?: GraphQLTypes['Customer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['customerFilters']: {
    externalId?: string | undefined
    email?: string | undefined
    fulltext?: string | undefined
    companies?: Array<number | undefined> | undefined
    user?: number | undefined
    checkouts?: Array<number | undefined> | undefined
    priceLevel?: number | undefined
    tags?: Array<number | undefined> | undefined
  }
  ['customers']: {
    __typename: 'customers'
    items?: Array<GraphQLTypes['Customer'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['address']: {
    __typename: 'address'
    data?: GraphQLTypes['Address'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['addressesFilters']: {
    customer?: number | undefined
    externalId?: string | undefined
    isStored?: boolean | undefined
    isBilling?: boolean | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
    email?: string | undefined
  }
  ['addresses']: {
    __typename: 'addresses'
    items?: Array<GraphQLTypes['Address'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['checkout']: {
    __typename: 'checkout'
    data?: GraphQLTypes['Checkout'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['checkoutFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    user?: number | undefined
  }
  ['checkouts']: {
    __typename: 'checkouts'
    items?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['eventTypes']: {
    __typename: 'eventTypes'
    items?: Array<string | undefined> | undefined
  }
  ['consent']: {
    __typename: 'consent'
    data?: GraphQLTypes['Consent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['consentsFilters']: {
    name?: string | undefined
  }
  ['consents']: {
    __typename: 'consents'
    items?: Array<GraphQLTypes['Consent'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['checkoutExtraOperation']: {
    __typename: 'checkoutExtraOperation'
    data?: GraphQLTypes['CheckoutExtraOperation'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['checkoutExtraOperationFilters']: {
    checkout?: number | undefined
    checkoutClosing?: number | undefined
    checkouts?: Array<number | undefined> | undefined
  }
  ['checkoutExtraOperations']: {
    __typename: 'checkoutExtraOperations'
    items?: Array<GraphQLTypes['CheckoutExtraOperation'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['checkoutClosing']: {
    __typename: 'checkoutClosing'
    data?: GraphQLTypes['CheckoutClosing'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['checkoutClosingFilters']: {
    checkouts?: Array<number | undefined> | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['checkoutClosings']: {
    __typename: 'checkoutClosings'
    items?: Array<GraphQLTypes['CheckoutClosing'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['vat']: {
    __typename: 'vat'
    data?: GraphQLTypes['Vat'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['vatFilters']: {
    name?: string | undefined
    percent?: number | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['vats']: {
    __typename: 'vats'
    items?: Array<GraphQLTypes['Vat'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['depot']: {
    __typename: 'depot'
    data?: GraphQLTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['depotFilters']: {
    companies?: Array<number | undefined> | undefined
    name?: string | undefined
    checkouts?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['depots']: {
    __typename: 'depots'
    items?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['supplier']: {
    __typename: 'supplier'
    data?: GraphQLTypes['Supplier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['supplierFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['suppliers']: {
    __typename: 'suppliers'
    items?: Array<GraphQLTypes['Supplier'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['producer']: {
    __typename: 'producer'
    data?: GraphQLTypes['Producer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['producerFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['producers']: {
    __typename: 'producers'
    items?: Array<GraphQLTypes['Producer'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['exportStockFilters']: {
    depots?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
  }
  ['exportStocks']: {
    __typename: 'exportStocks'
    items?: Array<GraphQLTypes['Stock'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['Stock']: {
    __typename: 'Stock'
    id?: number | undefined
    date?: GraphQLTypes['DateTime'] | undefined
    generated?: GraphQLTypes['DateTime'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    suppliers?: Array<GraphQLTypes['Supplier'] | undefined> | undefined
    producers?: Array<GraphQLTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportSaleFilters']: {
    checkouts?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
  }
  ['exportSales']: {
    __typename: 'exportSales'
    items?: Array<GraphQLTypes['Sale'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['Sale']: {
    __typename: 'Sale'
    id?: number | undefined
    dateFrom?: GraphQLTypes['DateTime'] | undefined
    dateTo?: GraphQLTypes['DateTime'] | undefined
    generated?: GraphQLTypes['DateTime'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    suppliers?: Array<GraphQLTypes['Supplier'] | undefined> | undefined
    producers?: Array<GraphQLTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportPurchaseFilters']: {
    depots?: Array<number | undefined> | undefined
    checkouts?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
  }
  ['exportPurchases']: {
    __typename: 'exportPurchases'
    items?: Array<GraphQLTypes['Purchase'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['Purchase']: {
    __typename: 'Purchase'
    id?: number | undefined
    dateFrom?: GraphQLTypes['DateTime'] | undefined
    dateTo?: GraphQLTypes['DateTime'] | undefined
    generated?: GraphQLTypes['DateTime'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    suppliers?: Array<GraphQLTypes['Supplier'] | undefined> | undefined
    producers?: Array<GraphQLTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportOrderFilters']: {
    status?: string | undefined
    checkouts?: Array<number | undefined> | undefined
  }
  ['exportOrders']: {
    __typename: 'exportOrders'
    items?: Array<GraphQLTypes['exportOrder'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['exportOrder']: {
    __typename: 'exportOrder'
    id?: number | undefined
    dateFrom?: GraphQLTypes['DateTime'] | undefined
    dateTo?: GraphQLTypes['DateTime'] | undefined
    generated?: GraphQLTypes['DateTime'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    status?: GraphQLTypes['OrderStatus'] | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    url?: string | undefined
    format?: string | undefined
  }
  ['exportInventory']: {
    __typename: 'exportInventory'
    data?: GraphQLTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['Inventory']: {
    __typename: 'Inventory'
    id?: number | undefined
    date?: GraphQLTypes['DateTime'] | undefined
    generated?: GraphQLTypes['DateTime'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    depots?: Array<GraphQLTypes['Depot'] | undefined> | undefined
    suppliers?: Array<GraphQLTypes['Supplier'] | undefined> | undefined
    producers?: Array<GraphQLTypes['Producer'] | undefined> | undefined
    url?: string | undefined
    files?: Array<GraphQLTypes['File'] | undefined> | undefined
    approved?: GraphQLTypes['DateTime'] | undefined
    rejected?: GraphQLTypes['DateTime'] | undefined
    finished?: GraphQLTypes['DateTime'] | undefined
    pmpIn?: GraphQLTypes['ProductMovePack'] | undefined
    pmpOut?: GraphQLTypes['ProductMovePack'] | undefined
  }
  ['exportInventoryFilters']: {
    checkouts?: Array<number | undefined> | undefined
    producers?: Array<number | undefined> | undefined
    suppliers?: Array<number | undefined> | undefined
    approved?: boolean | undefined
    rejected?: boolean | undefined
    finished?: boolean | undefined
  }
  ['exportInventories']: {
    __typename: 'exportInventories'
    items?: Array<GraphQLTypes['Inventory'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['export']: {
    __typename: 'export'
    data?: GraphQLTypes['Export'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['Export']: {
    __typename: 'Export'
    id?: number | undefined
    file?: GraphQLTypes['File'] | undefined
    generated?: GraphQLTypes['DateTime'] | undefined
    created?: GraphQLTypes['DateTime'] | undefined
    url?: string | undefined
    type?: GraphQLTypes['ExportType'] | undefined
    filter?: string | undefined
    createdBy?: GraphQLTypes['User'] | undefined
    note?: string | undefined
  }
  ['ExportType']: {
    __typename: 'ExportType'
    id?: string | undefined
    name?: string | undefined
    format?: string | undefined
    group?: string | undefined
  }
  ['exportFilters']: {
    types?: Array<string | undefined> | undefined
    group?: string | undefined
  }
  ['exports']: {
    __typename: 'exports'
    items?: Array<GraphQLTypes['Export'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['exportTypeFilters']: {
    group?: string | undefined
  }
  ['exportTypes']: {
    __typename: 'exportTypes'
    items?: Array<GraphQLTypes['ExportType'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['shop']: {
    __typename: 'shop'
    data?: GraphQLTypes['Shop'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['Shop']: {
    __typename: 'Shop'
    id?: number | undefined
    name?: string | undefined
    street?: string | undefined
    city?: string | undefined
    country?: string | undefined
    zip?: string | undefined
    eetId?: number | undefined
    cert?: GraphQLTypes['Cert'] | undefined
    checkouts?: Array<GraphQLTypes['Checkout'] | undefined> | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['Cert']: {
    __typename: 'Cert'
    id?: number | undefined
    name?: string | undefined
    publicKey?: string | undefined
    expirationDate?: GraphQLTypes['DateTime'] | undefined
    company?: GraphQLTypes['Company'] | undefined
  }
  ['shopFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['shops']: {
    __typename: 'shops'
    items?: Array<GraphQLTypes['Shop'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['cert']: {
    __typename: 'cert'
    data?: GraphQLTypes['Cert'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['filters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['certs']: {
    __typename: 'certs'
    items?: Array<GraphQLTypes['Cert'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['eetReceiptFilters']: {
    checkouts?: Array<number | undefined> | undefined
    number?: number | undefined
    hasFik?: boolean | undefined
    playground?: boolean | undefined
    verificationMode?: boolean | undefined
  }
  ['eetReceipts']: {
    __typename: 'eetReceipts'
    items?: Array<GraphQLTypes['Receipt'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['payment']: {
    __typename: 'payment'
    data?: GraphQLTypes['Payment'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['paymentFilters']: {
    company?: number | undefined
    name?: string | undefined
    type?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['payments']: {
    __typename: 'payments'
    items?: Array<GraphQLTypes['Payment'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['paymentItem']: {
    __typename: 'paymentItem'
    data?: GraphQLTypes['PaymentItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['paymentItemsFilters']: {
    order?: number | undefined
    type?: string | undefined
    payment?: number | undefined
    externalId?: string | undefined
  }
  ['paymentItems']: {
    __typename: 'paymentItems'
    items?: Array<GraphQLTypes['PaymentItem'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['paymentTypeFilters']: {
    const?: string | undefined
  }
  ['paymentTypes']: {
    __typename: 'paymentTypes'
    items?: Array<GraphQLTypes['PaymentType'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['processStatusesFilters']: {
    name?: string | undefined
  }
  ['processStatuses']: {
    __typename: 'processStatuses'
    items?: Array<GraphQLTypes['ProcessStatus'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['carriersFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['carriers']: {
    __typename: 'carriers'
    items?: Array<GraphQLTypes['Carrier'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['currenciesFilters']: {
    name?: string | undefined
  }
  ['currencies']: {
    __typename: 'currencies'
    items?: Array<GraphQLTypes['Currency'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['package']: {
    __typename: 'package'
    data?: GraphQLTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['packageFilters']: {
    code?: string | undefined
    carrier?: string | undefined
    order?: number | undefined
    disposal?: number | undefined
    hasDisposal?: boolean | undefined
    isSent?: boolean | undefined
    companies?: Array<number | undefined> | undefined
    sent?: string | undefined
  }
  ['packages']: {
    __typename: 'packages'
    items?: Array<GraphQLTypes['Package'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['packageStatsFilters']: {
    carrier?: string | undefined
    companies?: Array<number | undefined> | undefined
    fromDate?: GraphQLTypes['DateTimeAsString'] | undefined
    toDate?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['packageStats']: {
    __typename: 'packageStats'
    items?: Array<GraphQLTypes['PackageStats'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['PackageStats']: {
    __typename: 'PackageStats'
    company?: GraphQLTypes['Company'] | undefined
    processStatus?: GraphQLTypes['ProcessStatus'] | undefined
    packageAmount?: number | undefined
    orderAmount?: number | undefined
    country?: string | undefined
  }
  ['disposal']: {
    __typename: 'disposal'
    data?: GraphQLTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['disposalFilters']: {
    carrier?: string | undefined
  }
  ['disposals']: {
    __typename: 'disposals'
    items?: Array<GraphQLTypes['PackageDisposal'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productDepotMetric']: {
    __typename: 'productDepotMetric'
    data?: GraphQLTypes['ProductDepotMetric'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productDepotMetricFilters']: {
    product?: number | undefined
    depot?: number | undefined
  }
  ['productDepotMetrics']: {
    __typename: 'productDepotMetrics'
    items?: Array<GraphQLTypes['ProductDepotMetric'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['file']: {
    __typename: 'file'
    data?: GraphQLTypes['File'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['fileFilters']: {
    product?: number | undefined
    order?: number | undefined
  }
  ['files']: {
    __typename: 'files'
    items?: Array<GraphQLTypes['File'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['nextEan']: {
    __typename: 'nextEan'
    data?: string | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['statsCarrierUsage']: {
    __typename: 'statsCarrierUsage'
    items?: Array<GraphQLTypes['CarrierUsage'] | undefined> | undefined
  }
  ['CarrierUsage']: {
    __typename: 'CarrierUsage'
    amount?: number | undefined
    carrier?: GraphQLTypes['Carrier'] | undefined
  }
  ['statsPaymentSales']: {
    __typename: 'statsPaymentSales'
    items?: Array<GraphQLTypes['PaymentSales'] | undefined> | undefined
  }
  ['PaymentSales']: {
    __typename: 'PaymentSales'
    amount?: number | undefined
    sales?: Array<GraphQLTypes['Price'] | undefined> | undefined
    payment?: GraphQLTypes['Payment'] | undefined
  }
  ['Price']: {
    __typename: 'Price'
    value?: number | undefined
    currency?: GraphQLTypes['Currency'] | undefined
  }
  ['statsBestsellersSort']: statsBestsellersSort
  ['statsBestsellers']: {
    __typename: 'statsBestsellers'
    items?: Array<GraphQLTypes['Bestsellers'] | undefined> | undefined
  }
  ['Bestsellers']: {
    __typename: 'Bestsellers'
    amount?: number | undefined
    sales?: Array<GraphQLTypes['Price'] | undefined> | undefined
    product?: GraphQLTypes['Product'] | undefined
  }
  ['companyCarrier']: {
    __typename: 'companyCarrier'
    data?: GraphQLTypes['CompanyCarrier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['companyCarrierFilters']: {
    carrier?: string | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
  }
  ['companyCarriers']: {
    __typename: 'companyCarriers'
    items?: Array<GraphQLTypes['CompanyCarrier'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['category']: {
    __typename: 'category'
    data?: GraphQLTypes['Category'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['categoryFilters']: {
    name?: string | undefined
    parent?: number | undefined
    hasParent?: boolean | undefined
    companies?: Array<number | undefined> | undefined
    externalId?: string | undefined
    tags?: Array<number | undefined> | undefined
  }
  ['categories']: {
    __typename: 'categories'
    items?: Array<GraphQLTypes['Category'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['parameter']: {
    __typename: 'parameter'
    data?: GraphQLTypes['Parameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['parametersFilters']: {
    name?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['parameters']: {
    __typename: 'parameters'
    items?: Array<GraphQLTypes['Parameter'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['orderGroup']: {
    __typename: 'orderGroup'
    data?: GraphQLTypes['OrderGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['orderGroupFilters']: {
    companies?: Array<number | undefined> | undefined
    hasUser?: boolean | undefined
    user?: number | undefined
    name?: string | undefined
    processStatus?: string | undefined
  }
  ['orderGroups']: {
    __typename: 'orderGroups'
    items?: Array<GraphQLTypes['OrderGroup'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['voucher']: {
    __typename: 'voucher'
    data?: GraphQLTypes['Voucher'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['voucherFilters']: {
    companies?: Array<number | undefined> | undefined
    name?: string | undefined
    code?: string | undefined
    externalId?: string | undefined
    discountType?: string | undefined
    enabled?: boolean | undefined
    isPayment?: boolean | undefined
    isValid?: boolean | undefined
    isUsed?: boolean | undefined
    validDate?: GraphQLTypes['voucherValidFilter'] | undefined
    created?: GraphQLTypes['voucherCreatedFilter'] | undefined
  }
  ['voucherValidFilter']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['voucherCreatedFilter']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['vouchers']: {
    __typename: 'vouchers'
    items?: Array<GraphQLTypes['Voucher'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productPriceFilters']: {
    product?: number | undefined
  }
  ['productPrices']: {
    __typename: 'productPrices'
    items?: Array<GraphQLTypes['ProductPrice'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['priceLevel']: {
    __typename: 'priceLevel'
    data?: GraphQLTypes['PriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['priceLevelFilters']: {
    name?: string | undefined
    externalId?: string | undefined
    isPercentage?: boolean | undefined
    withVat?: boolean | undefined
    currency?: string | undefined
  }
  ['priceLevels']: {
    __typename: 'priceLevels'
    items?: Array<GraphQLTypes['PriceLevel'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productParameter']: {
    __typename: 'productParameter'
    data?: GraphQLTypes['ProductParameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productParameterFilters']: {
    product?: number | undefined
    parameter?: number | undefined
    value?: string | undefined
    externalId?: string | undefined
  }
  ['productParameters']: {
    __typename: 'productParameters'
    items?: Array<GraphQLTypes['ProductParameter'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productPriceLevel']: {
    __typename: 'productPriceLevel'
    data?: GraphQLTypes['ProductPriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productPriceLevelFilters']: {
    product?: number | undefined
    priceLevel?: number | undefined
    externalId?: string | undefined
  }
  ['productPriceLevels']: {
    __typename: 'productPriceLevels'
    items?: Array<GraphQLTypes['ProductPriceLevel'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['tag']: {
    __typename: 'tag'
    data?: GraphQLTypes['Tag'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['tagFilters']: {
    name?: string | undefined
    type?: string | undefined
    externalId?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['tags']: {
    __typename: 'tags'
    items?: Array<GraphQLTypes['Tag'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['productPack']: {
    __typename: 'productPack'
    data?: GraphQLTypes['ProductPack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['productPackFilters']: {
    product?: number | undefined
    quantity?: number | undefined
    ean?: string | undefined
    code?: string | undefined
    externalId?: string | undefined
  }
  ['productPacks']: {
    __typename: 'productPacks'
    items?: Array<GraphQLTypes['ProductPack'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['purchaseOrder']: {
    __typename: 'purchaseOrder'
    data?: GraphQLTypes['PurchaseOrder'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['purchaseOrderFilters']: {
    fulltext?: string | undefined
    status?: string | undefined
    supplier?: number | undefined
    depot?: number | undefined
    number?: number | undefined
    externalId?: string | undefined
    externalReference?: string | undefined
    supplierReference?: string | undefined
    createdBy?: number | undefined
    tags?: Array<number | undefined> | undefined
    updated?: GraphQLTypes['updated'] | undefined
    created?: GraphQLTypes['created'] | undefined
    dateEstimatedArrival?: GraphQLTypes['dateEstimatedArrival'] | undefined
    company?: number | undefined
  }
  ['dateEstimatedArrival']: {
    left_date?: GraphQLTypes['DateTimeAsString'] | undefined
    right_date?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['purchaseOrders']: {
    __typename: 'purchaseOrders'
    items?: Array<GraphQLTypes['PurchaseOrder'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['purchaseOrderItem']: {
    __typename: 'purchaseOrderItem'
    data?: GraphQLTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['modulesFilters']: {
    name?: string | undefined
    companies?: Array<number | undefined> | undefined
  }
  ['modules']: {
    __typename: 'modules'
    items?: Array<GraphQLTypes['Module'] | undefined> | undefined
    paginator?: GraphQLTypes['Paginator'] | undefined
  }
  ['RootSchemaMutation']: {
    __typename: 'RootSchemaMutation'
    createProduct?: GraphQLTypes['createProduct'] | undefined
    updateProduct?: GraphQLTypes['updateProduct'] | undefined
    deleteProduct?: GraphQLTypes['deleteProduct'] | undefined
    refreshProductDepots?: GraphQLTypes['refreshProductDepots'] | undefined
    createProductMovePack?: GraphQLTypes['createProductMovePack'] | undefined
    updateProductMovePack?: GraphQLTypes['updateProductMovePack'] | undefined
    deleteProductMovePack?: GraphQLTypes['deleteProductMovePack'] | undefined
    updateProductMove?: GraphQLTypes['updateProductMove'] | undefined
    deleteProductMove?: GraphQLTypes['deleteProductMove'] | undefined
    createProductPrice?: GraphQLTypes['createProductPrice'] | undefined
    updateProductPrice?: GraphQLTypes['updateProductPrice'] | undefined
    deleteProductPrice?: GraphQLTypes['deleteProductPrice'] | undefined
    createProductBundle?: GraphQLTypes['createProductBundle'] | undefined
    updateProductBundle?: GraphQLTypes['updateProductBundle'] | undefined
    deleteProductBundle?: GraphQLTypes['deleteProductBundle'] | undefined
    createProductDepotMetric?: GraphQLTypes['createProductDepotMetric'] | undefined
    updateProductDepotMetric?: GraphQLTypes['updateProductDepotMetric'] | undefined
    deleteProductDepotMetric?: GraphQLTypes['deleteProductDepotMetric'] | undefined
    createInstitution?: GraphQLTypes['createInstitution'] | undefined
    updateInstitution?: GraphQLTypes['updateInstitution'] | undefined
    deleteInstitution?: GraphQLTypes['deleteInstitution'] | undefined
    createOrder?: GraphQLTypes['createOrder'] | undefined
    updateOrder?: GraphQLTypes['updateOrder'] | undefined
    deleteReservation?: GraphQLTypes['deleteReservation'] | undefined
    createOrderItem?: GraphQLTypes['createOrderItem'] | undefined
    updateOrderItem?: GraphQLTypes['updateOrderItem'] | undefined
    deleteOrderItem?: GraphQLTypes['deleteOrderItem'] | undefined
    updateClearanceItem?: GraphQLTypes['updateClearanceItem'] | undefined
    createUser?: GraphQLTypes['createUser'] | undefined
    updateUser?: GraphQLTypes['updateUser'] | undefined
    deleteUser?: GraphQLTypes['deleteUser'] | undefined
    createUserGroup?: GraphQLTypes['createUserGroup'] | undefined
    updateUserGroup?: GraphQLTypes['updateUserGroup'] | undefined
    deleteUserGroup?: GraphQLTypes['deleteUserGroup'] | undefined
    createCustomer?: GraphQLTypes['createCustomer'] | undefined
    updateCustomer?: GraphQLTypes['updateCustomer'] | undefined
    deleteCustomer?: GraphQLTypes['deleteCustomer'] | undefined
    createAddress?: GraphQLTypes['createAddress'] | undefined
    updateAddress?: GraphQLTypes['updateAddress'] | undefined
    deleteAddress?: GraphQLTypes['deleteAddress'] | undefined
    createCheckout?: GraphQLTypes['createCheckout'] | undefined
    updateCheckout?: GraphQLTypes['updateCheckout'] | undefined
    deleteCheckout?: GraphQLTypes['deleteCheckout'] | undefined
    createCheckoutExtraOperation?: GraphQLTypes['createCheckoutExtraOperation'] | undefined
    updateCheckoutExtraOperation?: GraphQLTypes['updateCheckoutExtraOperation'] | undefined
    deleteCheckoutExtraOperation?: GraphQLTypes['deleteCheckoutExtraOperation'] | undefined
    createCheckoutClosing?: GraphQLTypes['createCheckoutClosing'] | undefined
    updateCheckoutClosing?: GraphQLTypes['updateCheckoutClosing'] | undefined
    deleteCheckoutClosing?: GraphQLTypes['deleteCheckoutClosing'] | undefined
    createVat?: GraphQLTypes['createVat'] | undefined
    updateVat?: GraphQLTypes['updateVat'] | undefined
    deleteVat?: GraphQLTypes['deleteVat'] | undefined
    createDepot?: GraphQLTypes['createDepot'] | undefined
    updateDepot?: GraphQLTypes['updateDepot'] | undefined
    deleteDepot?: GraphQLTypes['deleteDepot'] | undefined
    createSupplier?: GraphQLTypes['createSupplier'] | undefined
    updateSupplier?: GraphQLTypes['updateSupplier'] | undefined
    deleteSupplier?: GraphQLTypes['deleteSupplier'] | undefined
    createProducer?: GraphQLTypes['createProducer'] | undefined
    updateProducer?: GraphQLTypes['updateProducer'] | undefined
    deleteProducer?: GraphQLTypes['deleteProducer'] | undefined
    createExportStock?: GraphQLTypes['createExportStock'] | undefined
    createExportSale?: GraphQLTypes['createExportSale'] | undefined
    createExportPurchase?: GraphQLTypes['createExportPurchase'] | undefined
    createExportOrder?: GraphQLTypes['createExportOrder'] | undefined
    createExportInventory?: GraphQLTypes['createExportInventory'] | undefined
    updateExportInventory?: GraphQLTypes['updateExportInventory'] | undefined
    refreshExportInventory?: GraphQLTypes['refreshExportInventory'] | undefined
    createExport?: GraphQLTypes['createExport'] | undefined
    updateCompany?: GraphQLTypes['updateCompany'] | undefined
    createShop?: GraphQLTypes['createShop'] | undefined
    updateShop?: GraphQLTypes['updateShop'] | undefined
    deleteShop?: GraphQLTypes['deleteShop'] | undefined
    createCert?: GraphQLTypes['createCert'] | undefined
    updateCert?: GraphQLTypes['updateCert'] | undefined
    deleteCert?: GraphQLTypes['deleteCert'] | undefined
    createEetReceipt?: GraphQLTypes['createEetReceipt'] | undefined
    sendEetReceipt?: GraphQLTypes['sendEetReceipt'] | undefined
    createPayment?: GraphQLTypes['createPayment'] | undefined
    updatePayment?: GraphQLTypes['updatePayment'] | undefined
    deletePayment?: GraphQLTypes['deletePayment'] | undefined
    createPaymentItem?: GraphQLTypes['createPaymentItem'] | undefined
    updatePaymentItem?: GraphQLTypes['updatePaymentItem'] | undefined
    deletePaymentItem?: GraphQLTypes['deletePaymentItem'] | undefined
    createDevLog?: GraphQLTypes['createDevLog'] | undefined
    createPackage?: GraphQLTypes['createPackage'] | undefined
    updatePackage?: GraphQLTypes['updatePackage'] | undefined
    deletePackage?: GraphQLTypes['deletePackage'] | undefined
    resetPackage?: GraphQLTypes['resetPackage'] | undefined
    sendPackage?: GraphQLTypes['sendPackage'] | undefined
    createDisposal?: GraphQLTypes['createDisposal'] | undefined
    updateDisposal?: GraphQLTypes['updateDisposal'] | undefined
    deleteDisposal?: GraphQLTypes['deleteDisposal'] | undefined
    sendDisposal?: GraphQLTypes['sendDisposal'] | undefined
    createFile?: GraphQLTypes['createFile'] | undefined
    updateFile?: GraphQLTypes['updateFile'] | undefined
    deleteFile?: GraphQLTypes['deleteFile'] | undefined
    createCompanyCarrier?: GraphQLTypes['createCompanyCarrier'] | undefined
    updateCompanyCarrier?: GraphQLTypes['updateCompanyCarrier'] | undefined
    deleteCompanyCarrier?: GraphQLTypes['deleteCompanyCarrier'] | undefined
    createConsent?: GraphQLTypes['createConsent'] | undefined
    updateConsent?: GraphQLTypes['updateConsent'] | undefined
    deleteConsent?: GraphQLTypes['deleteConsent'] | undefined
    createCustomerConsent?: GraphQLTypes['createCustomerConsent'] | undefined
    deleteCustomerConsent?: GraphQLTypes['deleteCustomerConsent'] | undefined
    updateProductDepot?: GraphQLTypes['updateProductDepot'] | undefined
    createCategory?: GraphQLTypes['createCategory'] | undefined
    updateCategory?: GraphQLTypes['updateCategory'] | undefined
    deleteCategory?: GraphQLTypes['deleteCategory'] | undefined
    createParameter?: GraphQLTypes['createParameter'] | undefined
    updateParameter?: GraphQLTypes['updateParameter'] | undefined
    deleteParameter?: GraphQLTypes['deleteParameter'] | undefined
    createOrderGroup?: GraphQLTypes['createOrderGroup'] | undefined
    updateOrderGroup?: GraphQLTypes['updateOrderGroup'] | undefined
    deleteOrderGroup?: GraphQLTypes['deleteOrderGroup'] | undefined
    createVoucher?: GraphQLTypes['createVoucher'] | undefined
    updateVoucher?: GraphQLTypes['updateVoucher'] | undefined
    deleteVoucher?: GraphQLTypes['deleteVoucher'] | undefined
    createPriceLevel?: GraphQLTypes['createPricePriceLevel'] | undefined
    updatePriceLevel?: GraphQLTypes['updatePriceLevel'] | undefined
    deletePriceLevel?: GraphQLTypes['deletePriceLevel'] | undefined
    createProductParameter?: GraphQLTypes['createProductParameter'] | undefined
    updateProductParameter?: GraphQLTypes['updateProductParameter'] | undefined
    deleteProductParameter?: GraphQLTypes['deleteProductParameter'] | undefined
    createProductPriceLevel?: GraphQLTypes['createProductPriceLevel'] | undefined
    updateProductPriceLevel?: GraphQLTypes['updateProductPriceLevel'] | undefined
    deleteProductPriceLevel?: GraphQLTypes['deleteProductPriceLevel'] | undefined
    createTag?: GraphQLTypes['createTag'] | undefined
    updateTag?: GraphQLTypes['updateTag'] | undefined
    deleteTag?: GraphQLTypes['deleteTag'] | undefined
    createProductPack?: GraphQLTypes['createProductPack'] | undefined
    updateProductPack?: GraphQLTypes['updateProductPack'] | undefined
    deleteProductPack?: GraphQLTypes['deleteProductPack'] | undefined
    createPurchaseOrder?: GraphQLTypes['createPurchaseOrder'] | undefined
    updatePurchaseOrder?: GraphQLTypes['updatePurchaseOrder'] | undefined
    deletePurchaseOrder?: GraphQLTypes['deletePurchaseOrder'] | undefined
    createPurchaseOrderItem?: GraphQLTypes['createPurchaseOrderItem'] | undefined
    updatePurchaseOrderItem?: GraphQLTypes['updatePurchaseOrderItem'] | undefined
    deletePurchaseOrderItem?: GraphQLTypes['deletePurchaseOrderItem'] | undefined
  }
  ['createProduct']: {
    __typename: 'createProduct'
    data?: GraphQLTypes['Product'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProduct']: {
    __typename: 'updateProduct'
    data?: GraphQLTypes['Product'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProduct']: {
    __typename: 'deleteProduct'
    errors?: Array<string | undefined> | undefined
  }
  ['refreshProductDepots']: {
    __typename: 'refreshProductDepots'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductMove']: {
    product?: number | undefined
    depotFrom?: number | undefined
    depotTo?: number | undefined
    productDepot?: number | undefined
    supplier?: number | undefined
    amount: number
    purchasePrice?: number | undefined
    purchaseCurrency?: string | undefined
    purchaseCurrencyDate?: GraphQLTypes['DateTimeAsString'] | undefined
    purchaseOrderItem?: number | undefined
    expirationDate?: GraphQLTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    note?: string | undefined
    orderItem?: number | undefined
    serialNumbers?: Array<string | undefined> | undefined
  }
  ['createProductMovePack']: {
    __typename: 'createProductMovePack'
    data?: GraphQLTypes['ProductMovePack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductMovePack']: {
    __typename: 'updateProductMovePack'
    data?: GraphQLTypes['ProductMovePack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductMovePack']: {
    __typename: 'deleteProductMovePack'
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductMove']: {
    __typename: 'updateProductMove'
    data?: GraphQLTypes['ProductMove'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductMove']: {
    __typename: 'deleteProductMove'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductPrice']: {
    __typename: 'createProductPrice'
    data?: GraphQLTypes['ProductPrice'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductPrice']: {
    __typename: 'updateProductPrice'
    data?: GraphQLTypes['ProductPrice'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductPrice']: {
    __typename: 'deleteProductPrice'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductBundle']: {
    __typename: 'createProductBundle'
    data?: GraphQLTypes['ProductBundle'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductBundle']: {
    __typename: 'updateProductBundle'
    data?: GraphQLTypes['ProductBundle'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductBundle']: {
    __typename: 'deleteProductBundle'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductDepotMetric']: {
    __typename: 'createProductDepotMetric'
    data?: GraphQLTypes['ProductDepotMetric'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductDepotMetric']: {
    __typename: 'updateProductDepotMetric'
    data?: GraphQLTypes['ProductDepotMetric'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductDepotMetric']: {
    __typename: 'deleteProductDepotMetric'
    errors?: Array<string | undefined> | undefined
  }
  ['createInstitution']: {
    __typename: 'createInstitution'
    data?: GraphQLTypes['Institution'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateInstitution']: {
    __typename: 'updateInstitution'
    data?: GraphQLTypes['Institution'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteInstitution']: {
    __typename: 'deleteInstitution'
    errors?: Array<string | undefined> | undefined
  }
  ['createOrderItem_']: {
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    price?: number | undefined
    sale?: number | undefined
    vat?: number | undefined
    product?: number | undefined
    serial?: string | undefined
    expirationDate?: GraphQLTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    note?: string | undefined
    isForSubsequentSettlement?: boolean | undefined
    picked?: boolean | undefined
    packed?: boolean | undefined
    voucher?: number | undefined
    externalId?: string | undefined
  }
  ['createPaymentItem_']: {
    payment: number
    checkout?: number | undefined
    voucher?: number | undefined
    amount: number
    dateCreated?: GraphQLTypes['DateTimeAsString'] | undefined
    dateCancelled?: GraphQLTypes['DateTimeAsString'] | undefined
    externalId?: string | undefined
    isPaid?: boolean | undefined
    datePaid?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['processStatusRelation']: {
    status?: string | undefined
    note?: string | undefined
  }
  ['createOrder']: {
    __typename: 'createOrder'
    data?: GraphQLTypes['Order'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateOrderItem_']: {
    id?: number | undefined
    name?: string | undefined
    type?: string | undefined
    ean?: string | undefined
    code?: string | undefined
    quantity?: number | undefined
    price?: string | undefined
    sale?: string | undefined
    vat?: number | undefined
    product?: number | undefined
    serial?: string | undefined
    expirationDate?: GraphQLTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    note?: string | undefined
    isForSubsequentSettlement?: boolean | undefined
    picked?: boolean | undefined
    packed?: boolean | undefined
    voucher?: number | undefined
    externalId?: string | undefined
  }
  ['updatePaymentItem_']: {
    payment?: number | undefined
    checkout?: number | undefined
    voucher?: number | undefined
    amount?: string | undefined
    dateCreated?: GraphQLTypes['DateTimeAsString'] | undefined
    dateCancelled?: GraphQLTypes['DateTimeAsString'] | undefined
    externalId?: string | undefined
    isPaid?: boolean | undefined
    datePaid?: GraphQLTypes['DateTimeAsString'] | undefined
  }
  ['updateOrder']: {
    __typename: 'updateOrder'
    data?: GraphQLTypes['Order'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteReservation']: {
    __typename: 'deleteReservation'
    errors?: Array<string | undefined> | undefined
  }
  ['createOrderItem']: {
    __typename: 'createOrderItem'
    data?: GraphQLTypes['OrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateOrderItem']: {
    __typename: 'updateOrderItem'
    data?: GraphQLTypes['OrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteOrderItem']: {
    __typename: 'deleteOrderItem'
    errors?: Array<string | undefined> | undefined
  }
  ['updateClearanceItemLocations']: {
    amount: number
    depot?: number | undefined
    productDepot?: number | undefined
    position1?: string | undefined
    position2?: string | undefined
    position3?: string | undefined
    expirationDate?: GraphQLTypes['DateTimeAsString'] | undefined
    batch?: string | undefined
    locked?: boolean | undefined
  }
  ['updateClearanceItem']: {
    __typename: 'updateClearanceItem'
    data?: GraphQLTypes['ClearanceItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createUser']: {
    __typename: 'createUser'
    data?: GraphQLTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateUser']: {
    __typename: 'updateUser'
    data?: GraphQLTypes['User'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteUser']: {
    __typename: 'deleteUser'
    errors?: Array<string | undefined> | undefined
  }
  ['createUserGroup']: {
    __typename: 'createUserGroup'
    data?: GraphQLTypes['UserGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateUserGroup']: {
    __typename: 'updateUserGroup'
    data?: GraphQLTypes['UserGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteUserGroup']: {
    __typename: 'deleteUserGroup'
    errors?: Array<string | undefined> | undefined
  }
  ['createCustomerAddress']: {
    companyName?: string | undefined
    firstName?: string | undefined
    lastName?: string | undefined
    street?: string | undefined
    city?: string | undefined
    country?: string | undefined
    zip?: string | undefined
    ic?: string | undefined
    dic?: string | undefined
    phone?: string | undefined
  }
  ['createCustomer']: {
    __typename: 'createCustomer'
    data?: GraphQLTypes['Customer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCustomer']: {
    __typename: 'updateCustomer'
    data?: GraphQLTypes['Customer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCustomer']: {
    __typename: 'deleteCustomer'
    errors?: Array<string | undefined> | undefined
  }
  ['createAddress']: {
    __typename: 'createAddress'
    data?: GraphQLTypes['Address'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateAddress']: {
    __typename: 'updateAddress'
    data?: GraphQLTypes['Address'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteAddress']: {
    __typename: 'deleteAddress'
    errors?: Array<string | undefined> | undefined
  }
  ['createCheckout']: {
    __typename: 'createCheckout'
    data?: GraphQLTypes['Checkout'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCheckout']: {
    __typename: 'updateCheckout'
    data?: GraphQLTypes['Checkout'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCheckout']: {
    __typename: 'deleteCheckout'
    errors?: Array<string | undefined> | undefined
  }
  ['createCheckoutExtraOperation']: {
    __typename: 'createCheckoutExtraOperation'
    data?: GraphQLTypes['CheckoutExtraOperation'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCheckoutExtraOperation']: {
    __typename: 'updateCheckoutExtraOperation'
    data?: GraphQLTypes['CheckoutExtraOperation'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCheckoutExtraOperation']: {
    __typename: 'deleteCheckoutExtraOperation'
    errors?: Array<string | undefined> | undefined
  }
  ['createCheckoutClosing']: {
    __typename: 'createCheckoutClosing'
    data?: GraphQLTypes['CheckoutClosing'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCheckoutClosing']: {
    __typename: 'updateCheckoutClosing'
    data?: GraphQLTypes['CheckoutClosing'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCheckoutClosing']: {
    __typename: 'deleteCheckoutClosing'
    errors?: Array<string | undefined> | undefined
  }
  ['createVat']: {
    __typename: 'createVat'
    data?: GraphQLTypes['Vat'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateVat']: {
    __typename: 'updateVat'
    data?: GraphQLTypes['Vat'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteVat']: {
    __typename: 'deleteVat'
    errors?: Array<string | undefined> | undefined
  }
  ['createDepot']: {
    __typename: 'createDepot'
    data?: GraphQLTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateDepot']: {
    __typename: 'updateDepot'
    data?: GraphQLTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteDepot']: {
    __typename: 'deleteDepot'
    errors?: Array<string | undefined> | undefined
  }
  ['createSupplier']: {
    __typename: 'createSupplier'
    data?: GraphQLTypes['Supplier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateSupplier']: {
    __typename: 'updateSupplier'
    data?: GraphQLTypes['Supplier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteSupplier']: {
    __typename: 'deleteSupplier'
    errors?: Array<string | undefined> | undefined
  }
  ['createProducer']: {
    __typename: 'createProducer'
    data?: GraphQLTypes['Producer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProducer']: {
    __typename: 'updateProducer'
    data?: GraphQLTypes['Producer'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProducer']: {
    __typename: 'deleteProducer'
    errors?: Array<string | undefined> | undefined
  }
  ['createExportStock']: {
    __typename: 'createExportStock'
    data?: GraphQLTypes['Stock'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportSale']: {
    __typename: 'createExportSale'
    data?: GraphQLTypes['Sale'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportPurchase']: {
    __typename: 'createExportPurchase'
    data?: GraphQLTypes['Purchase'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportOrder']: {
    __typename: 'createExportOrder'
    data?: GraphQLTypes['exportOrder'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExportInventory']: {
    __typename: 'createExportInventory'
    data?: GraphQLTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateExportInventory']: {
    __typename: 'updateExportInventory'
    data?: GraphQLTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['refreshExportInventory']: {
    __typename: 'refreshExportInventory'
    data?: GraphQLTypes['Inventory'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createExport']: {
    __typename: 'createExport'
    data?: GraphQLTypes['Export'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCompany']: {
    __typename: 'updateCompany'
    data?: GraphQLTypes['Company'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createShop']: {
    __typename: 'createShop'
    data?: GraphQLTypes['Shop'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateShop']: {
    __typename: 'updateShop'
    data?: GraphQLTypes['Shop'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteShop']: {
    __typename: 'deleteShop'
    errors?: Array<string | undefined> | undefined
  }
  ['createCert']: {
    __typename: 'createCert'
    data?: GraphQLTypes['Cert'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCert']: {
    __typename: 'updateCert'
    data?: GraphQLTypes['Depot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCert']: {
    __typename: 'deleteCert'
    errors?: Array<string | undefined> | undefined
  }
  ['createEetReceipt']: {
    __typename: 'createEetReceipt'
    data?: GraphQLTypes['Receipt'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['sendEetReceipt']: {
    __typename: 'sendEetReceipt'
    data?: GraphQLTypes['Receipt'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createPayment']: {
    __typename: 'createPayment'
    data?: GraphQLTypes['Payment'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePayment']: {
    __typename: 'updatePayment'
    data?: GraphQLTypes['Payment'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePayment']: {
    __typename: 'deletePayment'
    errors?: Array<string | undefined> | undefined
  }
  ['createPaymentItem']: {
    __typename: 'createPaymentItem'
    data?: GraphQLTypes['PaymentItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePaymentItem']: {
    __typename: 'updatePaymentItem'
    data?: GraphQLTypes['PaymentItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePaymentItem']: {
    __typename: 'deletePaymentItem'
    errors?: Array<string | undefined> | undefined
  }
  ['createDevLog']: {
    __typename: 'createDevLog'
    data?: GraphQLTypes['DevLog'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['DevLog']: {
    __typename: 'DevLog'
    id?: number | undefined
    name?: string | undefined
    content?: string | undefined
    created?: GraphQLTypes['DateTime'] | undefined
  }
  ['createPackage']: {
    __typename: 'createPackage'
    data?: GraphQLTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePackage']: {
    __typename: 'updatePackage'
    data?: GraphQLTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePackage']: {
    __typename: 'deletePackage'
    errors?: Array<string | undefined> | undefined
  }
  ['resetPackage']: {
    __typename: 'resetPackage'
    errors?: Array<string | undefined> | undefined
  }
  ['sendPackage']: {
    __typename: 'sendPackage'
    data?: GraphQLTypes['Package'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createDisposal']: {
    __typename: 'createDisposal'
    data?: GraphQLTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateDisposal']: {
    __typename: 'updateDisposal'
    data?: GraphQLTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteDisposal']: {
    __typename: 'deleteDisposal'
    errors?: Array<string | undefined> | undefined
  }
  ['sendDisposal']: {
    __typename: 'sendDisposal'
    data?: GraphQLTypes['PackageDisposal'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createFile']: {
    __typename: 'createFile'
    data?: GraphQLTypes['File'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateFile']: {
    __typename: 'updateFile'
    data?: GraphQLTypes['File'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteFile']: {
    __typename: 'deleteFile'
    errors?: Array<string | undefined> | undefined
  }
  ['createCompanyCarrier']: {
    __typename: 'createCompanyCarrier'
    data?: GraphQLTypes['CompanyCarrier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCompanyCarrier']: {
    __typename: 'updateCompanyCarrier'
    data?: GraphQLTypes['CompanyCarrier'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCompanyCarrier']: {
    __typename: 'deleteCompanyCarrier'
    errors?: Array<string | undefined> | undefined
  }
  ['createConsent']: {
    __typename: 'createConsent'
    data?: GraphQLTypes['Consent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateConsent']: {
    __typename: 'updateConsent'
    data?: GraphQLTypes['Consent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteConsent']: {
    __typename: 'deleteConsent'
    errors?: Array<string | undefined> | undefined
  }
  ['createCustomerConsent']: {
    __typename: 'createCustomerConsent'
    data?: GraphQLTypes['CustomerConsent'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCustomerConsent']: {
    __typename: 'deleteCustomerConsent'
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductDepot']: {
    __typename: 'updateProductDepot'
    data?: GraphQLTypes['ProductDepot'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['createCategory']: {
    __typename: 'createCategory'
    data?: GraphQLTypes['Category'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateCategory']: {
    __typename: 'updateCategory'
    data?: GraphQLTypes['Category'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteCategory']: {
    __typename: 'deleteCategory'
    errors?: Array<string | undefined> | undefined
  }
  ['createParameter']: {
    __typename: 'createParameter'
    data?: GraphQLTypes['Parameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateParameter']: {
    __typename: 'updateParameter'
    data?: GraphQLTypes['Parameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteParameter']: {
    __typename: 'deleteParameter'
    errors?: Array<string | undefined> | undefined
  }
  ['createOrderGroup']: {
    __typename: 'createOrderGroup'
    data?: GraphQLTypes['OrderGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateOrderGroup']: {
    __typename: 'updateOrderGroup'
    data?: GraphQLTypes['OrderGroup'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteOrderGroup']: {
    __typename: 'deleteOrderGroup'
    errors?: Array<string | undefined> | undefined
  }
  ['createVoucher']: {
    __typename: 'createVoucher'
    data?: GraphQLTypes['Voucher'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateVoucher']: {
    __typename: 'updateVoucher'
    data?: GraphQLTypes['Voucher'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteVoucher']: {
    __typename: 'deleteVoucher'
    errors?: Array<string | undefined> | undefined
  }
  ['createPricePriceLevel']: {
    __typename: 'createPricePriceLevel'
    data?: GraphQLTypes['PriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePriceLevel']: {
    __typename: 'updatePriceLevel'
    data?: GraphQLTypes['PriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePriceLevel']: {
    __typename: 'deletePriceLevel'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductParameter']: {
    __typename: 'createProductParameter'
    data?: GraphQLTypes['ProductParameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductParameter']: {
    __typename: 'updateProductParameter'
    data?: GraphQLTypes['ProductParameter'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductParameter']: {
    __typename: 'deleteProductParameter'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductPriceLevel']: {
    __typename: 'createProductPriceLevel'
    data?: GraphQLTypes['ProductPriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductPriceLevel']: {
    __typename: 'updateProductPriceLevel'
    data?: GraphQLTypes['ProductPriceLevel'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductPriceLevel']: {
    __typename: 'deleteProductPriceLevel'
    errors?: Array<string | undefined> | undefined
  }
  ['createTag']: {
    __typename: 'createTag'
    data?: GraphQLTypes['Tag'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateTag']: {
    __typename: 'updateTag'
    data?: GraphQLTypes['Tag'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteTag']: {
    __typename: 'deleteTag'
    errors?: Array<string | undefined> | undefined
  }
  ['createProductPack']: {
    __typename: 'createProductPack'
    data?: GraphQLTypes['ProductPack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updateProductPack']: {
    __typename: 'updateProductPack'
    data?: GraphQLTypes['ProductPack'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deleteProductPack']: {
    __typename: 'deleteProductPack'
    errors?: Array<string | undefined> | undefined
  }
  ['createPurchaseOrderItem_']: {
    product: number
    currency: string
    quantity: number
    purchasePrice?: number | undefined
    note?: string | undefined
    externalId?: string | undefined
  }
  ['createPurchaseOrder']: {
    __typename: 'createPurchaseOrder'
    data?: GraphQLTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePurchaseOrder']: {
    __typename: 'updatePurchaseOrder'
    data?: GraphQLTypes['PurchaseOrder'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePurchaseOrder']: {
    __typename: 'deletePurchaseOrder'
    errors?: Array<string | undefined> | undefined
  }
  ['createPurchaseOrderItem']: {
    __typename: 'createPurchaseOrderItem'
    data?: GraphQLTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['updatePurchaseOrderItem']: {
    __typename: 'updatePurchaseOrderItem'
    data?: GraphQLTypes['PurchaseOrderItem'] | undefined
    errors?: Array<string | undefined> | undefined
  }
  ['deletePurchaseOrderItem']: {
    __typename: 'deletePurchaseOrderItem'
    errors?: Array<string | undefined> | undefined
  }
}
export enum statsBestsellersSort {
  amount = 'amount',
  sales = 'sales',
}

type ZEUS_VARIABLES = {
  ['DateTimeAsString']: ValueTypes['DateTimeAsString']
  ['DateTime']: ValueTypes['DateTime']
  ['StringOrArray']: ValueTypes['StringOrArray']
  ['userFilters']: ValueTypes['userFilters']
  ['userGroupFilters']: ValueTypes['userGroupFilters']
  ['productFilters']: ValueTypes['productFilters']
  ['productMovePackFilters']: ValueTypes['productMovePackFilters']
  ['created']: ValueTypes['created']
  ['productMoveFilters']: ValueTypes['productMoveFilters']
  ['institutionFilters']: ValueTypes['institutionFilters']
  ['orderFilters']: ValueTypes['orderFilters']
  ['processStatusUpdated']: ValueTypes['processStatusUpdated']
  ['dateCreated']: ValueTypes['dateCreated']
  ['dateBill']: ValueTypes['dateBill']
  ['dateExpedition']: ValueTypes['dateExpedition']
  ['updated']: ValueTypes['updated']
  ['soldItemFilters']: ValueTypes['soldItemFilters']
  ['orderItemFilters']: ValueTypes['orderItemFilters']
  ['customerFilters']: ValueTypes['customerFilters']
  ['addressesFilters']: ValueTypes['addressesFilters']
  ['checkoutFilters']: ValueTypes['checkoutFilters']
  ['consentsFilters']: ValueTypes['consentsFilters']
  ['checkoutExtraOperationFilters']: ValueTypes['checkoutExtraOperationFilters']
  ['checkoutClosingFilters']: ValueTypes['checkoutClosingFilters']
  ['vatFilters']: ValueTypes['vatFilters']
  ['depotFilters']: ValueTypes['depotFilters']
  ['supplierFilters']: ValueTypes['supplierFilters']
  ['producerFilters']: ValueTypes['producerFilters']
  ['exportStockFilters']: ValueTypes['exportStockFilters']
  ['exportSaleFilters']: ValueTypes['exportSaleFilters']
  ['exportPurchaseFilters']: ValueTypes['exportPurchaseFilters']
  ['exportOrderFilters']: ValueTypes['exportOrderFilters']
  ['exportInventoryFilters']: ValueTypes['exportInventoryFilters']
  ['exportFilters']: ValueTypes['exportFilters']
  ['exportTypeFilters']: ValueTypes['exportTypeFilters']
  ['shopFilters']: ValueTypes['shopFilters']
  ['filters']: ValueTypes['filters']
  ['eetReceiptFilters']: ValueTypes['eetReceiptFilters']
  ['paymentFilters']: ValueTypes['paymentFilters']
  ['paymentItemsFilters']: ValueTypes['paymentItemsFilters']
  ['paymentTypeFilters']: ValueTypes['paymentTypeFilters']
  ['processStatusesFilters']: ValueTypes['processStatusesFilters']
  ['carriersFilters']: ValueTypes['carriersFilters']
  ['currenciesFilters']: ValueTypes['currenciesFilters']
  ['packageFilters']: ValueTypes['packageFilters']
  ['packageStatsFilters']: ValueTypes['packageStatsFilters']
  ['disposalFilters']: ValueTypes['disposalFilters']
  ['productDepotMetricFilters']: ValueTypes['productDepotMetricFilters']
  ['fileFilters']: ValueTypes['fileFilters']
  ['statsBestsellersSort']: ValueTypes['statsBestsellersSort']
  ['companyCarrierFilters']: ValueTypes['companyCarrierFilters']
  ['categoryFilters']: ValueTypes['categoryFilters']
  ['parametersFilters']: ValueTypes['parametersFilters']
  ['orderGroupFilters']: ValueTypes['orderGroupFilters']
  ['voucherFilters']: ValueTypes['voucherFilters']
  ['voucherValidFilter']: ValueTypes['voucherValidFilter']
  ['voucherCreatedFilter']: ValueTypes['voucherCreatedFilter']
  ['productPriceFilters']: ValueTypes['productPriceFilters']
  ['priceLevelFilters']: ValueTypes['priceLevelFilters']
  ['productParameterFilters']: ValueTypes['productParameterFilters']
  ['productPriceLevelFilters']: ValueTypes['productPriceLevelFilters']
  ['tagFilters']: ValueTypes['tagFilters']
  ['productPackFilters']: ValueTypes['productPackFilters']
  ['purchaseOrderFilters']: ValueTypes['purchaseOrderFilters']
  ['dateEstimatedArrival']: ValueTypes['dateEstimatedArrival']
  ['modulesFilters']: ValueTypes['modulesFilters']
  ['createProductMove']: ValueTypes['createProductMove']
  ['createOrderItem_']: ValueTypes['createOrderItem_']
  ['createPaymentItem_']: ValueTypes['createPaymentItem_']
  ['processStatusRelation']: ValueTypes['processStatusRelation']
  ['updateOrderItem_']: ValueTypes['updateOrderItem_']
  ['updatePaymentItem_']: ValueTypes['updatePaymentItem_']
  ['updateClearanceItemLocations']: ValueTypes['updateClearanceItemLocations']
  ['createCustomerAddress']: ValueTypes['createCustomerAddress']
  ['createPurchaseOrderItem_']: ValueTypes['createPurchaseOrderItem_']
}
