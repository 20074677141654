import React, { Fragment, useState } from 'react'
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { OrderItem } from 'depoto-core'
import { Fn } from 'depoto-core'
import { Btn } from './Btn'
import { FormInput } from './Form/FormInput'
import { FormTextarea } from './Form/FormTextarea'
import { useCore } from '../hooks'
import Modal from './Modal'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGQL } from '../lib/GQLContext'
import { ControlledTextarea } from './Form/Controlled/ControlledTextarea'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-duotone-svg-icons'

type Props = {
  orderItem: OrderItem
}
export const EditOrderItemModal: React.FC<Props> = ({ orderItem }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [note, setNote] = useState(orderItem.note)
  const gql = useGQL()

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      quantity: orderItem.quantity,
      note: orderItem.note,
    },
  })

  const queryClient = useQueryClient()

  const {
    mutateAsync: orderUpdateMutate,
    isLoading,
    error,
  } = useMutation({
    mutationKey: ['orderItemUpdate', orderItem.id],
    mutationFn: ({ note, quantity }: { note: string; quantity: number }) =>
      gql('mutation')({
        updateOrderItem: [
          {
            id: orderItem.id,
            note,
            quantity,
          },
          {
            data: {
              id: true,
              note: true,
              quantity: true,
            },
            errors: true,
          },
        ],
      }),
    onSuccess: data => {
      if (data?.updateOrderItem?.data?.id) {
        queryClient.setQueryData(['order', orderItem.order.id], (old: any) => {
          if (old) {
            return {
              ...old,
              items: old.items.map((item: OrderItem) =>
                item.id === orderItem.id
                  ? {
                      ...item,
                      note: data?.updateOrderItem?.data?.note,
                      quantity: data?.updateOrderItem?.data?.quantity,
                    }
                  : item,
              ),
            }
          }
        })
        queryClient.invalidateQueries({ queryKey: ['order', orderItem.order.id] })
        closeModal()
        reset({
          quantity: data?.updateOrderItem?.data?.quantity,
          note: data?.updateOrderItem?.data?.note,
        })
      }
    },
  })

  const { mutateAsync: deleteMutate, isLoading: deleteIsLoading } = useMutation({
    mutationKey: ['orderItemDelete', orderItem.id],
    mutationFn: () =>
      gql('mutation')({
        deleteOrderItem: [
          {
            id: orderItem.id,
          },
          {
            errors: true,
          },
        ],
      }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['order', orderItem.order.id] })
      closeModal()
    },
  })

  const handleSave = handleSubmit(data => {
    toast
      .promise(orderUpdateMutate(data), {
        pending: 'Ukládám objednávku...',
        success: 'Objednávka uložena',
        error: 'Chyba při ukládání objednávky',
      })
      .catch(e => console.error(e))
  })

  const handleDelete = () => {
    toast
      .promise(deleteMutate(), {
        pending: 'Smazání položky...',
        success: 'Položka smazána',
        error: 'Chyba při smazání položky',
      })
      .catch(e => console.error(e))
  }

  const closeModal = () => setIsOpen(false)

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <>
      <div
        className="bg-primary-blue rounded-xl px-3 py-2 text-white flex items-center gap-2 text-md cursor-pointer select-none"
        onClick={openModal}
      >
        <FontAwesomeIcon icon={faPen} size="lg" className="text-white" />
        Upravit
      </div>

      <Modal isOpen={isOpen} onClose={closeModal} title={`Upravit položku ${orderItem.name}`}>
        <h2 className="text-sm font-bold mb-1">Počet kusů</h2>
        <div className={'flex items-stretch gap-1 '}>
          <Controller
            control={control}
            name="quantity"
            render={({ field: { onChange, value } }) => (
              <>
                <Btn
                  className={'btn-square btn-danger flex-1'}
                  children={'-10'}
                  title={'Odebrat 10'}
                  onClick={() => onChange(value > 9 ? value - 10 : 0)}
                />
                <Btn
                  className={'btn-square btn-danger flex-1'}
                  children={'-1'}
                  title={'Odebrat 1'}
                  onClick={() => onChange(value > 0 ? value - 1 : 0)}
                />
                <FormInput
                  inputclassName={'text-right'}
                  className={'w-28 flex-1'}
                  type={'number'}
                  value={`${value}`}
                  onChange={q => onChange(Number(q) >= 0 ? Number(q) : 0)}
                />
                <Btn
                  className={'btn-square btn-success flex-1'}
                  children={'+1'}
                  title={'Přidat 1'}
                  onClick={() => onChange(value + 1)}
                />
                <Btn
                  className={'btn-square btn-success flex-1'}
                  children={'+10'}
                  title={'Přidat 10'}
                  onClick={() => onChange(value + 10)}
                />
              </>
            )}
          />
        </div>

        <ControlledTextarea
          control={control}
          name="note"
          label={'Poznámka k položce'}
          className={'mt-2'}
          value={note}
          onChange={n => setNote(n)}
        />

        {error && <div className="mt-2 text-red-500">{error}</div>}

        <div className="mt-4 flex justify-between gap-2">
          <div className={'flex gap-2'}>
            <Btn
              className={'btn-danger'}
              icon={'fa-trash-alt'}
              children={'Smazat položku'}
              title={'Smazat položku'}
              onClick={handleDelete}
              isDisabled={deleteIsLoading}
              isLoading={deleteIsLoading}
            />
          </div>
          <div className={'flex gap-2'}>
            <Btn
              onClick={closeModal}
              isDisabled={isLoading}
              className={'btn-outline border-light-grey'}
              icon={'fa-ban'}
              children={'Zrušit'}
              title={'Zrušit'}
            />
            <Btn
              onClick={handleSave}
              isDisabled={isLoading || !isDirty || deleteIsLoading}
              isLoading={isLoading}
              className={'btn-primary'}
              icon={'fa-check'}
              children={'Uložit'}
              title={'Uložit'}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
