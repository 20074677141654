import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownToArc,
  faArrowsLeftRight,
  faArrowsUpDown,
  faTruckFast,
  faWeightHanging,
} from '@fortawesome/pro-solid-svg-icons'
import { Btn } from './Btn'
import { printTicket, wait } from '../lib'
import { Fn } from 'depoto-core'
import { useForm, FormProvider } from 'react-hook-form'
import { useOrderDetailContext } from '../views/OrderDetails/OrderDetailContext'
import { FromPackage } from './OrderItemsInPackages'
import { ControlledCompactInput } from './Form/Controlled/ControlledCompactInput'
import { ControlledCompactSelect } from './Form/Controlled/ControlledCompactSelect'
import { useGQL } from '../lib/GQLContext'
import { toast } from 'react-toastify'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'

type Props = {
  pack: FromPackage
  index: number
  isMoving?: boolean
  onMovePressed?: () => void
  savePackages: () => void
  onPackageChange?: (packageId: number, data: Omit<FromPackage, 'packageId' | 'items' | 'code' | 'carrier'>) => void
}

type PackageFormData = Omit<FromPackage, 'packageId' | 'items' | 'code' | 'carrier'>

export const PackageDetail: React.FC<Props> = ({
  pack,
  index,
  isMoving,
  onMovePressed,
  savePackages,
  onPackageChange,
}) => {
  const [isListeningForScale, setIsListeningForScale] = useState<boolean>(false)

  const { order } = useOrderDetailContext()
  const gql = useGQL()
  const queryClient = useQueryClient()

  const serverPack = order?.packages?.find(p => p.id === pack.packageId)

  const form = useForm<PackageFormData>({
    defaultValues: {
      tariff: pack.tariff,
      weight: pack.weight,
      dimensionX: pack.dimensionX,
      dimensionY: pack.dimensionY,
      dimensionZ: pack.dimensionZ,
    },
  })

  const { control, handleSubmit, formState, watch, reset, setValue } = form

  const readWeight = () => {
    if (isListeningForScale) {
      setIsListeningForScale(false)
    } else {
      setIsListeningForScale(true)

      if (window.weightHistory?.length) {
        setValue('weight', parseFloat(window.weightHistory[window.weightHistory.length - 1]))
        setIsListeningForScale(false)
        if (window.weightHistory.length > 100) {
          window.weightHistory = []
        }
      }
    }
  }

  // Update form values when pack changes
  useEffect(() => {
    reset({
      tariff: pack.tariff,
      weight: pack.weight,
      dimensionX: pack.dimensionX,
      dimensionY: pack.dimensionY,
      dimensionZ: pack.dimensionZ,
    })
  }, [pack.tariff, pack.weight, pack.dimensionX, pack.dimensionY, pack.dimensionZ])

  // Watch for changes and notify parent
  useEffect(() => {
    const subscription = watch(value => {
      if (onPackageChange) {
        onPackageChange(pack.packageId, value as PackageFormData)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, pack.packageId, onPackageChange])

  const tariffs = (serverPack?.carrier?.tariffs || []).map(t => ({
    value: String(t.id),
    label: t.name,
  }))

  const handleSendPackage = async () => {
    if (order?.carrier?.requiredShippingAddress && !order?.shippingAddress) {
      alert('Není nastavena doručovací adresa')
      return
    }
    if (formState.isDirty) {
      alert('Mate neuložené změny u balíku')
      return
    }
    sendPackage()
  }

  const { mutate: sendPackage } = useMutation({
    mutationFn: () =>
      toast.promise(
        gql('mutation')({
          sendPackage: [
            {
              id: pack.packageId,
            },
            {
              errors: true,
            },
          ],
        }),
        {
          pending: 'Odesílám balík...',
          success: 'Balík odeslán!',
          error: 'Chyba při odesílání balíku',
        },
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['order', order.id] })
    },
  })

  const printPackageTicket = async () => {
    await printTicket(serverPack)
    await wait()
  }

  return (
    <FormProvider {...form}>
      <div className={'flex gap-4 justify-between flex-1 py-2 px-4'}>
        <div className={'flex items-center gap-4'}>
          <div className={'flex items-center gap-2'}>
            <FontAwesomeIcon icon={faTruckFast} className={'text-shadow-grey'} size={'lg'} />
            <ControlledCompactSelect
              control={control}
              name={'tariff'}
              options={tariffs}
              label={'Tarif'}
              className={'w-64'}
            />
          </div>
          <div className={'flex items-center gap-2'}>
            <FontAwesomeIcon icon={faWeightHanging} className={'text-shadow-grey'} size={'lg'} />
            <ControlledCompactInput
              label={'Hmotnost'}
              right={'kg'}
              control={control}
              name={'weight'}
              className={'w-32'}
              type={'number'}
              rules={{ required: true, pattern: /^\d+(\.\d+)?$/ }}
            />
          </div>
          {'_depoto' in window ? (
            <FontAwesomeIcon
              icon={['far', 'weight-scale']}
              size="lg"
              color={isListeningForScale ? '#a7130e' : '#000'}
              style={{ marginLeft: '-30px', marginRight: '5px' }}
              data-tippy-content={isListeningForScale ? 'Probíhá načítání hmotnosti' : 'Načíst hmotnost z váhy'}
              onClick={readWeight}
            />
          ) : null}
          <div className={'flex items-center gap-2'}>
            <FontAwesomeIcon icon={faArrowsLeftRight} className={'text-shadow-grey'} size={'lg'} />
            <ControlledCompactInput
              label={'Šířka'}
              right={'cm'}
              control={control}
              name={'dimensionX'}
              className={'w-32'}
              type={'number'}
              rules={{ required: true, pattern: /^\d+(\.\d+)?$/ }}
            />
          </div>
          <div className={'flex items-center gap-2'}>
            <FontAwesomeIcon icon={faArrowsUpDown} className={'text-shadow-grey'} size={'lg'} />
            <ControlledCompactInput
              label={'Výška'}
              right={'cm'}
              control={control}
              name={'dimensionY'}
              className={'w-32'}
              type={'number'}
              rules={{ required: true, pattern: /^\d+(\.\d+)?$/ }}
            />
          </div>
          <div className={'flex items-center gap-2'}>
            <FontAwesomeIcon icon={faArrowDownToArc} className={'text-shadow-grey'} size={'lg'} />
            <ControlledCompactInput
              label={'Hloubka'}
              right={'cm'}
              control={control}
              name={'dimensionZ'}
              className={'w-32'}
              type={'number'}
              rules={{ required: true, pattern: /^\d+(\.\d+)?$/ }}
            />
          </div>
        </div>
        <div className={'flex items-center gap-2 min-h-14'}>
          {isMoving && (
            <Btn
              className={'bg-orange text-white hover:bg-orange-light'}
              icon={'arrows-up-down-left-right'}
              children={'Přesunout sem'}
              title={'Přesunout sem'}
              onClick={onMovePressed}
            />
          )}
          {serverPack && !serverPack.sent && (
            <Btn
              className={'btn-primary'}
              // icon={'send'}
              children={'Poslat balik'}
              title={'Poslat balik'}
              onClick={handleSendPackage}
            />
          )}
          {serverPack && serverPack.sent && (
            <Btn
              className={'btn-primary'}
              icon={'print'}
              children={'Vytisknout štítek'}
              title={'Vytisknout štítek'}
              onClick={printPackageTicket}
            />
          )}
        </div>
      </div>
    </FormProvider>
  )
}
