import React from 'react'
import { OrderGroup as OrderGroupType } from 'depoto-core'
import { Link } from 'react-router-dom'

type Props = {
  group: OrderGroupType
}
export const Group: React.FC<Props> = ({ group }) => {
  return (
    <Link
      to={`/dashboard?groupId=${group.id}`}
      className="border rounded-lg p-3"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
    >
      <div>{group.id || ''}</div>
      <div className="font-bold overflow-hidden">{group.name || group.id}</div>
      <div className="font-bold overflow-hidden">
        {group.orders?.length}{' '}
        {group.orders?.length === 1 ? 'objednávka' : group.orders?.length < 5 ? 'objednávky' : 'objednávek'}
      </div>
    </Link>
  )
}
