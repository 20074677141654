import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Order as OrderComponent } from './Order'
import { Loader } from './Loader'
import { useCore } from '../hooks'
import { KeyEventType, printTicket, Schema, useSearchParams } from '../lib'
import { Order, Package } from 'depoto-core'
import { Btn } from './Btn'
import { PackageBarcodeUtil } from '../lib/PackageBarcode'
import { GroupRecord } from '../store/core/reducer'
import { usePaginatedOrders } from '../lib/hooks/usePaginatedOrders'
import { useIntersect } from '../lib/hooks/useIntersect'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons'
import { OrdersFilters } from './OrdersFilters'
import { useOrderFilters } from '../lib/hooks/useOrderFilters'
import { useGroups } from '../lib/hooks/useGroups'
import { toast } from 'react-toastify'

export const Orders = () => {
  const [paginator, setPaginator] = useState({ current: 0, endPage: 0, totalCount: 0 })
  const { core, isFetching, setIsFetching, itemCount, setItemCount, ordersHistory } = useCore()

  const history = useHistory()

  const { filters, groupId, processStatus, resetFilters, searchString } = useOrderFilters()

  const {
    batchGroupStatus,
    batchGroupErrors,
    groupRecord,
    resolveGroup,
    toggleGroupLogVisibility,
    toggleBatchGroupStatus,
  } = useGroups(groupId)

  const { refresh, orders, loading, loadMore, isLastPage, totalCount } = usePaginatedOrders({
    filters,
    sort: ['recieved', 'picking', 'packing'].includes(processStatus)
      ? 'priority'
      : ['dispatched', 'delivered', 'returned', 'packed'].includes(processStatus)
        ? 'dateExpedition'
        : 'dateCreated',
    direction: 'DESC',
    schema: Schema.order.list,
  })

  const interval = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    interval.current = setInterval(() => {
      refresh()
    }, 60 * 1000)
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [searchString])

  // Open order, when after filtering only one order is left
  useEffect(() => {
    if (!loading && orders.length === 1 && !!searchString && searchString.length > 0) {
      resetFilters()
      history.push(`/order/${orders[0].id}`)
    }
  }, [loading, orders, searchString])

  useEffect(() => {
    setItemCount(totalCount)
  }, [totalCount])

  const { measureRef, isIntersecting } = useIntersect()

  useEffect(() => {
    if (isIntersecting) {
      loadMore()
    }
  }, [isIntersecting])

  useEffect(() => {
    if ((!processStatus || processStatus == 'all') && !groupId && !searchString) {
      setTimeout(() => history.push('/dashboard/packing'), 10)
    } else {
      refresh()
      const processCode = (c: CustomEvent | Event | any) => {
        if (!location.href?.includes('/order/')) {
          history.push(`/search/${PackageBarcodeUtil.getPackageCodeFromBarcode(c.detail)}`)
        }
      }
      document.addEventListener(KeyEventType.BARCODE, processCode)
      return () => {
        document.removeEventListener(KeyEventType.BARCODE, processCode)
      }
    }
  }, [core, processStatus, searchString, groupId])

  return isFetching || (loading && orders.length <= 0) ? (
    <Loader size="fill-container" />
  ) : (
    <div className={'p-4 grid gap-4 orderColumns'}>
      {orders.length > 0 && orders.map(o => <OrderComponent order={o} key={o.id} />)}
      {!isLastPage && (
        <Btn
          ref={measureRef}
          id={'next-orders'}
          children={'Načíst další'}
          className={'btn-primary'}
          icon={faArrowDown}
          title={'Načíst další'}
          isLoading={loading}
          isDisabled={loading}
          onClick={loadMore}
        />
      )}
      {batchGroupStatus.isVisible && (
        <div className="fixed top-25" style={{ width: 'calc(100% - 90px)' }}>
          <div className="card bg-white">
            <div className="flex flex-row w-full">
              <p className="text-black">
                <strong>Progres:</strong> {(batchGroupStatus.progress / (batchGroupStatus.all / 100) || 0).toFixed(2)}%
                <br />
                <strong>Celkem:</strong> {batchGroupStatus.progress} / {batchGroupStatus.all}
                <br />
                <strong>Operace:</strong> {batchGroupStatus.currentOperation}
              </p>
              {batchGroupStatus.progress === batchGroupStatus.all && batchGroupStatus.all > 0 && (
                <div className="fixed top-16 mt-3 right-48">
                  {/*<div className="ml-auto mt-8">*/}
                  <button className="btn text-black bg-white" onClick={() => toggleBatchGroupStatus(false)}>
                    Zavřít přehled
                  </button>
                </div>
              )}
            </div>
            {batchGroupErrors.length > 0 && (
              <div>
                <p className="font-bold">Některé skočily chybou:</p>
                <ul>
                  {batchGroupErrors.map((e, i) => (
                    <li key={`error_${i}`} className="cursor-pointer">
                      <NavLink to={`/order/${e.orderId}`}>{e.error}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="w-full relative bottom-0">
            <div
              className="bg-primary h-1"
              style={{ width: `${batchGroupStatus.progress / (batchGroupStatus.all / 100)}%` }}
            />
          </div>
        </div>
      )}
      {groupId !== undefined && groupRecord?.log?.length > 0 && groupRecord?.isVisible && (
        <div className="fixed top-25" style={{ width: 'calc(100% - 90px)' }}>
          <div className="card bg-white h-auto max-h-72 scrollable-content">
            <div className="flex flex-row w-full">
              <p className="text-black">
                <strong>OrderGroup ID:</strong> {groupId}
              </p>
            </div>
            {groupRecord?.log?.length > 0 && (
              <div>
                <p className="font-bold">Log:</p>
                <ul>
                  {groupRecord?.log.map((l, i) => (
                    <li key={`glog_${i}`} className="cursor-pointer">
                      <NavLink to={`/order/${l.orderId}`}>{l.record}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {groupRecord?.errors?.length > 0 && (
              <div>
                <p className="font-bold text-warning">Některé akce skočily chybou:</p>
                <ul>
                  {groupRecord?.errors.map((l, i) => (
                    <li key={`gerror_${i}`} className="cursor-pointer">
                      <NavLink to={`/order/${l.orderId}`}>{l.record}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
      {groupId !== undefined && (
        <button
          onClick={() => (isFetching ? null : resolveGroup(orders))}
          disabled={isFetching}
          className="btn btn-primary fixed top-25 right-3 w-44">
          Odeslat skupinu
        </button>
      )}
      {groupId !== undefined && (
        <button
          onClick={() => (isFetching ? null : history.push(`/groups/${groupId}`))}
          disabled={isFetching}
          className="btn btn-primary fixed top-32 right-3 w-44">
          Zobrazit položky
        </button>
      )}
      {groupId !== undefined && batchGroupStatus.all > 0 && !batchGroupStatus.isVisible && (
        <button onClick={() => toggleBatchGroupStatus(true)} className="btn btn-primary fixed top-44 mt-1 right-3 w-44">
          Zobrazit přehled
        </button>
      )}
      {groupId !== undefined && groupRecord && groupRecord?.log?.length > 0 && (
        <button onClick={() => toggleGroupLogVisibility()} className="btn btn-primary fixed top-25 right-48 mr-1 w-44">
          {groupRecord.isVisible ? 'Skrýt log' : 'Zobrazit log'}
        </button>
      )}
    </div>
  )
}
