import React from 'react'
import { Fn } from 'depoto-core'

export type CheckboxProps = {
  checked?: boolean
  disabled?: boolean
  label?: string
  labelOnRight?: boolean
  onChange?: Fn
}

export const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled, label, labelOnRight, onChange }) => {
  return (
    <div className="form-control">
      <label className="cursor-pointer label">
        {!labelOnRight ? <span className="label-text mr-2">{label}</span> : ''}
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className="checkbox checkbox-primary"
        />
        {labelOnRight ? <span className="label-text ml-2">{label}</span> : ''}
      </label>
    </div>
  )
}
