import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import DtLogo from '../assets/img/App-Icon.svg?react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, NavLink, useHistory, useLocation, useParams } from 'react-router-dom'
import { ProcessStatusIcon } from './ProcessStatusIcon'
import { getHeaderTitle, Schema } from '../lib'
import { Header } from './Header'
import { useCore, useLogin } from '../hooks'
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query'
import { Order } from 'depoto-core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { usePaginatedOrders } from '../lib/hooks/usePaginatedOrders'

type Props = {
  children: Element[] | ReactElement | any
  hasMainHeader: boolean
  isSettingsView?: boolean
}

// Menu item configuration type
type MenuItem = {
  path: string
  status?: string
  title: string
  icon: IconProp | string
  isIconComponent?: boolean
  isSeparator?: boolean
  badge?: number
  onClick?: () => void
}

export const SideMenu: React.FC<Props> = ({ children, hasMainHeader, isSettingsView }) => {
  const history = useHistory()
  const { isLoggedIn, handleLogout } = useLogin()
  const location = useLocation()
  const { pathname } = location
  const { core, currentOrder, setSearchString } = useCore()
  const [splitLocation, setSplitLocation] = useState<string[]>(pathname.split('/'))
  const [headerTitle, setHeaderTitle] = useState<string>(getHeaderTitle({ splitLocation, currentOrder }))
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const serverType = core.services.oauth.session.clientType
  const desktopApp = window._depoto
  const routeParams = window.location.search

  const queryClient = useQueryClient()

  const currentOrderId = useMemo(() => {
    const path = pathname.split('/')
    if (path[1] === 'order') {
      return path[path.length - 1]
    }
    return null
  }, [pathname])

  const isOrderLoading = useIsFetching({ queryKey: ['order', parseInt(currentOrderId)] })

  const orderQuery = useMemo(
    () => queryClient.getQueryData(['order', parseInt(currentOrderId)]) as Order,
    [currentOrderId, isOrderLoading],
  )
  const currentOrderProcessStatus = orderQuery?.processStatus?.id

  useEffect(() => {
    const splitLoc = pathname.split('/')
    setSplitLocation(splitLoc)
    setHeaderTitle(getHeaderTitle({ splitLocation: splitLoc, currentOrder }))
  }, [pathname, currentOrder])

  // Handler for menu expansion/collapse
  const handleMenuToggle = () => {
    setIsMenuExpanded(!isMenuExpanded)
  }

  // Handle clicks outside the menu to collapse it when expanded
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const menuElement = document.getElementById('side-menu')
      if (isMenuExpanded && menuElement && !menuElement.contains(event.target as Node)) {
        setIsMenuExpanded(false)
      }
    }

    if (isMenuExpanded) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMenuExpanded])

  const { orders, isLastPage, loadMore, loading } = usePaginatedOrders({
    filters: {
      processStatus: 'picking_error',
    },
    direction: 'DESC',
    schema: Schema.order.list,
  })

  const query = queryClient.getQueriesData({ queryKey: ['orders', { processStatus: 'picking_error' }] })

  // @ts-ignore Yes, it is ugly. Yes, it is working perfectly. Yes, I am proud of it. No, it was not easeier to pass total count from usePaginatedOrders.
  // Why? Because you will see this ugly code here and consider refactoring in favour of replacing depricated usePaginatedOrders hook with tanstack-query's useInfiniteQuery.
  const pickingErrorCount = query?.[0]?.[1]?.pages?.[0]?.paginator?.totalCount || orders.length || undefined

  // We want to load all picking_error orders in order to show the badge
  useEffect(() => {
    if (!isLastPage && !loading) {
      loadMore()
    }
  }, [isLastPage, loading])

  // Create menu configuration
  const mainMenuItems: MenuItem[] = [
    {
      path: `/dashboard/received${routeParams}`,
      status: 'received',
      title: 'Přijaté objednávky',
      icon: 'received',
      isIconComponent: true,
      onClick: () => setSearchString(''),
    },
    {
      path: `/dashboard/picking${routeParams}`,
      status: 'picking',
      title: 'Vyskladnění',
      icon: 'picking',
      isIconComponent: true,
      onClick: () => setSearchString(''),
    },
    {
      path: `/dashboard/packing${routeParams}`,
      status: 'packing',
      title: 'Balení',
      icon: 'packing',
      isIconComponent: true,
      onClick: () => setSearchString(''),
    },
    {
      path: `/dashboard/packed${routeParams}`,
      status: 'packed',
      title: 'Zabaleno',
      icon: 'packed',
      isIconComponent: true,
      onClick: () => setSearchString(''),
    },
    {
      path: `/dashboard/dispatched${routeParams}`,
      status: 'dispatched',
      title: 'Předáno dopravci',
      icon: 'dispatched',
      isIconComponent: true,
      onClick: () => setSearchString(''),
    },
    {
      path: `/dashboard/picking_error${routeParams}`,
      status: 'picking_error',
      title: 'Chyba vykladnění',
      icon: 'picking_error',
      isIconComponent: true,
      onClick: () => setSearchString(''),
      badge: pickingErrorCount,
    },
    {
      isSeparator: true,
      path: '',
      title: '',
      icon: '',
    },
    {
      path: `/dashboard/history`,
      status: 'history',
      title: 'Historie',
      icon: ['far', 'clock-rotate-left'],
      onClick: () => setSearchString(''),
    },
    {
      path: `/groups/${routeParams}`,
      status: 'groups',
      title: 'Skupiny',
      icon: ['far', 'boxes'],
      onClick: () => setSearchString(''),
    },
    {
      path: `/dispatch/${routeParams}`,
      status: 'dispatch',
      title: 'Svoz',
      icon: ['far', 'truck-loading'],
      onClick: () => setSearchString(''),
    },
  ]

  const footerMenuItems: MenuItem[] = [
    {
      path: '/settings',
      title: 'Nastavení',
      icon: ['far', 'cog'],
    },
    {
      path: '',
      title: 'Odhlásit',
      icon: ['far', 'sign-out-alt'],
      onClick: () => handleLogout(() => history.push('/login')),
    },
  ]

  if (pathname === '/login') {
    return children
  }

  return (
    <div className="relative min-h-screen flex">
      {/* Background overlay when menu is expanded */}
      <div
        className={`fixed inset-0 bg-black z-20 transition-opacity duration-300 ${
          isMenuExpanded ? 'opacity-30 pointer-events-auto' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMenuExpanded(false)}
      />

      {/* Sidebar */}
      <div
        id="side-menu"
        className={`fixed h-full z-30 flex flex-col gap-5 py-5 bg-base-blue text-primary-blue shadow-xl transition-all duration-300 ease-in-out ${
          isMenuExpanded ? 'w-64' : 'w-20'
        }`}
        onMouseEnter={() => setIsMenuExpanded(true)}
        onMouseLeave={() => setIsMenuExpanded(false)}
        onTouchStart={() => setIsMenuExpanded(!isMenuExpanded)}>
        {/* Logo */}
        <Link to={'/dashboard'} className="w-full ml-6">
          <DtLogo width="35" />
        </Link>

        {/* Server type indicator */}
        <nav className="text-center" title={'Typ serveru'}>
          {serverType === 'prod' ? (
            <div></div>
          ) : serverType === 'stage' ? (
            <div className={'bg-danger text-white font-bold'}>[STAGE]</div>
          ) : serverType === 'dev' ? (
            <div className={'bg-danger text-white font-bold'}>[DEV]</div>
          ) : (
            ''
          )}
        </nav>

        {/* Main navigation */}
        <nav className="flex flex-col flex-1">
          <nav className="flex flex-col gap-3 items-center menuItems mb-auto">
            <ul className={'w-full flex flex-col gap-3'}>
              {mainMenuItems.map((item, index) => {
                const isActive = (currentOrderProcessStatus || headerTitle) === item.status
                return item.isSeparator ? (
                  <div
                    key={`separator-${index}`}
                    className="mt-5 border-b border-light-blue w-14 ml-[.75rem] opacity-30"></div>
                ) : (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    className={`${isActive ? 'menuItem-active' : ''} h-14 flex flex-col justify-center w-full`}>
                    <li className={'flex items-center'} onClick={item.onClick}>
                      <span
                        className={`flex items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors ease-out duration-150 w-full ${
                          isActive ? 'text-primary-blue' : ''
                        }`}>
                        <span className={`flex justify-center transition-colors ease-out min-w-20 relative`}>
                          {item.isIconComponent ? (
                            <ProcessStatusIcon status={item.icon as string} size="lg" />
                          ) : (
                            <FontAwesomeIcon icon={item.icon as IconProp} size={'lg'} />
                          )}
                          {item.badge !== undefined && (
                            <span className="absolute -top-5 right-1 bg-danger text-white text-sm font-semibold rounded-full w-6 h-6 flex items-center justify-center">
                              {item.badge > 99 ? '99+' : item.badge}
                            </span>
                          )}
                        </span>
                        {isMenuExpanded && (
                          <span className="font-medium whitespace-nowrap overflow-hidden text-gray-600 transition-colors ease-out">
                            {item.title}
                          </span>
                        )}
                      </span>
                    </li>
                  </NavLink>
                )
              })}
            </ul>
          </nav>

          {/* Footer navigation - settings, logout */}
          {isLoggedIn && (
            <nav className="flex flex-col gap-3 items-center menuItems">
              <ul className={'w-full flex flex-col gap-3'}>
                {footerMenuItems.map((item, index) => {
                  const isActive = splitLocation[1] === item.path?.split('/')[1]
                  return item.path ? (
                    <NavLink
                      key={`footer-${index}`}
                      to={item.path}
                      className={`${isActive ? 'menuItem-active' : ''} h-14 flex flex-col justify-center w-full`}>
                      <li className={'flex items-center'} onClick={item.onClick}>
                        <span
                          className={`flex items-center text-light-blue hover:text-primary-blue cursor-pointer transition-colors ease-out duration-150 w-full ${
                            isActive ? 'text-primary-blue' : ''
                          }`}>
                          <span className={`flex justify-center transition-colors ease-out min-w-20 relative`}>
                            <FontAwesomeIcon icon={item.icon as IconProp} size={'lg'} />
                            {item.badge !== undefined && (
                              <span className="absolute -top-1 -right-1 bg-danger text-white text-xs font-semibold rounded-full w-5 h-5 flex items-center justify-center">
                                {item.badge > 99 ? '99+' : item.badge}
                              </span>
                            )}
                          </span>
                          {isMenuExpanded && (
                            <span className="font-medium text-gray-600 whitespace-nowrap overflow-hidden transition-colors ease-out">
                              {item.title}
                            </span>
                          )}
                        </span>
                      </li>
                    </NavLink>
                  ) : (
                    <li key={`footer-${index}`} className={'flex items-center h-14 w-full'} onClick={item.onClick}>
                      <span
                        className={`flex items-center text-light-blue hover:text-danger cursor-pointer transition-colors ease-out duration-150 w-full`}>
                        <span className={`flex justify-center transition-colors ease-out min-w-20 relative`}>
                          <FontAwesomeIcon icon={item.icon as IconProp} size={'lg'} />
                        </span>
                        {isMenuExpanded && (
                          <span className="font-medium text-gray-600 whitespace-nowrap overflow-hidden transition-colors ease-out">
                            {item.title}
                          </span>
                        )}
                      </span>
                    </li>
                  )
                })}
              </ul>
            </nav>
          )}
        </nav>
      </div>

      {/* Main content */}
      <div className={`flex-1 flex flex-col transition-all duration-300 ml-20`}>
        {isSettingsView === true ? (
          <div className={'flex flex-col h-full justify-between'}>{children}</div>
        ) : (
          <>
            {hasMainHeader && <Header isMainHeader={hasMainHeader} activeProcessStatus={headerTitle} />}
            <div className={'h-full'}>{children}</div>
          </>
        )}
      </div>
    </div>
  )
}
