import React, { useEffect, useRef, useState } from 'react'
import DepotoLogo from '../assets/img/group559.svg?react'
import BannerLogo from '../assets/img/dt-expe_login.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Btn, FormInput, FormSelect, Toggle } from '../components'
import { OAuthSession } from 'depoto-core'
import { useHistory, useLocation } from 'react-router-dom'
import { useCore, useLogin } from '../hooks'
import { DepotoCore } from 'depoto-core'
import { wait } from '../lib'

const serverTypes = [
  { label: 'Prod', value: 'prod' },
  { label: 'Stage', value: 'stage' },
  { label: 'Dev', value: 'dev' },
  { label: 'Custom', value: 'custom' },
]

type Props = {
  core: DepotoCore
}

export const Login: React.FC<Props> = ({ core }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState('0px')
  const [className, setclassName] = useState('overflow-auto')
  const [rotate, setRotate] = useState('transform duration-150 ease')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [server, setServer] = useState('prod')
  const [customUrl, setCustomUrl] = useState('https://')
  const [isRememberMe, setIsRememberMe] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState<string>('')

  const history = useHistory()
  const location: any = useLocation()
  const { handleLogin, handleAuthInit, isLoggedIn } = useLogin()
  const { setSelectedChildCompany } = useCore()
  const { from } = location.state || { from: { pathname: '/' } }

  const contentSpace: any = useRef(null)

  const toggleAccordion = () => {
    setActive(!active)
    setHeight(active ? '0px' : `${contentSpace?.current?.scrollHeight || 0}px`)
    setclassName(active ? 'overflow-auto' : 'overflow-visible mb-4')
    setRotate(active ? 'transform duration-150 ease' : 'transform duration-150 ease rotate-180')
  }

  const login = async () => {
    const session = new OAuthSession({
      email: username,
      password,
      clientType: server,
      isRememberMe,
    })
    if (server === 'custom' && customUrl?.length > 0) {
      session.clientCustomUri = customUrl
    }
    setIsFetching(true)
    const isAuthenticated = await handleLogin(username, password, server, customUrl, core) // todo
    // console.log('loginComponent', { isAuthenticated })
    await wait(1000)
    setIsFetching(false)
    if (!isAuthenticated) {
      setError('Chyba při přihlašování') // todo
    } else {
      setError('')
      setSelectedChildCompany(undefined)
      if (from?.pathname === '/login') {
        from.pathname = '/'
      }
      // console.log('login history replace, from, lastRoute ', from, window.lastRoute)
      history.replace(from)
      // todo this!!!!!!!!!!!!!!!!!!!!! is the culprit. cant do this with new store mngmt (leave it for reference for now):
      // setTimeout(() => window.location.replace(window.lastRoute))
    }
  }

  // useEffect(() => {
  //   if (!isLoggedIn && core.services.oauth.session.token.accessToken?.length && +new Date() - core.services.oauth.session.token.timestamp < 24 * 60 * 60 * 1000) {
  //     handleAuthInit(core).then(() => {
  //       setError('')
  //       if (from?.pathname === '/login') {
  //         from.pathname = '/'
  //       }
  //       history.replace(from)
  //     })
  //   }
  // }, [isLoggedIn])

  return (
    <div className="grid grid-cols-3 h-screen">
      <div className="col-span-1 p-12 bg-sky-blue flex flex-col justify-between xs:hidden">
        <div className="grid grid-cols-1 gap-6">
          <div className="col-span-1 text-black font-black sm:text-5xl lg:text-6xl 2xl:text-8xl">Chytrá</div>
          <div className="col-span-1 text-white font-black sm:text-5xl lg:text-6xl 2xl:text-8xl">expediční</div>
          <div className="col-span-1 text-white font-black sm:text-5xl lg:text-6xl 2xl:text-8xl">aplikace</div>
        </div>
        <div className="flex justify-center items-center flex-1">
          <img src={BannerLogo} alt="Depoto Logo" className="sm:w-64 lg:w-auto object-contain" />
        </div>
      </div>

      <div className="col-span-2 flex justify-center items-center bg-base-blue xs:col-span-4">
        <div className="xs:p-0 mx-auto md:w-full md:max-w-md">
          <div className="card-login">
            <div className="grid grid-cols-2 mb-10">
              <div className="col-span-1 flex items-center text-xl text-black font-bold">Přihlášení</div>
              <div className="col-span-1 text-right flex justify-end">
                <DepotoLogo />
              </div>
            </div>
            <div className="flex flex-col gap-4 mb-4">
              <FormInput
                type={'text'}
                inputclassName={'text-left'}
                label={'Uživatelské jméno'}
                value={username}
                onChange={setUsername}
                onSubmit={login}
                debounceMs={0}
              />
              <FormInput
                type={'password'}
                inputclassName={'text-left'}
                label={'Heslo'}
                value={password}
                onChange={setPassword}
                onSubmit={login}
                debounceMs={0} // for what fucking reason we need default 500ms debounce on every input :'(
              />
              <div className={'flex justify-between items-center '}>
                <Toggle label={'Neodhlašovat'} value={isRememberMe} onChange={setIsRememberMe} />
                <div
                  className="flex gap-2 items-center text-primary-blue font-normal cursor-pointer hover:text-blue-900"
                  onClick={toggleAccordion}
                >
                  Rozšířené nastavení
                  <FontAwesomeIcon icon={['far', 'chevron-down']} size="lg" className={`${rotate} inline-block`} />
                </div>
              </div>
              <div
                ref={contentSpace}
                style={{ maxHeight: `${height}` }}
                className={`${className} transition-max-height duration-150 ease-in-out`}
                // className="overflow-visible transition-max-height duration-150 ease-in-out"
              >
                <FormSelect className={'w-full'} value={server} options={serverTypes} onChange={setServer} />
              </div>
              {server === 'custom' && (
                <FormInput
                  type={'text'}
                  inputclassName={'text-left'}
                  label={`Url (${customUrl}/oauth/v2/token)`}
                  value={customUrl}
                  onChange={setCustomUrl}
                  onSubmit={login}
                />
              )}
            </div>
            <Btn
              className={'btn-primary w-full'}
              icon={'fa-sign-in-alt'}
              children={'Přihlásit'}
              title={'Přihlásit'}
              isDisabled={isFetching}
              isLoading={isFetching}
              onClick={login}
            />
            <div>{error}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
