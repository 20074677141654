import { toast, TypeOptions } from 'react-toastify'
import { ToastErrorRenderer } from '../components/ToastErrorRenderer'
import React from 'react'

interface ToastPromiseOptions {
  pending: string
  success:
    | string
    | {
        render: (data: any) => React.ReactNode
        [key: string]: any
      }
  error:
    | string
    | {
        render?: (data: any) => React.ReactNode
        [key: string]: any
      }
}

/**
 * Enhanced version of toast.promise that simplifies error rendering
 * using the ToastErrorRenderer component
 *
 * @param promise The promise to track
 * @param messages Object containing pending, success, and error messages
 * @param options Additional toast options
 * @returns The original promise
 */
export function toastPromise<T>(
  promise: Promise<T>,
  messages: {
    pending: string
    success: string | { render?: (data: T) => React.ReactNode; [key: string]: any }
    error: string
  },
  options?: { autoClose?: number | false; type?: TypeOptions; position?: any },
): Promise<T> {
  return toast.promise(
    promise,
    {
      pending: messages.pending,
      success: typeof messages.success === 'string' ? messages.success : messages.success,
      error: {
        render({ data }) {
          return <ToastErrorRenderer data={data} message={messages.error} />
        },
        ...options,
      },
    },
    options,
  )
}

/**
 * Helper for handling a Promise.all array with proper toast error handling
 *
 * @param promises Array of promises to resolve
 * @returns Promise resolving to the array of results
 */
export function handlePromiseAllToast<T>(promises: Promise<T>[]): Promise<T[]> {
  return Promise.all(promises).then(results => {
    // Handle errors in the results (e.g., GraphQL errors)
    const hasErrors = results.some((r: any) => r?.errors)
    if (hasErrors) {
      const errorResult = results.find((r: any) => r?.errors)
    }
    return results
  })
}
