import React, { useEffect, useState } from 'react'
import { Btn, FormInput, OrderItems, OrderItemsInPackages } from '../../components'
import { Order } from 'depoto-core'
import { Fn } from 'depoto-core'
import { ClearanceItems } from './ClearanceItems'
import { OrderItemsForReturns } from '../../components/OrderItemsForReturns'
import { useCore } from '../../hooks'
import { useOrderPicking } from './lib/hooks/useOrderPicking'

type Props = {
  order: Order
  onUpdate: Fn
}
export const OrderProducts: React.FC<Props> = ({ order, onUpdate }) => {
  const [isFetchingOrder, setIsFetchingOrder] = useState<boolean>(false)
  const [openTab, setOpenTab] = useState<number>(1)
  const [canBeReturned, setCanBeReturned] = useState<boolean>(false)
  const [box, setBox] = useState<string>('')
  const { core, currentOrder, isFetching, setIsFetching } = useCore()

  useEffect(() => {
    if (order) {
      const canReturnOrder =
        order.processStatus?.id === 'packed' ||
        order.processStatus?.id === 'dispatched' ||
        order.processStatus?.id === 'delivered' ||
        order.processStatus?.id === 'returned'
      setCanBeReturned(canReturnOrder)
      if (canReturnOrder) {
        if (order.processStatus?.id === 'returned') {
          setOpenTab(3)
        } else {
          setOpenTab(2)
        }
      }
    }

    if (order.processStatus?.id === 'packing' || order.processStatus?.id === 'packed') {
      setOpenTab(2)
    }
  }, [order, currentOrder])

  useOrderPicking()

  const toggleAllItems = async () => {
    setIsFetching(true)
    for (const orderItem of order.items) {
      try {
        if (order.processStatus?.id === 'picking') {
          await core?.services.order.updateOrderItemPart({ id: orderItem.id, picked: true })
        } else if (order.processStatus?.id === 'packing') {
          await core?.services.order.updateOrderItemPart({ id: orderItem.id, packed: true })
        }
      } catch (errors) {
        alert(`Chyba:\n${JSON.stringify(errors)}`)
      }
    }
    setIsFetching(false)
    onUpdate()
  }

  const addBox = async () => {
    if (box?.length > 0) {
      setIsFetching(true)
      await core?.services.order.updateOrderPart({ id: order.id, boxes: [...order.boxes, box] })
      setIsFetching(false)
      setBox('')
      onUpdate()
    }
  }

  return (
    <div>
      <ul className="p-sub-main flex justify-between border-b border-cool-grey" role="tablist">
        <div className={'flex gap-4'}>
          <div className={'my-auto'}>
            <div className={'flex bg-cool-grey border border-cool-grey rounded-md gap-px'}>
              {!canBeReturned && (
                <li className="text-center my-auto">
                  <a
                    className={
                      'text-sm font-bold px-5 py-3 rounded block ' +
                      (openTab === 1 ? 'bg-primary-blue text-white' : 'bg-white text-black')
                    }
                    onClick={e => {
                      e.preventDefault()
                      setOpenTab(1)
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    Položky k expedici
                  </a>
                </li>
              )}
              <li className="text-center my-auto">
                <a
                  className={
                    'text-sm font-semibold px-5 py-3 rounded block ' +
                    (openTab === 2 ? 'bg-primary-blue text-white' : 'bg-white text-black')
                  }
                  onClick={e => {
                    e.preventDefault()
                    setOpenTab(2)
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Položky objednávky
                </a>
              </li>
              {canBeReturned && (
                <li className="text-center my-auto">
                  <a
                    className={
                      'text-sm font-semibold px-5 py-3 rounded block ' +
                      (openTab === 3 ? 'bg-primary-blue text-white' : 'bg-white text-black')
                    }
                    onClick={e => {
                      e.preventDefault()
                      setOpenTab(3)
                    }}
                    data-toggle="tab"
                    href="#link3"
                    role="tablist"
                  >
                    Vratky
                  </a>
                </li>
              )}
            </div>
          </div>
          {(order.processStatus?.id === 'picking' || order.processStatus?.id === 'packing') && (
            <li className={'flex gap-2 items-center'}>
              <FormInput
                label={''}
                isDisabled={false}
                placeholder={'Přepravka'}
                type={'text'}
                value={box}
                onChange={val => setBox(val)}
                onSubmit={addBox}
              />
              {order.boxes.join(', ')}
              <Btn
                className="btn-secondary"
                icon={'fa-download'}
                children={'Přidat přepravku'}
                title={'Přidat přepravku'}
                isLoading={isFetching || isFetchingOrder}
                onClick={addBox}
              />
            </li>
          )}
        </div>
        {(order.processStatus?.id === 'picking' || order.processStatus?.id === 'packing') && (
          <Btn
            className="btn-outline border-light-grey"
            icon={'check-square'}
            children={'Označit vše'}
            title={'Označit vše'}
            isLoading={isFetching || isFetchingOrder}
            onClick={toggleAllItems}
          />
        )}
      </ul>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded scrollable-content">
        <div>
          <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
            <ClearanceItems order={order} onUpdate={onUpdate} />
          </div>
          <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
            {order.processStatus?.id === 'received' ||
            order.processStatus?.id === 'recieved' ||
            order.processStatus?.id === 'picking' ? (
              <OrderItems items={order.items?.filter(oi => oi.type === 'product') || []} />
            ) : (
              <OrderItemsInPackages orderId={order.id} />
            )}
          </div>
          <div className={openTab === 3 ? 'block' : 'hidden'} id="link3">
            {openTab === 3 && <OrderItemsForReturns order={order} onUpdate={onUpdate} />}
          </div>
        </div>
      </div>
    </div>
  )
}
