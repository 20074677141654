import React from 'react'
import { Fn } from 'depoto-core'

export interface FormTextareaProps {
  value?: string
  onChange: Fn
  className?: string
  label?: string
  placeholder?: string
  textAreaclassName?: string
}

export const FormTextarea: React.FC<FormTextareaProps> = ({
  value,
  onChange,
  className,
  label,
  placeholder,
  textAreaclassName,
}) => {
  return (
    <div className={className === undefined ? 'form-control' : className + ' form-control '}>
      <label className="mb-1">{label}</label>
      <textarea
        className={textAreaclassName === undefined ? 'textarea' : textAreaclassName + ' textarea'}
        placeholder={placeholder}
        value={value}
        onChange={ev => (typeof onChange === 'function' ? onChange(ev.target.value) : null)}
      ></textarea>
    </div>
  )
}
