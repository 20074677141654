import React, { useEffect, useMemo } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { Order as OrderType } from 'depoto-core'
import { formatPrice } from './OrderPrice'
import { Link } from 'react-router-dom'
import { useCore } from '../hooks'
import {
  faBox,
  faBoxCircleCheck,
  faCashRegister,
  faHeadset,
  faMessage,
  faMoneyBill,
  faMoneyBill1Wave,
  faReceipt,
  faSackDollar,
  faWarehouseFull,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCalendar, faReceipt as faReceiptSolid, faTruckFast, faUserVneck } from '@fortawesome/pro-solid-svg-icons'
import tinycolor from 'tinycolor2'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { formatDate } from '../lib'
import 'dayjs/locale/cs'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Tag } from './Tag'

dayjs.locale('cs')
dayjs.extend(relativeTime)
type Props = {
  order: OrderType
}

const rtf = new Intl.RelativeTimeFormat('cs', { style: 'short' })

export const Order: React.FC<Props> = ({ order }) => {
  const color = tinycolor.mix(tinycolor(order.carrier?.color || 'rgb(150, 150, 150)'), '#fff', 80).toString()

  const { company } = useCore()
  // @ts-ignore
  const hasChildren = company?.children?.length > 0

  useEffect(() => {
    tippy('[data-tippy-content]')
  }, [])

  const {
    reservationNumber,
    shippingAddress,
    boxes,
    carrier,
    note,
    privateNote,
    customer,
    isPaid,
    quantityUnavailable,
    depots,
    dateCreated,
    priority,
  } = order
  const statusIcons: {
    iconProps: FontAwesomeIconProps
    active: boolean
    warning: boolean
    tooltip: string
  }[] = useMemo(
    () => [
      {
        iconProps: {
          icon: faMoneyBill1Wave,
        },
        active: true,
        warning: !isPaid,
        tooltip: isPaid ? 'Zaplaceno' : 'Nezaplaceno',
      },
      {
        iconProps: {
          icon: quantityUnavailable === 0 ? faBoxCircleCheck : faBox,
        },
        active: true,
        warning: quantityUnavailable !== 0,
        tooltip: quantityUnavailable === 0 ? 'Nechybí zboží' : 'Chybí zboží',
      },
      {
        iconProps: {
          icon: faWarehouseFull,
          size: 'lg',
        },
        active: depots.length === 1,
        warning: depots.length > 1,
        tooltip: depots.length === 1 ? 'Zboží z 1 skladu' : 'Zboží z více skladů',
      },
      {
        iconProps: {
          icon: faMessage,
          size: 'lg',
        },
        active: !!note?.length,
        warning: !!privateNote?.length,
        tooltip: privateNote?.length ? 'Privátní poznámka' : note?.length ? 'Poznámka' : 'Nemá poznámku',
      },
    ],
    [],
  )

  const renderStatusIcons = () => {
    const country = order.shippingAddress?.country || 'CZ'
    const countryIcon = {
      // CZ: '🇨🇿', // show country code for now
      // SK: '🇸🇰',
    }[country]
    return (
      <div className={'flex flex-row gap-3 items-center'}>
        {statusIcons
          .filter(v => v.active)
          .map((statusIcon, index) => {
            const { iconProps, active, warning, tooltip } = statusIcon
            return (
              <FontAwesomeIcon
                key={index}
                data-tippy-content={tooltip}
                size={'lg'}
                style={{
                  // @ts-ignore
                  '--fa-primary-color': warning ? 'rgb(245, 0, 0)' : active ? 'rgb(150,206,126)' : 'rgb(227,227,227)',
                  '--fa-secondary-color': warning ? 'rgb(255, 0, 0)' : active ? 'rgb(39,178,13)' : 'rgb(152,152,152)',
                  '--fa-secondary-opacity': 0.5,
                  opacity: active ? 1 : 0.5,
                  ...(iconProps.style || {}),
                }}
                {...iconProps}
              />
            )
          })}
        {countryIcon ? (
          <img src={`https://emojicdn.elk.sh/${countryIcon}`} alt={country} className={'h-6'} />
        ) : (
          <span>{country}</span>
        )}
      </div>
    )
  }

  const orderDetailItems: {
    label: string
    icon: IconProp
    value: string | undefined
    isVisible: boolean
  }[] = useMemo(
    () =>
      [
        {
          label: 'Doprava',
          icon: faTruckFast,
          value: order.carrier?.name,
          isVisible: !!order.carrier?.name,
        },
        {
          label: 'Zakaznik',
          icon: faUserVneck,
          value: `${order.shippingAddress?.firstName} ${order.shippingAddress?.lastName}`,
          isVisible: !!order.shippingAddress?.firstName || !!order.shippingAddress?.lastName,
        },
        order.externalReference
          ? {
              label: 'External reference',
              icon: faHeadset,
              value: order.externalReference,
              isVisible: !!order?.externalReference,
            }
          : undefined,
        {
          label: 'Datum',
          icon: faCalendar,
          value: `${formatDate(order?.dateCreated, true)}`,
          // value: `${formatDate(order?.dateCreated, false)} (${dayjs(order?.dateCreated).fromNow()})`,
          isVisible: !!order?.dateCreated,
        },
        {
          label: 'Pokladna',
          icon: faCashRegister,
          value: order.checkout?.name,
          isVisible: !!order?.checkout?.name,
        },
        {
          label: 'Cena',
          icon: faReceiptSolid,
          value: formatPrice(order.priceAll || 0, order.currency?.id || 'CZK'),
          isVisible: !!order.priceAll && !!order.currency?.id,
        },
      ].filter(v => !!v),
    [],
  )

  const renderOrderDetailItems = () => {
    let iconColor = tinycolor.mix(tinycolor(order.carrier?.color || 'rgb(150, 150, 150)'), '#fff', 80).toString()

    for (let i = 0; i < 100; i++) {
      if (tinycolor.readability(iconColor, color) > 1.6) {
        break
      }
      iconColor = tinycolor(iconColor).darken(1).toString()
    }

    const hasFragiles = order?.items?.filter(oi => oi.product?.isFragile).length! > 0
    const hasOversized = order?.items?.filter(oi => oi.product?.isOversize).length! > 0

    return (
      <div className="p-3">
        {orderDetailItems.map((el, i) => (
          <div className={'flex flex-row items-center gap-3'} key={el.label}>
            <div className={'flex w-7 h-7 items-center justify-center'}>
              <FontAwesomeIcon icon={el.icon} color={iconColor} size={'lg'} />
            </div>
            <span className={'font-semibold'} style={!el.isVisible ? { color: 'rgba(0, 0, 0, 0.3)' } : {}}>
              {el.isVisible ? el.value : 'Nezadano'}
            </span>
          </div>
        ))}
        <div className="flex flex-row flex-wrap items-center gap-2 mt-2">
          <div className={'flex items-center gap-3 flex-wrap'}>
            {!order?.isPaid && (
              <div>
                <span className={'label-red'}>
                  <FontAwesomeIcon icon={['far', 'money-bill']} size="lg" className={'mr-2'} />
                  <span>Neuhrazeno</span>
                </span>
              </div>
            )}
            {hasFragiles && (
              <div>
                <span className={'label-yellow'}>
                  <FontAwesomeIcon icon={['far', 'fragile']} size="lg" className={'mr-2'} />
                  <span>Křehké</span>
                </span>
              </div>
            )}
            {hasOversized && (
              <div>
                <span className={'label-purple'}>
                  <FontAwesomeIcon icon={['far', 'expand-alt']} size="lg" className={'mr-2'} />
                  <span>Nadměrné</span>
                </span>
              </div>
            )}
            {(order?.tags || []).map(tag => (
              <div key={tag.id}>
                <Tag tag={tag} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Link
      to={`/order/${order.id}`}
      className="flex rounded-lg flex-1 flex-col shadow-md"
      style={{ backgroundColor: color, breakInside: 'avoid' }}
    >
      <div className={'flex flex-row gap-3 items-center justify-between px-3 py-2 rounded-t-lg bg-gray-50 orderShadow'}>
        <div className={'flex flex-row gap-3 items-center '}>
          {/*<ProcessStatusIcon status={order.processStatus?.id || 'received'} />*/}
          <span className={'font-bold text-lg'}>#{reservationNumber}</span>
        </div>
        <div className={'flex flex-row gap-3 items-center'}>{renderStatusIcons()}</div>
      </div>
      {renderOrderDetailItems()}
    </Link>
  )
}
