import React, { useMemo } from 'react'
import { ClearanceItem, Order } from 'depoto-core'
import { Fn } from 'depoto-core'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { ProductCell } from './components/ProductCell'
import { Table } from './components/Table'
import { SquareAmountCell } from './components/SquareAmountCell'
import { PickingCheckboxCell } from './components/PickingCheckboxCell'
import { EditExpeditionBatchPositionModal } from '../../components'

type Props = {
  order: Order
  onUpdate: Fn
}

const columnHelper = createColumnHelper<ClearanceItem>()

export const ClearanceItems: React.FC<Props> = ({ order, onUpdate }) => {
  const clearanceItems: ClearanceItem[] = order.clearanceItems

  const Columns = useMemo(
    () => [
      columnHelper.accessor('product', {
        header: 'Zboží',
        cell: ({ getValue }) => <ProductCell product={getValue()} />,
      }),
      columnHelper.accessor('product.code', { header: 'Kód', size: 180 }),
      columnHelper.accessor('product.ean', { header: 'EAN', size: 120 }),
      columnHelper.accessor(
        ({ expirationDate }) =>
          expirationDate.substring(expirationDate.length - 8) === '00:00:00'
            ? expirationDate.substring(0, 11)
            : expirationDate,
        { header: 'Expirace', id: 'expirationDate' },
      ),
      columnHelper.accessor('batch', { header: 'Šarže' }),
      columnHelper.accessor('position', { header: 'Pozice', size: 120 }),
      columnHelper.accessor('amount', {
        header: () => <div className={'text-center'}>Počet ks</div>,
        cell: ({ getValue }) => <SquareAmountCell>{getValue()}</SquareAmountCell>,
        size: 100,
      }),
      columnHelper.accessor('picked', {
        header: () => <div className={'text-center flex-1'}>Vyskladněno</div>,
        cell: ({ getValue }) => <SquareAmountCell>{getValue()}</SquareAmountCell>,
        size: 100,
      }),
      columnHelper.accessor('id', {
        header: () => <div className={'text-center flex-1'}>Vyskladněno</div>,
        cell: ({ getValue }) => <PickingCheckboxCell orderId={order.id} clearanceItemId={getValue()} />,
        size: 100,
      }),
      columnHelper.display({
        id: 'actions',
        cell: props => (
          <EditExpeditionBatchPositionModal clearanceItem={props.row.original} onUpdate={onUpdate} order={order} />
        ),
        size: 100,
      }),
    ],
    [order],
  )

  const table = useReactTable({
    columns: Columns,
    data: clearanceItems,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        batch: !clearanceItems.every(el => !el.batch),
        expirationDate: !clearanceItems.every(el => !el.expirationDate),
        'product.ean': !clearanceItems.every(el => !el.product.ean),
        'product.code': !clearanceItems.every(el => !el.product.code),
        position: !clearanceItems.every(el => el.position == '--'),
      },
    },
    defaultColumn: {
      // @ts-ignore
      size: '1fr',
    },
  })

  return <Table table={table} />
}
