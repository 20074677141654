import React from 'react'

interface ToastErrorProps {
  data: any
  message: string
}

/**
 * A component for rendering error messages in toasts with a standardized format.
 * The main message appears in normal size, while the technical error details
 * are shown in smaller, grayed-out text below.
 */
export const ToastErrorRenderer: React.FC<ToastErrorProps> = ({ data, message }) => {
  // Extract error message from data with proper type handling
  const errorMessage = (() => {
    if (!data) return 'Neznámá chyba'

    // Handle GraphQL errors
    if (data.errors && Array.isArray(data.errors)) {
      return data.errors.map((e: any) => e.message || e.toString()).join(', ')
    }

    // Handle standard error objects
    if (typeof data.message === 'string') {
      return data.message
    }

    // Handle non-standard errors
    if (typeof data.toString === 'function') {
      const stringified = data.toString()
      if (stringified !== '[object Object]') {
        return stringified
      }
    }

    // Fallback to JSON stringify for complex objects
    try {
      return JSON.stringify(data)
    } catch (e) {
      return 'Neznámá chyba'
    }
  })()

  return (
    <div className="flex flex-col">
      <div>{message}</div>
      <div className="text-sm text-gray-500 mt-1">{errorMessage}</div>
    </div>
  )
}
