/* eslint-disable */

export const AllTypesProps: Record<string, any> = {
  RootSchemaQuery: {
    user: {},
    users: {
      filters: 'userFilters',
    },
    isUserEmailUnique: {},
    userGroup: {},
    userGroups: {
      filters: 'userGroupFilters',
    },
    product: {},
    products: {
      filters: 'productFilters',
      currencyDate: 'DateTimeAsString',
    },
    productPurchasePrice: {},
    productSellItems: {},
    productMovePacks: {
      filters: 'productMovePackFilters',
    },
    productMoves: {
      filters: 'productMoveFilters',
    },
    productMovePack: {},
    institution: {},
    institutions: {
      filters: 'institutionFilters',
    },
    order: {},
    orders: {
      filters: 'orderFilters',
    },
    soldItems: {
      filters: 'soldItemFilters',
    },
    orderItems: {
      filters: 'orderItemFilters',
    },
    orderItem: {},
    customer: {},
    customers: {
      filters: 'customerFilters',
    },
    address: {},
    addresses: {
      filters: 'addressesFilters',
    },
    checkout: {},
    checkouts: {
      filters: 'checkoutFilters',
    },
    consent: {},
    consents: {
      filters: 'consentsFilters',
    },
    checkoutExtraOperation: {},
    checkoutExtraOperations: {
      filters: 'checkoutExtraOperationFilters',
    },
    checkoutClosing: {},
    checkoutClosings: {
      filters: 'checkoutClosingFilters',
    },
    vat: {},
    vats: {
      filters: 'vatFilters',
    },
    depot: {},
    depots: {
      filters: 'depotFilters',
    },
    supplier: {},
    suppliers: {
      filters: 'supplierFilters',
    },
    producer: {},
    producers: {
      filters: 'producerFilters',
    },
    exportStocks: {
      filters: 'exportStockFilters',
    },
    exportSales: {
      filters: 'exportSaleFilters',
    },
    exportPurchases: {
      filters: 'exportPurchaseFilters',
    },
    exportOrders: {
      filters: 'exportOrderFilters',
    },
    exportInventory: {},
    exportInventories: {
      filters: 'exportInventoryFilters',
    },
    export: {},
    exports: {
      filters: 'exportFilters',
    },
    exportTypes: {
      filters: 'exportTypeFilters',
    },
    shop: {},
    shops: {
      filters: 'shopFilters',
    },
    cert: {},
    certs: {
      filters: 'filters',
    },
    eetReceipts: {
      filters: 'eetReceiptFilters',
    },
    payment: {},
    payments: {
      filters: 'paymentFilters',
    },
    paymentItem: {},
    paymentItems: {
      filters: 'paymentItemsFilters',
    },
    paymentTypes: {
      filters: 'paymentTypeFilters',
    },
    processStatuses: {
      filters: 'processStatusesFilters',
    },
    carriers: {
      filters: 'carriersFilters',
    },
    currencies: {
      filters: 'currenciesFilters',
    },
    package: {},
    packages: {
      filters: 'packageFilters',
    },
    stats: {
      filters: 'packageStatsFilters',
    },
    disposal: {},
    disposals: {
      filters: 'disposalFilters',
    },
    productDepotMetric: {},
    productDepotMetrics: {
      filters: 'productDepotMetricFilters',
    },
    file: {},
    files: {
      filters: 'fileFilters',
    },
    statsCarrierUsage: {
      from: 'DateTimeAsString',
      to: 'DateTimeAsString',
    },
    statsPaymentSales: {
      from: 'DateTimeAsString',
      to: 'DateTimeAsString',
    },
    statsBestsellers: {
      from: 'DateTimeAsString',
      to: 'DateTimeAsString',
      sort: 'statsBestsellersSort',
    },
    companyCarrier: {},
    companyCarriers: {
      filters: 'companyCarrierFilters',
    },
    category: {},
    categories: {
      filters: 'categoryFilters',
    },
    parameter: {},
    parameters: {
      filters: 'parametersFilters',
    },
    orderGroup: {},
    orderGroups: {
      filters: 'orderGroupFilters',
    },
    voucher: {},
    vouchers: {
      filters: 'voucherFilters',
    },
    productPrices: {
      filters: 'productPriceFilters',
    },
    priceLevel: {},
    priceLevels: {
      filters: 'priceLevelFilters',
    },
    productParameter: {},
    productParameters: {
      filters: 'productParameterFilters',
    },
    productPriceLevel: {},
    productPriceLevels: {
      filters: 'productPriceLevelFilters',
    },
    tag: {},
    tags: {
      filters: 'tagFilters',
    },
    productPack: {},
    productPacks: {
      filters: 'productPackFilters',
    },
    purchaseOrder: {},
    purchaseOrders: {
      filters: 'purchaseOrderFilters',
    },
    purchaseOrderItem: {},
    modules: {
      filters: 'modulesFilters',
    },
  },
  DateTimeAsString: `scalar.DateTimeAsString` as const,
  DateTime: `scalar.DateTime` as const,
  StringOrArray: `scalar.StringOrArray` as const,
  userFilters: {},
  userGroupFilters: {},
  productFilters: {
    expirationDateTo: 'DateTimeAsString',
    createdFrom: 'DateTimeAsString',
    updatedFrom: 'DateTimeAsString',
  },
  productMovePackFilters: {
    created: 'created',
  },
  created: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  productMoveFilters: {
    created: 'created',
    expirationDate: 'DateTimeAsString',
  },
  institutionFilters: {},
  orderFilters: {
    processStatusChange: 'DateTimeAsString',
    processStatusUpdated: 'processStatusUpdated',
    dateCreated: 'dateCreated',
    dateBill: 'dateBill',
    dateExpedition: 'dateExpedition',
    updated: 'updated',
    dateLocked: 'DateTimeAsString',
  },
  processStatusUpdated: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  dateCreated: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  dateBill: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  dateExpedition: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  updated: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  soldItemFilters: {
    processStatusUpdated: 'processStatusUpdated',
    dateCreated: 'dateCreated',
  },
  orderItemFilters: {},
  customerFilters: {},
  addressesFilters: {},
  checkoutFilters: {},
  consentsFilters: {},
  checkoutExtraOperationFilters: {},
  checkoutClosingFilters: {},
  vatFilters: {},
  depotFilters: {},
  supplierFilters: {},
  producerFilters: {},
  exportStockFilters: {},
  exportSaleFilters: {},
  exportPurchaseFilters: {},
  exportOrderFilters: {},
  exportInventoryFilters: {},
  exportFilters: {},
  exportTypeFilters: {},
  shopFilters: {},
  filters: {},
  eetReceiptFilters: {},
  paymentFilters: {},
  paymentItemsFilters: {},
  paymentTypeFilters: {},
  processStatusesFilters: {},
  carriersFilters: {},
  currenciesFilters: {},
  packageFilters: {},
  packageStatsFilters: {
    fromDate: 'DateTimeAsString',
    toDate: 'DateTimeAsString',
  },
  disposalFilters: {},
  productDepotMetricFilters: {},
  fileFilters: {},
  statsBestsellersSort: 'enum' as const,
  companyCarrierFilters: {},
  categoryFilters: {},
  parametersFilters: {},
  orderGroupFilters: {},
  voucherFilters: {
    validDate: 'voucherValidFilter',
    created: 'voucherCreatedFilter',
  },
  voucherValidFilter: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  voucherCreatedFilter: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  productPriceFilters: {},
  priceLevelFilters: {},
  productParameterFilters: {},
  productPriceLevelFilters: {},
  tagFilters: {},
  productPackFilters: {},
  purchaseOrderFilters: {
    updated: 'updated',
    created: 'created',
    dateEstimatedArrival: 'dateEstimatedArrival',
  },
  dateEstimatedArrival: {
    left_date: 'DateTimeAsString',
    right_date: 'DateTimeAsString',
  },
  modulesFilters: {},
  RootSchemaMutation: {
    createProduct: {},
    updateProduct: {},
    deleteProduct: {},
    refreshProductDepots: {},
    createProductMovePack: {
      moves: 'createProductMove',
    },
    updateProductMovePack: {},
    deleteProductMovePack: {},
    updateProductMove: {},
    deleteProductMove: {},
    createProductPrice: {
      dateFrom: 'DateTimeAsString',
      dateTo: 'DateTimeAsString',
    },
    updateProductPrice: {
      dateFrom: 'DateTimeAsString',
      dateTo: 'DateTimeAsString',
    },
    deleteProductPrice: {},
    createProductBundle: {},
    updateProductBundle: {},
    deleteProductBundle: {},
    createProductDepotMetric: {},
    updateProductDepotMetric: {},
    deleteProductDepotMetric: {},
    createInstitution: {},
    updateInstitution: {},
    deleteInstitution: {},
    createOrder: {
      dateCreated: 'DateTimeAsString',
      dateBill: 'DateTimeAsString',
      dateExpedition: 'DateTimeAsString',
      dateDue: 'DateTimeAsString',
      dateTax: 'DateTimeAsString',
      items: 'createOrderItem_',
      paymentItems: 'createPaymentItem_',
      processStatusRelation: 'processStatusRelation',
    },
    updateOrder: {
      dateCreated: 'DateTimeAsString',
      dateBill: 'DateTimeAsString',
      dateExpedition: 'DateTimeAsString',
      dateDue: 'DateTimeAsString',
      dateTax: 'DateTimeAsString',
      items: 'updateOrderItem_',
      paymentItems: 'updatePaymentItem_',
      processStatusRelation: 'processStatusRelation',
    },
    deleteReservation: {},
    createOrderItem: {
      expirationDate: 'DateTimeAsString',
    },
    updateOrderItem: {
      expirationDate: 'DateTimeAsString',
    },
    deleteOrderItem: {},
    updateClearanceItem: {
      locations: 'updateClearanceItemLocations',
    },
    createUser: {},
    updateUser: {},
    deleteUser: {},
    createUserGroup: {},
    updateUserGroup: {},
    deleteUserGroup: {},
    createCustomer: {
      addresses: 'createCustomerAddress',
      birthday: 'DateTimeAsString',
    },
    updateCustomer: {
      birthday: 'DateTimeAsString',
    },
    deleteCustomer: {},
    createAddress: {},
    updateAddress: {},
    deleteAddress: {},
    createCheckout: {},
    updateCheckout: {},
    deleteCheckout: {},
    createCheckoutExtraOperation: {
      dateCreated: 'DateTimeAsString',
    },
    updateCheckoutExtraOperation: {
      dateCreated: 'DateTimeAsString',
    },
    deleteCheckoutExtraOperation: {},
    createCheckoutClosing: {
      dateOpen: 'DateTimeAsString',
      dateClosed: 'DateTimeAsString',
    },
    updateCheckoutClosing: {
      dateOpen: 'DateTimeAsString',
      dateClosed: 'DateTimeAsString',
    },
    deleteCheckoutClosing: {},
    createVat: {},
    updateVat: {},
    deleteVat: {},
    createDepot: {},
    updateDepot: {},
    deleteDepot: {},
    createSupplier: {},
    updateSupplier: {},
    deleteSupplier: {},
    createProducer: {},
    updateProducer: {},
    deleteProducer: {},
    createExportStock: {
      date: 'DateTimeAsString',
    },
    createExportSale: {
      dateFrom: 'DateTimeAsString',
      dateTo: 'DateTimeAsString',
    },
    createExportPurchase: {
      dateFrom: 'DateTimeAsString',
      dateTo: 'DateTimeAsString',
    },
    createExportOrder: {
      dateFrom: 'DateTimeAsString',
      dateTo: 'DateTimeAsString',
    },
    createExportInventory: {
      date: 'DateTimeAsString',
    },
    updateExportInventory: {
      date: 'DateTimeAsString',
    },
    refreshExportInventory: {},
    createExport: {},
    updateCompany: {},
    createShop: {},
    updateShop: {},
    deleteShop: {},
    createCert: {},
    updateCert: {},
    deleteCert: {},
    createEetReceipt: {},
    sendEetReceipt: {},
    createPayment: {},
    updatePayment: {},
    deletePayment: {},
    createPaymentItem: {
      dateCreated: 'DateTimeAsString',
      dateCancelled: 'DateTimeAsString',
      datePaid: 'DateTimeAsString',
    },
    updatePaymentItem: {
      dateCreated: 'DateTimeAsString',
      dateCancelled: 'DateTimeAsString',
      datePaid: 'DateTimeAsString',
    },
    deletePaymentItem: {},
    createDevLog: {},
    createPackage: {},
    updatePackage: {},
    deletePackage: {},
    resetPackage: {},
    sendPackage: {},
    createDisposal: {},
    updateDisposal: {},
    deleteDisposal: {},
    sendDisposal: {},
    createFile: {},
    updateFile: {},
    deleteFile: {},
    createCompanyCarrier: {},
    updateCompanyCarrier: {},
    deleteCompanyCarrier: {},
    createConsent: {},
    updateConsent: {},
    deleteConsent: {},
    createCustomerConsent: {},
    deleteCustomerConsent: {},
    updateProductDepot: {
      expirationDate: 'DateTimeAsString',
    },
    createCategory: {},
    updateCategory: {},
    deleteCategory: {},
    createParameter: {},
    updateParameter: {},
    deleteParameter: {},
    createOrderGroup: {},
    updateOrderGroup: {},
    deleteOrderGroup: {},
    createVoucher: {
      validFrom: 'DateTimeAsString',
      validTo: 'DateTimeAsString',
    },
    updateVoucher: {
      validFrom: 'DateTimeAsString',
      validTo: 'DateTimeAsString',
    },
    deleteVoucher: {},
    createPriceLevel: {},
    updatePriceLevel: {},
    deletePriceLevel: {},
    createProductParameter: {
      value: 'StringOrArray',
    },
    updateProductParameter: {
      value: 'StringOrArray',
    },
    deleteProductParameter: {},
    createProductPriceLevel: {},
    updateProductPriceLevel: {},
    deleteProductPriceLevel: {},
    createTag: {},
    updateTag: {},
    deleteTag: {},
    createProductPack: {},
    updateProductPack: {},
    deleteProductPack: {},
    createPurchaseOrder: {
      dateEstimatedArrival: 'DateTimeAsString',
      items: 'createPurchaseOrderItem_',
    },
    updatePurchaseOrder: {
      dateEstimatedArrival: 'DateTimeAsString',
      items: 'createPurchaseOrderItem_',
    },
    deletePurchaseOrder: {},
    createPurchaseOrderItem: {},
    updatePurchaseOrderItem: {},
    deletePurchaseOrderItem: {},
  },
  createProductMove: {
    purchaseCurrencyDate: 'DateTimeAsString',
    expirationDate: 'DateTimeAsString',
  },
  createOrderItem_: {
    expirationDate: 'DateTimeAsString',
  },
  createPaymentItem_: {
    dateCreated: 'DateTimeAsString',
    dateCancelled: 'DateTimeAsString',
    datePaid: 'DateTimeAsString',
  },
  processStatusRelation: {},
  updateOrderItem_: {
    expirationDate: 'DateTimeAsString',
  },
  updatePaymentItem_: {
    dateCreated: 'DateTimeAsString',
    dateCancelled: 'DateTimeAsString',
    datePaid: 'DateTimeAsString',
  },
  updateClearanceItemLocations: {
    expirationDate: 'DateTimeAsString',
  },
  createCustomerAddress: {},
  createPurchaseOrderItem_: {},
}

export const ReturnTypes: Record<string, any> = {
  RootSchemaQuery: {
    user: 'user',
    userMyProfile: 'userMyProfile',
    users: 'users',
    isUserEmailUnique: 'isUserEmailUnique',
    userGroup: 'userGroup',
    userGroups: 'userGroups',
    roles: 'Role',
    product: 'product',
    products: 'products',
    productPurchasePrice: 'productPurchasePrice',
    productSellItems: 'productSellItems',
    productMovePacks: 'productMovePacks',
    productMoves: 'productMoves',
    productMovePack: 'productMovePack',
    institution: 'institution',
    institutions: 'institutions',
    order: 'order',
    orders: 'orders',
    soldItems: 'soldItems',
    orderItems: 'orderItems',
    orderItem: 'orderItem',
    customer: 'customer',
    customers: 'customers',
    address: 'address',
    addresses: 'addresses',
    checkout: 'checkout',
    checkouts: 'checkouts',
    eventTypes: 'eventTypes',
    consent: 'consent',
    consents: 'consents',
    checkoutExtraOperation: 'checkoutExtraOperation',
    checkoutExtraOperations: 'checkoutExtraOperations',
    checkoutClosing: 'checkoutClosing',
    checkoutClosings: 'checkoutClosings',
    vat: 'vat',
    vats: 'vats',
    depot: 'depot',
    depots: 'depots',
    supplier: 'supplier',
    suppliers: 'suppliers',
    producer: 'producer',
    producers: 'producers',
    exportStocks: 'exportStocks',
    exportSales: 'exportSales',
    exportPurchases: 'exportPurchases',
    exportOrders: 'exportOrders',
    exportInventory: 'exportInventory',
    exportInventories: 'exportInventories',
    export: 'export',
    exports: 'exports',
    exportTypes: 'exportTypes',
    shop: 'shop',
    shops: 'shops',
    cert: 'cert',
    certs: 'certs',
    eetReceipts: 'eetReceipts',
    payment: 'payment',
    payments: 'payments',
    paymentItem: 'paymentItem',
    paymentItems: 'paymentItems',
    paymentTypes: 'paymentTypes',
    processStatuses: 'processStatuses',
    carriers: 'carriers',
    currencies: 'currencies',
    package: 'package',
    packages: 'packages',
    stats: 'packageStats',
    disposal: 'disposal',
    disposals: 'disposals',
    productDepotMetric: 'productDepotMetric',
    productDepotMetrics: 'productDepotMetrics',
    file: 'file',
    files: 'files',
    nextEan: 'nextEan',
    statsCarrierUsage: 'statsCarrierUsage',
    statsPaymentSales: 'statsPaymentSales',
    statsBestsellers: 'statsBestsellers',
    companyCarrier: 'companyCarrier',
    companyCarriers: 'companyCarriers',
    category: 'category',
    categories: 'categories',
    parameter: 'parameter',
    parameters: 'parameters',
    orderGroup: 'orderGroup',
    orderGroups: 'orderGroups',
    voucher: 'voucher',
    vouchers: 'vouchers',
    productPrices: 'productPrices',
    priceLevel: 'priceLevel',
    priceLevels: 'priceLevels',
    productParameter: 'productParameter',
    productParameters: 'productParameters',
    productPriceLevel: 'productPriceLevel',
    productPriceLevels: 'productPriceLevels',
    tag: 'tag',
    tags: 'tags',
    productPack: 'productPack',
    productPacks: 'productPacks',
    purchaseOrder: 'purchaseOrder',
    purchaseOrders: 'purchaseOrders',
    purchaseOrderItem: 'purchaseOrderItem',
    modules: 'modules',
  },
  user: {
    data: 'User',
    errors: 'String',
  },
  User: {
    id: 'Int',
    email: 'String',
    name: 'String',
    firstName: 'String',
    lastName: 'String',
    phone: 'String',
    groups: 'UserGroup',
    roles: 'String',
    enabled: 'Boolean',
    company: 'Company',
    pin: 'String',
    externalId: 'String',
    customers: 'Customer',
    checkouts: 'Checkout',
    depots: 'Depot',
    locale: 'String',
    deletable: 'Boolean',
  },
  UserGroup: {
    id: 'Int',
    name: 'String',
    roles: 'String',
    externalId: 'String',
    company: 'Company',
  },
  Company: {
    id: 'Int',
    name: 'String',
    ic: 'String',
    dic: 'String',
    phone: 'String',
    email: 'String',
    street: 'String',
    city: 'String',
    zip: 'String',
    country: 'String',
    currency: 'Currency',
    registrationNote: 'String',
    nextEan: 'String',
    billLogo: 'String',
    parent: 'Company',
    children: 'Company',
    carrierRelations: 'CompanyCarrier',
    moduleRelations: 'CompanyModule',
    payments: 'Payment',
    defaultVoucherValidity: 'Int',
    pickingOnlyOneDepot: 'Boolean',
    customOptions: 'String',
    batchExpirationMode: 'String',
    externalId: 'String',
    purchasePriceWithVat: 'Boolean',
  },
  Currency: {
    id: 'String',
    name: 'String',
    ratio: 'Float',
  },
  CompanyCarrier: {
    id: 'Int',
    enable: 'Boolean',
    options: 'String',
    carrier: 'Carrier',
    checkout: 'Checkout',
    externalId: 'String',
  },
  Carrier: {
    id: 'String',
    name: 'String',
    color: 'String',
    position: 'Int',
    tariffs: 'CarrierTariff',
    requiredShippingAddress: 'Boolean',
  },
  CarrierTariff: {
    id: 'Int',
    const: 'String',
    name: 'String',
    cod: 'Boolean',
    position: 'Int',
  },
  Checkout: {
    id: 'Int',
    name: 'String',
    amount: 'Float',
    nextReservationNumber: 'Int',
    nextBillNumber: 'Int',
    billFooter: 'String',
    payments: 'Payment',
    depots: 'Depot',
    returnsDepot: 'Depot',
    currentClosing: 'CheckoutClosing',
    eetId: 'Int',
    eetEnable: 'Boolean',
    eetPlayground: 'Boolean',
    eetVerificationMode: 'Boolean',
    billWidth: 'Int',
    billFontSize: 'Int',
    billFontWeight: 'String',
    eventUrl: 'String',
    eventTypes: 'String',
    company: 'Company',
    negativeReservation: 'Boolean',
    externalId: 'String',
    users: 'User',
    autoPicking: 'Boolean',
    autoPacking: 'Boolean',
    autoDispatched: 'Boolean',
    institution: 'Institution',
    position: 'Int',
    deletable: 'Boolean',
    created: 'DateTime',
    updated: 'DateTime',
  },
  Payment: {
    id: 'Int',
    name: 'String',
    eetEnable: 'Boolean',
    dispatchUnpaid: 'Boolean',
    type: 'PaymentType',
    externalId: 'String',
    company: 'Company',
  },
  PaymentType: {
    id: 'String',
    name: 'String',
  },
  Depot: {
    id: 'Int',
    name: 'String',
    emailIn: 'String',
    unavailablesUrl: 'String',
    externalId: 'String',
    company: 'Company',
    companyCarriers: 'CompanyCarrier',
    checkouts: 'Checkout',
    institution: 'Institution',
    users: 'User',
    productDepots: 'ProductDepot',
    position: 'Int',
    deletable: 'Boolean',
    created: 'DateTime',
    updated: 'DateTime',
  },
  Institution: {
    id: 'Int',
    name: 'String',
    externalId: 'String',
    company: 'Company',
    checkouts: 'Checkout',
    depots: 'Depot',
  },
  ProductDepot: {
    id: 'Int',
    depot: 'Depot',
    product: 'Product',
    supplier: 'Supplier',
    purchasePrice: 'Float',
    quantityStock: 'Float',
    quantityReservation: 'Float',
    quantityAvailable: 'Float',
    quantityUnavailable: 'Float',
    inventoryQuantityStock: 'Float',
    expirationDate: 'DateTime',
    batch: 'String',
    position: 'String',
    position1: 'String',
    position2: 'String',
    position3: 'String',
    clearanceItemId: 'String',
    created: 'DateTime',
    updated: 'DateTime',
  },
  Product: {
    id: 'Int',
    name: 'String',
    fullName: 'String',
    ean: 'String',
    ean2: 'String',
    ean3: 'String',
    ean4: 'String',
    ean5: 'String',
    code: 'String',
    code2: 'String',
    code3: 'String',
    externalId: 'String',
    sellPrice: 'Float',
    sellPriceWithoutVat: 'Float',
    beforeSellPrice: 'Float',
    actualSellPrice: 'Float',
    actualSellPriceWithoutVat: 'Float',
    actualBeforeSellPrice: 'Float',
    purchasePrice: 'Float',
    purchaseCurrency: 'Currency',
    weight: 'Float',
    dimensionX: 'Float',
    dimensionY: 'Float',
    dimensionZ: 'Float',
    originCountry: 'String',
    hsCode: 'String',
    enabled: 'Boolean',
    vat: 'Vat',
    producer: 'Producer',
    supplier: 'Supplier',
    parent: 'Product',
    children: 'Product',
    depots: 'ProductDepot',
    productDepots: 'ProductDepot',
    isForSubsequentSettlement: 'Boolean',
    mainImage: 'File',
    images: 'File',
    files: 'File',
    isBundle: 'Boolean',
    isFragile: 'Boolean',
    isOversize: 'Boolean',
    bundleParents: 'ProductBundle',
    bundleChildren: 'ProductBundle',
    note: 'String',
    description: 'String',
    customData: 'String',
    advancedPrices: 'ProductPrice',
    quantityUnavailable: 'Int',
    quantityStock: 'Float',
    quantityAvailable: 'Float',
    quantityReservation: 'Float',
    quantityPurchase: 'Float',
    categories: 'Category',
    mainCategory: 'Category',
    company: 'Company',
    quantities: 'Quantity',
    metrics: 'ProductDepotMetric',
    packs: 'ProductPack',
    productParameters: 'ProductParameter',
    productPriceLevels: 'ProductPriceLevel',
    tags: 'Tag',
    sellItems: 'SellItem',
    isDeletable: 'Boolean',
    deletable: 'Boolean',
    enabledBatchExpiration: 'Boolean',
    requiredSerialNumbers: 'Boolean',
    created: 'DateTime',
    updated: 'DateTime',
  },
  Vat: {
    id: 'Int',
    name: 'String',
    percent: 'Float',
    default: 'Boolean',
    coefficient: 'Float',
    externalId: 'String',
    company: 'Company',
    created: 'DateTimeAsString',
    updated: 'DateTimeAsString',
  },
  DateTimeAsString: `scalar.DateTimeAsString` as const,
  Producer: {
    id: 'Int',
    name: 'String',
    externalId: 'String',
    company: 'Company',
  },
  Supplier: {
    id: 'Int',
    name: 'String',
    externalId: 'String',
    company: 'Company',
    deletable: 'Boolean',
  },
  File: {
    id: 'Int',
    originalFilename: 'String',
    text: 'String',
    main: 'Boolean',
    mimeType: 'String',
    size: 'Int',
    url: 'String',
    product: 'Product',
    order: 'Order',
    thumbnails: 'FileThumbnail',
  },
  Order: {
    id: 'Int',
    company: 'Company',
    status: 'OrderStatus',
    reservationNumber: 'Int',
    billNumber: 'Int',
    vs: 'Int',
    dateCreated: 'DateTime',
    dateBill: 'DateTime',
    dateExpedition: 'DateTime',
    dateDue: 'DateTime',
    dateTax: 'DateTime',
    items: 'OrderItem',
    paymentItems: 'PaymentItem',
    checkout: 'Checkout',
    checkoutClosing: 'CheckoutClosing',
    customer: 'Customer',
    note: 'String',
    privateNote: 'String',
    invoiceAddress: 'Address',
    shippingAddress: 'Address',
    relatedParent: 'Order',
    rounding: 'Float',
    eetReceipt: 'Receipt',
    deleted: 'DateTime',
    priceAll: 'Float',
    priceAllWithoutVat: 'Float',
    profit: 'Float',
    vatAllocations: 'VatAllocation',
    billUrl: 'String',
    invoiceUrl: 'String',
    reservationUrl: 'String',
    invoiceReceiptUrl: 'String',
    reservationReceiptUrl: 'String',
    processStatus: 'ProcessStatus',
    processStatusUpdated: 'DateTime',
    processStatusRelation: 'OrderProcessStatus',
    processStatusRelations: 'OrderProcessStatus',
    carrier: 'Carrier',
    packages: 'Package',
    boxes: 'String',
    isPaid: 'Boolean',
    currency: 'Currency',
    files: 'File',
    quantityUnavailable: 'Int',
    createdBy: 'User',
    created: 'DateTime',
    updated: 'DateTime',
    movePacks: 'ProductMovePack',
    depots: 'Depot',
    isEditable: 'Boolean',
    externalId: 'String',
    externalReference: 'String',
    group: 'OrderGroup',
    groupPosition: 'Int',
    clearanceItems: 'ClearanceItem',
    tags: 'Tag',
    priority: 'Int',
    locked: 'Boolean',
    dateLocked: 'DateTimeAsString',
    lockedBy: 'User',
  },
  OrderStatus: {
    id: 'String',
    name: 'String',
  },
  DateTime: `scalar.DateTime` as const,
  OrderItem: {
    id: 'Int',
    name: 'String',
    type: 'String',
    ean: 'String',
    code: 'String',
    quantity: 'Int',
    sale: 'Float',
    price: 'Float',
    priceWithoutVat: 'Float',
    priceAll: 'Float',
    priceAllWithoutVat: 'Float',
    vat: 'Vat',
    product: 'Product',
    serial: 'String',
    expirationDate: 'DateTime',
    batch: 'String',
    note: 'String',
    profit: 'Float',
    isForSubsequentSettlement: 'Boolean',
    picked: 'Boolean',
    packed: 'Boolean',
    quantityUnavailable: 'Int',
    moves: 'ProductMove',
    clearanceItems: 'ClearanceItem',
    voucher: 'Voucher',
    order: 'Order',
    externalId: 'String',
  },
  ProductMove: {
    id: 'Int',
    pack: 'ProductMovePack',
    productDepotFrom: 'ProductDepot',
    productDepotFromQuantityStock: 'Int',
    productDepotTo: 'ProductDepot',
    productDepotToQuantityStock: 'Int',
    product: 'Product',
    amount: 'Float',
    note: 'String',
    created: 'DateTime',
    updated: 'DateTime',
    deleted: 'DateTime',
    quantityUnavailable: 'Float',
    createdBy: 'User',
    orderItem: 'OrderItem',
    picked: 'Int',
    packed: 'Int',
    locked: 'Boolean',
    purchasePrice: 'Float',
    purchasePriceAll: 'Float',
    purchasePriceWithVat: 'Float',
    purchasePriceAllWithVat: 'Float',
    purchaseOrderItem: 'PurchaseOrderItem',
    deletable: 'Boolean',
    serialNumbers: 'String',
  },
  ProductMovePack: {
    id: 'Int',
    externalId: 'String',
    externalReference: 'String',
    number: 'Int',
    type: 'ProductMoveType',
    purpose: 'ProductMovePurpose',
    moves: 'ProductMove',
    note: 'String',
    url: 'String',
    dateCreated: 'DateTime',
    order: 'Order',
    checkout: 'Checkout',
    depotFrom: 'Depot',
    depotTo: 'Depot',
    purchasePrice: 'Float',
    purchasePriceWithVat: 'Float',
    purchaseOrder: 'PurchaseOrder',
    createdBy: 'User',
    files: 'File',
    company: 'Company',
    deletable: 'Boolean',
  },
  ProductMoveType: {
    id: 'String',
    name: 'String',
  },
  ProductMovePurpose: {
    id: 'String',
    name: 'String',
  },
  PurchaseOrder: {
    id: 'Int',
    company: 'Company',
    status: 'PurchaseOrderStatus',
    supplier: 'Supplier',
    depot: 'Depot',
    items: 'PurchaseOrderItem',
    files: 'File',
    tags: 'Tag',
    number: 'String',
    note: 'String',
    privateNote: 'String',
    dateEstimatedArrival: 'DateTime',
    externalId: 'String',
    externalReference: 'String',
    supplierReference: 'String',
    purchasePriceAll: 'Float',
    productMovePacks: 'ProductMovePack',
    createdBy: 'User',
    created: 'DateTime',
    updated: 'DateTime',
  },
  PurchaseOrderStatus: {
    id: 'String',
    name: 'String',
  },
  PurchaseOrderItem: {
    id: 'Int',
    purchaseOrder: 'PurchaseOrder',
    currency: 'Currency',
    product: 'Product',
    quantity: 'Int',
    purchasePrice: 'Float',
    purchasePriceAll: 'Float',
    productMoves: 'ProductMove',
    note: 'String',
    externalId: 'String',
    quantityIn: 'Float',
  },
  Tag: {
    id: 'Int',
    name: 'String',
    type: 'String',
    color: 'String',
    externalId: 'String',
    company: 'Company',
    created: 'DateTimeAsString',
    updated: 'DateTimeAsString',
  },
  ClearanceItem: {
    id: 'String',
    product: 'Product',
    amount: 'Int',
    quantityStock: 'Int',
    quantityReservation: 'Int',
    quantityAvailable: 'Int',
    expirationDate: 'DateTime',
    batch: 'String',
    position: 'String',
    position1: 'String',
    position2: 'String',
    position3: 'String',
    picked: 'Int',
    packed: 'Int',
    productMoves: 'ProductMove',
    productDepots: 'ProductDepot',
    orderItems: 'OrderItem',
    locked: 'Boolean',
  },
  Voucher: {
    id: 'Int',
    company: 'Company',
    product: 'Product',
    name: 'String',
    code: 'String',
    externalId: 'String',
    discountType: 'String',
    discountPercent: 'Float',
    discountValue: 'Float',
    maxUse: 'Int',
    validFrom: 'DateTimeAsString',
    validTo: 'DateTimeAsString',
    isValid: 'Boolean',
    used: 'Int',
    paymentItems: 'Product',
    orderItems: 'Product',
    enabled: 'Boolean',
    isPayment: 'Boolean',
  },
  PaymentItem: {
    id: 'Int',
    amount: 'Float',
    checkout: 'Checkout',
    payment: 'Payment',
    voucher: 'Voucher',
    dateCreated: 'DateTime',
    dateCancelled: 'DateTime',
    isPaid: 'Boolean',
    datePaid: 'DateTime',
    externalId: 'String',
  },
  CheckoutClosing: {
    id: 'Int',
    company: 'Company',
    checkout: 'Checkout',
    dateOpen: 'DateTime',
    amountOpen: 'Float',
    amountRealOpen: 'Float',
    userOpen: 'User',
    noteOpen: 'String',
    dateClosed: 'DateTime',
    amountClosed: 'Float',
    amountRealClosed: 'Float',
    userClosed: 'User',
    noteClosed: 'String',
    orders: 'Order',
    profit: 'Float',
    vatAllocations: 'VatAllocation',
    extraOperations: 'CheckoutExtraOperation',
    billUrl: 'String',
    enumeration: 'String',
    paymentSum: 'paymentSum',
  },
  VatAllocation: {
    vat: 'Vat',
    priceWithoutVat: 'Float',
    vatPrice: 'Float',
    price: 'Float',
  },
  CheckoutExtraOperation: {
    id: 'Int',
    dateCreated: 'DateTime',
    amount: 'Float',
    note: 'String',
    user: 'User',
    checkout: 'Checkout',
    checkoutClosing: 'CheckoutClosing',
  },
  paymentSum: {
    payment: 'Payment',
    count: 'Int',
    sum: 'Int',
  },
  Customer: {
    id: 'Int',
    externalId: 'String',
    email: 'String',
    phone: 'String',
    name: 'String',
    firstName: 'String',
    lastName: 'String',
    companyName: 'String',
    addresses: 'Address',
    wholesale: 'Boolean',
    note: 'String',
    consentRelations: 'CustomerConsent',
    birthday: 'DateTime',
    users: 'User',
    minExpirationDays: 'Int',
    priceLevel: 'PriceLevel',
    tags: 'Tag',
    customData: 'String',
  },
  Address: {
    id: 'Int',
    company: 'Company',
    customer: 'Customer',
    companyName: 'String',
    firstName: 'String',
    lastName: 'String',
    street: 'String',
    city: 'String',
    country: 'String',
    state: 'String',
    zip: 'String',
    ic: 'String',
    dic: 'String',
    phone: 'String',
    email: 'String',
    isStored: 'Boolean',
    isBilling: 'Boolean',
    zasilkovnaAddressId: 'String',
    branchId: 'String',
    externalId: 'String',
    note: 'String',
    customData: 'String',
  },
  CustomerConsent: {
    id: 'Int',
    customer: 'Customer',
    consent: 'Consent',
    name: 'String',
    body: 'String',
    externalId: 'String',
    created: 'DateTime',
    updated: 'DateTime',
  },
  Consent: {
    id: 'Int',
    name: 'String',
    body: 'String',
    externalId: 'String',
    created: 'DateTime',
    updated: 'DateTime',
    company: 'Company',
  },
  PriceLevel: {
    id: 'Int',
    name: 'String',
    isPercentage: 'Boolean',
    percent: 'Float',
    withVat: 'Boolean',
    currency: 'Currency',
    externalId: 'String',
    company: 'Company',
  },
  Receipt: {
    id: 'Int',
    dic: 'String',
    checkoutEetId: 'Int',
    shopEetId: 'Int',
    playground: 'Boolean',
    verificationMode: 'Boolean',
    number: 'Int',
    dateCreated: 'DateTime',
    totalPrice: 'Int',
    priceZeroVat: 'Int',
    priceStandardVat: 'Int',
    vatStandard: 'Int',
    priceFirstReducedVat: 'Int',
    vatFirstReduced: 'Int',
    priceSecondReducedVat: 'Int',
    vatSecondReduced: 'Int',
    priceForSubsequentSettlement: 'Int',
    priceUsedSubsequentSettlement: 'Int',
    fik: 'String',
    bkp: 'String',
    pkp: 'String',
  },
  ProcessStatus: {
    id: 'String',
    name: 'String',
    position: 'Int',
  },
  OrderProcessStatus: {
    status: 'ProcessStatus',
    note: 'String',
    created: 'DateTime',
    createdBy: 'User',
    actual: 'Boolean',
  },
  Package: {
    id: 'Int',
    code: 'String',
    carrier: 'Carrier',
    tariff: 'CarrierTariff',
    items: 'OrderItem',
    statuses: 'PackageStatus',
    ticketUrl: 'String',
    sent: 'DateTime',
    order: 'Order',
    disposal: 'PackageDisposal',
    weight: 'Float',
    weightRequired: 'Boolean',
    dimensionsRequired: 'Boolean',
    dimensionX: 'Float',
    dimensionY: 'Float',
    dimensionZ: 'Float',
    company: 'Company',
    clearanceItems: 'ClearanceItem',
  },
  PackageStatus: {
    id: 'Int',
    code: 'String',
    text: 'String',
    package: 'Package',
    created: 'DateTime',
  },
  PackageDisposal: {
    id: 'Int',
    code: 'String',
    company: 'Company',
    carrier: 'Carrier',
    packages: 'Package',
    ticketUrl: 'String',
    sent: 'DateTime',
    files: 'File',
  },
  OrderGroup: {
    id: 'Int',
    name: 'String',
    company: 'Company',
    orders: 'Order',
    user: 'User',
    userPosition: 'Int',
    clearanceItems: 'ClearanceItem',
  },
  FileThumbnail: {
    format: 'String',
    mimeType: 'String',
    url: 'String',
  },
  ProductBundle: {
    id: 'Int',
    parent: 'Product',
    child: 'Product',
    quantity: 'Int',
  },
  ProductPrice: {
    id: 'Int',
    product: 'Product',
    sellPrice: 'Float',
    available: 'Int',
    dateFrom: 'DateTime',
    dateTo: 'DateTime',
    isActual: 'Boolean',
    note: 'String',
  },
  Category: {
    id: 'Int',
    externalId: 'String',
    name: 'String',
    text: 'String',
    parent: 'Category',
    children: 'Category',
    hasChildren: 'Boolean',
    position: 'Int',
    company: 'Company',
    treePath: 'Int',
    tags: 'Tag',
  },
  Quantity: {
    depot: 'Depot',
    quantityAvailable: 'Float',
    quantityReservation: 'Float',
    quantityStock: 'Float',
    quantityPurchase: 'Float',
  },
  ProductDepotMetric: {
    id: 'Int',
    depot: 'Depot',
    product: 'Product',
    quantityMinimum: 'Int',
    quantityOptimum: 'Int',
    quantityMaximum: 'Int',
    quantityStock: 'Int',
    quantityPurchase: 'Int',
    created: 'DateTime',
    updated: 'DateTime',
  },
  ProductPack: {
    id: 'Int',
    ean: 'String',
    code: 'String',
    note: 'String',
    quantity: 'Int',
    product: 'Product',
    externalId: 'String',
  },
  ProductParameter: {
    id: 'Int',
    value: 'StringOrArray',
    externalId: 'String',
    product: 'Product',
    parameter: 'Parameter',
  },
  StringOrArray: `scalar.StringOrArray` as const,
  Parameter: {
    id: 'Int',
    name: 'String',
    type: 'String',
    enumValues: 'String',
    unit: 'String',
    text: 'String',
    externalId: 'String',
    company: 'Company',
  },
  ProductPriceLevel: {
    id: 'Int',
    sellPrice: 'Float',
    externalId: 'String',
    product: 'Product',
    priceLevel: 'PriceLevel',
  },
  SellItem: {
    id: 'String',
    product: 'Product',
    quantityStock: 'Int',
    quantityReservation: 'Int',
    quantityAvailable: 'Int',
    expirationDate: 'DateTime',
    batch: 'String',
    productDepots: 'ProductDepot',
  },
  CompanyModule: {
    id: 'Int',
    module: 'Module',
  },
  Module: {
    id: 'String',
    name: 'String',
  },
  userMyProfile: {
    data: 'User',
    errors: 'String',
  },
  users: {
    items: 'User',
    paginator: 'Paginator',
  },
  Paginator: {
    current: 'Int',
    currentItemCount: 'Int',
    endPage: 'Int',
    first: 'Int',
    firstItemNumber: 'Int',
    firstPageInRange: 'Int',
    last: 'Int',
    lastItemNumber: 'Int',
    lastPageInRange: 'Int',
    next: 'Int',
    numItemsPerPage: 'Int',
    pageCount: 'Int',
    pageRange: 'Int',
    startPage: 'Int',
    totalCount: 'Int',
    pagesInRange: 'Int',
  },
  isUserEmailUnique: {
    data: 'Boolean',
    errors: 'String',
  },
  userGroup: {
    data: 'UserGroup',
    errors: 'String',
  },
  userGroups: {
    items: 'UserGroup',
    paginator: 'Paginator',
  },
  Role: {
    name: 'String',
    role: 'String',
  },
  product: {
    data: 'Product',
    errors: 'String',
  },
  products: {
    items: 'Product',
    paginator: 'Paginator',
  },
  productPurchasePrice: {
    items: 'ProductPurchasePrice',
    errors: 'String',
  },
  ProductPurchasePrice: {
    productDepot: 'Depot',
    quantity: 'Int',
    purchasePrice: 'Float',
  },
  productSellItems: {
    items: 'SellItem',
  },
  productMovePacks: {
    items: 'ProductMovePack',
    paginator: 'Paginator',
  },
  productMoves: {
    items: 'ProductMove',
    paginator: 'Paginator',
  },
  productMovePack: {
    data: 'ProductMovePack',
    errors: 'String',
  },
  institution: {
    data: 'Institution',
    errors: 'String',
  },
  institutions: {
    items: 'Institution',
    paginator: 'Paginator',
  },
  order: {
    data: 'Order',
    errors: 'String',
  },
  orders: {
    items: 'Order',
    paginator: 'Paginator',
  },
  soldItems: {
    items: 'SoldItem',
    paginator: 'Paginator',
  },
  SoldItem: {
    name: 'String',
    type: 'String',
    ean: 'String',
    code: 'String',
    quantity: 'Int',
    priceAll: 'Float',
    priceAllWithoutVat: 'Float',
    product: 'Product',
    profit: 'Float',
  },
  orderItems: {
    items: 'OrderItem',
    paginator: 'Paginator',
  },
  orderItem: {
    data: 'OrderItem',
    errors: 'String',
  },
  customer: {
    data: 'Customer',
    errors: 'String',
  },
  customers: {
    items: 'Customer',
    paginator: 'Paginator',
  },
  address: {
    data: 'Address',
    errors: 'String',
  },
  addresses: {
    items: 'Address',
    paginator: 'Paginator',
  },
  checkout: {
    data: 'Checkout',
    errors: 'String',
  },
  checkouts: {
    items: 'Checkout',
    paginator: 'Paginator',
  },
  eventTypes: {
    items: 'String',
  },
  consent: {
    data: 'Consent',
    errors: 'String',
  },
  consents: {
    items: 'Consent',
    paginator: 'Paginator',
  },
  checkoutExtraOperation: {
    data: 'CheckoutExtraOperation',
    errors: 'String',
  },
  checkoutExtraOperations: {
    items: 'CheckoutExtraOperation',
    paginator: 'Paginator',
  },
  checkoutClosing: {
    data: 'CheckoutClosing',
    errors: 'String',
  },
  checkoutClosings: {
    items: 'CheckoutClosing',
    paginator: 'Paginator',
  },
  vat: {
    data: 'Vat',
    errors: 'String',
  },
  vats: {
    items: 'Vat',
    paginator: 'Paginator',
  },
  depot: {
    data: 'Depot',
    errors: 'String',
  },
  depots: {
    items: 'Depot',
    paginator: 'Paginator',
  },
  supplier: {
    data: 'Supplier',
    errors: 'String',
  },
  suppliers: {
    items: 'Supplier',
    paginator: 'Paginator',
  },
  producer: {
    data: 'Producer',
    errors: 'String',
  },
  producers: {
    items: 'Producer',
    paginator: 'Paginator',
  },
  exportStocks: {
    items: 'Stock',
    paginator: 'Paginator',
  },
  Stock: {
    id: 'Int',
    date: 'DateTime',
    generated: 'DateTime',
    created: 'DateTime',
    depots: 'Depot',
    suppliers: 'Supplier',
    producers: 'Producer',
    url: 'String',
    format: 'String',
  },
  exportSales: {
    items: 'Sale',
    paginator: 'Paginator',
  },
  Sale: {
    id: 'Int',
    dateFrom: 'DateTime',
    dateTo: 'DateTime',
    generated: 'DateTime',
    created: 'DateTime',
    checkouts: 'Checkout',
    suppliers: 'Supplier',
    producers: 'Producer',
    url: 'String',
    format: 'String',
  },
  exportPurchases: {
    items: 'Purchase',
    paginator: 'Paginator',
  },
  Purchase: {
    id: 'Int',
    dateFrom: 'DateTime',
    dateTo: 'DateTime',
    generated: 'DateTime',
    created: 'DateTime',
    depots: 'Depot',
    checkouts: 'Checkout',
    suppliers: 'Supplier',
    producers: 'Producer',
    url: 'String',
    format: 'String',
  },
  exportOrders: {
    items: 'exportOrder',
    paginator: 'Paginator',
  },
  exportOrder: {
    id: 'Int',
    dateFrom: 'DateTime',
    dateTo: 'DateTime',
    generated: 'DateTime',
    created: 'DateTime',
    status: 'OrderStatus',
    checkouts: 'Checkout',
    url: 'String',
    format: 'String',
  },
  exportInventory: {
    data: 'Inventory',
    errors: 'String',
  },
  Inventory: {
    id: 'Int',
    date: 'DateTime',
    generated: 'DateTime',
    created: 'DateTime',
    depots: 'Depot',
    suppliers: 'Supplier',
    producers: 'Producer',
    url: 'String',
    files: 'File',
    approved: 'DateTime',
    rejected: 'DateTime',
    finished: 'DateTime',
    pmpIn: 'ProductMovePack',
    pmpOut: 'ProductMovePack',
  },
  exportInventories: {
    items: 'Inventory',
    paginator: 'Paginator',
  },
  export: {
    data: 'Export',
    errors: 'String',
  },
  Export: {
    id: 'Int',
    file: 'File',
    generated: 'DateTime',
    created: 'DateTime',
    url: 'String',
    type: 'ExportType',
    filter: 'String',
    createdBy: 'User',
    note: 'String',
  },
  ExportType: {
    id: 'String',
    name: 'String',
    format: 'String',
    group: 'String',
  },
  exports: {
    items: 'Export',
    paginator: 'Paginator',
  },
  exportTypes: {
    items: 'ExportType',
    paginator: 'Paginator',
  },
  shop: {
    data: 'Shop',
    errors: 'String',
  },
  Shop: {
    id: 'Int',
    name: 'String',
    street: 'String',
    city: 'String',
    country: 'String',
    zip: 'String',
    eetId: 'Int',
    cert: 'Cert',
    checkouts: 'Checkout',
    company: 'Company',
  },
  Cert: {
    id: 'Int',
    name: 'String',
    publicKey: 'String',
    expirationDate: 'DateTime',
    company: 'Company',
  },
  shops: {
    items: 'Shop',
    paginator: 'Paginator',
  },
  cert: {
    data: 'Cert',
    errors: 'String',
  },
  certs: {
    items: 'Cert',
    paginator: 'Paginator',
  },
  eetReceipts: {
    items: 'Receipt',
    paginator: 'Paginator',
  },
  payment: {
    data: 'Payment',
    errors: 'String',
  },
  payments: {
    items: 'Payment',
    paginator: 'Paginator',
  },
  paymentItem: {
    data: 'PaymentItem',
    errors: 'String',
  },
  paymentItems: {
    items: 'PaymentItem',
    paginator: 'Paginator',
  },
  paymentTypes: {
    items: 'PaymentType',
    paginator: 'Paginator',
  },
  processStatuses: {
    items: 'ProcessStatus',
    paginator: 'Paginator',
  },
  carriers: {
    items: 'Carrier',
    paginator: 'Paginator',
  },
  currencies: {
    items: 'Currency',
    paginator: 'Paginator',
  },
  package: {
    data: 'Package',
    errors: 'String',
  },
  packages: {
    items: 'Package',
    paginator: 'Paginator',
  },
  packageStats: {
    items: 'PackageStats',
    paginator: 'Paginator',
  },
  PackageStats: {
    company: 'Company',
    processStatus: 'ProcessStatus',
    packageAmount: 'Int',
    orderAmount: 'Int',
    country: 'String',
  },
  disposal: {
    data: 'PackageDisposal',
    errors: 'String',
  },
  disposals: {
    items: 'PackageDisposal',
    paginator: 'Paginator',
  },
  productDepotMetric: {
    data: 'ProductDepotMetric',
    errors: 'String',
  },
  productDepotMetrics: {
    items: 'ProductDepotMetric',
    paginator: 'Paginator',
  },
  file: {
    data: 'File',
    errors: 'String',
  },
  files: {
    items: 'File',
    paginator: 'Paginator',
  },
  nextEan: {
    data: 'String',
    errors: 'String',
  },
  statsCarrierUsage: {
    items: 'CarrierUsage',
  },
  CarrierUsage: {
    amount: 'Int',
    carrier: 'Carrier',
  },
  statsPaymentSales: {
    items: 'PaymentSales',
  },
  PaymentSales: {
    amount: 'Int',
    sales: 'Price',
    payment: 'Payment',
  },
  Price: {
    value: 'Float',
    currency: 'Currency',
  },
  statsBestsellers: {
    items: 'Bestsellers',
  },
  Bestsellers: {
    amount: 'Int',
    sales: 'Price',
    product: 'Product',
  },
  companyCarrier: {
    data: 'CompanyCarrier',
    errors: 'String',
  },
  companyCarriers: {
    items: 'CompanyCarrier',
    paginator: 'Paginator',
  },
  category: {
    data: 'Category',
    errors: 'String',
  },
  categories: {
    items: 'Category',
    paginator: 'Paginator',
  },
  parameter: {
    data: 'Parameter',
    errors: 'String',
  },
  parameters: {
    items: 'Parameter',
    paginator: 'Paginator',
  },
  orderGroup: {
    data: 'OrderGroup',
    errors: 'String',
  },
  orderGroups: {
    items: 'OrderGroup',
    paginator: 'Paginator',
  },
  voucher: {
    data: 'Voucher',
    errors: 'String',
  },
  vouchers: {
    items: 'Voucher',
    paginator: 'Paginator',
  },
  productPrices: {
    items: 'ProductPrice',
    paginator: 'Paginator',
  },
  priceLevel: {
    data: 'PriceLevel',
    errors: 'String',
  },
  priceLevels: {
    items: 'PriceLevel',
    paginator: 'Paginator',
  },
  productParameter: {
    data: 'ProductParameter',
    errors: 'String',
  },
  productParameters: {
    items: 'ProductParameter',
    paginator: 'Paginator',
  },
  productPriceLevel: {
    data: 'ProductPriceLevel',
    errors: 'String',
  },
  productPriceLevels: {
    items: 'ProductPriceLevel',
    paginator: 'Paginator',
  },
  tag: {
    data: 'Tag',
    errors: 'String',
  },
  tags: {
    items: 'Tag',
    paginator: 'Paginator',
  },
  productPack: {
    data: 'ProductPack',
    errors: 'String',
  },
  productPacks: {
    items: 'ProductPack',
    paginator: 'Paginator',
  },
  purchaseOrder: {
    data: 'PurchaseOrder',
    errors: 'String',
  },
  purchaseOrders: {
    items: 'PurchaseOrder',
    paginator: 'Paginator',
  },
  purchaseOrderItem: {
    data: 'PurchaseOrderItem',
    errors: 'String',
  },
  modules: {
    items: 'Module',
    paginator: 'Paginator',
  },
  RootSchemaMutation: {
    createProduct: 'createProduct',
    updateProduct: 'updateProduct',
    deleteProduct: 'deleteProduct',
    refreshProductDepots: 'refreshProductDepots',
    createProductMovePack: 'createProductMovePack',
    updateProductMovePack: 'updateProductMovePack',
    deleteProductMovePack: 'deleteProductMovePack',
    updateProductMove: 'updateProductMove',
    deleteProductMove: 'deleteProductMove',
    createProductPrice: 'createProductPrice',
    updateProductPrice: 'updateProductPrice',
    deleteProductPrice: 'deleteProductPrice',
    createProductBundle: 'createProductBundle',
    updateProductBundle: 'updateProductBundle',
    deleteProductBundle: 'deleteProductBundle',
    createProductDepotMetric: 'createProductDepotMetric',
    updateProductDepotMetric: 'updateProductDepotMetric',
    deleteProductDepotMetric: 'deleteProductDepotMetric',
    createInstitution: 'createInstitution',
    updateInstitution: 'updateInstitution',
    deleteInstitution: 'deleteInstitution',
    createOrder: 'createOrder',
    updateOrder: 'updateOrder',
    deleteReservation: 'deleteReservation',
    createOrderItem: 'createOrderItem',
    updateOrderItem: 'updateOrderItem',
    deleteOrderItem: 'deleteOrderItem',
    updateClearanceItem: 'updateClearanceItem',
    createUser: 'createUser',
    updateUser: 'updateUser',
    deleteUser: 'deleteUser',
    createUserGroup: 'createUserGroup',
    updateUserGroup: 'updateUserGroup',
    deleteUserGroup: 'deleteUserGroup',
    createCustomer: 'createCustomer',
    updateCustomer: 'updateCustomer',
    deleteCustomer: 'deleteCustomer',
    createAddress: 'createAddress',
    updateAddress: 'updateAddress',
    deleteAddress: 'deleteAddress',
    createCheckout: 'createCheckout',
    updateCheckout: 'updateCheckout',
    deleteCheckout: 'deleteCheckout',
    createCheckoutExtraOperation: 'createCheckoutExtraOperation',
    updateCheckoutExtraOperation: 'updateCheckoutExtraOperation',
    deleteCheckoutExtraOperation: 'deleteCheckoutExtraOperation',
    createCheckoutClosing: 'createCheckoutClosing',
    updateCheckoutClosing: 'updateCheckoutClosing',
    deleteCheckoutClosing: 'deleteCheckoutClosing',
    createVat: 'createVat',
    updateVat: 'updateVat',
    deleteVat: 'deleteVat',
    createDepot: 'createDepot',
    updateDepot: 'updateDepot',
    deleteDepot: 'deleteDepot',
    createSupplier: 'createSupplier',
    updateSupplier: 'updateSupplier',
    deleteSupplier: 'deleteSupplier',
    createProducer: 'createProducer',
    updateProducer: 'updateProducer',
    deleteProducer: 'deleteProducer',
    createExportStock: 'createExportStock',
    createExportSale: 'createExportSale',
    createExportPurchase: 'createExportPurchase',
    createExportOrder: 'createExportOrder',
    createExportInventory: 'createExportInventory',
    updateExportInventory: 'updateExportInventory',
    refreshExportInventory: 'refreshExportInventory',
    createExport: 'createExport',
    updateCompany: 'updateCompany',
    createShop: 'createShop',
    updateShop: 'updateShop',
    deleteShop: 'deleteShop',
    createCert: 'createCert',
    updateCert: 'updateCert',
    deleteCert: 'deleteCert',
    createEetReceipt: 'createEetReceipt',
    sendEetReceipt: 'sendEetReceipt',
    createPayment: 'createPayment',
    updatePayment: 'updatePayment',
    deletePayment: 'deletePayment',
    createPaymentItem: 'createPaymentItem',
    updatePaymentItem: 'updatePaymentItem',
    deletePaymentItem: 'deletePaymentItem',
    createDevLog: 'createDevLog',
    createPackage: 'createPackage',
    updatePackage: 'updatePackage',
    deletePackage: 'deletePackage',
    resetPackage: 'resetPackage',
    sendPackage: 'sendPackage',
    createDisposal: 'createDisposal',
    updateDisposal: 'updateDisposal',
    deleteDisposal: 'deleteDisposal',
    sendDisposal: 'sendDisposal',
    createFile: 'createFile',
    updateFile: 'updateFile',
    deleteFile: 'deleteFile',
    createCompanyCarrier: 'createCompanyCarrier',
    updateCompanyCarrier: 'updateCompanyCarrier',
    deleteCompanyCarrier: 'deleteCompanyCarrier',
    createConsent: 'createConsent',
    updateConsent: 'updateConsent',
    deleteConsent: 'deleteConsent',
    createCustomerConsent: 'createCustomerConsent',
    deleteCustomerConsent: 'deleteCustomerConsent',
    updateProductDepot: 'updateProductDepot',
    createCategory: 'createCategory',
    updateCategory: 'updateCategory',
    deleteCategory: 'deleteCategory',
    createParameter: 'createParameter',
    updateParameter: 'updateParameter',
    deleteParameter: 'deleteParameter',
    createOrderGroup: 'createOrderGroup',
    updateOrderGroup: 'updateOrderGroup',
    deleteOrderGroup: 'deleteOrderGroup',
    createVoucher: 'createVoucher',
    updateVoucher: 'updateVoucher',
    deleteVoucher: 'deleteVoucher',
    createPriceLevel: 'createPricePriceLevel',
    updatePriceLevel: 'updatePriceLevel',
    deletePriceLevel: 'deletePriceLevel',
    createProductParameter: 'createProductParameter',
    updateProductParameter: 'updateProductParameter',
    deleteProductParameter: 'deleteProductParameter',
    createProductPriceLevel: 'createProductPriceLevel',
    updateProductPriceLevel: 'updateProductPriceLevel',
    deleteProductPriceLevel: 'deleteProductPriceLevel',
    createTag: 'createTag',
    updateTag: 'updateTag',
    deleteTag: 'deleteTag',
    createProductPack: 'createProductPack',
    updateProductPack: 'updateProductPack',
    deleteProductPack: 'deleteProductPack',
    createPurchaseOrder: 'createPurchaseOrder',
    updatePurchaseOrder: 'updatePurchaseOrder',
    deletePurchaseOrder: 'deletePurchaseOrder',
    createPurchaseOrderItem: 'createPurchaseOrderItem',
    updatePurchaseOrderItem: 'updatePurchaseOrderItem',
    deletePurchaseOrderItem: 'deletePurchaseOrderItem',
  },
  createProduct: {
    data: 'Product',
    errors: 'String',
  },
  updateProduct: {
    data: 'Product',
    errors: 'String',
  },
  deleteProduct: {
    errors: 'String',
  },
  refreshProductDepots: {
    errors: 'String',
  },
  createProductMovePack: {
    data: 'ProductMovePack',
    errors: 'String',
  },
  updateProductMovePack: {
    data: 'ProductMovePack',
    errors: 'String',
  },
  deleteProductMovePack: {
    errors: 'String',
  },
  updateProductMove: {
    data: 'ProductMove',
    errors: 'String',
  },
  deleteProductMove: {
    errors: 'String',
  },
  createProductPrice: {
    data: 'ProductPrice',
    errors: 'String',
  },
  updateProductPrice: {
    data: 'ProductPrice',
    errors: 'String',
  },
  deleteProductPrice: {
    errors: 'String',
  },
  createProductBundle: {
    data: 'ProductBundle',
    errors: 'String',
  },
  updateProductBundle: {
    data: 'ProductBundle',
    errors: 'String',
  },
  deleteProductBundle: {
    errors: 'String',
  },
  createProductDepotMetric: {
    data: 'ProductDepotMetric',
    errors: 'String',
  },
  updateProductDepotMetric: {
    data: 'ProductDepotMetric',
    errors: 'String',
  },
  deleteProductDepotMetric: {
    errors: 'String',
  },
  createInstitution: {
    data: 'Institution',
    errors: 'String',
  },
  updateInstitution: {
    data: 'Institution',
    errors: 'String',
  },
  deleteInstitution: {
    errors: 'String',
  },
  createOrder: {
    data: 'Order',
    errors: 'String',
  },
  updateOrder: {
    data: 'Order',
    errors: 'String',
  },
  deleteReservation: {
    errors: 'String',
  },
  createOrderItem: {
    data: 'OrderItem',
    errors: 'String',
  },
  updateOrderItem: {
    data: 'OrderItem',
    errors: 'String',
  },
  deleteOrderItem: {
    errors: 'String',
  },
  updateClearanceItem: {
    data: 'ClearanceItem',
    errors: 'String',
  },
  createUser: {
    data: 'User',
    errors: 'String',
  },
  updateUser: {
    data: 'User',
    errors: 'String',
  },
  deleteUser: {
    errors: 'String',
  },
  createUserGroup: {
    data: 'UserGroup',
    errors: 'String',
  },
  updateUserGroup: {
    data: 'UserGroup',
    errors: 'String',
  },
  deleteUserGroup: {
    errors: 'String',
  },
  createCustomer: {
    data: 'Customer',
    errors: 'String',
  },
  updateCustomer: {
    data: 'Customer',
    errors: 'String',
  },
  deleteCustomer: {
    errors: 'String',
  },
  createAddress: {
    data: 'Address',
    errors: 'String',
  },
  updateAddress: {
    data: 'Address',
    errors: 'String',
  },
  deleteAddress: {
    errors: 'String',
  },
  createCheckout: {
    data: 'Checkout',
    errors: 'String',
  },
  updateCheckout: {
    data: 'Checkout',
    errors: 'String',
  },
  deleteCheckout: {
    errors: 'String',
  },
  createCheckoutExtraOperation: {
    data: 'CheckoutExtraOperation',
    errors: 'String',
  },
  updateCheckoutExtraOperation: {
    data: 'CheckoutExtraOperation',
    errors: 'String',
  },
  deleteCheckoutExtraOperation: {
    errors: 'String',
  },
  createCheckoutClosing: {
    data: 'CheckoutClosing',
    errors: 'String',
  },
  updateCheckoutClosing: {
    data: 'CheckoutClosing',
    errors: 'String',
  },
  deleteCheckoutClosing: {
    errors: 'String',
  },
  createVat: {
    data: 'Vat',
    errors: 'String',
  },
  updateVat: {
    data: 'Vat',
    errors: 'String',
  },
  deleteVat: {
    errors: 'String',
  },
  createDepot: {
    data: 'Depot',
    errors: 'String',
  },
  updateDepot: {
    data: 'Depot',
    errors: 'String',
  },
  deleteDepot: {
    errors: 'String',
  },
  createSupplier: {
    data: 'Supplier',
    errors: 'String',
  },
  updateSupplier: {
    data: 'Supplier',
    errors: 'String',
  },
  deleteSupplier: {
    errors: 'String',
  },
  createProducer: {
    data: 'Producer',
    errors: 'String',
  },
  updateProducer: {
    data: 'Producer',
    errors: 'String',
  },
  deleteProducer: {
    errors: 'String',
  },
  createExportStock: {
    data: 'Stock',
    errors: 'String',
  },
  createExportSale: {
    data: 'Sale',
    errors: 'String',
  },
  createExportPurchase: {
    data: 'Purchase',
    errors: 'String',
  },
  createExportOrder: {
    data: 'exportOrder',
    errors: 'String',
  },
  createExportInventory: {
    data: 'Inventory',
    errors: 'String',
  },
  updateExportInventory: {
    data: 'Inventory',
    errors: 'String',
  },
  refreshExportInventory: {
    data: 'Inventory',
    errors: 'String',
  },
  createExport: {
    data: 'Export',
    errors: 'String',
  },
  updateCompany: {
    data: 'Company',
    errors: 'String',
  },
  createShop: {
    data: 'Shop',
    errors: 'String',
  },
  updateShop: {
    data: 'Shop',
    errors: 'String',
  },
  deleteShop: {
    errors: 'String',
  },
  createCert: {
    data: 'Cert',
    errors: 'String',
  },
  updateCert: {
    data: 'Depot',
    errors: 'String',
  },
  deleteCert: {
    errors: 'String',
  },
  createEetReceipt: {
    data: 'Receipt',
    errors: 'String',
  },
  sendEetReceipt: {
    data: 'Receipt',
    errors: 'String',
  },
  createPayment: {
    data: 'Payment',
    errors: 'String',
  },
  updatePayment: {
    data: 'Payment',
    errors: 'String',
  },
  deletePayment: {
    errors: 'String',
  },
  createPaymentItem: {
    data: 'PaymentItem',
    errors: 'String',
  },
  updatePaymentItem: {
    data: 'PaymentItem',
    errors: 'String',
  },
  deletePaymentItem: {
    errors: 'String',
  },
  createDevLog: {
    data: 'DevLog',
    errors: 'String',
  },
  DevLog: {
    id: 'Int',
    name: 'String',
    content: 'String',
    created: 'DateTime',
  },
  createPackage: {
    data: 'Package',
    errors: 'String',
  },
  updatePackage: {
    data: 'Package',
    errors: 'String',
  },
  deletePackage: {
    errors: 'String',
  },
  resetPackage: {
    errors: 'String',
  },
  sendPackage: {
    data: 'Package',
    errors: 'String',
  },
  createDisposal: {
    data: 'PackageDisposal',
    errors: 'String',
  },
  updateDisposal: {
    data: 'PackageDisposal',
    errors: 'String',
  },
  deleteDisposal: {
    errors: 'String',
  },
  sendDisposal: {
    data: 'PackageDisposal',
    errors: 'String',
  },
  createFile: {
    data: 'File',
    errors: 'String',
  },
  updateFile: {
    data: 'File',
    errors: 'String',
  },
  deleteFile: {
    errors: 'String',
  },
  createCompanyCarrier: {
    data: 'CompanyCarrier',
    errors: 'String',
  },
  updateCompanyCarrier: {
    data: 'CompanyCarrier',
    errors: 'String',
  },
  deleteCompanyCarrier: {
    errors: 'String',
  },
  createConsent: {
    data: 'Consent',
    errors: 'String',
  },
  updateConsent: {
    data: 'Consent',
    errors: 'String',
  },
  deleteConsent: {
    errors: 'String',
  },
  createCustomerConsent: {
    data: 'CustomerConsent',
    errors: 'String',
  },
  deleteCustomerConsent: {
    errors: 'String',
  },
  updateProductDepot: {
    data: 'ProductDepot',
    errors: 'String',
  },
  createCategory: {
    data: 'Category',
    errors: 'String',
  },
  updateCategory: {
    data: 'Category',
    errors: 'String',
  },
  deleteCategory: {
    errors: 'String',
  },
  createParameter: {
    data: 'Parameter',
    errors: 'String',
  },
  updateParameter: {
    data: 'Parameter',
    errors: 'String',
  },
  deleteParameter: {
    errors: 'String',
  },
  createOrderGroup: {
    data: 'OrderGroup',
    errors: 'String',
  },
  updateOrderGroup: {
    data: 'OrderGroup',
    errors: 'String',
  },
  deleteOrderGroup: {
    errors: 'String',
  },
  createVoucher: {
    data: 'Voucher',
    errors: 'String',
  },
  updateVoucher: {
    data: 'Voucher',
    errors: 'String',
  },
  deleteVoucher: {
    errors: 'String',
  },
  createPricePriceLevel: {
    data: 'PriceLevel',
    errors: 'String',
  },
  updatePriceLevel: {
    data: 'PriceLevel',
    errors: 'String',
  },
  deletePriceLevel: {
    errors: 'String',
  },
  createProductParameter: {
    data: 'ProductParameter',
    errors: 'String',
  },
  updateProductParameter: {
    data: 'ProductParameter',
    errors: 'String',
  },
  deleteProductParameter: {
    errors: 'String',
  },
  createProductPriceLevel: {
    data: 'ProductPriceLevel',
    errors: 'String',
  },
  updateProductPriceLevel: {
    data: 'ProductPriceLevel',
    errors: 'String',
  },
  deleteProductPriceLevel: {
    errors: 'String',
  },
  createTag: {
    data: 'Tag',
    errors: 'String',
  },
  updateTag: {
    data: 'Tag',
    errors: 'String',
  },
  deleteTag: {
    errors: 'String',
  },
  createProductPack: {
    data: 'ProductPack',
    errors: 'String',
  },
  updateProductPack: {
    data: 'ProductPack',
    errors: 'String',
  },
  deleteProductPack: {
    errors: 'String',
  },
  createPurchaseOrder: {
    data: 'PurchaseOrderItem',
    errors: 'String',
  },
  updatePurchaseOrder: {
    data: 'PurchaseOrder',
    errors: 'String',
  },
  deletePurchaseOrder: {
    errors: 'String',
  },
  createPurchaseOrderItem: {
    data: 'PurchaseOrderItem',
    errors: 'String',
  },
  updatePurchaseOrderItem: {
    data: 'PurchaseOrderItem',
    errors: 'String',
  },
  deletePurchaseOrderItem: {
    errors: 'String',
  },
}

export const Ops = {
  query: 'RootSchemaQuery' as const,
  mutation: 'RootSchemaMutation' as const,
}
