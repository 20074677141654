import React, { useEffect, useState } from 'react'
import { BrowserRouter as RouterModule, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { DepotoCore } from 'depoto-core'
import { Carrier, CompanyCarrier, Order } from 'depoto-core'
import { Schema, updateOrder, updatePackages } from './'
import {
  Dashboard,
  Dispatch,
  DispatchList,
  GroupClearanceItems,
  Groups,
  Login,
  Main,
  NotFound404,
  OrderDetail,
  PublicHome,
  Settings,
} from '../views'
import { Loader, Notification, OrdersHistory } from '../components'
import { useCore, useLogin } from '../hooks'
import { GeoConfig } from 'depoto-core'
import { Fn } from 'depoto-core'
// import { initNotifications } from './Firebase'

let lastLocation = ''

type Props = {
  core: DepotoCore
}

export const Router: React.FC<Props> = ({ core }) => {
  const [isReady, setIsReady] = useState(false)
  const {
    setCore,
    searchString,
    setSearchString,
    currentOrder,
    setCurrentOrder,
    // orderUpdates,
    // setOrderUpdates,
    // packagesUpdates,
    // setPackageUpdates,
    setCarriers,
    setCheckouts,
    setCountries,
    setCompany,
    setChildCompanies,
    childCompanyId,
    setCurrencies,
    setDepots,
    setPayments,
    setTags,
    setIsStrictDepotFilter,
  } = useCore()
  const { isLoggedIn, handleAuthInit } = useLogin()
  useEffect(() => {
    if (!!currentOrder && !window.location.pathname.includes('order')) {
      setTimeout(() => setCurrentOrder(undefined))
    }
  }, [currentOrder?.updatedAt])

  useEffect(() => {
    // console.log({isLoggedIn, core, tokjen: core.services.oauth.session?.token?.accessToken, ts: +new Date() - core.services.oauth.session?.token?.timestamp || 0})
    if (core) {
      // if (!isLoggedIn && core.services.oauth.session.token?.accessToken?.length && +new Date() - core.services.oauth.session.token?.timestamp < 24 * 60 * 60 * 1000) {
      //   handleAuthInit(core)
      // }
      setCore(core)
    }
    setIsReady(true)
    reloadTokensAndLists(
      core,
      isLoggedIn,
      setCarriers,
      setCheckouts,
      setCountries,
      setCompany,
      setChildCompanies,
      setCurrencies,
      setDepots,
      setPayments,
      setTags,
      setIsStrictDepotFilter,
      childCompanyId,
    ).then(() => setIsReady(true))
  }, [core, core?.services.oauth.session?.token?.timestamp, isLoggedIn, childCompanyId])

  if (!isReady) {
    return <Loader />
  }
  return (
    <RouterModule>
      <Notification />
      <Routes core={core} />
    </RouterModule>
  )
}

const Routes: React.FC<any> = ({ core }) => {
  const {
    currentOrder,
    setCurrentOrder,
    orderUpdates,
    setOrderUpdates,
    packagesUpdates,
    setPackageUpdates,
    setIsFetching,
  } = useCore()
  const { isLoggedIn } = useLogin()
  const location = useLocation()

  if (location?.pathname !== '/dashboard' && location?.search?.startsWith('?groupId')) {
    // hotfix get rid of groupId param outside of groups
    history.pushState(null, '', `${window.location.origin}${window.location.pathname}`)
  }

  const getOrder = async () => {
    setIsFetching(true)
    const res = await core?.services.order.getById(currentOrder?.id || 0, Schema.order.detail)
    if (res && res.id > 0) {
      const o = new Order(res)
      setIsFetching(false)
      setCurrentOrder(o)
    } else {
      setIsFetching(false)
    }
  }

  if (location.pathname !== lastLocation) {
    // console.log('loc changed', location)
    if (
      lastLocation.startsWith('/order') &&
      // !location.pathname.startsWith('/order') &&
      (Object.keys(orderUpdates || {}).length || Object.keys(packagesUpdates || {}).length)
    ) {
      // console.log('loc changed with extra', lastLocation, location, orderUpdates, packagesUpdates)
      if (confirm('Máte neuložené změny. Přejete si je uložit?')) {
        // console.log('update from router', { packagesUpdates, orderUpdates })
        if (orderUpdates && Object.keys(orderUpdates).length > 0) {
          updateOrder(orderUpdates, setOrderUpdates, currentOrder!, core, getOrder)
        }
        if (packagesUpdates && Object.keys(packagesUpdates).length > 0) {
          updatePackages(packagesUpdates, setPackageUpdates, core, getOrder)
        }
      } else {
        setTimeout(() => {
          setOrderUpdates(undefined)
          setPackageUpdates(undefined)
        }, 1000)
        // setTimeout(() => patchState({ orderUpdates: undefined, packagesUpdates: undefined }),500)
        //nope todo routing able to do things like this..
        // return (
        //   <Redirect
        //     to={{
        //       pathname: lastLocation,
        //       state: { from: location },
        //     }}
        //   />
        // )
      }
    }
    lastLocation = location.pathname
  }
  return (
    <Main
      hasMainHeader={!location.pathname.includes('/order')}
      isSettingsView={location.pathname.includes('/settings')}>
      <RootRedirect />
      {/*<TransitionGroup>*/}
      {/*  <CSSTransition timeout={300} classNames="reroute" key={location?.key}>*/}
      <Switch location={location}>
        <Route exact path="/">
          <PublicHome />
        </Route>
        <Route path="/login">
          <Login core={core} />
        </Route>
        <AuthenticatedRoute exact path="/dashboard/history">
          <OrdersHistory />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/dashboard/:processStatus">
          <Dashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/settings">
          <Settings />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/dashboard">
          <Dashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/order/:orderId">
          <OrderDetail />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/order">
          <Dashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/search/:searchString">
          <Dashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/search">
          <Dashboard />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/groups">
          <Groups />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/groups/:groupId">
          <GroupClearanceItems />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/dispatch/create">
          <Dispatch />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path="/dispatch">
          <DispatchList />
        </AuthenticatedRoute>
        <Route path="*">
          <NotFound404 />
        </Route>
      </Switch>
      {/*  </CSSTransition>*/}
      {/*</TransitionGroup>*/}
    </Main>
  )
}

const AuthenticatedRoute: React.FC<any> = ({ children, ...props }) => {
  const { isLoggedIn } = useLogin()
  return (
    <Route
      {...props}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const RootRedirect = () => {
  const { isLoggedIn } = useLogin()
  const location: any = useLocation()
  if (location.pathname === '/') {
    return (
      <Redirect
        to={{
          pathname: isLoggedIn ? '/dashboard' : '/login',
          state: { from: location },
        }}
      />
    )
  }
  return null
}

const reloadTokensAndLists = async (
  core: DepotoCore,
  isLoggedIn: boolean,
  setCarriers: Fn,
  setCheckouts: Fn,
  setCountries: Fn,
  setCompany: Fn,
  setChildCompanies: Fn,
  setCurrencies: Fn,
  setDepots: Fn,
  setPayments: Fn,
  setTags: Fn,
  setIsStrictDepotFilter: Fn,
  childCompanyId?: number,
): Promise<void> => {
  const filters: any = {}
  if (childCompanyId && childCompanyId > 0) {
    filters.companies = [childCompanyId]
  }
  core.services.oauth.onLogoutEmitter
    .subscribe()
    .then(() => (window.location.pathname !== '/login' ? (window.location.pathname = '/login') : null))
  core.services.oauth.session.clientId = '26_24166lmf1t0k00gogck8gsgc88og0848g88owwoc4w0404w4og' // TODO!!!!!
  core.services.oauth.session.clientSecret = '1ccidhta12pwk84ocow0wg00osksgk8oow8kksssc8gsc4og0k' // TODO!!!! core fix!!!!!!!!
  if (window.depotoEnv === 'custom' && window.localStorage.getItem('depoto_server_custom_url')?.length) {
    core.services.oauth.session.clientCustomUri = `${window.localStorage.getItem('depoto_server_custom_url') || ''}`
    core.services.oauth.session.clientType = 'custom'
  }
  if (isLoggedIn) {
    // const { token } = core.services.oauth?.session
    // console.log(token)
    const doesTokenExists = core.services.oauth?.session?.token?.accessToken?.length > 0
    const user = doesTokenExists ? await core.services.user.getMyProfile(Schema.user.detail) : null
    if (!user) {
      return core.services.oauth.logout()
    }
    // await initNotifications()
    const [company, childCompanies] = [user.company, user?.company?.children || []]
    const [carriers, checkouts, countries, currencies, depots, payments, tags] = await Promise.all([
      core.services.companyCarrier
        .getList({ filters }, Schema.companyCarrier.list) // todo on parent company there is nothing even with filter... (TN knows and will do smth about it)
        .then(r => [
          { id: undefined, name: ' ' },
          ...r.items
            .map((c: CompanyCarrier) => c.carrier)
            .sort((a: Carrier, b: Carrier) => (a.position === b.position ? 0 : a.position > b.position ? 1 : -1)),
        ]),
      // core.services.carrier.getList().then(r => [{id: undefined, name: ' '}, ...r.items.sort((a: Carrier, b: Carrier) => a.position === b.position ? 0 : a.position > b.position ? 1 : -1)] || []),
      core.services.checkout.getList({ filters }, Schema.basic.list).then(r => r.items || []),
      GeoConfig.countries,
      core.services.payment.getCurrencies(undefined, Schema.currency.detail).then(r => r.items || []),
      core.services.depot.getList({ filters }, Schema.basic.list).then(r => r.items || []),
      core.services.payment.getList({ filters }, Schema.payment.list).then(r => r.items || []),
      core.services.tag
        .getList({ filters }, { ...Schema.basic.list, type: null, externalId: null })
        .then(r => r.items || []),
    ])
    const uniqueCarrierIds = Array.from(new Set(carriers.map(c => c.id)))
    const uniqueCarriers = []
    for (const cId of uniqueCarrierIds) {
      uniqueCarriers.push(carriers.find(c => c.id === cId))
    }
    const isStrictDepotFilter = window.localStorage.getItem('isStrictDepotFilter') === 'true'
    setCarriers(uniqueCarriers)
    setCheckouts(checkouts)
    setCountries(countries)
    setCompany(company)
    setChildCompanies(childCompanies)
    setCurrencies(currencies)
    setDepots(depots)
    setPayments(payments)
    setTags(tags)
    setIsStrictDepotFilter(isStrictDepotFilter)
  } else {
    core.services.oauth.logout()
  }
}

export const setSearchParams = (params: { [key: string]: string | number } | any) => {
  let queryStr = '?'
  Object.keys(params).forEach((k, i) => {
    // console.warn(k, params[k], i)
    if (i > 0) {
      queryStr += '&'
    }
    if (params[k] !== undefined && params[k] !== 'all') {
      queryStr += `${k}=${params[k]}`
    }
  })
  // console.warn(params, queryStr)
  const oldLocation = `${window.location.origin}${window.location.pathname}`
  history.pushState(null, '', `${oldLocation}${queryStr === '?' ? '' : queryStr}`)
}

export const useSearchParams = () => {
  const queryString = window.location.search ? window.location.search : ''
  const spArr = queryString.replace('?', '').split('&')
  const searchParams: any = {}
  for (let p of spArr) {
    const par = p.split('=')
    searchParams[par[0]] = par[1]
  }
  return searchParams
}
