import React from 'react'
import tinycolor from 'tinycolor2'
import { Tag } from 'depoto-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TagComponent({ tag }: { tag: Tag }) {
  const isWhite = tinycolor(tag.color).getLuminance() > 0.95

  return (
    <span
      className={'label-blue'}
      style={{
        color: tinycolor(tag.color || '#0058ff').getBrightness() > 170 ? '#000' : '#fff',
        backgroundColor: tag.color || '#0058ff',
        borderWidth: 1,
        borderColor: isWhite ? '#000' : '#fff',
      }}
    >
      <FontAwesomeIcon icon={['far', 'tag']} size="lg" className={'mr-2'} />
      <span>{tag.name}</span>
    </span>
  )
}

export { TagComponent as Tag }
