import { Table as TanstackTable } from '@tanstack/table-core'
import React from 'react'
import { flexRender, Row } from '@tanstack/react-table'

export function Table<T>({
  table,
  className,
  getClassnameForRow,
}: {
  table: TanstackTable<T>
  className?: string
  getClassnameForRow?: (row: Row<T>) => string
}) {
  const { getHeaderGroups } = table

  const columnsTemplate = {
    gridTemplateColumns: table
      .getFlatHeaders()
      .map(header => header.getSize() || '1fr')
      .map(size => (typeof size === 'number' ? `${size}px` : size))
      .join(' '),
  }

  return (
    <div>
      <div className={`grid leading-10 border-b px-6 ${className}`} style={columnsTemplate}>
        {table.getFlatHeaders().map(header => (
          <div
            className="font-medium text-shadow-grey px-1"
            key={header.id}
            style={{ width: header.getSize() || 'unset' }}
          >
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        ))}
      </div>
      <div className="overflow-x-auto">
        {table.getRowModel().rows.map((row, i) => (
          <div
            className={`grid leading-10 border-b px-6 py-2 ${i % 2 ? 'bg-gray-100' : ''} ${getClassnameForRow?.(row)}`}
            style={columnsTemplate}
          >
            {row.getVisibleCells().map(cell => (
              <div key={cell.id} className="line-clamp-2 leading-5 flex items-center px-1">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
