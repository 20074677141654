import React, { Fragment } from 'react'
import { Fn } from 'depoto-core'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  title?: string
  selectedId?: number | string
  options: any[]
  hasAllOption?: boolean
  isDownDirection?: boolean
  isAnchorRight?: boolean
  onChange: Fn
}
export const Dropdown: React.FC<Props> = ({
  title,
  selectedId,
  options = [],
  isDownDirection,
  isAnchorRight = true,
  hasAllOption = true,
  onChange,
}) => {
  const selectedOption = selectedId ? options?.filter(o => o.id === selectedId)[0] : null
  if (!title) {
    title = selectedOption ? selectedOption.name : 'Vše'
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="inline-flex justify-center w-full py-2 gap-2">
        {title}
        <FontAwesomeIcon icon={['far', 'chevron-down']} size="lg" className="self-center text-primary-blue" />
      </MenuButton>
      <MenuItems
        style={{ width: 370, maxHeight: 550, bottom: isDownDirection ? '2rem' : '' }}
        className={`absolute ${
          isAnchorRight ? 'right' : 'left'
        }-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 overflow-y-auto focus:outline-none z-10 w-80`}
      >
        {hasAllOption && (
          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => onChange(undefined)}
                className={'group flex rounded-md items-center w-full px-2 py-2'}
                style={{ fontWeight: focus || selectedId === undefined ? 'bold' : 'normal' }}
              >
                {(selectedId === undefined || selectedId === 'all') && (
                  <FontAwesomeIcon icon={['far', 'chevron-right']} size="lg" className={'mr-2'} />
                )}
                Vše
              </button>
            )}
          </MenuItem>
        )}
        {options.map((o, i) => (
          <MenuItem key={`${o.id}_${o.name}_${i}`}>
            {({ focus }) => (
              <button
                onClick={() => onChange(o.id)}
                className={'flex justify-start items-center w-full px-2 py-2 gap-2'}
                style={{
                  backgroundColor: o.color,
                  fontWeight: focus || selectedId === o.id ? 'bold' : 'normal',
                }}
              >
                {selectedId === o.id && <FontAwesomeIcon icon={['far', 'chevron-right']} size="lg" />}
                {o.name}
              </button>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  )
}
