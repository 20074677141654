import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from './Dropdown'
import {
  getHeaderTitle,
  Schema,
  setSearchParams,
  strings,
  translate,
  updateOrder,
  updatePackages,
  useSearchParams,
} from '../lib'
import { useCore, useLogin } from '../hooks'
import { Order } from 'depoto-core'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { Btn } from './Btn'
import { Icon, IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormInput } from './Form/FormInput'
import { faBox, faChevronDown, faEnvelope, faListAlt, faSearch, faUser } from '@fortawesome/pro-light-svg-icons'
import { faDoorClosed, faRightFromBracket } from '@fortawesome/pro-duotone-svg-icons'
import pckg from '../../package.json'

type Props = {
  activeProcessStatus?: string
  isMainHeader: boolean
}
export const Header: React.FC<Props> = ({ isMainHeader, activeProcessStatus }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [openTab, setOpenTab] = useState<number>(1)
  const [searchIcon, setSearchIcon] = useState<IconDefinition>(faSearch)
  const [groupName, setGroupName] = useState<string>('')
  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false)
  const {
    core,
    currentOrder,
    setCurrentOrder,
    ordersHistory,
    groupSortProp,
    setGroupSortProp,
    groupSortDir,
    setGroupSortDir,
    groupFilterName,
    setGroupFilterName,
    itemCount,
    isFetching,
    setIsFetching,
    isStrictDepotFilter,
    searchString,
    setSearchString,
    searchType,
    setSearchType,
    depots,
    carriers,
    checkouts,
    countries,
    childCompanies,
    childCompanyId,
    setSelectedChildCompany,
    selectedDepotId,
    setSelectedDepotId,
    selectedCheckoutId,
    setSelectedCheckoutId,
    selectedCarrierId,
    setSelectedCarrierId,
    selectedCountry,
    setSelectedCountry,
    orderUpdates,
    setOrderUpdates,
    packagesUpdates,
    setPackageUpdates,
    selectedDate,
    setSelectedDate,
    tags,
    selectedTagId,
    setSelectedTagId,
  } = useCore()
  const history = useHistory()
  const { pathname } = useLocation()
  const [splitLocation, setSplitLocation] = useState<string[]>(pathname.split('/'))
  const [headerTitle, setHeaderTitle] = useState<string>(getHeaderTitle({ splitLocation, currentOrder }))
  const [datepickerOpen, setDatepickerOpen] = useState<boolean>(false)
  const datePickerRef = React.createRef<DatePicker>()

  const { handleLogout } = useLogin()
  // const { url } = useRouteMatch()
  const pathParts = window.location.pathname.split('/')
  let url = '/' // only used on OrderDetail
  if (pathParts.length > 2 && pathParts[1] === 'order') {
    url = `/order/${pathParts[2]}`
  }
  const { searchString: urlSearchString }: any = useParams()
  const { carrierId, checkoutId, countryId, depotId }: any = useSearchParams()
  const searchTypes = [
    { id: 'reservationNumber', name: 'Číslo rezervace' },
    { id: 'billNumber', name: 'Číslo objednávky' },
    { id: 'package', name: 'Balík' },
    { id: 'box', name: 'Přepravka' },
    { id: 'customer', name: 'Zákazník' },
    { id: 'fulltext', name: 'Fulltext' },
  ]

  const isOnGroupsView = window.location.href?.includes('/groups')

  useEffect(() => {
    // document.addEventListener('updateOrderDetails', e => {
    //   console.log('updateOrderDetails event')
    //   onSaveBtnClick()
    // })
    if (window.location.pathname.includes('dashboard')) {
      setTimeout(() => {
        setSelectedDepotId(depotId && depotId !== 'all' ? Number(depotId) : undefined)
        setSelectedCheckoutId(checkoutId && checkoutId !== 'all' ? Number(checkoutId) : undefined)
        setSelectedCarrierId(carrierId && carrierId !== 'all' ? carrierId : undefined)
        setSelectedCountry(countryId && countryId !== 'all' ? countryId : undefined)
      }, 200)
    }
  }, [])
  // todo batch sending packs -. errors thrown in console - to alert
  const filteredTags = tags.filter(filter => {
    if (activeProcessStatus === 'groups') {
      return filter.type === 'order_group'
    } else {
      return filter.type === 'order'
    }
  })
  useEffect(() => {
    if (activeProcessStatus === 'groups') {
      const tag = tags.find(t => t.externalId === 'same')
      tag && setSelectedTagId(tag.id)
    }
  }, [activeProcessStatus])

  const hasFragiles = currentOrder?.items?.filter(oi => oi.product?.isFragile).length! > 0
  const hasOversized = currentOrder?.items?.filter(oi => oi.product?.isOversize).length! > 0

  useEffect(() => {
    const splitLoc = pathname.split('/')
    const orderStatusAndNumber =
      currentOrder && currentOrder.processStatus?.name
        ? `${currentOrder.processStatus?.name} | ${currentOrder.reservationNumber}`
        : ''
    setSplitLocation(splitLoc)
    const hTitle =
      splitLoc[1] === 'dashboard'
        ? translate(getHeaderTitle({ splitLocation: splitLoc, currentOrder }))
        : orderStatusAndNumber
    setHeaderTitle(hTitle)
  }, [pathname, currentOrder])

  useEffect(() => {
    switch (searchType) {
      case 'reservationNumber':
        setSearchIcon(faListAlt)
        break
      case 'billNumber':
        setSearchIcon(faBox)
        break
      case 'package':
        setSearchIcon(faEnvelope)
        break
      case 'box':
        setSearchIcon(faBox)
        break
      case 'customer':
        setSearchIcon(faUser)
        break
      case 'fulltext':
        setSearchIcon(faSearch)
        break
    }
  }, [searchType])

  const doSearch = (
    nextFilters: {
      carrierId?: string
      checkoutId?: number
      countryId?: string
      depotId?: number
      processStatusChange?: string
    } = {},
  ) => {
    if (searchString && searchString.length > 0) {
      history.push(`/search/${searchString}`)
    } else if (nextFilters && Object.keys(nextFilters).length > 0) {
      if (nextFilters.carrierId) {
        setSelectedCarrierId(nextFilters.carrierId)
      }
      if (nextFilters.checkoutId) {
        setSelectedCheckoutId(nextFilters.checkoutId)
      }
      if (nextFilters.countryId) {
        setSelectedCountry(nextFilters.countryId)
      }
      if (nextFilters.depotId) {
        setSelectedDepotId(nextFilters.depotId)
      }
      history.push(`/dashboard/${activeProcessStatus || 'all'}`)
      setSearchParams(nextFilters)
    } else {
      history.push(activeProcessStatus ? `/dashboard/${activeProcessStatus}` : '/dashboard')
    }
    // console.log('header dosearch', {nextFilters, searchString})
  }

  if (window.location.pathname.includes('order') && window.location.pathname.length > 6) {
    let nextOpenTab = 1
    switch (window.location.pathname.replace(url, '')) {
      case '/items':
      default:
        nextOpenTab = 1
        break
      case '/shipping':
        nextOpenTab = 2
        break
      case '/notes':
        nextOpenTab = 3
        break
      case '/states':
        nextOpenTab = 4
        break
      case '/files':
        nextOpenTab = 5
        break
    }
    if (openTab !== nextOpenTab) {
      setOpenTab(nextOpenTab)
    }
  }

  const onSaveBtnClick = async () => {
    console.log('update from header', { packagesUpdates, orderUpdates })
    if (orderUpdates && Object.keys(orderUpdates).length > 0) {
      await updateOrder(orderUpdates, setOrderUpdates, currentOrder!, core, getOrder)
    }
    if (packagesUpdates && Object.keys(packagesUpdates).length > 0) {
      await updatePackages(packagesUpdates, setPackageUpdates, core, getOrder)
    }
  }

  const getOrder = async () => {
    setIsFetching(true)
    const res = await core?.services.order.getById(currentOrder?.id || 0, Schema.order.detail)
    if (res && res.id > 0) {
      const o = new Order(res)
      setIsFetching(false)
      setCurrentOrder(o)
    } else {
      setIsFetching(false)
    }
  }

  const navigateToOrder = (orderId: number) => {
    history.push(`/order/${orderId}`)
  }

  const clearFilters = () => {
    setSelectedChildCompany(undefined)
    setSelectedDepotId(undefined)
    setSelectedCheckoutId(undefined)
    setSelectedCarrierId(undefined)
    setSelectedCountry(undefined)
    setSelectedTagId(undefined)
    setSelectedDate(undefined)
    setSearchString('')
    setSearchType('reservationNumber')
  }

  return (
    <nav className="bg-white border-b border-cool-grey flex justify-between items-center min-h-16 px-3">
      {/*<div className="grid grid-cols-2 place-content-center divide-x divide-cool-grey">*/}
      <div className="flex flex-row divide-x divide-cool-grey">
        {!isMainHeader && (
          <Btn
            // onClick={() => history.push(`/dashboard/${activeProcessStatus || ''}`)} // was there any reason to do this?
            onClick={() => history.goBack()}
            isDisabled={isFetching}
            isLoading={isFetching}
            className={'btn-outline btn-sm btn-header border-light-grey mr-6'}
            children={'Zpět'}
            title={'Zpět'}
            icon={'arrow-left'}
          />
        )}
        <div className="text-center font-bold text-xl px-6">{headerTitle || strings.appName}</div>
        {!!currentOrder &&
        (window.location.pathname?.includes('order') || window.location.pathname?.includes('history')) ? null : (
          <div className="text-center font-bold text-dark-grey text-xl px-6">
            Celkem: {isFetching ? <span className="animate-pulse">...</span> : itemCount || 0}
          </div>
        )}
        {window.location.pathname?.includes('history') ? (
          <div className="text-center font-bold text-dark-grey text-xl px-6">Historie</div>
        ) : null}
      </div>

      {/* User profile dropdown */}
      <div className="relative flex items-center">
        <button
          className="flex items-center text-gray-600 focus:outline-none gap-3"
          onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}>
          <FontAwesomeIcon icon={faUser} className="w-5 h-5 p-2 rounded-full bg-cool-grey" />
          <span className="text-md">{core.services.oauth.session.email}</span>
          <FontAwesomeIcon icon={faChevronDown} className="text-md" />
        </button>

        <Transition
          show={profileDropdownOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10 top-full border border-gray-200">
            <div className="flex gap-2">
              <button
                onClick={() => handleLogout(() => history.push('/login'))}
                className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex-1">
                <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" />
                Odhlásit se
              </button>
            </div>
            <div className="border-t border-gray-100 mt-1 pt-1">
              <div className="px-4 py-2 text-xs text-gray-500">Verze aplikace: {pckg.version}</div>
            </div>
          </div>
        </Transition>
      </div>
    </nav>
  )
}
