import { useState, useEffect } from 'react'
import { useCore } from '../../hooks'
import { printTicket, Schema } from '..'
import { Package } from 'depoto-core'
import { GroupRecord } from '../../store/core/reducer'

export interface BatchGroupStatus {
  isVisible: boolean
  progress: number
  all: number
  currentOperation: string
}

export interface BatchGroupError {
  orderId: number
  error: string
}

export interface GroupLogEntry {
  orderId: number
  reservationNumber: number | string
  record: string
}

export const useGroups = (groupId?: string | number) => {
  const [batchGroupStatus, setBatchGroupStatus] = useState<BatchGroupStatus>({
    isVisible: false,
    progress: 0,
    all: 0,
    currentOperation: '',
  })
  const [batchGroupErrors, setBatchGroupErrors] = useState<BatchGroupError[]>([])
  const { core, isFetching, setIsFetching, groupHistory, setGroupHistory } = useCore()

  // Get the current group record
  const groupRecord: GroupRecord = groupHistory?.[groupId] || {
    log: [],
    errors: [],
    date: +new Date(),
    isVisible: true,
  }

  // Update batch group status
  const updateBatchGroupStatus = (status?: BatchGroupStatus, errors?: BatchGroupError[]) => {
    if (!window.batchGroupStatus) {
      window.batchGroupStatus = {}
    }
    window.batchGroupStatus[groupId] = {
      // keep things till reload
      status: status ?? batchGroupStatus,
      errors: errors ?? batchGroupErrors,
    }
    setBatchGroupStatus(status ?? batchGroupStatus)
    setBatchGroupErrors(errors ?? batchGroupErrors)
  }

  // Toggle group log visibility
  const toggleGroupLogVisibility = () => {
    const currentGroupRecord: GroupRecord = groupHistory?.[groupId] || {
      log: [],
      errors: [],
      date: +new Date(),
      isVisible: false,
    }
    const nextGroupHistory = { ...groupHistory }
    currentGroupRecord.isVisible = !currentGroupRecord.isVisible
    nextGroupHistory[groupId] = currentGroupRecord
    setGroupHistory(nextGroupHistory)
  }

  // Resolve (process) the group
  const resolveGroup = async (orders: any[]) => {
    setIsFetching(true)
    updateBatchGroupStatus({ isVisible: true, progress: 0, all: 0, currentOperation: 'init, prepare orders' }, [])
    const groupRecord: GroupRecord = groupHistory?.[groupId] || {
      log: [],
      errors: [],
      date: +new Date(),
      isVisible: true,
    }
    console.log({ groupRecord })
    const errors = []

    let allOrdersInGroup = [...orders]
    // Get all orders in the group if needed
    const group = await core?.services.order.getOrderGroupById(groupId, Schema.orderGroup.forBatchUpdate)
    allOrdersInGroup = group?.orders || []

    updateBatchGroupStatus({
      isVisible: true,
      progress: 0,
      all: allOrdersInGroup.length,
      currentOperation: `orders prepared ${allOrdersInGroup.length}`,
    })

    let x = 0
    for (let o of allOrdersInGroup) {
      let hasError = false
      try {
        o.items = o.items.map(oit => ({ ...oit, ...{ picked: true, packed: true } }))
        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: `order ${o.id}: setPicked`,
        })
        groupRecord.log.push({
          orderId: o.id,
          reservationNumber: o.reservationNumber,
          record: `order ${o.reservationNumber}: setPicked`,
        })
        const resPick = await core?.services.order.updateOrderPickedPacked(o)
        console.warn({ resPick })
        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: `order ${o.id}: setPicked OK`,
        })
        groupRecord.log.push({
          orderId: o.id,
          reservationNumber: o.reservationNumber,
          record: `order ${o.reservationNumber}: setPicked OK`,
        })
        let resCreatePack = null
        if (o.packages.length > 0 && o.packages[0].id > 0) {
          resCreatePack = o.packages[0]
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: use Package ${resCreatePack?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: use Package ${resCreatePack?.id}`,
          })
        } else {
          resCreatePack = await core?.services.pack.create(
            new Package({
              order: o,
              carrier: o.carrier,
              items: o.items,
            }),
          )
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: createPackage ${resCreatePack?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: createPackage ${resCreatePack?.id}`,
          })
        }
        let resSendPackage = null
        console.log({ resCreatePack })
        if (!!resCreatePack?.sent) {
          resSendPackage = resCreatePack
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: use sent Package ${resSendPackage?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: use sent Package ${resSendPackage?.id}`,
          })
        } else {
          resSendPackage = await core?.services.pack.send(resCreatePack!)
          hasError = !resSendPackage
          console.warn({ resSendPackage, hasError })
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id} (reservationNumber: ${o.reservationNumber}): sendPackage ${resSendPackage?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: sendPackage ${resSendPackage?.id}`,
          })
        }
        if (hasError) {
          console.log('hasError, end here')
          setIsFetching(false)
          groupRecord.errors.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: error sending Package ${resCreatePack?.id}`,
          })
          const nextGroupHistory = { ...groupHistory }
          nextGroupHistory[groupId] = groupRecord
          setGroupHistory(nextGroupHistory)
          return
        }
        const pack = await core?.services.pack.getById(resSendPackage?.id, {
          id: null,
          ticketUrl: null,
          carrier: { id: null },
        })
        console.warn({ pack })
        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: `order ${o.id}: getPackDetails ${pack?.id}`,
        })
        if (window._depoto) {
          const resPrintTicket = await printTicket(pack!)
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: printPack ${pack?.id}`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: printPackage ${pack?.id}`,
          })
        }
        if (o.processStatus?.id === 'packed') {
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: is packed. ORDER DONE`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: is packed already. ORDER DONE!`,
          })
        } else {
          const resStatusUpdate = await core?.services.order.updateProcessStatus(o, 'packed', '')
          console.warn({ resStatusUpdate })
          updateBatchGroupStatus({
            isVisible: true,
            progress: x,
            all: allOrdersInGroup.length,
            currentOperation: `order ${o.id}: updateStatus to packed. ORDER DONE`,
          })
          groupRecord.log.push({
            orderId: o.id,
            reservationNumber: o.reservationNumber,
            record: `order ${o.reservationNumber}: updateStatus to packed. ORDER DONE!`,
          })
        }
      } catch (e: unknown) {
        // First, ensure e is not null/undefined
        if (!e) return

        // Create a type guard for error-like objects
        const isErrorLike = (err: unknown): err is { message?: string } => {
          return typeof err === 'object' && err !== null
        }

        // Type guard check
        if (!isErrorLike(e)) return

        console.warn(e)

        updateBatchGroupStatus({
          isVisible: true,
          progress: x,
          all: allOrdersInGroup.length,
          currentOperation: e.message ? `order ${o.id}: ERROR: ${e.message ?? JSON.stringify(e)}` : '',
        })
        errors.push({
          orderId: o.id,
          error: `[reservationNumber: ${o.reservationNumber}]: ${e.message ?? JSON.stringify(e)}`,
        })
        groupRecord.errors.push({
          orderId: o.id,
          reservationNumber: o.reservationNumber,
          record: `order ${o.reservationNumber}: ERROR: ${e.message ?? JSON.stringify(e)}`,
        })
      }
      x++
    }
    updateBatchGroupStatus(
      {
        isVisible: true,
        progress: x,
        all: allOrdersInGroup.length,
        currentOperation: `Všechny objednávky ve skupině byly zpracovány.`,
      },
      errors,
    )
    groupRecord.log.push({
      orderId: 0,
      reservationNumber: 0,
      record: `GROUP DONE!`,
    })
    const nextGroupHistory = { ...groupHistory }
    nextGroupHistory[groupId] = groupRecord
    setGroupHistory(nextGroupHistory)
    setIsFetching(false)
  }

  // Load saved batch group status on mount
  useEffect(() => {
    if (groupId && window.batchGroupStatus && !!window.batchGroupStatus[groupId]?.status) {
      setBatchGroupStatus(window.batchGroupStatus[groupId].status)
      setBatchGroupErrors(window.batchGroupStatus[groupId].errors)
    }
  }, [groupId])

  // Show or hide the batch group status
  const toggleBatchGroupStatus = (visible: boolean) => {
    setBatchGroupStatus({ ...batchGroupStatus, isVisible: visible })
  }

  return {
    batchGroupStatus,
    batchGroupErrors,
    groupRecord,
    resolveGroup,
    toggleGroupLogVisibility,
    updateBatchGroupStatus,
    toggleBatchGroupStatus,
    isFetching,
  }
}

// Add this to make TypeScript recognize the batchGroupStatus property on window
declare global {
  interface Window {
    _depoto: boolean
    batchGroupStatus: {
      [key: string]: {
        status: BatchGroupStatus
        errors: BatchGroupError[]
      }
    }
  }
}
