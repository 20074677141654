import React, { useEffect, useState } from 'react'
import { Fn, OrderItem, Package } from 'depoto-core'
import { PackageDetail } from './PackageDetail'
import { Btn } from './Btn'
import { useCore } from '../hooks'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { OrderItems } from '../views/OrderDetails/OrderItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsUpDownLeftRight, faRotateRight, faTrash, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useGQL } from '../lib/GQLContext'
import { toast } from 'react-toastify'
import { useOrderDetailContext } from '../views/OrderDetails/OrderDetailContext'
import { compareObjectPrimitives } from '../lib/Util'
import { toastPromise, handlePromiseAllToast } from '../lib/toastHelpers'
import { ToastErrorRenderer } from './ToastErrorRenderer'

type Props = {
  orderId: number
}

export interface FromPackage {
  packageId: number
  items: number[]
  code: string
  carrier: string
  tariff: number
  weight: number
  dimensionX: number
  dimensionY: number
  dimensionZ: number
}

export interface FromData {
  packages: {
    packageId: number
    items: number[]
  }[]
}

type PackageFormData = {
  [packageId: number]: Omit<FromPackage, 'packageId' | 'items' | 'code' | 'carrier'>
}

export const OrderItemsInPackages: React.FC<Props> = ({ orderId }) => {
  const [movingOrderItemId, setMovingOrderItemId] = useState<number | null>(null)
  const [packageForms, setPackageForms] = useState<PackageFormData>({})
  const [newPackageCounter, setNewPackageCounter] = useState(0)

  const queryClient = useQueryClient()
  const { order: currentOrder } = useOrderDetailContext()
  const packages: Package[] = currentOrder.packages || []

  const gql = useGQL()

  const form = useForm<FromData>({
    defaultValues: {
      packages: packages.map(p => ({
        packageId: p?.id,
        items: p?.items.map(oi => oi.id),
      })),
    },
  })

  const { control, setValue, handleSubmit, watch, reset } = form

  useEffect(() => {
    reset({
      packages: packages.map(p => ({
        packageId: p?.id,
        items: p?.items.map(oi => oi?.id),
      })),
    })

    // Reset package forms
    const newPackageForms: PackageFormData = {}
    packages.forEach(p => {
      newPackageForms[p.id] = {
        tariff: p?.tariff?.id || null,
        weight: p?.weight || null,
        dimensionX: p?.dimensionX || null,
        dimensionY: p?.dimensionY || null,
        dimensionZ: p?.dimensionZ || null,
      }
    })
    setPackageForms(newPackageForms)
  }, [packages])

  const packagesForm = watch('packages')

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'packages',
  })

  const handlePackageChange = (
    packageId: number,
    data: Omit<FromPackage, 'packageId' | 'items' | 'code' | 'carrier'>,
  ) => {
    setPackageForms(prev => ({
      ...prev,
      [packageId]: data,
    }))
  }

  const {
    mutate: savePackages,
    isLoading: isSaving,
    isError,
  } = useMutation({
    mutationFn: async (submittedForm: FromData) => {
      const newPackages = submittedForm.packages.filter(p => p.packageId < 0)
      const removedPackages = packages.filter(p => !submittedForm.packages.find(fp => fp.packageId === p.id))
      const packagesToUpdate = submittedForm.packages.filter(fp => {
        if (fp.packageId < 0) {
          return false
        }
        const serverPackage = packages.find(p => p.id === fp.packageId)
        // Check if order items in package is different form server state
        const areItemsChanged = fp.items.some(el => !serverPackage?.items.map(oi => oi.id).includes(el))
        const packageForm = packageForms[fp.packageId]
        const arePrimitivesChanged =
          Object.keys(
            compareObjectPrimitives({ ...fp, ...packageForm }, serverPackage, [
              'tariff',
              'weight',
              'dimensionX',
              'dimensionY',
              'dimensionZ',
            ]),
          ).length > 0
        return areItemsChanged || arePrimitivesChanged
      })

      const promises = []

      // 1. Save all new packages
      if (newPackages.length > 0) {
        promises.push(
          toastPromise(
            Promise.all(
              newPackages.map(p => {
                const packageForm = packageForms[p.packageId]
                return gql('mutation')({
                  createPackage: [
                    {
                      order: currentOrder.id,
                      items: p.items,
                      carrier: currentOrder?.carrier?.id || null,
                      ...(packageForm || {}),
                    },
                    { data: { id: true }, errors: true },
                  ],
                })
              }),
            ),
            {
              pending: 'Vytvářím balíky...',
              success: 'Balíky vytvořeny',
              error: 'Chyba při vytváření balíků',
            },
          ),
        )
      }

      // 2. Remove all removed packages
      if (removedPackages.length > 0) {
        promises.push(
          toastPromise(
            handlePromiseAllToast(
              removedPackages.map(p => {
                return gql('mutation')({
                  deletePackage: [{ id: p.id }, { errors: true }],
                })
              }),
            ),
            {
              pending: 'Mažu balíky...',
              success: 'Balíky smazány',
              error: 'Chyba při mazání balíků',
            },
          ),
        )
      }

      // 3. Update all packages that have changed
      if (packagesToUpdate.length > 0) {
        promises.push(
          toastPromise(
            Promise.all(
              packagesToUpdate.map(fp => {
                const serverPackage = packages.find(p => p.id === fp.packageId)
                const packageForm = packageForms[fp.packageId]
                return gql('mutation')({
                  updatePackage: [
                    {
                      id: fp.packageId,
                      items: fp.items,
                      ...(packageForm?.tariff &&
                      serverPackage?.tariff?.id &&
                      packageForm.tariff !== serverPackage?.tariff?.id
                        ? { tariff: packageForm.tariff }
                        : {}),
                      ...compareObjectPrimitives({ ...fp, ...packageForm }, serverPackage, [
                        'weight',
                        'dimensionX',
                        'dimensionY',
                        'dimensionZ',
                      ]),
                    },
                    { data: { id: true }, errors: true },
                  ],
                })
              }),
            ),
            {
              pending: 'Aktualizuji balíky...',
              success: 'Balíky aktualizovány',
              error: 'Chyba při aktualizaci balíků',
            },
          ),
        )
      }

      return Promise.all(promises)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['order', orderId] })
    },
    onError: error => {
      console.error(error)
      toast.error('Chyba při zpracování balíků')
    },
  })

  const addPackage = () => {
    const orderItemsInPackages: number[] = packages
      .flatMap(p => p.items.filter(oi => oi.type === 'product'))
      .map(oi => oi.id)
    const items = currentOrder.items.filter(oi => !orderItemsInPackages.includes(oi.id)).map(oi => oi.id)

    const newPackageId = -(newPackageCounter + 1)
    setNewPackageCounter(prev => prev + 1)

    append({
      packageId: newPackageId,
      items: items,
    })

    // Initialize form data for the new package
    setPackageForms(prev => ({
      ...prev,
      [newPackageId]: {
        tariff: currentOrder?.carrier?.tariffs?.[0]?.id || null,
        weight: 0,
        dimensionX: 0,
        dimensionY: 0,
        dimensionZ: 0,
      },
    }))

    savePackages(form.getValues())
  }

  // If no packages, add one
  useEffect(() => {
    if (packages.length === 0 && !isError) {
      addPackage()
    }
  }, [packages])

  const addPackages = async () => {
    const quantity = Number(prompt('Kolik?', '2'))
    if (isNaN(quantity)) {
      alert('Zadejte platné číslo')
      return
    }
    const q = new Array(quantity)
    for (const _ of q) {
      addPackage()
    }
  }

  const resetPackage = async (packId: number) => {
    await toastPromise(
      gql('mutation')({
        resetPackage: [{ id: packId }, { errors: true }],
      }),
      {
        pending: 'Resetování balíku...',
        success: 'Balík byl úspěšně resetován',
        error: 'Chyba při resetování balíku',
      },
    )
    queryClient.invalidateQueries({ queryKey: ['order', orderId] })
  }

  const handleMovePressed = (pack: FromPackage) => () => {
    setMovingOrderItemId(null)
    setValue(
      'packages',
      fields.map(f =>
        f.packageId === pack.packageId
          ? {
              ...f,
              items: [...f.items, movingOrderItemId],
            }
          : {
              ...f,
              items: f.items.filter(el => el !== movingOrderItemId),
            },
      ),
    )
    handleSubmit(d => savePackages(d))()
  }

  const renderActions = (item: OrderItem) => {
    if (movingOrderItemId === item.id) {
      return (
        <div
          className="bg-orange rounded-xl px-3 py-2 text-white flex items-center gap-2 text-md cursor-pointer select-none"
          onClick={() => setMovingOrderItemId(null)}>
          <FontAwesomeIcon icon={faXmark} size="lg" className="text-white" />
          Zrušit
        </div>
      )
    }
    if (packagesForm.length > 1) {
      return (
        <div
          className="border-orange border-[1px] text-orange bg-white rounded-xl px-3 py-2 flex items-center gap-2 text-md cursor-pointer select-none"
          onClick={() => setMovingOrderItemId(item.id)}>
          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} size="lg" className="text-orange" />
          Přesunout
        </div>
      )
    }
  }

  return (
    <div className="p-4">
      <div className="flex flex-col gap-4">
        <FormProvider {...form}>
          {fields.map((pack, i) => {
            const orderItems: OrderItem[] =
              pack.items
                .map(el => currentOrder.items.find(oi => oi.id === el))
                ?.filter(Boolean)
                ?.filter(oi => oi?.type === 'product') || []
            const serverPack = packages.find(p => p.id === pack.packageId)
            console.log(serverPack)
            const isMoving = !orderItems.find(oi => oi.id === movingOrderItemId) ? !!movingOrderItemId : undefined
            return (
              <div key={pack.id} className="shadow-lg rounded-xl">
                <div className={'flex items-center justify-between gap-2 py-3 px-4 bg-blue-100 rounded-t-xl'}>
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={['far', 'box-open']} className={'text-black'} size={'lg'} />
                    <div className={'text-black font-semibold'}>
                      {orderItems.length > 0 ? 'Balík' : 'Prázdný balík'} #{i + 1}{' '}
                      {serverPack?.carrier?.name && `- ${serverPack.carrier.name}`}
                      {serverPack?.code && ` (${serverPack.code})`}
                    </div>
                  </div>
                  <div className="gap-2 flex">
                    {isMoving && (
                      <div className="flex items-center gap-2">
                        <div
                          className="flex flex-1 justify-end"
                          onClick={handleMovePressed({
                            ...pack,
                            code: serverPack?.code || '',
                            carrier: serverPack?.carrier?.id || currentOrder.carrier.id,
                            ...packageForms[pack.packageId],
                          })}>
                          <div className="bg-orange hover:bg-orange-light rounded-lg px-2 py-1 text-white flex items-center gap-2 text-sm cursor-pointer select-none">
                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} size="sm" className="text-white" />
                            Přesunout sem
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex items-center gap-2">
                      <div className="flex flex-1 justify-end" onClick={() => resetPackage(serverPack.id)}>
                        <div className="border-danger border-[1px] text-danger rounded-lg px-2 py-0.5 flex items-center gap-2 text-sm cursor-pointer select-none">
                          <FontAwesomeIcon icon={faRotateRight} size="sm" className="text-danger" />
                          Resetovat
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <div
                        className="flex flex-1 justify-end"
                        onClick={() => {
                          remove(i)
                          savePackages(form.getValues())
                        }}>
                        <div className="bg-danger rounded-lg px-2 py-1 text-white flex items-center gap-2 text-sm cursor-pointer select-none">
                          <FontAwesomeIcon icon={faTrash} size="sm" className="text-white" />
                          Smazat
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PackageDetail
                  savePackages={savePackages as () => void}
                  pack={{
                    ...pack,
                    code: serverPack?.code || '',
                    carrier: serverPack?.carrier?.id || currentOrder?.carrier?.id,
                    ...packageForms[pack.packageId],
                  }}
                  index={i}
                  onMovePressed={handleMovePressed({
                    ...pack,
                    code: serverPack?.code || '',
                    carrier: serverPack?.carrier?.id || currentOrder?.carrier?.id,
                    ...packageForms[pack.packageId],
                  })}
                  onPackageChange={handlePackageChange}
                />
                {orderItems.length > 0 && (
                  <OrderItems
                    items={orderItems}
                    renderActions={renderActions}
                    actionsWidth={packagesForm.length > 1 ? 230 : undefined}
                    movingOrderItemId={movingOrderItemId}
                    packaging={currentOrder.processStatus?.id === 'packing'}
                  />
                )}
              </div>
            )
          })}
        </FormProvider>
      </div>
      <div className={'flex gap-2 py-4'}>
        {currentOrder.processStatus?.id === 'packing' && (
          <Btn
            onClick={() => addPackage()}
            isDisabled={isSaving}
            className={'btn-outline'}
            // icon={'plus'}
            children={'Přidat balík'}
            title={'Přidat balík'}
          />
        )}
        {currentOrder.processStatus?.id === 'packing' && (
          <Btn
            onClick={() => addPackages()}
            isDisabled={isSaving}
            className={'btn-outline'}
            // icon={'plus'}
            children={'Přidat prázdné balíky'}
            title={'Přidat prázdné balíky'}
          />
        )}
        {(form.formState.isDirty || Object.keys(packageForms).length > 0) && (
          <Btn
            onClick={handleSubmit(d => savePackages(d))}
            isDisabled={isSaving}
            isLoading={isSaving}
            className={'btn-outline'}
            children={'Uložit'}
            title={'Uložit'}
          />
        )}
      </div>
    </div>
  )
}
