import { Checkbox } from '../../../components/Checkbox'
import { Order, OrderItem } from 'depoto-core'
import React, { useMemo } from 'react'
import { useGQL } from '../../../lib/GQLContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
export function PackingCheckboxCell({ orderId, orderItemId }: { orderId: number; orderItemId: number }) {
  const gql = useGQL()
  const queryClient = useQueryClient()

  const order = queryClient.getQueryData<Order>(['order', orderId])

  const orderItem = useMemo(() => order?.items?.find(el => el.id === orderItemId), [order, orderItemId])

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      const item = order.items.find(el => el.id === orderItemId)

      return gql('mutation')({
        updateOrderItem: [
          {
            id: orderItemId,
            // order: orderId,
            packed: !item.packed,
          },
          {
            data: {
              id: true,
              packed: true,
            },
            errors: true,
          },
        ],
      })
    },
    onSuccess: data =>
      queryClient.setQueryData(['order', orderId], (old: Order) => ({
        ...old,
        items: old.items.map(oldItem =>
          oldItem.id === orderItemId ? { ...oldItem, ...(data?.updateOrderItem?.data ?? {}) } : oldItem,
        ),
      })),
    onError: error => {
      toast.error(error)
    },
  })

  if (order.processStatus.id !== 'packing') return null

  if (isLoading)
    return (
      <div className={'flex items-center justify-center flex-1 h-8'}>
        <div className="loader-small ease-linear" />
      </div>
    )

  return (
    <div className={'flex items-center justify-center flex-1 h-8'}>
      <Checkbox checked={orderItem?.packed} onChange={() => mutate()} />
    </div>
  )
}
